import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React from "react";
import Utils from "../../Common/Utils";
import { Circle } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
type Props = {
  chartDataArr: { y: number; color: string }[];
  xAxisCategories: string[];
  endDate: string | null | undefined;
  startDate: string | null | undefined;
  resultArr: {
    sourcename: string;
    dailyrowcountsum: number;
    differencebwdate: number;
    lastloaddate: string;
  }[];
};

const HorizontalBarChart: React.FC<Props> = (props: Props) => {
  const min = 1;
  const max =
    props.endDate && props.startDate
      ? Math.abs(
          Utils.getDaysBetweenDates(
            new Date(props.startDate),
            new Date(props.endDate)
          )
        )
      : 0;

  const intervals = max <= 12 ? max : max <= 14 ? 7 : max % 2 === 0 ? 7 : 6;
  const intervalLength = Math.ceil((max - min) / intervals);
  const positions: number[] = [];
  const intervalBoundaries: { intervalStart: number; intervalEnd?: number }[] =
    [];

  for (let i = 0; i <= intervals; i++) {
    const intervalStart = min + i * intervalLength;
    const intervalEnd = Math.min(max, intervalStart + intervalLength - 1);
    if (intervalStart <= max) positions.push(intervalStart);
    intervalStart !== intervalEnd
      ? intervalBoundaries.push({ intervalStart, intervalEnd })
      : intervalBoundaries.push({ intervalStart });
  }
  const chartData: Highcharts.Options = {
    chart: {
      type: "bar",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: props.xAxisCategories,
      tickLength: 0, // Remove x-axis dividers[- when no label is present]
      lineWidth: 0,

      labels: {
        enabled: false,
        x: 12,
        y: -16, // Position the labels above the bars

        style: {
          fontWeight: "bolder",
        },
      },
    },
    yAxis: {
      labels: {
        formatter: function () {
          // Convert day number back to a date string for display
          // Assuming the start date is March 1, 2024, for example purposes

          const startDateValue = moment(props.startDate).toDate();
          const intervalIndex = Math.floor(
            (Number(this.value) - min) / intervalLength
          );
          const { intervalStart, intervalEnd } =
            intervalBoundaries[intervalIndex];

          const currentDate = new Date(
            startDateValue.getTime() +
              Number(intervalStart) * 24 * 60 * 60 * 1000
          );
          let nextDate;
          if (intervalEnd) {
            nextDate = new Date(
              startDateValue.getTime() +
                Number(intervalEnd) * 24 * 60 * 60 * 1000
            );
          }
          return (
            Highcharts.dateFormat("%e %b", Number(currentDate)) +
            (nextDate
              ? "-" + Highcharts.dateFormat("%e %b", Number(nextDate))
              : "")
          );
        },
      },

      tickPositioner: function () {
        return positions;
      },
      title: {
        text: "",
      },
      min: 1, // Assuming day 1 as the start
      // Adjust the max value according to your latest date in the dataset
      max:
        props.endDate && props.startDate
          ? Math.abs(
              Utils.getDaysBetweenDates(
                new Date(props.startDate),
                new Date(props.endDate)
              )
            ) + 1
          : 0,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "#2E2F30",

      formatter: function () {
        return `
        <div  style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
        <span style=color:#BFBFBF;>Source</span>    <span style=padding-left:10px>${
          this.x
        }</span><br>
        <span style=color:#BFBFBF>Load Start Date</span> <span style=padding-left:10px>${
          props.startDate
        }</span><br>
        <span style=color:#BFBFBF>Last Load Date</span> <span style=padding-left:10px>${
          props.resultArr.find((el) => el.sourcename === this.x)?.lastloaddate
        }</span><br>
       </div>
        `;
      },
    },

    plotOptions: {
      bar: {
        borderRadius: 10,
        pointWidth: 10,
      },
    },
    legend: {
      enabled: false,
      verticalAlign: "top",
      align: "left",
    },
    series: [
      {
        type: "bar",
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (
              props.resultArr.length > 0 && this
                ? props.resultArr.find((el) => el.sourcename === this.x)
                : ""
            ) {
              const data = props.resultArr.find(
                (el) => el.sourcename === this.x
              );
              const index = props.resultArr.findIndex(
                (el) => el.sourcename === data?.sourcename
              );

              return data!.differencebwdate === 1
                ? `<span style="color: ${props.chartDataArr[index].color}; font-size:28px">\u25CF</span>` +
                    data!.dailyrowcountsum
                : data!.dailyrowcountsum;
            }
            return props.resultArr.length > 0 && this
              ? props.resultArr.find((el) => el.sourcename === this.x)!
                  .dailyrowcountsum
              : "";
          },
          style: {
            fontSize: "13px",
          },
          y:-12,
        },

        data: props.chartDataArr,
      },
    ],
  };

  return (
    <div>
      <Grid
        container
        display="flex"
        justifyContent="center"
        flexDirection="row"
      >
        {props.resultArr.map((el, ind) => {
          return (
            <React.Fragment key={ind}>
              <Circle
                style={{
                  color:
                    el.dailyrowcountsum === 0
                      ? "#939393"
                      : props.chartDataArr[ind].color,
                  fontSize: "1rem",
                }}
              />
              <Typography fontSize={"0.8rem"} ml={1} mr={2}>
                {el.sourcename}
              </Typography>
            </React.Fragment>
          );
        })}
      </Grid>
      <HighchartsReact highcharts={Highcharts} options={chartData} />
    </div>
  );
};

export default HorizontalBarChart;