import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import AuthProviderService from "../../Services/AuthProviderService";
import UserAuthService from "../../Services/UserAuthService";
import BackdropLoaderCircular from "../../Components/Loader/BackdropLoaderCircular";
import { UserLoginContext } from "../../Contexts";

interface Props extends RouteComponentProps<any> {}
interface State {}
class AuthCallback extends React.Component<Props, State> {
  private shouldRedirect = true;
  static contextType = UserLoginContext;
  context!: React.ContextType<typeof UserLoginContext>;
  componentDidMount() {
    const loginTimeout = setTimeout(() => {
      if (this.shouldRedirect) {
        window.location.href = window.location.origin;
      }
    }, 6000);
    AuthProviderService.handlerPromise(this.processLogin);
    return () => clearTimeout(loginTimeout);
  }

  render() {
    return <BackdropLoaderCircular show={true} />;
  }

  processLogin = () => {
    this.shouldRedirect = false;
    UserAuthService.initUser()
      .then((res) => {
        if (res.isSuccess) {
          const path = AuthProviderService.getCallbackPath();
          this.props.history.push(path);
          this.props.history.go(0);
        } else {
          console.log("test log callback");
          this.props.history.push("/auth/accessdenied");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export default withRouter(AuthCallback);
