import {
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../../App.css";
import Utils from "../../Common/Utils";
import SwitchButton from "../../Components/Button/Switchbutton";
import DatePicker from "../../Components/DatePicker/DatePicker";
import ModalDialog from "../../Components/Modal/ModelDialog";
import MultiAutoComplete from "../../Components/Select/MultiAutoComplete";
import SelectModel from "../../Components/Select/SelectModel";
import PageHeader from "../../Components/Text/PageHeader";
import AuthProviderService from "../../Services/AuthProviderService";
import CustomizedViewService from "../../Services/CustomizedViewService";
import ExpandOpen from "../../Static/ExpandOpen.png";
import AODSChart from "./AODSChart";
import DataLoadingTrendingChart from "./DataLoadingTrendChart/DataLoadingTrendingChart";
import ExceptionReportingChart from "./ExceptionReportingChart/ExceptionReportingChart";
import KickoutMonitoringSources from "./KickoutMonitoringSources";
import KOVolumeChart from "./KOVolumeChart/KOVolumeChart";
import LastLoadDataGraph from "./LastLoadData/LastLoadDataGraph";
import NoOfExceptionsChart from "./NoOfExceptionsChart/NoOfExceptionsChart";
import ReportingDeadlines from "./ReportingDeadlines";
import GradientButton from "../../Components/Button/GradientButton";
import RedButton from "../../Components/Button/RedButton";
//import CustomizedView from "../../Components/Layout/CustomizedView";

const custViewServices = new CustomizedViewService();

interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  showmapDialog: boolean;
  showLastLoadDateDialog: boolean;
  showNoOfExceptionDateDialog: boolean;
  showKODateDialog: boolean;
  userName: string;
  selectedSourceValues: SelectModel[];
  usersData: SelectModel[];
  switchOn: boolean;
  startDatePicker: string | null;
  endDatePicker: string | null;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  sourceDropdownValues: SelectModel[];
  countryDropdownValues: SelectModel[];
  selectedCountries: SelectModel[];
  defaultSourceValues: SelectModel[];
  defaultCountryValues: SelectModel[];
  cardCountries: SelectModel[];
  cardSourceValues: SelectModel[];
  selectedStartDate: string | null;
  selectedendDate: string | null;
  isSearchButtonLoad: boolean;
  isLoading: boolean;
  showCustomisedDialog: boolean;
  isViewLoading: boolean;
}

class Dashboard extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      showmapDialog: false,
      showLastLoadDateDialog: false,
      showNoOfExceptionDateDialog: false,
      showKODateDialog: false,
      userName: "",
      selectedSourceValues: [],
      selectedCountries: [],
      usersData: [],
      switchOn: false,
      startDatePicker: null,
      endDatePicker: null,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      countryDropdownValues: [],
      sourceDropdownValues: [],
      defaultSourceValues: [],
      defaultCountryValues: [],
      cardCountries: [],
      cardSourceValues: [],
      selectedStartDate: null,
      selectedendDate: null,
      isSearchButtonLoad: false,
      isLoading: true,
      showCustomisedDialog: false,
      isViewLoading: false,
    };
  }

  async componentDidMount() {
    const token = await AuthProviderService.getAccessToken();
    const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
    const userName = JSON.parse(decodedToken).name;
    const isSwitchPath = window.location.pathname === "/lockedview";
    this.getAllSourceAndCountryData();
    if (isSwitchPath) {
      this.getAllSourceAndCountryData();
    } else {
      this.setState({ isViewLoading: true }, () => {
        this.getSourceAndCountryData();
      });
    }

    this.setState(
      {
        userName,
        switchOn: isSwitchPath ? true : false,
      },
      () => {
        this.getDefaultData();
      }
    );
  }
  getAllSourceAndCountryData = async () => {
    const response = await custViewServices.getAllCountryAndSourceData();
    const resultObj = this.reduceData(response?.data.result);
    const sourcesData = resultObj.SRC.map((el: string) => {
      return {
        text: el,
        value: el,
      };
    });
    const countriesData = resultObj.CNTRY.map((el: string) => {
      return {
        text: el,
        value: el,
      };
    });
    this.setState({
      sourceDropdownValues: sourcesData,
      countryDropdownValues: countriesData,
    });
  };
  getSourceAndCountryData = async () => {
    const myViewResponse = await custViewServices.getallMyViewData();
    let sourcesData;
    let countriesData;
    if (
      myViewResponse &&
      myViewResponse.data.data.length > 0 &&
      myViewResponse.data.data[0].sources.length > 0
    ) {
      sourcesData = myViewResponse.data.data[0].sources.map((el: any) => {
        return {
          text: el.name,
          value: el.name,
        };
      });
      this.setState({
        sourceDropdownValues: sourcesData,
        isViewLoading: false,
      });
    } else {
      this.setState({ showCustomisedDialog: true, isViewLoading: false });
    }
    if (
      myViewResponse &&
      myViewResponse.data.data.length > 0 &&
      myViewResponse.data.data[0].countries.length > 0
    ) {
      countriesData = myViewResponse.data.data[0].countries.map((el: any) => {
        return {
          text: el.name,
          value: el.name,
        };
      });
      this.setState({
        countryDropdownValues: countriesData,
        isViewLoading: false,
      });
    } else {
      this.setState({ showCustomisedDialog: true, isViewLoading: false });
    }
  };
  reduceData = (data: any) => {
    return data.reduce((acc: any, ele: any) => {
      if (!acc[ele.type]) {
        acc[ele.type] = [ele.col];
      } else {
        acc[ele.type].push(ele.col);
      }
      return acc;
    }, {});
  };

  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (targetId === "sourceName") {
      this.setState({ selectedSourceValues: selected });
    } else if (targetId === "countryName") {
      this.setState({ selectedCountries: selected });
    }
  };
  handleChange = () => {
    this.setState(
      {
        switchOn: false,
      },
      () => {
        const urlPath = window.location.origin + "/lockedview";

        const newTab = window.open(urlPath, "_blank", "noopener,noreferrer");
        if (newTab) newTab.opener = null;
      }
    );
  };
  handleEndDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (this.state.startDatePicker) {
      const startDate = new Date(this.state.startDatePicker);
      const endDate = new Date(value!);
      const currentDate = new Date();

      // Calculate the difference in days between the start and end dates
      const diffInDays =
        Math.abs(Utils.getDaysBetweenDates(endDate, startDate)) + 1;
      if (
        7 <= diffInDays &&
        diffInDays <= 365 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          endDatePicker: moment(value).format("YYYY-MM-DD"),
        });
      } else if (startDate > endDate || endDate > currentDate) {
        this.setState({
          openAlert: true,
          alertMsg:
            "The End Date cannot be greater than current date or less than start date",
          alertType: "error",
          endDatePicker: null,
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be 7 or less than 365",
          alertType: "error",
          endDatePicker: null,
        });
      }
    }
  };
  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        startDatePicker: moment(value).format("YYYY-MM-DD"),
        endDatePicker: null,
      });
    }
  };
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 364);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;
    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };
  getDefaultData = () => {
    this.setState(
      {
        selectedSourceValues: [
          { text: "CONCUR", value: "CONCUR" },
          { text: "CPS", value: "CPS" },
          { text: "SAP", value: "SAP" },
        ],
        selectedCountries: [
          { text: "CO", value: "CO" },
          { text: "MN", value: "MN" },
          { text: "US", value: "US" },
        ],
        startDatePicker: moment(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD"),
        endDatePicker: moment(new Date()).format("YYYY-MM-DD"),
      },
      () => {
        this.handleSearchClick();
      }
    );
  };
  handleSearchClick = () => {
    this.setState(
      {
        defaultSourceValues: this.state.sourceDropdownValues,
        defaultCountryValues: this.state.countryDropdownValues,
        cardCountries: this.state.selectedCountries,
        cardSourceValues: this.state.selectedSourceValues,
        selectedStartDate: this.state.startDatePicker,
        selectedendDate: this.state.endDatePicker,
        isSearchButtonLoad: true,
      },
      () => {
        this.setState({ isSearchButtonLoad: false, isLoading: false });
      }
    );
  };
  onResetClick = () => {
    this.getDefaultData();
  };
  isEmptyCheck = () => {
    const {
      selectedSourceValues,
      selectedCountries,
      startDatePicker,
      endDatePicker,
    } = this.state;
    if (
      startDatePicker &&
      endDatePicker &&
      selectedSourceValues.length > 0 &&
      selectedCountries.length > 0
    ) {
      return false;
    }
    return true;
  };
  handleModalClose = () => {
    this.setState({ showCustomisedDialog: false });
  };
  render() {
    const {
      showmapDialog,
      switchOn,
      selectedSourceValues,
      startDatePicker,
      endDatePicker,
      sourceDropdownValues,
      countryDropdownValues,
      selectedCountries,
      cardCountries,
      cardSourceValues,
      selectedStartDate,
      selectedendDate,
      isSearchButtonLoad,
      isLoading,
      // showCustomisedDialog,
      isViewLoading,
    } = this.state;
    return (
      <Box style={{ minHeight: "100vh" }}>
        {isViewLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            {/* {showCustomisedDialog ? (
              <CustomizedView
                showCustomisedDialog={showCustomisedDialog}
                handleModalClose={this.handleModalClose}
              />
            ) : ( */}
            <>
              {isLoading ? (
                <Box
                  style={{
                    justifyContent: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    margin: "30vh",
                  }}
                >
                  <CircularProgress sx={{ color: "#d52b1e" }} />
                </Box>
              ) : (
                <div className="MapContent">
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    mt={2}
                    mb={2}
                  >
                    <Grid item xs={3.8}>
                      <PageHeader
                        label={`Welcome ${this.state.userName.split(" ")[0]}!`}
                        style={{
                          fontSize: "22px",
                        }}
                      />
                      <div
                        className="DashboardSwitch"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <SwitchButton
                          isDisabled={
                            window.location.pathname === "/lockedview"
                          }
                          label="Switch"
                          checked={switchOn}
                          onChange={this.handleChange}
                        />
                      </div>
                    </Grid>
                    <Grid container columnGap={1} justifyContent={"flex-end"}>
                      <Grid item xs={3}>
                        <InputLabel>Select Sources</InputLabel>
                        <MultiAutoComplete
                          id="sourceName"
                          label="Select Sources"
                          selected={selectedSourceValues}
                          values={sourceDropdownValues}
                          onChange={this.handleMultiSelectChange}
                        />
                      </Grid>
                      <Grid item xs={2.5}>
                        <InputLabel>Select Geography</InputLabel>
                        <MultiAutoComplete
                          id="countryName"
                          label="Select Geography"
                          selected={selectedCountries}
                          values={countryDropdownValues}
                          onChange={this.handleMultiSelectChange}
                        />
                      </Grid>
                      <Grid item xs={2} ml={1}>
                        <InputLabel> Select Start Date</InputLabel>
                        <DatePicker
                          name="startDate"
                          placeHolder="Select Date"
                          maxDate={
                            new Date(
                              new Date().getTime() - 6 * 24 * 60 * 60 * 1000
                            )
                          }
                          value={startDatePicker}
                          onChange={this.handleStartDateChange}
                        />
                      </Grid>
                      <Grid item xs={2} className="EndDate">
                        <InputLabel> Select End Date</InputLabel>
                        <DatePicker
                          name="endDate"
                          placeHolder="Select Date"
                          minDate={new Date(startDatePicker!)}
                          maxDate={this.calculateMaxDate(startDatePicker)}
                          value={endDatePicker}
                          onChange={this.handleEndDateChange}
                        />
                      </Grid>
                      <Grid item xs={0.8} mt={3} ml={0.8}>
                        <GradientButton
                          disabled={this.isEmptyCheck()}
                          isButtonLoad={isSearchButtonLoad}
                          label="Search"
                          onClick={this.handleSearchClick}
                        />
                      </Grid>
                      <Grid item xs={0.8} mt={3} ml={0.8}>
                        <RedButton label="Reset" onClick={this.onResetClick} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container direction="row">
                    <Grid
                      item
                      xs={4}
                      style={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 1px 4px #15223214",
                        borderRadius: "10px",
                        padding: "25px",
                        zIndex: "9",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <PageHeader
                          label="Reporting Deadlines"
                          style={{
                            fontSize: "16px",
                            lineHeight: "1",
                          }}
                        />
                        <Box
                          style={{
                            display: "flex",
                            gap: "1em",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            alt="ExpandOpen"
                            src={ExpandOpen}
                            width={"14px"}
                            height={"14px"}
                            style={{
                              cursor: "pointer",
                              zIndex: "9999",
                            }}
                            onClick={() => {
                              this.setState({ showmapDialog: true });
                            }}
                          />
                        </Box>
                      </Box>
                      <ReportingDeadlines />
                    </Grid>
                    <Grid item xs={3.8} ml={0.5} mr={0.5}>
                      <Grid
                        container
                        flexDirection={"column"}
                        display="flex"
                        alignItems="center"
                        style={{ position: "relative" }}
                      >
                        <LastLoadDataGraph />
                        <KOVolumeChart
                          defaultSources={cardSourceValues}
                          defaultCountries={cardCountries}
                          defaultStartDate={selectedStartDate}
                          defaultEndDate={selectedendDate}
                          sourceDropDownValues={sourceDropdownValues}
                          countryDropDownValues={countryDropdownValues}
                        />
                        <NoOfExceptionsChart
                          defaultSources={cardSourceValues}
                          defaultCountries={cardCountries}
                          defaultStartDate={selectedStartDate}
                          defaultEndDate={selectedendDate}
                          sourceDropDownValues={sourceDropdownValues}
                          countryDropDownValues={countryDropdownValues}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      ml={1}
                      style={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 1px 4px #15223214",
                        borderRadius: "10px",
                        padding: "25px",
                        zIndex: "9",
                      }}
                      className="ExceptionReportingContent"
                    >
                      <ExceptionReportingChart
                        defaultSources={cardSourceValues}
                        defaultCountries={cardCountries}
                        defaultStartDate={selectedStartDate}
                        defaultEndDate={selectedendDate}
                        sourceDropDownValues={sourceDropdownValues}
                        countryDropDownValues={countryDropdownValues}
                      />
                    </Grid>
                  </Grid>
                  <Grid container mt={3} mb={3} columnGap={2}>
                    <Grid
                      item
                      xs={5.9}
                      style={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 1px 4px #15223214",
                        borderRadius: "10px",
                        padding: "25px",
                        zIndex: "9",
                      }}
                    >
                      <KickoutMonitoringSources
                        defaultSources={cardSourceValues}
                        defaultCountries={cardCountries}
                        defaultStartDate={selectedStartDate}
                        defaultEndDate={selectedendDate}
                        sourceDropDownValues={sourceDropdownValues}
                        countryDropDownValues={countryDropdownValues}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={5.9}
                      style={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 1px 4px #15223214",
                        borderRadius: "10px",
                        padding: "25px",
                        zIndex: "9",
                      }}
                    >
                      <DataLoadingTrendingChart />
                    </Grid>
                  </Grid>

                  <Box
                    mt={3}
                    mb={3}
                    style={{
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "0px 1px 4px #15223214",
                      borderRadius: "10px",
                      padding: "25px",
                      zIndex: "9",
                    }}
                  >
                    <AODSChart />
                  </Box>
                  <ModalDialog
                    isOpen={showmapDialog}
                    title="Reporting Deadlines"
                    onClose={() => this.setState({ showmapDialog: false })}
                    dialogWidth="lg"
                    scrollBar={showmapDialog}
                  >
                    <ReportingDeadlines />
                  </ModalDialog>
                </div>
              )}
            </>
            {/* )} */}
          </>
        )}
      </Box>
    );
  }
}

export default withRouter(Dashboard);
