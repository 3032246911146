import { Component } from "react";

import { Box, CircularProgress, Divider, Grid } from "@mui/material";
import PageHeader from "../../../Components/Text/PageHeader";
type Props = {
  values: any;
};

type State = {
  isHovering: string;
};

export default class MissingTrFilesInfo extends Component<Props, State> {
  render() {
    return (
      <Box>
        <PageHeader style={{ marginLeft: "5px",color: "#D52B1E" }} label="Files Info" />
        <Divider style={{ marginBottom: "1em" }} />
        <Grid
          container
          width={"30em"}
          maxHeight={"30em"}
          direction={"row"}
          style={{
            alignContent: "left",
            paddingRight: "5px",
            paddingLeft: "2px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {this.props.values.length > 0 ? (
            <>
              {this.props.values.map((el: any, index: any) => {
                return (
                  <Grid container item key={index}>
                    <Grid item xs={12}>
                      <ul key={index}>
                        <li
                          style={{ fontSize: "1rem" }}
                        >{`${el}.xlsx or ${el}.csv`}</li>
                      </ul>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Box
              style={{
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                margin: "20vh",
              }}
            >
              <CircularProgress sx={{ color: "#d52b1e" }} />
            </Box>
          )}
        </Grid>
      </Box>
    );
  }
}
