import React, { ReactNode } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import SelectModel from "./SelectModel";

interface Props {
  id?: string;
  label?: string;
  value?: string | number;
  style?: React.CSSProperties;
  size?: "small" | "medium";
  values?: SelectModel[];
  defaultValue?: string;
  placeholder?: string;
  AllValue?: boolean;
  name?: string;
  noneValue?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange: (selected: SelectModel, targetId?: string) => void;
  customOptions?: string;
  displayEmpty?: boolean;
  backgroundColor?: string;
  color?: string;
  border?: string;
}
interface State {}
class SingleSelect extends React.Component<Props, State> {
  render() {
    const {
      id,
      AllValue,
      label,
      value,
      defaultValue,
      values,
      size,
      placeholder,
      isDisabled,
      isRequired,
      customOptions,
      backgroundColor,
      color,
      border,
    } = this.props;
    const labelId = id ? id : label;
    return (
      <FormControl
        size={size ?? "small"}
        style={{ alignContent: "center", justifyContent: "center" }}
        variant="outlined"
        fullWidth
      >
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          style={{
            background: "#263F6A",
            color: "#ffffff",
          }}
          required={isRequired}
          disabled={isDisabled}
          labelId={labelId + "label"}
          id={labelId}
          value={value}
          onChange={this.handleChange}
          label={label}
          defaultValue={defaultValue}
          MenuProps={{
            PaperProps: {
              style: { overflowY: "auto", maxHeight: "20em" },
            },
          }}
          sx={{
            "& .MuiSelect-icon ": {
              color: isDisabled ? "#707070 !important" : "#ffffff",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#9e9e9e",
            },
            "& .MuiInputBase-input": {
              border: isDisabled ? "1px solid #ffffff !important" : "",
            },

            backgroundColor: { backgroundColor },
            color: { color },
            border: { border },
          }}
          placeholder={placeholder}
          displayEmpty={true}
          renderValue={(value) => {
            if (!value || value === "None") {
              return <>{customOptions || "Select Value"}</>;
            }
            return value;
          }}
        >
          <MenuItem disabled value={""}>
            <>{customOptions || "Select Value"}</>
          </MenuItem>
          {AllValue ? (
            <MenuItem key={"All"} value={"All"}>
              All
            </MenuItem>
          ) : null}

          {values
            ?.sort((a, b) => {
              if (a.value < b.value) return -1;

              if (a.value > b.value) return 1;

              return 0;
            })
            .map((v) => {
              return (
                <MenuItem key={v.text} value={v.value}>
                  {v.text}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }

  handleChange = (
    event: SelectChangeEvent<string | number>,
    child: ReactNode
  ) => {
    const { id, values, noneValue, AllValue } = this.props;
    const value = event.target.value as string;

    const selectedValue = values?.find((p) => p.value === value);

    if (selectedValue) {
      this.props.onChange(selectedValue, id);
    } else if (noneValue) {
      this.props.onChange({ text: "None", value: "None" }, id);
    } else if (AllValue) {
      this.props.onChange({ text: "All", value: "All" }, id);
    }
  };
}

export default SingleSelect;
