import { faPaperclip, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { Component } from "react";
import { ImgFileType } from "../../Common/Enums";
import SnackbarAlert from "../SnackBarAlert/SnackbarAlert";
/* import MyCustomSnackbar from "../SnackBar/MyCustomSnackbar"; */
interface Props {
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    file: File | null,
    imagePreview: string | null
  ) => void;
  name?: string;
  label?: string;
  size?: number;
  accept?: string;
  maxWidth?: string;
  file: File | null;
  isDisabled?: boolean;
  onCancel: () => void;
  imagePreview: string | null;
}
interface State {
  file?: File | null;
  imagePreview?: string | null;

  throwErr: boolean;
  errMsg: string;
}
class ImageUpload extends Component<Props, State> {
  fileUploadInput: HTMLInputElement | null = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      file: this.props.file || null,
      imagePreview: this.props.imagePreview || null,
      throwErr: false,
      errMsg: "",
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.imagePreview !== this.props.imagePreview) {
      this.setState({
        imagePreview: this.props.imagePreview,
      });
    }
  }
  render() {
    const { name, label, isDisabled } = this.props;
    const { file, imagePreview, errMsg, throwErr } = this.state;

    return (
      <Box>
        <SnackbarAlert
          message={errMsg}
          alertType="error"
          open={throwErr}
          onClose={() => {
            this.setState({ throwErr: false });
          }}
        />
        <Grid container flexDirection={"row"}>
          <Grid item xs={2.5}>
            <label htmlFor={name}>
              <Grid item container xs={12} display={"flex"}>
                <IconButton aria-label="upload picture" component="span">
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    color="red"
                    fontSize="18px"
                  />
                </IconButton>

                <Typography
                  onChange={this.handleChange}
                  style={{
                    marginTop: "0.5em",
                    color: "red",
                    cursor: "pointer",
                  }}
                >
                  {label || "Attach Image"}
                </Typography>
              </Grid>

              <input
                accept="image/*"
                id={name}
                type="file"
                onChange={this.handleChange}
                style={{ display: "none" }}
                ref={(input) => {
                  this.fileUploadInput = input;
                }}
                name={name}
                disabled={isDisabled}
              />
            </label>
          </Grid>
          <Grid item xs={9.5}>
            {imagePreview && (
              <Grid item container xs={10}>
                <Grid item mt={"0.5em"}>
                  <img
                    src={imagePreview}
                    alt="Logo"
                    style={{
                      marginRight: "0.5em",
                      maxHeight: "25px",
                    }}
                  />
                </Grid>
                {file ? (
                  <Grid item mt={"0.5em"}>
                    <Typography style={{ marginRight: "1em" }}>
                      {file?.name}{" "}
                      <span color="#cececf">
                        {file?.size ? `(${file.size} Bytes)` : ""}
                      </span>
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item>
                  <IconButton onClick={this.handleCancel} disabled={isDisabled}>
                    <FontAwesomeIcon
                      icon={faXmark}
                      color="red"
                      fontSize={"20px"}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (file.size > 5120) {
        this.setState({
          throwErr: true,
          errMsg: "Image size should not exceed more than 5 KB.",
        });
      } else {
        if (file.type === ImgFileType.JPG || file.type === ImgFileType.PNG) {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.setState({
              file,
              imagePreview: event.target?.result?.toString() ?? null,
            });
          };
          reader.readAsDataURL(file);
        } else {
          this.setState({
            file: null,
            imagePreview: null,
            throwErr: true,
            errMsg: "App Image should be in PNG or JPG format.",
          });
        }
        this.props.onChange(event, file, this.state.imagePreview!);
      }
    }
  };
  handleCancel = () => {
    if (this.fileUploadInput) {
      this.fileUploadInput.value = "";
    }
    this.setState({ file: null, imagePreview: null });
    this.props.onCancel();
  };
}

export default ImageUpload;
