import { Grid } from "@mui/material";

import React from "react";

import { RouteComponentProps, withRouter } from "react-router-dom";

import BackgroundImg from "../../Static/Background_image.png";
import DashboardBackgroundImg from "../../Static/dashboard-background-image.svg";
import Footer from "./Footer";

import Header from "./Header";

import DrawerMenu from "./Menu/DrawerMenu";

import TableDrawer from "./TableDrawer";
import "./Layout.css";
import MonitoringDrawer from "./MonitoringDrawer";

interface Props extends RouteComponentProps {
  showMenu: boolean;

  children?: React.ReactNode;

  showTableMenu: boolean;

  path?: string;
}

interface State {
  childrenWidth: string;

  isDrawerOpen: boolean;

  isTableDrawerOpen: boolean;
  isMonitoringDrawerOpen: boolean;
}

class Layout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      childrenWidth: "auto",

      isDrawerOpen: false,

      isTableDrawerOpen: false,
      isMonitoringDrawerOpen: false,
    };
  }

  onToggleDrawer = (drawerState?: boolean) => {
    this.setState({
      childrenWidth: drawerState ? "80%" : "auto",

      isDrawerOpen: drawerState!,
    });
  };

  onTableToggleDrawer = (tableDrawerState?: boolean) => {
    this.setState({
      childrenWidth: tableDrawerState ? "75%" : "auto",

      isTableDrawerOpen: tableDrawerState!,
    });
  };
  onMonitoringTableToggleDrawer = (reportDrawerState?: boolean) => {
    this.setState({
      childrenWidth: reportDrawerState ? "75%" : "auto",

      isMonitoringDrawerOpen: reportDrawerState!,
    });
  };

  render() {
    const { isDrawerOpen, isTableDrawerOpen, isMonitoringDrawerOpen } =
      this.state;
    return (
      <Grid
        id="mainlayout"
        container
        flexDirection={"column"}
        style={{
          background:
            window.location.pathname === "/dashboard" ||
            window.location.pathname === "/lockedview"
              ? "transparent url(" + DashboardBackgroundImg + ")"
              : "transparent url(" + BackgroundImg + ")",

          opacity: 1,
        }}
      >
        <Grid item container>
          <Header showMenu={this.props.showMenu} />
        </Grid>

        <Grid item container style={{ width: "100%" }}>
          {this.props.location.pathname.includes("/sdm") ? (
            <>
              <Grid
                item
                className="drawer-grid"
                sx={{
                  "& .MuiDrawer-paper ": {
                    position: "inherit",
                  },
                }}
              >
                <DrawerMenu
                  showMenu={this.props.showMenu}
                  onToggleDrawer={this.onToggleDrawer}
                />
              </Grid>

              <Grid
                mr={isTableDrawerOpen ? 1.0 : 4.8}
                item
                className="drawer-grid"
                sx={{
                  "& .MuiDrawer-paper ": {
                    position: "inherit",

                    background: "#FFFFFF 0% 0% no-repeat padding-box",

                    boxShadow: "0px 3px 6px #00000029",
                  },
                }}
              >
                <TableDrawer
                  isTableDrawerOpen={isTableDrawerOpen}
                  showTableMenu={this.props.showTableMenu}
                  onTableToggleDrawer={this.onTableToggleDrawer}
                />
              </Grid>

              <Grid
                item
                xs={
                  isDrawerOpen && isTableDrawerOpen
                    ? 7.75
                    : isDrawerOpen
                    ? 9.5
                    : isTableDrawerOpen
                    ? 9.15
                    : 10.85
                }
                style={{ minHeight: "90vh", opacity: "0.87" }}
              >
                {this.props.children}
              </Grid>
            </>
          ) : this.props.location.pathname.includes("/monitoring") ? (
            <>
              <Grid
                item
                className="drawer-grid"
                sx={{
                  "& .MuiDrawer-paper ": {
                    position: "inherit",
                  },
                }}
              >
                <DrawerMenu
                  showMenu={this.props.showMenu}
                  onToggleDrawer={this.onToggleDrawer}
                />
              </Grid>
              <Grid
                mr={isMonitoringDrawerOpen ? 1.0 : 4.8}
                item
                className="drawer-grid"
                sx={{
                  "& .MuiDrawer-paper ": {
                    position: "inherit",

                    background: "#FFFFFF 0% 0% no-repeat padding-box",

                    boxShadow: "0px 3px 6px #00000029",
                  },
                }}
              >
                <MonitoringDrawer
                  isMonitoringDrawerOpen={isMonitoringDrawerOpen}
                  onMonitoringTableToggleDrawer={
                    this.onMonitoringTableToggleDrawer
                  }
                  showTableMenu={this.props.showTableMenu}
                />
              </Grid>
              <Grid
                item
                xs={
                  isDrawerOpen && isMonitoringDrawerOpen
                    ? 7.75
                    : isDrawerOpen
                    ? 9.5
                    : isMonitoringDrawerOpen
                    ? 9.15
                    : 10.85
                }
                style={{ minHeight: "90vh", opacity: "0.87" }}
              >
                {this.props.children}
              </Grid>
            </>
          ) : (
            <>
              <Grid
                mr={isDrawerOpen ? 3.5 : 4.9}
                item
                className="drawer-grid"
                sx={{
                  "& .MuiDrawer-paper ": {
                    position: "inherit",
                  },
                }}
              >
                <DrawerMenu
                  showMenu={this.props.showMenu}
                  onToggleDrawer={this.onToggleDrawer}
                />
              </Grid>

              <Grid
                item
                xs={isDrawerOpen ? 9.75 : 11}
                style={{ minHeight: "90vh", opacity: "0.95" }}
              >
                {this.props.children}
              </Grid>
            </>
          )}
        </Grid>

        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(Layout);
