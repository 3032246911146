import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Link as TextLink } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import "../../App.css";
import Utils from "../../Common/Utils";
import {
  NotificationWidgetCountModel,
  NotificationWidgetSummaryModel,
} from "../../Models/NotificationWidgetModel";
import AuthProviderService from "../../Services/AuthProviderService";
import NotificationService from "../../Services/NotificationWidgetService";
import NoRecords from "../NoRecords/NoRecords";
import ExceptionReportingSevice from "../../Services/ExceptionReportingServices";
const notificationServices = new NotificationService();
const ExceptionReportingServices = new ExceptionReportingSevice();
interface Props extends RouteComponentProps {}
interface State {
  notifications: any[];
  notificationData: any[];
  unreadCount: number;
  anchorEl: any;
  userName: string;
  isExceptionAssigned: boolean;
  interval: NodeJS.Timeout | null;
  countInterval: NodeJS.Timeout | null;
}
class Notification extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      notifications: [],
      notificationData: [],
      unreadCount: 0,
      anchorEl: null,
      userName: "",
      isExceptionAssigned: false,
      interval: null,
      countInterval: null,
    };
  }

  async componentDidMount() {
    try {
      const token = await AuthProviderService.getAccessToken();
      const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
      const userName = JSON.parse(decodedToken).name;

      this.setState({ userName }, () => {
        this.getNotificationDataAndCount();
        this.fetchExceptionData();
      });
      this.setNotificationInterval();
    } catch (error) {
      console.log(error);
    }
  }

  setNotificationInterval = () => {
    if (this.state.countInterval) {
      clearInterval(this.state.countInterval);
      this.setState({ countInterval: null });
    }
    if (this.state.interval) {
      clearInterval(this.state.interval);
      this.setState({ interval: null });
    }
    const interval = setInterval(() => {
      this.getNotificationDataAndCount();
    }, 60000);
    const countInterval = setInterval(() => {
      const countValue = sessionStorage.getItem("unreadCount");
      if (countValue) {
        sessionStorage.removeItem("unreadCount");
      }
      if (countValue && Number(countValue) !== this.state.unreadCount) {
        sessionStorage.removeItem("unreadCount");
        this.setState({ unreadCount: Number(countValue) }, () => {
          this.fetchNotificationData();
          //this.fetchNotificationCount();
        });
      }
    }, 5000);
    this.setState({
      interval,
      countInterval,
    });
  };
  componentWillUnmount(): void {
    if (this.state.countInterval) {
      clearInterval(this.state.countInterval);
    }
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevState.unreadCount !== this.state.unreadCount) {
      this.fetchNotificationCount();
    }
    if (prevState.anchorEl !== this.state.anchorEl) {
      this.getNotificationDataAndCount();
      this.fetchExceptionData();
    }
  }
  fetchExceptionData = async () => {
    const token = await AuthProviderService.getAccessToken();
    const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
    const userName = JSON.parse(decodedToken).name;
    const exceptionResponse =
      await ExceptionReportingServices.getExceptionAssignedUserData();
    let isExceptionAssigned =
      exceptionResponse.data.result.filter(
        (el: any) => el.assigned_to === userName
      ).length > 0;
    this.setState({ isExceptionAssigned });
  };
  getNotificationDataAndCount = () => {
    this.fetchNotificationData();
    this.fetchNotificationCount();
  };
  fetchNotificationCount = async () => {
    try {
      const unreadCountObj: NotificationWidgetCountModel = {
        par_assigned_to: this.state.userName,
      };
      const unreadCount = await notificationServices.getNotificationsCount(
        unreadCountObj
      );
      if (unreadCount.Unread_Notification_Count !== this.state.unreadCount) {
        this.setState({ unreadCount: unreadCount.Unread_Notification_Count });
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchNotificationData = async () => {
    try {
      const obj: NotificationWidgetSummaryModel = {
        par_summary_type: "short",
        par_assigned_to: this.state.userName,
        par_whereclause: null,
        par_sortorder: null,
      };
      const response = await notificationServices.getNotificationWidgetSummary(
        obj
      );
      if (JSON.parse(response.result).length > 0) {
        this.setState({ notificationData: JSON.parse(response.result) });
      } else if (JSON.parse(response.result).length === 0) {
        this.setState({ notificationData: [] });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleMenuClick = (el?: any) => {
    this.fetchNotificationCount().then(() => {
      this.fetchNotificationData();
      this.fetchExceptionData();
      this.setState({ anchorEl: null });
      if (el === "exception") {
        localStorage.setItem("exceptionUser", this.state.userName);
      }
    });
  };
  handleSeeAllNotification = () => {
    this.setState({ anchorEl: null });
    this.props.history.push("/notifications");
  };
  handleSeeAllExceptions = () => {
    this.setState({ anchorEl: null });
    this.props.history.push("/exceptionreporting");
  };
  render() {
    const { anchorEl, unreadCount, notificationData, isExceptionAssigned } =
      this.state;
    const open = Boolean(anchorEl);
    return (
      <>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Notifications">
            <IconButton
              onClick={
                window.location.pathname === "/lockedview"
                  ? () => {}
                  : this.handleClick
              }
              size="small"
              sx={{
                ml: 2,
                cursor:
                  window.location.pathname === "/lockedview"
                    ? "default"
                    : "pointer",
              }}
              aria-controls={open ? "notification-card" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Badge
                badgeContent={unreadCount}
                max={99}
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "#00749e",
                    right: 3,
                    top: 15,
                  },
                }}
                showZero
              >
                <NotificationsIcon
                  style={{
                    marginTop: 8,
                    color: "#FFFFFF",
                    height: "2rem",
                    width: "2rem",
                  }}
                />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="notification-card"
          title="Notifications"
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              background: "#CCD5DD",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },

              maxHeight: "36em",
              width: "44em",
              overflowY: "auto",
              minHeight: "2em",
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Box
              style={{
                display: "flex",
                marginLeft: "1em",
                marginRight: "1em",
                marginTop: "0.5em",
                marginBottom: "0.5em",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginTop: "auto",
                  marginBottom: "auto",
                  fontSize: "1.5em",
                }}
              >
                Notifications{" "}
              </Typography>
              <Box
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#D52B1E",
                  color: "white",
                  marginLeft: "1em",
                  padding: "0.1em",
                  width: "1.3em",
                  height: "1.3em",
                  alignItems: "center",
                  alignContent: "right",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Box style={{ fontWeight: "bold", fontSize: "13px" }}>
                  {unreadCount > 99 ? "99+" : unreadCount}
                </Box>
              </Box>
              <Box style={{ marginLeft: "auto", marginRight: "-15px" }}>
                <CloseIcon
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    width: "2em",
                    cursor: "pointer",
                  }}
                  onClick={this.handleClose}
                />
              </Box>
            </Box>
            <Divider variant="fullWidth" />
          </Box>

          {!notificationData.length ? (
            <NoRecords
              style={{ marginTop: "2em" }}
              msg="No Notifications for you"
            />
          ) : null}

          <Box
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              borderTop: "0.5px solid #D8D8D8",
            }}
          >
            {notificationData.map((el: any, index: number) => {
              return (
                <Link
                  onClick={() => this.handleMenuClick(el)}
                  to={{ pathname: "/notifications", state: { el } }}
                  style={{ textDecoration: "none" }}
                  key={index}
                >
                  <MenuItem
                    key={index}
                    style={{
                      backgroundColor:
                        el.var_read_status.toLowerCase() === "unread"
                          ? "#E4EAF0"
                          : "#CCD5DD",
                      borderImage:
                        el.var_read_status.toLowerCase() === "unread"
                          ? "linear-gradient(to bottom, #263F6A,  #890756) 0 0 0 1"
                          : "none",
                      borderWidth:
                        el.var_read_status.toLowerCase() === "unread"
                          ? "5px"
                          : "0px",
                      borderStyle:
                        el.var_read_status.toLowerCase() === "unread"
                          ? "solid"
                          : "none",
                      fontWeight:
                        el.var_read_status.toLowerCase() === "unread"
                          ? "bold"
                          : "normal",
                      color: "black",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    <Box style={{ display: "flex" }}>
                      <AccountCircleIcon
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          marginRight: "0.5em",
                        }}
                        style={{
                          color: "#7A8FB2",
                        }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "34.5rem",
                        }}
                      >
                        <Box
                          style={{
                            wordBreak: "break-all",
                            marginBottom: "10px",
                          }}
                        >
                          ID_{el.var_notification_id} {el.var_title}
                        </Box>
                        <Box
                          style={{
                            color: "#575757",
                            fontWeight: 400,
                            fontSize: "13px",
                          }}
                        >
                          {Utils.getFormattedDateTime(el.last_updt_ime)}
                        </Box>
                        <Box position={"absolute"} right={"20px"} top={"25px"}>
                          <CircleIcon
                            style={{
                              fontSize: "13px",
                              color:
                                el.notification_status.toLowerCase() === "open"
                                  ? "#50AF7F"
                                  : el.notification_status.toLowerCase() ===
                                    "in progress"
                                  ? "#F08508"
                                  : el.notification_status.toLowerCase() ===
                                    "closed"
                                  ? "#868686"
                                  : el.notification_status.toLowerCase() ===
                                    "rejected"
                                  ? "#F44E4E"
                                  : el.notification_status.toLowerCase() ===
                                    "review requested"
                                  ? "#08E7F0"
                                  : el.notification_status.toLowerCase() ===
                                    "review completed"
                                  ? "#50AF7F"
                                  : el.notification_status.toLowerCase() ===
                                    "on hold"
                                  ? "#9D24F5"
                                  : "",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </MenuItem>

                  <Divider
                    variant="middle"
                    sx={{
                      "&.MuiDivider-root": {
                        marginTop: 0,
                        marginBottom: 0,
                      },
                    }}
                  />
                </Link>
              );
            })}
          </Box>
          {isExceptionAssigned ? (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Box
                style={{
                  display: "flex",
                  marginLeft: "1em",
                  marginRight: "1em",
                  marginTop: "2em",
                  marginBottom: "0.8em",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    marginTop: "auto",
                    marginBottom: "auto",
                    fontSize: "1.5em",
                  }}
                >
                  Exception Reporting
                </Typography>
              </Box>
              <Divider variant="fullWidth" />
              <Box
                mt={1}
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Link
                  onClick={() => this.handleMenuClick("exception")}
                  to={{
                    pathname: "/exceptionreporting",
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem
                    style={{
                      backgroundColor: "#CCD5DD",

                      color: "black",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    <Box style={{ display: "flex" }}>
                      <AccountCircleIcon
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          marginRight: "0.5em",
                        }}
                        style={{
                          color: "#7A8FB2",
                        }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "34.5rem",
                        }}
                      >
                        <Box
                          style={{
                            wordBreak: "break-all",
                            marginBottom: "10px",
                          }}
                        >
                          Exception Reporting: You have been assigned with
                          exception(s). Please view the exception reporting tab
                          for more info.
                        </Box>
                      </Box>
                    </Box>
                  </MenuItem>

                  <Divider
                    variant="middle"
                    sx={{
                      "&.MuiDivider-root": {
                        marginTop: 0,
                        marginBottom: 0,
                      },
                    }}
                  />
                </Link>
              </Box>
            </Box>
          ) : null}

          <Grid item container justifyContent={"space-between"}>
            <Grid item>
              {notificationData.length ? (
                <Typography pt={2} pb={2} pl={3}>
                  <TextLink
                    onClick={this.handleSeeAllNotification}
                    color={"#D52B1E"}
                    underline="none"
                    style={{ cursor: "pointer" }}
                  >
                    See All Notifications
                  </TextLink>
                </Typography>
              ) : null}
            </Grid>
            <Grid item>
              {isExceptionAssigned ? (
                <Typography pt={2} pb={2} pr={3}>
                  <TextLink
                    onClick={this.handleSeeAllExceptions}
                    color={"#D52B1E"}
                    underline="none"
                    style={{ cursor: "pointer" }}
                  >
                    See All Exceptions
                  </TextLink>
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Menu>
      </>
    );
  }
}
export default withRouter(Notification);
