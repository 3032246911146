import { Grid, Typography } from "@mui/material";
import { Component } from "react";
import GradientButton from "../Button/GradientButton";
import RedOutlinedButton from "../Button/RedOutlinedButton";
import TextAreaInputField from "../TextInputField/TextAreaInputField";

interface Props {
  onCommentClose: (event: any) => void;
  columnDetails: any;
  rationale: string;
  isButtonLoad: boolean;
  onRationaleInputChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleSave: (mode: string) => void;
  mode?: string;
  handleRemoveFilter?: () => void;
}

interface State {}

export default class CommentSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  handleSubmit = async (event?: any) => {
    this.props.handleSave("comment");
    if (this.props.handleRemoveFilter) {
      this.props.handleRemoveFilter();
    }

    event.preventDefault();
  };

  onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onRationaleInputChange(event);
  };
  render() {
    return (
      <Grid container p={2} mt={3}>
        <Grid item xs={12}>
          <TextAreaInputField
            isRequired={
              this.props.columnDetails.find(
                (val: any) => val.fieldName === "rationale"
              )?.isRequired! === "NO"
                ? true
                : false
            }
            id="rationale"
            value={this.props.rationale}
            minRows={3}
            width="40rem"
            placeholder="Enter Rationale"
            onChange={this.onInputChange}
          />
          <Typography
            style={{
              fontSize: 12,
              color: "red",
              marginLeft: "35rem",
              fontStyle: "italic",
            }}
          >
            * Field Mandatory
          </Typography>
        </Grid>
        <Grid container>
          <Grid item mr={2}>
            <RedOutlinedButton
              label="Cancel"
              onClick={(event: any) => {
                this.props.onCommentClose(event);
              }}
            />
          </Grid>
          <Grid item>
            <GradientButton
              disabled={this.props.rationale ? false : true}
              isButtonLoad={this.props.isButtonLoad}
              label="Save"
              onClick={this.handleSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
