import { URIs } from "../Common/Constants";
import {
  NotificationWidgetSummaryModel,
  NotificationAssignmentPageModel,
  NotificationWidgetModifyModel,
  NotificationWidgetCountModel,
  NotificationWidgetHistoryModel,
} from "../Models/NotificationWidgetModel";
import GenericAPIService from "./GenericAPIService";

const {
  NOTIFICATION_SUMMARY,
  NOTIFICATION_ASSIGNMENT,
  NOTIFICATION_MODIFY,
  GETALL_USERS,
  NOTIFICATION_COUNT,
  NOTIFICATION_HISTORY,
} = URIs;
class NotificationService extends GenericAPIService {
  async getNotificationWidgetSummary(body: NotificationWidgetSummaryModel) {
    const { data } = await this.postAsync<{ result: any }>(
      NOTIFICATION_SUMMARY,
      body
    );
    return data;
  }
  async getNotificationWidgetAssignment(body: NotificationAssignmentPageModel) {
    const { data } = await this.postAsync<{
      total_count: any;
      total_page_count: any;
      result_set: any;
    }>(NOTIFICATION_ASSIGNMENT, body);
    return data;
  }
  async notificationWidgetModify(body: NotificationWidgetModifyModel) {
    const { data } = await this.postAsync<{ result: any }>(
      NOTIFICATION_MODIFY,
      body
    );
    return data;
  }
  async getAllUsersData() {
    const { data } = await this.getAsync<{ data: { data: any } }>(GETALL_USERS);
    return data;
  }
  async getNotificationsCount(body: NotificationWidgetCountModel) {
    const { data } = await this.postAsync<{ Unread_Notification_Count: any }>(
      NOTIFICATION_COUNT,
      body
    );
    return data;
  }
  async getNotificationsHistory(body: NotificationWidgetHistoryModel) {
    const { data } = await this.postAsync<{ result: any }>(
      NOTIFICATION_HISTORY,
      body
    );
    return data;
  }
}

export default NotificationService;
