import { Box, Grid, InputLabel } from "@mui/material";
import moment from "moment";
import { Component } from "react";
import AODSCorrectedRecords from "../../../Components/Charts/AODSCorrectedRecords";
import AODSDialogChart from "../../../Components/Charts/AODSDialogChart";
import AODSFailedRecordsChart from "../../../Components/Charts/AODSFailedRecordsChart";
import AODSRawDataChart from "../../../Components/Charts/AODSRawDataChart";
import DatePicker from "../../../Components/DatePicker/DatePicker";
import ModalDialog from "../../../Components/Modal/ModelDialog";
import MultiAutoComplete from "../../../Components/Select/MultiAutoComplete";
import SelectModel from "../../../Components/Select/SelectModel";
import PageHeader from "../../../Components/Text/PageHeader";
import ExpandOpen from "../../../Static/ExpandOpen.png";

type Props = {};

type State = {
  showAODSDialog: boolean;
  selectedDate?: string | null;
  selectedValues: SelectModel[];
};

class AODSChart extends Component<Props, State> {
  state = {
    showAODSDialog: false,
    selectedDate: null,
    selectedValues: [],
  };
  handleDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        selectedDate: moment(value).format("YYYY-MM-DD"),
      });
    }
  };
  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (targetId) {
      this.setState({ selectedValues: selected });
    }
  };
  render() {
    const { showAODSDialog, selectedDate, selectedValues } = this.state;
    return (
      <>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <PageHeader
            label="AODS Raw Data"
            style={{
              fontSize: "16px",
              lineHeight: "1",
            }}
          />
          <Box style={{ display: "flex", gap: "1em", cursor: "pointer" }}>
            <img
              alt="ExpandOpen"
              src={ExpandOpen}
              width={"14px"}
              height={"14px"}
              style={{
                cursor: "pointer",
                zIndex: "9999",
              }}
              onClick={() => {
                this.setState({ showAODSDialog: true });
              }}
            />
          </Box>
        </Box>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={3}>
            <AODSFailedRecordsChart />
          </Grid>
          <Grid item xs={3} ml={"-10px"}>
            <AODSCorrectedRecords />
          </Grid>
        </Grid>
        <Box mt={-33}>
          <AODSRawDataChart />
        </Box>

        <ModalDialog
          isOpen={showAODSDialog}
          blackTitleColor
          title="AODS Raw Data"
          onClose={() => this.setState({ showAODSDialog: false })}
          dialogWidth="xl"
          scrollBar={showAODSDialog}
        >
          {" "}
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "0 25px 5px",
            }}
          >
            {" "}
            <Grid item xs={12}>
              {" "}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <AODSFailedRecordsChart />
                </Grid>
                <Grid item xs={6}>
                  <AODSCorrectedRecords />
                </Grid>
              </Grid>
            </Grid>
            <Grid container columnGap={1} justifyContent={"flex-end"}>
              <Grid item xs={4}>
                {" "}
                <InputLabel>Select Sources</InputLabel>
                <MultiAutoComplete
                  id="sourceName"
                  label="Select Sources"
                  selected={selectedValues}
                  values={[
                    { text: "AMX", value: "AMX" },
                    { text: "ANC", value: "ANC" },
                    { text: "ATR", value: "ATR" },
                    { text: "AVD", value: "AVD" },
                    { text: "SAP", value: "SAP" },
                  ]}
                  onChange={this.handleMultiSelectChange}
                />
              </Grid>
              <Grid item xs={3}>
                <InputLabel> Select Date Range</InputLabel>
                <DatePicker
                  name="Date"
                  placeHolder="Select Date"
                  maxDate={new Date()}
                  value={selectedDate}
                  onChange={this.handleDateChange}
                />
              </Grid>{" "}
            </Grid>
          </Grid>
          <Box mt={-33}>
            <AODSDialogChart />
          </Box>
        </ModalDialog>
      </>
    );
  }
}
export default AODSChart;
