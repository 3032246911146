import { CustomEventType } from "../Common/Enums";
import Utils from "../Common/Utils";

const CustomEventHandler = (eventName: CustomEventType, data: any): CustomEvent => {
    const isIE = Utils.isIE();

    if (isIE) {
        const customEvent = document.createEvent("CustomEvent");
        customEvent.initCustomEvent(CustomEventType[eventName], true, true, {
            detail: data
        });

        return customEvent;
    } else {
        const customEvent = new CustomEvent(CustomEventType[eventName], {
            detail: data
        });

        return customEvent;
    }
}

export default CustomEventHandler;