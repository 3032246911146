import {
  Tooltip,
  Box,
  CircularProgress,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
  Grid,
  Typography,
  Table as MaterialTable,
} from "@mui/material";

import { isEqual, isNull } from "lodash";
import React, { Component, ReactNode } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Utils from "../../Common/Utils";
import NoRecords from "../NoRecords/NoRecords";
import PaginationNew from "../Pagination";

interface Props extends RouteComponentProps<any, any, any> {
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  totalRecordsCount: number | undefined;
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  isLoading?: boolean;
  data: any;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
}

type State = {
  data: any;
};

class CardContentTable extends Component<Props, State> {
  state = {
    data: [],
  };
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        data: this.props.data,
      });
    }
  };
  componentDidMount = () => {
    this.setState({
      data: this.props.data,
    });
  };
  render() {
    const { headCellData } = this.props;
    const { data } = this.state;
    return (
      <Box pr={"16px"} pl={"16px"}>
        {this.props.isLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            {headCellData.length > 0 ? (
              <TableContainer
                component={Paper}
                style={{
                  boxShadow: "none",
                }}
                sx={{
                  maxHeight: "40rem",
                  overflow: "auto",
                }}
              >
                <MaterialTable
                  stickyHeader
                  sx={{ minWidth: "fit-content" }}
                  aria-label="sticky table"
                  style={{
                    width: "140rem",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {data.length > 0
                          ? data.map((record: any, index: number) => {
                              return (
                                <Card
                                  key={`${index}-row-data`}
                                  style={{
                                    marginBottom: "10px",
                                    background: "#CCD5DD",
                                    boxShadow: "0px 3px 6px #00000029",
                                    borderRadius: "6px",
                                    opacity: 1,
                                  }}
                                >
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="row"
                                      columns={12}
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      {headCellData.map((el, cellIndex) => {
                                        return (
                                          <Grid
                                            item
                                            xs={el.key === "id" ? 0.5 : 1.3}
                                            key={`${cellIndex}-cell-data`}
                                          >
                                            <Typography
                                              style={{
                                                fontWeight: 600,
                                                fontSize: "15px",
                                              }}
                                            >
                                              {Utils.camelToTitle(el.key)}
                                            </Typography>

                                            <Grid
                                              item
                                              style={{
                                                paddingTop: "5px",
                                              }}
                                            >
                                              <>
                                                {!isNull(el?.linkTo) ? (
                                                  <Link
                                                    style={{
                                                      color: "#0075A2",
                                                      textDecoration: "none",
                                                      fontWeight: 600,
                                                    }}
                                                    to={{
                                                      state: {
                                                        record,
                                                      },
                                                    }}
                                                  >
                                                    {record[el?.key]}
                                                  </Link>
                                                ) : (
                                                  <Tooltip
                                                    arrow
                                                    title={record[el?.key]}
                                                    disableHoverListener={
                                                      record[el?.key] &&
                                                      record[el?.key].toString()
                                                        .length < 26
                                                    }
                                                    componentsProps={{
                                                      tooltip: {
                                                        sx: {
                                                          bgcolor: "#F5F5F5",
                                                          padding: "5px",
                                                          fontSize: "13px",
                                                          boxShadow:
                                                            "0px 3px 6px #00000029",
                                                          color: "#000000",
                                                          "& .MuiTooltip-arrow":
                                                            {
                                                              color: "#F5F5F5",
                                                            },
                                                        },
                                                      },
                                                    }}
                                                  >
                                                    <Typography
                                                      className="AssignedName"
                                                      style={{
                                                        color:
                                                          el.key === "TableName"
                                                            ? "#0075A2"
                                                            : "",
                                                        fontWeight: 520,
                                                        maxWidth: "182px",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                      }}
                                                    >
                                                      {record[el?.key]
                                                        ? record[el?.key]
                                                        : "Null"}
                                                    </Typography>
                                                  </Tooltip>
                                                )}
                                              </>
                                              {el.elementToLoad}{" "}
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </CardContent>
                                </Card>
                              );
                            })
                          : null}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </MaterialTable>
              </TableContainer>
            ) : (
              <Box style={{ margin: "20vh" }}>
                <NoRecords />
              </Box>
            )}
          </>
        )}

        <PaginationNew
          isEmptyReset={this.props.isEmptyReset}
          isPaginationReset={this.props.isPaginationReset}
          isDisabled={this.props.isPaginationDisabled}
          onChangePage={this.props.onChangePage}
          onChangeRow={this.props.onChangeRow}
          totalRecordsCount={this.props.totalRecordsCount || 0}
        />
      </Box>
    );
  }
}
export default withRouter(CardContentTable);
