import React from 'react';
import { Typography } from "@mui/material";

interface Props {
    label: string;
    style?: React.CSSProperties;
}
interface State {}

class PageHeader extends React.Component<Props, State> {
    render() {
        const { label, style } = this.props;
        return(
            <Typography style={{color: '#1D1D1D', fontWeight: 600, fontSize: 18, ...style}}>{label}</Typography>
        );
    }
}

export default PageHeader;