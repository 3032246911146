import React, { Component } from "react";
import { RoleContext } from "..";
import BackdropLoaderCircular from "../../Components/Loader/BackdropLoaderCircular";
import { RolesModel } from "../../Models/RolesModel";
import AuthProviderService from "../../Services/AuthProviderService";
import UserAuthService from "../../Services/UserAuthService";
import Layout from "../../Components/Layout/Index";
interface Props extends React.PropsWithChildren {}

interface State {
  userRoles: RolesModel[];
  isLoading: boolean;
  hasAccess: boolean;
  accessedTables: any;
  uniqueColumnObj: any;
}

class RoleProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userRoles: [],
      isLoading: true,
      hasAccess: false,
      accessedTables: [],
      uniqueColumnObj: {},
    };
  }

  componentDidMount(): void {
    this.fetchUserRoles();
  }

  fetchUserRoles = async () => {
    const isLoggedIn = await AuthProviderService.isLoggedIn();
    if (!isLoggedIn) {
      if (
        window.location.pathname !== "/auth/callback" &&
        !window.location.pathname.includes("/logout") &&
        !window.location.pathname.includes("/accessdenied")
      ) {
        let pathName = window.location.pathname;
        if (window.location.search) {
          pathName += window.location.search;
        }
        AuthProviderService.setCallbackPath(pathName);
      }
      AuthProviderService.login();
    } else {
      try {
        const res = await UserAuthService.fetchUserRoles();
        if (res.statusCode === 401) {
          UserAuthService.initUser()
            .then(async (resp) => {
              if (resp.isSuccess) {
                window.location.reload();
              } else {
                this.setState({
                  uniqueColumnObj: [],
                  accessedTables: [],
                  userRoles: [],
                  isLoading: false,
                  hasAccess: false,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          const { data } = res.data?.data;
          const response = await UserAuthService.fetchAllowedSDMTables();
          
          this.setState({
            uniqueColumnObj:
              response.data.data.sdmtableassigned.uniqueColumnObj,
            accessedTables: response.data.data.sdmtableassigned.roleTableData,
            userRoles: data.roles,
            isLoading: false,
            hasAccess: res.statusCode !== 401,
          });
        }
      } catch (error) {
        this.setState({
          accessedTables: [],
          userRoles: [],
          isLoading: false,
          hasAccess: false,
        });
        console.error("Error in RoleProvider - ", error);
      }
    }
  };
  render() {
    if (this.state.isLoading) {
      return (
        <Layout showMenu={false} showTableMenu={false}>
          <BackdropLoaderCircular show={this.state.isLoading} />
        </Layout>
      );
    }
    return (
      <RoleContext.Provider value={this.state}>
        <Layout
          showMenu={this.state.hasAccess ? true : false}
          showTableMenu={this.state.hasAccess ? true : false}
        >
          {this.props.children}
        </Layout>
      </RoleContext.Provider>
    );
  }
}

export default RoleProvider;
