import { IUserDetails, IUserRoleResponse } from '../Models/UserAuthModel';
import GenericAPIService from './GenericAPIService';
import { URIs } from '../Common/Constants';
import Utils from '../Common/Utils';
import { RESTResponse } from '../Common/RESTClient';

const { AUTH_INIT_USER, AUTH_ROLES,ALLOWED_SDM_TABLES } = URIs;

class UserAuthService extends GenericAPIService {
    
    async initUser(): Promise<RESTResponse<{
        data: IUserDetails;
    }>> {
        const data = await this.getAsync<{ data: IUserDetails }>(AUTH_INIT_USER);
        return data;
    }
    
    async fetchUserRoles() {
        const data = await this.getAsync<{ data: { data: IUserRoleResponse } }>(AUTH_ROLES);
        return data;
    }
    async fetchAllowedSDMTables() {
        const data = await this.getAsync<{ data: any }>(ALLOWED_SDM_TABLES);
        return data;
    }
}

export default new UserAuthService();
