import {
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import GradientButton from "../../Components/Button/GradientButton";
import RedButton from "../../Components/Button/RedButton";
import CardContainer from "../../Components/Card/CardContainer";
import DatePicker from "../../Components/DatePicker/DatePicker";
import SingleSelect from "../../Components/Select/SingleSelect";
import CardContentDataLoader from "../../Components/Table/CardContentDataLoader";
import PageHeader from "../../Components/Text/PageHeader";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { AuditHistoryModel } from "../../Models/AuditHistoryModel";
import AuditHistoryService from "../../Services/AuditHistoryService";
import ExcelService from "../../Services/ExcelService";
import SelectModel from "../../Components/Select/SelectModel";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
const AuditHistoryServices = new AuditHistoryService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  selectedStartDate: string | null;
  selectedEndDate: string | null;
  searchText: string;
  searchKey: string;
  tblData: any[];
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isTableLoading: boolean;
  totalRecordsCount: number | undefined;
  isEmptyReset: boolean;
  response: any;
  rows: number;
  page: number;
  isSearchClick: boolean;
  isButtonLoading: boolean;
  selectedValue: string;
  isDownloading: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
}

class AuditHistory extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      selectedStartDate: null,
      selectedEndDate: null,
      searchText: "",
      searchKey: "",
      tblData: [],
      isPaginationDisabled: false,
      isPaginationReset: false,
      isTableLoading: false,
      totalRecordsCount: undefined,
      isEmptyReset: false,
      response: [],
      rows: 5,
      page: 0,
      isSearchClick: false,
      isButtonLoading: false,
      selectedValue: "",
      isDownloading: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
    };
  }
  componentDidMount() {
    this.getAllData(5, 1);
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!_.isEqual(prevState.selectedStartDate, this.state.selectedStartDate)) {
      this.setState({ selectedEndDate: null });
    }
  }
  getAllData = async (rows: number, page: number) => {
    try {
      this.setState({ isTableLoading: true });
      const payLoadObj: AuditHistoryModel = {
        var_table_name: "",
        var_rcrd_key: "",
        var_from_dt: "",
        var_to_dt: "",
        var_recordsperpage: rows,
        var_page: page,
      };
      const response = await AuditHistoryServices.getAuditHistory(payLoadObj);

      if (Number(response.total_count) > 0) {
        let tableData = response.result.map((el: any) => {
          return {
            TableName: el.table_name,
            RcrdKey: el.rcrd_key,
            ChangedNewValue: el.chg_new_val,
            ChangedPreviousValue: el.chg_prev_val,
            LastUpdatedColumns: el.last_updated_columns,
            ReasonForChange: el.reason_for_change,
            "Last Updated Date & Time": el.last_updated_date_time,
            LastUpdatedUser: el.last_updated_user,
          };
        });

        this.setState({
          response: response.result,
          tblData: tableData,
          isTableLoading: false,
          totalRecordsCount: Number(response.total_count),
        });
      } else {
        this.setState({
          isTableLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isTableLoading: false,
      });
    }
  };
  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    this.setState({ selectedStartDate: moment(value).format("YYYY-MM-DD") });
  };
  handleEndDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    this.setState({ selectedEndDate: moment(value).format("YYYY-MM-DD") });
  };
  handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({
      ...this.state,
      [event.target.id]: event.target.value,
    });
  };
  onResetClick = () => {
    this.setState({
      searchText: "",
      searchKey: "",
      selectedEndDate: null,
      selectedStartDate: null,
      isButtonLoading: false,
      isSearchClick: false,
      isPaginationReset: !this.state.isPaginationReset,
      page: 1,
      rows: 5,
    });
    this.getAllData(5, 1);
  };
  handleExcelDownload = (tblData: any) => {
    const trialResulData: any = [];
    const tableHeaders: any = Object.keys(tblData[0]);
    const newTableData = tblData.map((elem: any) => {
      const newObj = { ...elem };
      delete newObj.id;
      return newObj;
    });
    newTableData.forEach((obj: any) => {
      const allValues = Object.values(obj);
      trialResulData.push(allValues);
    });

    ExcelService.CreateDataFile(
      "Audit History Data",
      trialResulData,
      tableHeaders.filter((el: any) => el !== "id")
    );
  };
  getExcelData = async (rows: number) => {
    try {
      const { selectedStartDate, selectedEndDate, searchKey, searchText } =
        this.state;
      this.setState({ isDownloading: true });
      const payLoadObj: AuditHistoryModel = {
        var_table_name: searchText,
        var_rcrd_key: searchKey,
        var_from_dt: selectedStartDate,
        var_to_dt: selectedEndDate,
        var_recordsperpage: rows,
        var_page: 1,
      };
      const response = await AuditHistoryServices.getAuditHistory(payLoadObj);

      if (Number(response.total_count) > 0) {
        let tableData = response.result.map((el: any) => {
          return {
            TableName: el.table_name,
            RcrdKey: el.rcrd_key,
            ChangedNewValue: el.chg_new_val,
            ChangedPreviousValue: el.chg_prev_val,
            LastUpdatedColumns: el.last_updated_columns,
            ReasonForChange: el.reason_for_change,
            "Last Updated Date & Time": el.last_updated_date_time,
            LastUpdatedUser: el.last_updated_user,
          };
        });
        this.setState({ isDownloading: false });
        this.handleExcelDownload(tableData);
      } else {
        this.setState({
          alertMsg: "No data available to download.",
          alertType: "error",
          openAlert: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleDownloadSelectChange = (selected: SelectModel) => {
    //this.setState({ selectedValue: selected.value });
    if (selected.value === "Current Page") {
      this.handleExcelDownload(this.state.tblData);
    } else if (selected.value === "1000 records") {
      this.getExcelData(1000);
    } else if (selected.value === "2000 records") {
      this.getExcelData(2000);
    } else if (selected.value === "5000 records") {
      this.getExcelData(5000);
    }
  };
  onChangePage = (page: number) => {
    this.setState({ page }, () => {
      !this.state.isSearchClick
        ? this.getAllData(this.state.rows, page)
        : this.handleSearchClick();
    });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row, page: 1 }, () => {
      this.setState({ isEmptyReset: !this.state.isEmptyReset });
      !this.state.isSearchClick
        ? this.getAllData(row, this.state.page > 1 ? 1 : this.state.page)
        : this.handleSearchClick();
    });
  };
  onSearchClick = () => {
    this.setState(
      {
        rows: 5,
        page: 1,
        isPaginationReset: !this.state.isPaginationReset,
        isButtonLoading: true,
      },
      () => {
        this.handleSearchClick();
      }
    );
  };
  handleSearchClick = async () => {
    try {
      const {
        selectedStartDate,
        selectedEndDate,
        searchKey,
        searchText,
        page,
        rows,
      } = this.state;
      this.setState(
        {
          isTableLoading: true,
          isButtonLoading: true,
          isSearchClick: true,
        },
        async () => {
          const payLoadObj: AuditHistoryModel = {
            var_table_name: searchText,
            var_rcrd_key: searchKey,
            var_from_dt: selectedStartDate,
            var_to_dt: selectedEndDate,
            var_recordsperpage: rows,
            var_page: page,
          };
          const response = await AuditHistoryServices.getAuditHistory(
            payLoadObj
          );
          if (Number(response.total_count) > 0) {
            let tableData = response.result.map((el: any) => {
              return {
                TableName: el.table_name,
                RcrdKey: el.rcrd_key,
                ChangedNewValue: el.chg_new_val,
                ChangedPreviousValue: el.chg_prev_val,
                LastUpdatedColumns: el.last_updated_columns,
                ReasonForChange: el.reason_for_change,
                "Last Updated Date & Time": el.last_updated_date_time,
                LastUpdatedUser: el.last_updated_user,
              };
            });
            this.setState({
              response: response.result,
              tblData: tableData,
              totalRecordsCount: Number(response.total_count),
              isTableLoading: false,
              isButtonLoading: false,
            });
          } else {
            this.setState({
              tblData: [],
              totalRecordsCount: Number(response.total_count),
              isTableLoading: false,
              isPaginationReset: true,
              isEmptyReset: !this.state.isEmptyReset,
              page: 1,
              rows: 5,
              isButtonLoading: false,
              isSearchClick: false,
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
      this.setState({
        isTableLoading: false,
        isButtonLoading: false,
        isEmptyReset: false,
      });
    }
  };
  isEmptyCheck = () => {
    const { searchText, selectedStartDate, selectedEndDate, searchKey } =
      this.state;
    if (searchText || selectedStartDate || selectedEndDate || searchKey) {
      return false;
    }
    return true;
  };
  render() {
    const {
      selectedStartDate,
      selectedEndDate,
      searchText,
      isPaginationDisabled,
      isPaginationReset,
      isTableLoading,
      totalRecordsCount,
      isEmptyReset,
      isButtonLoading,
      searchKey,
      tblData,
      selectedValue,
      alertType,
      openAlert,
      alertMsg,
      isDownloading,
    } = this.state;

    return (
      <Box pb={4}>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "25px 10px",
          }}
        >
          <PageHeader
            label="Audit History"
            style={{
              fontSize: "22px",
            }}
          />
        </Box>
        <CardContainer>
          <Grid container p={2} flexDirection="row">
            <Grid container item columnSpacing={2}>
              <Grid item xs={2} className="">
                <InputLabel>Search by Table Name</InputLabel>
                <TextInputField
                  placeholder="Search by Table Name"
                  name="searchText"
                  id="searchText"
                  value={searchText}
                  onChange={this.handleSearchChange}
                />
              </Grid>
              <Grid item xs={2} className="">
                <InputLabel>Search by Rcrd Key</InputLabel>
                <TextInputField
                  placeholder="Search by Rcrd Key"
                  name="searchKey"
                  id="searchKey"
                  value={searchKey}
                  onChange={this.handleSearchChange}
                />
              </Grid>
              <Grid item ml={0.5} xs={1.5}>
                <InputLabel> From Date</InputLabel>
                <DatePicker
                  name="startDate"
                  placeHolder="Select Date"
                  maxDate={new Date()}
                  value={selectedStartDate}
                  onChange={this.handleStartDateChange}
                />
              </Grid>
              <Grid item xs={1.5} ml={0.5}>
                <InputLabel> To Date</InputLabel>
                <DatePicker
                  disabled={!selectedStartDate}
                  name="endDate"
                  minDate={new Date(selectedStartDate!)}
                  placeHolder="Select Date"
                  maxDate={new Date()}
                  value={selectedEndDate}
                  onChange={this.handleEndDateChange}
                />
              </Grid>
              <Grid
                className="EntryDropdown"
                item
                justifyContent="flex-end"
                xs={2}
                mt={2.9}
                textAlign={"center"}
              >
                {isDownloading ? (
                  <Box
                    style={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <CircularProgress sx={{ color: "#d52b1e" }} />
                  </Box>
                ) : (
                  <SingleSelect
                    isDisabled={tblData.length > 0 ? false : true}
                    customOptions="Download Excel"
                    values={[
                      {
                        text: "Current Page",
                        value: "Current Page",
                      },
                      {
                        text: "1000 records",
                        value: "1000 records",
                      },
                      {
                        text: "2000 records",
                        value: "2000 records",
                      },
                      {
                        text: "5000 records",
                        value: "5000 records",
                      },
                    ]}
                    value={selectedValue}
                    size="small"
                    onChange={this.handleDownloadSelectChange}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={2.1}
                mt={3}
                display="flex"
                justifyContent="flex-end"
              >
                <GradientButton
                  disabled={this.isEmptyCheck()}
                  isButtonLoad={isButtonLoading}
                  label="Search"
                  onClick={this.onSearchClick}
                />
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="flex-end"
                xs={0.7}
                mt={3}
              >
                <RedButton label="Reset" onClick={this.onResetClick} />
              </Grid>
            </Grid>
          </Grid>
          <CardContentDataLoader
            isEmptyReset={isEmptyReset}
            isPaginationReset={isPaginationReset}
            isPaginationDisabled={isPaginationDisabled}
            isLoading={isTableLoading}
            tableData={tblData}
            totalRecordsCount={totalRecordsCount}
            onChangeRow={this.onChangeRow}
            onChangePage={this.onChangePage}
          />
        </CardContainer>
      </Box>
    );
  }
}
export default withRouter(AuditHistory);
