import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
type Props = {
  chartDataArr: any;
  xAxisCategories: string[];
  resultArr: any;
};

const NoOfExceptionLineChart: React.FC<Props> = (props: Props) => {
  const options: Highcharts.Options = {
    chart: {
      type: "spline",
    },
    title: {
      text: "",
    },
    legend: {
      enabled: true,
      verticalAlign: "top",
      align: "left",
      x: -15,
    },
    subtitle: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "#2E2F30",

      formatter: function () {
        const index = props.chartDataArr
          .find((el: any) => el.color === this.color)
          .days.findIndex((days: string) => days === this.x);

        return `
                 <div  style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
                 <b>${this.x}</b><br><br>
                  <span style=color:#BFBFBF; padding-bottom:10px>Spend Src Sys Cd :</span> <span style=padding-left:10px>${
                    props.chartDataArr.find(
                      (el: any) => el.color === this.color
                    ).name
                  }</span><br>
                  <span style=color:#BFBFBF>Total No. Of Errors</span> <span style=padding-left:10px>${
                    this.y
                  }</span><br>
                  <span style=color:#BFBFBF>Corrections Made</span> <span style=padding-left:10px>${
                    props.chartDataArr.find(
                      (el: any) => el.color === this.color
                    ).completed[index]
                  }</span><br>
                  <span style=color:#BFBFBF>No Action Required</span> <span style=padding-left:10px>${
                    props.chartDataArr.find(
                      (el: any) => el.color === this.color
                    ).noactionrequired[index]
                  }</span><br>
                 
                 </div>
                  `;
      },
    },
    xAxis: {
      categories: props.xAxisCategories,
      tickmarkPlacement: "on",
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: "",
      },
      startOnTick: true,
    },

    plotOptions: {
      line: {
        dataLabels: {
          enabled: false,
        },
        linecap: "round",
        enableMouseTracking: true,
      },
      series: {
        lineWidth: 4, // Set the thickness of the lines to 3 pixels
      },
    },
    series: props.chartDataArr,
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default NoOfExceptionLineChart;
