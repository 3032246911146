import React from "react";
import { AppBar, Toolbar, Divider, Box, Typography, Grid } from "@mui/material";
import EliLillyLogo from "../../Static/EliLilly_Logo.png";

import AccountInfo from "./ProfileDrawer";
import Notification from "../Notification";
import OtherAppsDrawer from "./OtherApps/OtherAppsDrawer";

interface Props {
  showMenu: boolean;
}
interface State {}
class Header extends React.Component<Props, State> {
  render() {
    return (
      <AppBar position="static" color="transparent">
        <Box
          display="flex"
          style={{
            backgroundImage:
              "linear-gradient(to right, #D52B1E,#78105B,#273E69)",
            cursor: "pointer",
          }}
        >
          <Grid container>
            <Grid container item md={4}>
              <Toolbar
                style={{
                  paddingLeft: "0",
                }}
              >
                {this.props.showMenu ? <OtherAppsDrawer /> : <></>}
                <img
                  src={EliLillyLogo}
                  alt="logo"
                  style={{
                    filter: "brightness(0)invert(1)",
                    margin: 10,
                    height: 42,
                    width: 65,
                  }}
                />
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: 10, backgroundColor: "#ffffff" }}
                />
                <Typography
                  style={{ color: "#ffffff", fontSize: 20, fontWeight: 520 }}
                >
                  GT Hub
                </Typography>
              </Toolbar>
            </Grid>
            <Grid container item md={6}></Grid>
            <Grid
              container
              item
              md={2}
              justifyContent="end"
              style={{ marginTop: 10 }}
            >
              {this.props.showMenu ? (
                <Grid item>
                  <Notification />
                </Grid>
              ) : (
                <></>
              )}

              <Grid
                item
                style={{
                  marginTop: 5,
                  paddingRight: "1em",
                }}
              >
                <AccountInfo showMenu={this.props.showMenu} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </AppBar>
    );
  }
}

export default Header;
