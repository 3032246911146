import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { RoleContext } from "../../Contexts";
import { RolesModel } from "../../Models/RolesModel";

import AuthProviderService from "../../Services/AuthProviderService";

interface Props extends RouteProps {
  roles: RolesModel[];
  from?: string;
}
interface State {
  isLoading: boolean;
  isApproved: boolean;
  redirectPath: string;
  hasAccessToApp: boolean;
}
class PrivateRoute extends React.Component<Props, State> {
  static contextType = RoleContext;
  context!: React.ContextType<typeof RoleContext>;

  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      isLoading: true,
      isApproved: false,
      redirectPath: "/",
      hasAccessToApp: false,
    };
  }

  componentDidMount() {
    const { roles } = this.props;
    let pathName = window.location.pathname;
    if (window.location.search) {
      pathName += window.location.search;
    }
    if (
      window.location.pathname !== "/auth/callback" &&
      !window.location.pathname.includes("/logout") &&
      !window.location.pathname.includes("/accessdenied")
    ) {
      AuthProviderService.setCallbackPath(pathName);
    }

    AuthProviderService.isLoggedIn().then((isLoggedIn) => {
      let newState = {
        hasAccessToApp: false,
        isLoading: false,
        isApproved: false,
        redirectPath: "/",
      };

      if (!isLoggedIn) {
        AuthProviderService.login();
      } else {
        const hasAccessToApp = this.context.userRoles.length > 0;
        newState.hasAccessToApp = hasAccessToApp;
        if (!hasAccessToApp) {
          newState.redirectPath = "/auth/accessdenied";
        } else {
          const isApproved =
            roles.length === 0 ||
            this.context.userRoles.includes("SUPER_USER") ||
            roles.some((val) => this.context.userRoles.includes(val));
          newState.isApproved = isApproved;
          if (!isApproved) {
            AuthProviderService.setCallbackPath("/auth/noaccess");
            newState.redirectPath = "/auth/noaccess";
          }
        }
      }
      this.setState(newState);
    });
  }

  render() {
    const { isLoading, isApproved, redirectPath, hasAccessToApp } = this.state;
    if (isLoading) {
      return null;
    }

    if (this.props.path !== redirectPath && (!isApproved || !hasAccessToApp)) {
      const renderComponent = () => <Redirect to={redirectPath} />;
      return (
        <Route {...this.props} component={renderComponent} render={undefined} />
      );
    } else {
      return <Route {...this.props} />;
    }
  }
}

export default PrivateRoute;
