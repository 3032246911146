import React from "react";
import { Box, InputBaseComponentProps, TextField } from "@mui/material";
import { isNull, isUndefined } from "lodash";


interface Props {
    id?: string;
    label?: string;
    isReadonly: boolean;
    isDisabled: boolean;
    endAdornment?: React.ReactNode;
    value?: string | number;
    width?: string;
    placeholder?: string;
    type?: string;
    name?: string;
    isRequired?: boolean;
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void);
    min?: number;
    max?: number;
    backgroundColor?:string;
    color?:string
}
interface State {

}
class TextInputField extends React.Component<Props, State> {
    public static defaultProps = {
        isReadonly: false,
        isDisabled: false,
        placeholder: "",
        backgroundColor:"#263F6A",
        color:"#ffffff"
    };

    render() {
        const { id, label, isReadonly, isDisabled, isRequired, value, min, max, onChange, placeholder, width, name, type, backgroundColor, color } = this.props;
        let inputType = 'text';
        let inputValue: string | number = '';
        const inputProps: InputBaseComponentProps  = {};
        if(!isUndefined(min) && !isNull(min)){
            inputProps.min = min;
        }
        if(!isUndefined(max) && !isNull(max)){
            inputProps.max = max;
        }
        if (typeof (value) === 'number') {
            inputType = 'number';
        }

        if (value) {
            inputValue = value;
        }

        return (
            <Box style={{ width: width }}>
                <TextField type={type || inputType} variant="outlined" required
                    size="small"
                    name={name}
                    style={{
                        background: backgroundColor,
                        color: color,
                        borderRadius:"5px"
                    }}
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#ffffff",
                      },
                    }}
                    fullWidth
                    disabled={isDisabled || isReadonly}
                    InputLabelProps={{
                        style: { color: '#fff'}, 
                     }}
                    
                    InputProps={{ required: !isDisabled && isRequired}}
                    inputProps={inputProps}
                    aria-required
                    placeholder={isDisabled ? 'Autogenerated Field' :placeholder}
                    id={id} label={label} value={inputValue} onChange={onChange} />
                    
                
            </Box>
        );
    }
}

export default TextInputField;