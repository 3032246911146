import { IconDefinition, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card } from "@mui/material";
import { isEqual } from "lodash";
import { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IconType } from "../Button/IconButton";
import SupportDataTable from "./SupportDataTable";

interface Props extends RouteComponentProps<any, any, any> {
  tableData: any;
  actionArray?: IconType[];
  IconType?: IconDefinition;
  totalRecordsCount?: number;

  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  handleToolbarCancel?: () => void;

  /* columnDetails: GetColumnDetailsResponseModel[]; */
  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
}

interface State {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  totalCount: number;
  showstep: boolean;
  caretHover: boolean;
}

class SupportDataLoader extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      headCellData: [],
      totalCount: 0,
      showstep: false,
      caretHover: false,
    };
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      this.props.tableData &&
      !isEqual(prevProps.tableData[0], this.props.tableData[0])
    ) {
      this.changeHeadCellData();
    }
    if (prevProps.IconType !== this.props.IconType) {
      this.changeHeadCellData();
    }
    if (prevProps.actionArray?.length !== this.props.actionArray?.length) {
      this.changeHeadCellData();
    }
  };

  componentDidMount = () => {
    this.changeHeadCellData();
  };

  /* onCaretHover = () => {
    this.setState({ caretHover: true });
  };
 */
  changeHeadCellData = () => {
    if (this.props.tableData.length) {
      const headerData: any = Object.keys(this.props.tableData[0]).map((k) => {
        /*  const shouldBeLinked = Object.keys(FIELDS_TO_LINK).includes(k); */
        return {
          key: k,
          label: k,
          /* linkTo: shouldBeLinked
            ? FIELDS_TO_LINK[k as keyof typeof FIELDS_TO_LINK]
            : null, */
        };
      });

      if (this.props.actionArray?.length) {
        headerData.unshift({
          key: "Action",
          label: "Action",
          linkTo: null,
        });
      }
      this.setState({ headCellData: headerData });
    } else {
      this.setState({ headCellData: [] });
    }
  };

  onChangePage = (page: number) => {
    this.props.onChangePage(page);
  };

  handleCaretClick = () => {
    this.setState({ showstep: true });
  };

  onChangeRow = (row: number) => {
    this.props.onChangeRow(row);
  };
  handleCaretCloseClick = () => {
    this.setState({ showstep: false });
  };

  render() {
    const { headCellData, showstep } = this.state;

    return (
      <>
        <SupportDataTable
          isEmptyReset={this.props.isEmptyReset}
          totalRecordsCount={this.props.totalRecordsCount}
          isPaginationReset={this.props.isPaginationReset}
          isPaginationDisabled={this.props.isPaginationDisabled}
          onChangeRow={this.onChangeRow}
          onChangePage={this.onChangePage}
          headCellData={headCellData}
          data={this.props.tableData}
          isLoading={this.props.isLoading}
        />

        {showstep ? (
          <Card className="Card">
            <FontAwesomeIcon
              icon={faXmark}
              size="lg"
              onClick={this.handleCaretCloseClick}
              style={{ marginLeft: 800, marginTop: 12 }}
            />
            <Box style={{ width: "90%" }}></Box>
          </Card>
        ) : null}
      </>
    );
  }
}

export default withRouter(SupportDataLoader);
