import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
interface Props {
  dataValue: number;
}
const KickoutMonitoringTotalRecord = (props: Props) => {
const chartOptions = {
    chart: {
      type: "pie",
      marginRight: -50,
      marginBottom: -100,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "#2E2F30",

      formatter: function () {
        return `
                <div  style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
               <span style=color:#BFBFBF; padding-bottom:10px>Total :</span> <span style=padding-left:10px>${props.dataValue}</span><br>
               </div>
                `;
      },
    },
    title: {
      text: "",
    },
    yAxis: {
      gridLineWidth: 0,
    },
    subtitle: {
      useHTML: true,
      text: "<span style=color:#868B9C>Total</span>",
      floating: true,
      verticalAlign: "middle",
      y: 85,
      x: 8,
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        animation: false,
        states: {
          hover: {
            enabled: false, // Disable hover effects for the donut chart
          },
        },
        center: ["40%"],
        align: "left",
        size: "60%",
        innerSize: "80%", // Set the innerSize to create a hole in the center

        dataLabels: {
          distance: -75,
          y: -5,
          color: "black",
          format: " {point.percentage:.1f}%",
        },

        showInLegend: true,
      },
    },
    legend: {
      enabled: false,
      align: "right", // Set the alignment to the left
      verticalAlign: "middle", // Set the vertical alignment to the middle
      layout: "vertical",
      itemMarginTop: 5,
      itemMarginBottom: 5,
      itemStyle: {
        fontSize: "10px", // Set font size for legend items
      },
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Data",
        data: [
          {
            name: "Total Count",
            y: props.dataValue,
            color: {
              linearGradient: { cx: 0, cy: 0, r: 1 },
              stops: [
                [0, "#7A9FDD"],
                [1, "#1E50A7"],
              ],
            },
            size: "90%",
            dataLabels: {
              format: props.dataValue.toString(), // Display the value in the center
              style: {
                fontSize: "20px", // Set font size for the center value
                color: "#000000",
                fill: "#000000",
              },
            },
          },
          
        ],
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default KickoutMonitoringTotalRecord;
