import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Grid, InputLabel, Link, Popover } from "@mui/material";
import React, { Component } from "react";
import GradientButton from "../../Components/Button/GradientButton";
import { IconType } from "../../Components/Button/IconButton";
import RedButton from "../../Components/Button/RedButton";
import CardContainer from "../../Components/Card/CardContainer";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import ReportingDataloader from "../../Components/Table/ReportingDataloader";
import PageHeader from "../../Components/Text/PageHeader";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInputField";
import {
  OnDemandRptHistoryModel,
  OnDemandSelectionModel,
} from "../../Models/OnDemandReportModels";
import OnDemandReportService from "../../Services/OnDemandReportServices";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment";
import Utils from "../../Common/Utils";
import _ from "lodash";
const OnDemandReportServices = new OnDemandReportService();
type Props = {};

type State = {
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isTableLoading: boolean;
  totalRecordsCount: number | undefined;
  rows: number;
  page: number;
  tableData: any;
  tblData: any[];
  isOpenAdvancedSearch: boolean;
  ProceedViewHistory: boolean;
  isButtonLoading: boolean;
  countryData: SelectModel[];
  rptTypeData: SelectModel[];
  rptIdData: SelectModel[];
  result: OnDemandSelectionModel[];
  reportType: string;
  reportId: string;
  selectedCountry: string;
  submissionType: string;
  descriptionText: string;
  isSearchClick: boolean;
  isEmptyReset: boolean;
  response: any;
  selectedStartDate: string | null;
  selectedEndDate: string | null;
  fileConfigCd: string;
  fileConfigData: SelectModel[];
};

export default class ReportHistoryTable extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      isPaginationDisabled: false,
      isPaginationReset: false,
      isTableLoading: false,
      totalRecordsCount: undefined,
      rows: 5,
      page: 0,
      tableData: [],
      tblData: [],
      selectedStartDate: null,
      selectedEndDate: null,
      isOpenAdvancedSearch: false,
      ProceedViewHistory: false,
      isButtonLoading: false,
      reportType: "",
      reportId: "",
      selectedCountry: "",
      submissionType: "",
      descriptionText: "",
      countryData: [],
      rptTypeData: [],
      rptIdData: [],
      result: [],
      isSearchClick: false,
      isEmptyReset: false,
      response: [],
      fileConfigCd: "",
      fileConfigData: [],
    };
  }
  async componentDidMount() {
    try {
      this.getAllData(5, 1);
      const response = await OnDemandReportServices.getSelectionData();

      const countryData = [
        ...new Set(
          response.data.result.map(
            (el: OnDemandSelectionModel) => el.rpt_cntry_cd
          )
        ),
      ].map((val: string) => {
        return {
          text: val,
          value: val,
        };
      });
      const rptTypeData = [
        ...new Set(
          response.data.result.map((el: OnDemandSelectionModel) => el.rpt_type)
        ),
      ].map((elem) => {
        return {
          text: elem,
          value: elem,
        };
      });

      const rptIdData = [
        ...new Set(
          response.data.result.map((el: OnDemandSelectionModel) => el.rpt_id)
        ),
      ].map((elem: string) => {
        return {
          text: elem,
          value: elem,
        };
      });
      const fileConfigData = response.data.result[0].file_config_cd.map(
        (el: string) => {
          return {
            text: el,
            value: el,
          };
        }
      );
      this.setState({
        countryData,
        rptTypeData,
        rptIdData,
        fileConfigData,
        result: response.data.result,
      });
    } catch (error) {
      console.log(error);
    }
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!_.isEqual(prevState.selectedStartDate, this.state.selectedStartDate)) {
      this.setState({ selectedEndDate: null });
    }
  }
  getAllData = async (rows: number, page: number) => {
    try {
      this.setState({ isTableLoading: true });
      const payLoadObj: OnDemandRptHistoryModel = {
        var_rpt_cntry_cd: "",
        var_rpt_type: "",
        var_rpt_id: "",
        var_start_dt: "",
        var_end_dt: "",
        var_rpt_sbmsn_typ_cd: "",
        var_rpt_sbmsn_desc: "",
        var_recordsperpage: rows,
        var_page: page,
        var_file_config_cd: "",
      };
      const response = await OnDemandReportServices.getRptHistory(payLoadObj);
      if (Number(response.total_count) > 0) {
        let tableData = response.result.map((el: any) => {
          return {
            Rpt_Id: el.rpt_id,
            Rpt_Type: el.rpt_type,
            Rpt_Cntry_Cd: el.rpt_cntry_cd,
            "Submission Type": el.rpt_sbmsn_typ_cd,
            "Submission Description": el.rpt_sbmsn_desc,
            "Submission Preference": el.submission_preference,
            File_Config_Cd: el.file_config_cd,
            "Build Date": el.build_date,
            filePath: el.file_path,
            Status: el.build_status,
          };
        });

        this.setState({
          response: response.result,
          tblData: tableData,
          isTableLoading: false,
          totalRecordsCount: Number(response.total_count),
        });
      } else {
        this.setState({
          isTableLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isTableLoading: false,
      });
    }
  };
  onChangePage = (page: number) => {
    this.setState({ page }, () => {
      !this.state.isSearchClick
        ? this.getAllData(this.state.rows, page)
        : this.handleSearchClick();
    });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row, page: 1 }, () => {
      this.setState({ isEmptyReset: !this.state.isEmptyReset });
      !this.state.isSearchClick
        ? this.getAllData(row, this.state.page > 1 ? 1 : this.state.page)
        : this.handleSearchClick();
    });
  };
  onActionClick = async (action: IconType, record: any) => {
    switch (action) {
      case "Download":
        const filePath = record.filePath.split("/");
        let key = filePath[4];
        let countryName = filePath[2];
        let folderName = filePath[3];
        const response = await OnDemandReportServices.getFile(
          key,
          countryName,
          folderName
        );
        Utils.openUrlParent(response);
        break;
    }
  };
  onSearchClick = () => {
    this.setState(
      {
        rows: 5,
        page: 1,
        isPaginationReset: !this.state.isPaginationReset,
        isButtonLoading: true,
      },
      () => {
        this.handleSearchClick();
      }
    );
  };
  handleSearchClick = async () => {
    try {
      const {
        reportType,
        selectedCountry,
        selectedStartDate,
        selectedEndDate,
        submissionType,
        descriptionText,
        reportId,
        fileConfigCd,
        page,
        rows,
      } = this.state;
      this.setState(
        {
          isTableLoading: true,
          isButtonLoading: true,
          isSearchClick: true,
        },
        async () => {
          const payLoadObj: OnDemandRptHistoryModel = {
            var_rpt_cntry_cd: selectedCountry,
            var_rpt_type: reportType,
            var_rpt_id: reportId,
            var_start_dt: selectedStartDate,
            var_end_dt: selectedEndDate,
            var_rpt_sbmsn_typ_cd: submissionType,
            var_rpt_sbmsn_desc: descriptionText,
            var_page: page,
            var_recordsperpage: rows,
            var_file_config_cd: fileConfigCd,
          };
          const response = await OnDemandReportServices.getRptHistory(
            payLoadObj
          );
          if (Number(response.total_count) > 0) {
            let tableData = response.result.map((el: any) => {
              return {
                Rpt_Id: el.rpt_id,
                Rpt_Type: el.rpt_type,
                Rpt_Cntry_Cd: el.rpt_cntry_cd,
                "Submission Type": el.rpt_sbmsn_typ_cd,
                "Submission Description": el.rpt_sbmsn_desc,
                "Submission Preference": el.submission_preference,
                File_Config_Cd: el.file_config_cd,
                "Build Date": el.build_date,
                filePath: el.file_path,
                Status: el.build_status,
              };
            });
            this.setState({
              response: response.result,
              tblData: tableData,
              totalRecordsCount: Number(response.total_count),
              isTableLoading: false,
              isButtonLoading: false,
              isOpenAdvancedSearch: false,
            });
          } else {
            this.setState({
              tblData: [],
              totalRecordsCount: Number(response.total_count),
              isTableLoading: false,
              isPaginationReset: true,
              isEmptyReset: !this.state.isEmptyReset,
              page: 1,
              rows: 5,
              isButtonLoading: false,
              isSearchClick: false,
              isOpenAdvancedSearch: false,
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
      this.setState({
        isTableLoading: false,
        isButtonLoading: false,
        isEmptyReset: false,
      });
    }
  };

  handleAdvancedSearchLinkClick = () => {
    this.setState({ isOpenAdvancedSearch: !this.state.isOpenAdvancedSearch });
  };
  isEmptyCheck = () => {
    const {
      selectedCountry,
      reportType,
      selectedEndDate,
      selectedStartDate,
      fileConfigCd,
      submissionType,
      descriptionText,
      reportId,
    } = this.state;
    if (
      selectedCountry ||
      reportType ||
      fileConfigCd ||
      selectedStartDate ||
      selectedEndDate ||
      submissionType ||
      descriptionText ||
      reportId
    ) {
      return false;
    }
    return true;
  };
  handleCountrySelectChange = (selected: SelectModel, targetId?: string) => {
    this.setState({
      selectedCountry: selected.value,
    });
  };
  handleRptTypeSelectChange = (selected: SelectModel, targetId?: string) => {
    this.setState(
      {
        reportType: selected.value,
      },
      () => {
        const filteredRptIdData = this.state.result.filter(
          (el: OnDemandSelectionModel) =>
            el.rpt_id.includes("-")
              ? el.rpt_id.split("-")[0] === this.state.reportType.split("-")[0]
              : el.rpt_id.split("_")[0] === this.state.reportType.split("-")[0]
        );
        const rptIdData = [
          ...new Set(
            filteredRptIdData.map((el: OnDemandSelectionModel) => el.rpt_id)
          ),
        ].map((elem: string) => {
          return {
            text: elem,
            value: elem,
          };
        });
        this.setState({ rptIdData, reportId: "" });
      }
    );
  };

  handleSelectChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState({
        ...this.state,
        [targetId]: selected.value,
      });
    }
  };
  handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const descriptionVal = event.target.value;
    this.setState({
      descriptionText: descriptionVal,
    });
  };
  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        selectedStartDate: moment(value).format("YYYY-MM-DD"),
      });
    }
  };
  handleEndDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (this.state.selectedStartDate) {
      const startDate = new Date(this.state.selectedStartDate).getDate();
      const startDateMonth = new Date(this.state.selectedStartDate).getMonth();
      const startDateYear = new Date(
        this.state.selectedStartDate
      ).getFullYear();
      const endDate = new Date(value!).getDate();
      const endDateMonth = new Date(value!).getMonth();
      const endDateYear = new Date(value!).getFullYear();
      if (
        startDate > endDate &&
        startDateMonth >= endDateMonth &&
        startDateYear >= endDateYear
      ) {
        return null;
      }

      this.setState({
        selectedEndDate: moment(value).format("YYYY-MM-DD 23:59:59.999"),
      });
    }
  };
  formReset = () => {
    this.setState({
      selectedCountry: "",
      reportId: "",
      reportType: "",
      fileConfigCd: "",
      submissionType: "",
      descriptionText: "",
      selectedEndDate: null,
      selectedStartDate: null,
      isButtonLoading: false,
      isSearchClick: false,
      isPaginationReset: !this.state.isPaginationReset,
      page: 1,
      rows: 5,
    });
    this.getAllData(5, 1);
  };
  isResetEmptyCheck = () => {
    const {
      selectedCountry,
      reportType,
      fileConfigCd,
      submissionType,
      descriptionText,
      selectedStartDate,
      selectedEndDate,
      reportId,
    } = this.state;
    if (
      selectedCountry ||
      reportType ||
      selectedStartDate ||
      selectedEndDate ||
      fileConfigCd ||
      submissionType ||
      descriptionText ||
      reportId
    ) {
      return false;
    }
    return true;
  };
  render() {
    const { isPaginationDisabled, isPaginationReset, isTableLoading } =
      this.state;
    const {
      countryData,
      rptIdData,
      reportId,
      rptTypeData,
      reportType,
      selectedCountry,
      fileConfigData,
      submissionType,
      descriptionText,
      totalRecordsCount,
      isButtonLoading,
      isEmptyReset,
      selectedEndDate,
      selectedStartDate,
      fileConfigCd,
    } = this.state;
    return (
      <>
        <Box pb={4}>
          <CardContainer>
            <Grid
              container
              columns={8}
              p={2}
              columnSpacing={2}
              className="ProceedForm"
            >
              <Grid item xs={1} className="OnDemandReport">
                <InputLabel>Select Country</InputLabel>
                <SingleSelect
                  size="small"
                  id="selectedCountry"
                  noneValue={true}
                  value={selectedCountry}
                  values={countryData}
                  onChange={this.handleCountrySelectChange}
                />
              </Grid>
              <Grid item xs={1.2} className="OnDemandReport">
                <InputLabel>Report Type</InputLabel>
                <SingleSelect
                  size="small"
                  id="reportType"
                  noneValue={true}
                  value={reportType}
                  values={rptTypeData}
                  onChange={this.handleRptTypeSelectChange}
                />
              </Grid>

              <Grid item xs={1.2} className="OnDemandReport">
                <InputLabel>Report ID</InputLabel>
                <SingleSelect
                  size="small"
                  id="reportId"
                  noneValue={true}
                  value={reportId}
                  values={rptIdData}
                  onChange={this.handleSelectChange}
                />
              </Grid>
              <Grid item xs={1}>
                <InputLabel> From Date</InputLabel>
                <DatePicker
                  name="startDate"
                  placeHolder="Select Date"
                  maxDate={new Date()}
                  value={selectedStartDate}
                  onChange={this.handleStartDateChange}
                />
              </Grid>
              <Grid item xs={1} className="EndDate">
                <InputLabel> To Date</InputLabel>
                <DatePicker
                  disabled={!selectedStartDate}
                  name="endDate"
                  minDate={new Date(selectedStartDate!)}
                  placeHolder="Select Date"
                  maxDate={new Date()}
                  value={selectedEndDate}
                  onChange={this.handleEndDateChange}
                />
              </Grid>

              <Grid item xs={1.2} mt={2.1} ml={0.2}>
                <Link
                  style={{
                    color: "#0577A3",
                    cursor: "pointer",
                    textDecoration: "none",
                    marginTop: "10px",
                    display: "block",
                  }}
                  onClick={this.handleAdvancedSearchLinkClick}
                >
                  <FilterListIcon
                    style={{
                      marginTop: "1px",
                      float: "left",
                      marginRight: "3px",
                    }}
                  />
                  Advanced Search
                </Link>
                <Popover
                  className="CustomPopover"
                  open={this.state.isOpenAdvancedSearch}
                  anchorReference="anchorPosition"
                  anchorPosition={{ top: 10, left: 805 }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      width: 700,
                      position: "absolute",
                      top: "250px!important",
                      height: "450px",
                      overflowY: "auto",
                    },
                  }}
                >
                  <Box sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={6} md={8}>
                        <PageHeader label="Advanced Search" />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        md={4}
                        style={{ textAlign: "right", cursor: "pointer" }}
                      >
                        <FontAwesomeIcon
                          size="2x"
                          onClick={this.handleAdvancedSearchLinkClick}
                          icon={faXmark}
                        />
                      </Grid>
                    </Grid>
                    <hr style={{ border: "1px solid #D1D1D1" }} />

                    <Grid container mt={3}>
                      <Grid container item columnSpacing={2} mb={3}>
                        <Grid item xs={7} className="OnDemandReport">
                          <InputLabel>File Config Code</InputLabel>
                          <SingleSelect
                            size="small"
                            id="fileConfigCd"
                            noneValue={true}
                            value={fileConfigCd}
                            values={fileConfigData}
                            onChange={this.handleSelectChange}
                          />
                        </Grid>
                        <Grid item xs={5} className="OnDemandReport">
                          <InputLabel>Submission Type</InputLabel>
                          <SingleSelect
                            size="small"
                            id="submissionType"
                            noneValue={true}
                            value={submissionType}
                            values={[
                              {
                                text: "Full",
                                value: "Full",
                              },
                              {
                                text: "Partial",
                                value: "Partial",
                              },
                            ]}
                            onChange={this.handleSelectChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid item xs={12}>
                          <InputLabel>Submission Description</InputLabel>
                          <TextAreaInputField
                            minRows={4}
                            placeholder="Enter your Description"
                            id="descriptionText"
                            value={descriptionText}
                            onChange={this.handleDescriptionChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      columnGap={1.5}
                      justifyContent={"flex-end"}
                      mt={4}
                    >
                      <RedButton
                        label="Reset"
                        disabled={this.isResetEmptyCheck()}
                        onClick={this.formReset}
                      />

                      <GradientButton
                        isButtonLoad={isButtonLoading}
                        disabled={this.isEmptyCheck()}
                        label="Search"
                        onClick={this.onSearchClick}
                      />
                    </Grid>
                  </Box>
                </Popover>
              </Grid>
              <Grid item xs={1.2} mt={1.9}>
                <Grid container columnGap={1.5} justifyContent={"flex-end"}>
                  <RedButton
                    disabled={this.isResetEmptyCheck()}
                    label="Reset"
                    onClick={this.formReset}
                  />

                  <GradientButton
                    isButtonLoad={isButtonLoading}
                    disabled={this.isEmptyCheck()}
                    label="Search"
                    onClick={this.onSearchClick}
                  />
                </Grid>
              </Grid>
            </Grid>

            <hr
              style={{
                border: "0.5px solid #D8D8D8",
                marginBottom: "15px",
              }}
            />
            <ReportingDataloader
              isEmptyReset={isEmptyReset}
              isPaginationReset={isPaginationReset}
              isPaginationDisabled={isPaginationDisabled}
              isLoading={isTableLoading}
              tableData={this.state.tblData}
              totalRecordsCount={totalRecordsCount}
              onChangeRow={this.onChangeRow}
              onChangePage={this.onChangePage}
              actionArray={["Download"]}
              onActionClick={this.onActionClick}
            />
          </CardContainer>
        </Box>
      </>
    );
  }
}
