/* eslint-disable no-lone-blocks */
import {
  AlertColor,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import _, { isEqual } from "lodash";
import React from "react";
import { linkMappingData } from "../../Common/Constants";
import { CustomEventType } from "../../Common/Enums";
import Utils from "../../Common/Utils";
import { RoleContext } from "../../Contexts";
import CustomEventHandlerService from "../../Services/CustomEventHandlerService";
import GradientButton from "../Button/GradientButton";
import RedOutlinedButton from "../Button/RedOutlinedButton";
import NoRecords from "../NoRecords/NoRecords";
import PaginationNew from "../Pagination";
import SelectModel from "../Select/SelectModel";
import SingleSelect from "../Select/SingleSelect";
import SnackbarAlert from "../SnackBarAlert/SnackbarAlert";
import TextInputField from "../TextInputField/TextInputField";
import "./style.css";
import TextAreaInputField from "../TextInputField/TextAreaInputField";

type tableModeForException = "BulkComments" | undefined;
interface Props extends RouteComponentProps<any, any, any> {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  headCollapseData: {
    key: string;
    label: string;
  }[];
  data: any;
  dataCollapse: any;
  totalRecordsCount: number | undefined;
  errTblRecordsCount: number | undefined;
  onClickCheckBox?: (selectedRows: any, data: any) => void;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  onErrTblChangeRow: (row: number) => void;
  onErrTblChangePage: (page: number) => void;
  handleComment?: (record: any) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  handleMode: (action: tableModeForException) => void;
  handleToolbarCancel?: () => void;
  isLoading?: boolean;
  checkBoxDifferentiator?: string | number;
  isPaginationDisabled: boolean;
  isPaginationReset?: boolean;
  isEmptyReset?: boolean;
  isErrTblPaginationDisabled: boolean;
  isErrTblEmptyReset: boolean;
  exceptionModes: tableModeForException;
  handleApply: (event: any) => void;
  bulkEditInputFormData: any;
  getErrorType: (record: any) => void;
  isSubTableLoading: boolean;
  usersData: SelectModel[];
  handleBulkInputChange: (event: any, targetId?: string) => void;
  onResetClick: () => void;
  isButtonLoad: boolean;
  isSaveDisabled: boolean;
  handleSave: (data: any) => void;
  openLinksData: any[];
}

interface State {
  data: any;
  dataCollapse: any;
  selectedData: any;
  selectAll: boolean;
  selectSingle: boolean;
  rowsPerPage: number;
  page: number;
  ActionMenu: boolean;
  expandedRow: any;
  expandedPanel: any;
  collapsibleOpen: boolean;
  isLoading: boolean;
  isEmptyReset: boolean;
  isTableOpen: {
    open: boolean;
    recordId: number | null;
  };
  linkMappingData: any[];
  sdmTableDetails: string;
  selectedLink: string;
  accessedTableData: any[];
  roleData: any[];
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
}

const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

class Table extends Component<Props, State> {
  static contextType = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      dataCollapse: [],
      selectAll: false,
      selectSingle: false,
      selectedData: [],
      rowsPerPage: 5,
      page: 0,
      ActionMenu: false,
      expandedRow: null,
      expandedPanel: null,
      collapsibleOpen: false,
      isLoading: false,
      isEmptyReset: false,
      isTableOpen: {
        open: false,
        recordId: null,
      },
      linkMappingData: [],
      sdmTableDetails: "",
      selectedLink: "",
      accessedTableData: [],
      roleData: [],
      openAlert: false,
      alertMsg: "",
      alertType: "success",
    };
  }

  handleActionOptionClick = () => {
    this.setState({ ActionMenu: !this.state.ActionMenu });
  };
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        data: this.props.data,
        selectAll: false,
      });
    }
    if (!isEqual(prevProps.openLinksData, this.props.openLinksData)) {
      this.setState({
        linkMappingData: this.props.openLinksData
          .filter((el: any) => !el.includes("SDM"))
          .map((val: any) => {
            return {
              text: val,
              value: val,
            };
          }),
        sdmTableDetails: this.props.openLinksData.filter((el: any) =>
          el.includes("SDM")
        )[0],
      });
    }
    if (!isEqual(prevProps.dataCollapse, this.props.dataCollapse)) {
      this.setState({
        dataCollapse: this.props.dataCollapse,
        isTableOpen: {
          open: false,
          recordId: null,
        },
        linkMappingData: [],
        sdmTableDetails: "",
      });
    }
    if (this.props.onClickCheckBox) {
      if (!isEqual(prevState.data, this.state.data)) {
        this.setState({
          selectedData: this.state.data.filter(
            (el: any) => el.checked === true
          ),
          selectedLink: "",
        });
      }
      if (!isEqual(prevState.selectedData, this.state.selectedData)) {
        this.props.onClickCheckBox(this.state.selectedData, this.state.data);
      }
    }
  };

  componentDidMount = () => {
    this.setState({
      data: this.props.data,
      dataCollapse: this.props.dataCollapse,
    });
    if (this.context.accessedTables.length > 0) {
      const data = this.context.accessedTables.map((el: any) => {
        return {
          roleAssigned: el.roleAssigned,
          tables: el.tables.map((val: any) => {
            return val;
          }),
        };
      });
      const roleData = data.map((val: any) => {
        return val.roleAssigned;
      });
      this.setState({ roleData, accessedTableData: data });
    }
  };

  onClickCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    action: "single" | "all" | "errorHead",
    singleRow?: any,
    index?: number
  ) => {
    if (this.props.onClickCheckBox) {
      switch (action) {
        case "single":
          const dataMapped = this.state.data.map((el: any, ind: number) => {
            if (el.id) {
              if (el.id === singleRow?.id) {
                return { ...el, checked: event.target.checked };
              }
              return el;
            } else {
              if (index === ind) {
                return { ...el, checked: event.target.checked };
              }
              return el;
            }
          });

          this.setState({ data: dataMapped });

          if (
            dataMapped.some((d: any) => d.checked === false) &&
            this.state.selectAll
          ) {
            this.setState({ selectAll: false });
          } else if (
            dataMapped.every((d: any) => d.checked === true) &&
            !this.state.selectAll
          ) {
            this.setState({ selectSingle: true });
          }
          break;
        case "all":
          this.setState({
            data: this.state.data.map((d: any) => ({
              ...d,
              checked: event.target.checked,
            })),
          });
          this.setState({
            selectAll: event.target.checked,
          });

          break;
        default:
          break;
      }
    }
  };
  handleErrorHeadExpand = (isExpand: boolean) => {
    if (isExpand === true) {
      this.setState({
        linkMappingData: this.props.openLinksData
          .filter((el: any) => !el.includes("SDM"))
          .map((val: any) => {
            return {
              text: val,
              value: val,
            };
          }),
        sdmTableDetails: this.props.openLinksData.filter((el: any) =>
          el.includes("SDM")
        )[0],
      });
    } else {
      this.props.handleMode(undefined);
      this.props.getErrorType(null);
      this.setState({
        sdmTableDetails: "",
        linkMappingData: [],
        selectedLink: "",
        selectAll: false,
        data: this.state.data.map((el: any) => {
          return { ...el, checked: false };
        }),
      });
    }
  };
  onClickLink = (fieldClicked: string, record: any) => {
    if (this.props.onClickLink) {
      this.props.onClickLink(fieldClicked, record);
    }
  };
  onFormInputChange = (event: any, targetId?: string) => {
    this.props.handleBulkInputChange(event, targetId);
  };

  handleCommentClose = (event: any) => {
    this.props.handleMode(undefined);
  };

  handleOpenTable = (id: number, record: any) => {
    const { open, recordId } = this.state.isTableOpen;
    if (open) {
      if (id === recordId) {
        this.handleErrorHeadExpand(false);
        this.setState({
          isTableOpen: {
            recordId: null,
            open: false,
          },
        });
      } else {
        this.props.getErrorType(record);
        this.handleErrorHeadExpand(true);
        this.setState({
          isTableOpen: {
            recordId: id,
            open: true,
          },
        });
      }
    } else {
      this.handleErrorHeadExpand(!this.state.isTableOpen.open);
      this.props.getErrorType(record);
      this.setState({
        isTableOpen: {
          recordId: id,
          open: !this.state.isTableOpen.open,
        },
      });
    }
  };
  handleSubmit = (event: any) => {
    event.preventDefault();
    this.props.handleSave(
      this.state.data.filter((el: any) => el.checked === true)
    );
  };
  handleOpenLinkClick = (selected: SelectModel) => {
    this.setState({ selectedLink: selected.value });
    let linkMapping: any = linkMappingData;
    let linkValue = linkMapping[selected.value];
    Utils.openUrlParent(linkValue);
  };
  handleSdmButtonClick = () => {
    const { accessedTableData, sdmTableDetails } = this.state;
    let tableName = sdmTableDetails.split(": ")[1];
    let roleValueData = accessedTableData.filter((obj) =>
      obj.tables.includes(tableName)
    );
    localStorage.setItem("sdmTable", tableName);
    localStorage.setItem(
      "Role",
      _.startCase(_.lowerCase(roleValueData[0].roleAssigned))
    );

    if (roleValueData.length > 0) {
      const roleValue = _.startCase(_.lowerCase(roleValueData[0].roleAssigned));
      CustomEventHandlerService.dispatch(CustomEventType.ChangeTable, {
        tableName,
        roleValue,
      });
      Utils.openUrlParent(`/sdm`);
      //this.props.history.push(`/sdm`);
      localStorage.setItem("callbackPath", "/sdm");
    } else {
      this.setState({
        alertMsg: `You do not have access to ${tableName} SDM table.`,
        alertType: "error",
        openAlert: true,
      });
    }
  };
  onCancelClick = () => {
    this.props.onResetClick();
    this.setState({
      selectedLink: "",
      linkMappingData: [],
      sdmTableDetails: "",
    });
  };
  handleEditInputChange = (
    event: any,
    index: any,
    data?: any,
    targetId?: any
  ) => {
    this.setState({
      data: this.state.data.map((el: any, ind: any) => {
        if (ind === index) {
          return {
            ...el,
            [targetId]: event.target.value ? event.target.value : null,
            checked: true,
          };
        }
        return el;
      }),
    });
  };
  handleSelectChange = (selected: any, index: any, targetId?: any) => {
    this.setState({
      data: this.state.data.map((el: any, ind: any) => {
        if (ind === index) {
          return {
            ...el,
            [targetId]: selected.value ? selected.value : null,
            checked: true,
          };
        }
        return el;
      }),
    });
  };
  statusData = [
    {
      text: "Action Required",
      value: "Action Required",
    },
    {
      text: "Sent to Source",
      value: "Sent to Source",
    },
    {
      text: "In Progress",
      value: "In Progress",
    },
    {
      text: "No Action Required",
      value: "No Action Required",
    },
    {
      text: "Completed",
      value: "Completed",
    },
  ];
  render() {
    const {
      headCellData,
      headCollapseData,
      usersData,
      bulkEditInputFormData,
      isButtonLoad,
    } = this.props;
    const {
      data,
      dataCollapse,
      isTableOpen,
      selectAll,
      selectedData,
      linkMappingData,
      selectedLink,
      sdmTableDetails,
      openAlert,
      alertMsg,
      alertType,
    } = this.state;
    return (
      <Box p={2}>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        {this.props.isLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            {dataCollapse.length > 0 ? (
              <form onSubmit={this.handleSubmit}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  mb={2}
                  className="ExceptionActionbtns"
                >
                  {sdmTableDetails ? (
                    <Grid item mr={2}>
                      <RedOutlinedButton
                        label=" SDM "
                        onClick={this.handleSdmButtonClick}
                      />
                    </Grid>
                  ) : null}
                  {linkMappingData.length > 0 ? (
                    <Grid item mr={2}>
                      <SingleSelect
                        id="openLinks"
                        customOptions="Open Links"
                        defaultValue="None"
                        value={selectedLink}
                        values={linkMappingData}
                        backgroundColor="#ffffff!important"
                        color="#D52B1E!important"
                        border="2px solid #D52B1E!important"
                        onChange={this.handleOpenLinkClick}
                      />
                    </Grid>
                  ) : null}

                  {selectedData.length > 0 ? (
                    <>
                      {selectedData.length > 1 ? (
                        <>
                          <Grid item mr={2}>
                            <RedOutlinedButton
                              label="Cancel"
                              onClick={this.onCancelClick}
                            />
                          </Grid>
                          <Grid item mr={2}>
                            <GradientButton
                              isButtonLoad={isButtonLoad}
                              disabled={this.props.isSaveDisabled}
                              label="Save"
                              type="submit"
                            />
                          </Grid>
                          <Grid item>
                            <RedOutlinedButton
                              label=" Bulk Comment"
                              onClick={() => {
                                this.props.handleMode("BulkComments");
                              }}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          {selectedData.length === 1 ? (
                            <>
                              <Grid item mr={2}>
                                <RedOutlinedButton
                                  label="Cancel"
                                  onClick={this.onCancelClick}
                                />
                              </Grid>
                              <Grid item>
                                <GradientButton
                                  isButtonLoad={isButtonLoad}
                                  disabled={this.props.isSaveDisabled}
                                  label="Save"
                                  type="submit"
                                />
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </Grid>
                {dataCollapse.map((record: any, index: number) => (
                  <Card
                    key={`card-row-${index}`}
                    className="ExceptionCollapse"
                    style={{
                      background: "#CCD5DD",
                      boxShadow: "0px 3px 6px #00000029",
                      borderRadius: "6px",
                      border: "none",
                      opacity: 1,
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexGrow: "1",
                        padding: "12px 0px",
                      }}
                      className={
                        isTableOpen.open && isTableOpen.recordId === index
                          ? "ExpandCard"
                          : ""
                      }
                    >
                      <Grid container width={"5em"}>
                        <Grid pt={1} marginLeft={2}>
                          <IconButton
                            onClick={() => this.handleOpenTable(index, record)}
                          >
                            <KeyboardArrowRightIcon
                              className={
                                isTableOpen.open &&
                                isTableOpen.recordId === index
                                  ? "ExpandArrow"
                                  : "NoExpandArrow"
                              }
                              sx={{ fontSize: "0.9rem" }}
                              style={{
                                color: "#828282",
                                fontSize: "20px",
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                      {headCollapseData.map((el: any, ind: number) => (
                        <Grid
                          key={`headCollapse-${ind}`}
                          container
                          item
                          className="ColHeader"
                          xs={el.label === "errorType" ? 4.5 : 1.5}
                        >
                          <Grid
                            /*  xs={12} */
                            item
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              paddingBottom: "10px",
                            }}
                          >
                            {Utils.camelToTitle(el.label)}
                          </Grid>

                          <Grid
                            xs={12}
                            item
                            style={{
                              fontSize: "0.95rem",
                              fontWeight:
                                isTableOpen.open &&
                                isTableOpen.recordId === index &&
                                el.label === "errorType"
                                  ? "600"
                                  : "550",
                              color:
                                isTableOpen.open &&
                                isTableOpen.recordId === index &&
                                el.label === "errorType"
                                  ? "#D42A1E"
                                  : el.label === "errorType"
                                  ? "#0075a2"
                                  : "black",
                            }}
                          >
                            {record[el?.key]}
                          </Grid>
                        </Grid>
                      ))}
                    </div>

                    {isTableOpen.open && isTableOpen.recordId === index ? (
                      <Box bgcolor={"transparent"} boxShadow={"none"}>
                        {this.props.isSubTableLoading ? (
                          <Box
                            style={{
                              justifyContent: "center",
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "flex",
                              margin: "30vh",
                            }}
                          >
                            <CircularProgress sx={{ color: "#d52b1e" }} />
                          </Box>
                        ) : (
                          <TableContainer
                            component={Paper}
                            style={{
                              background: "transparent",
                              boxShadow: "none",
                            }}
                            className="ExceptionTable"
                            sx={{
                              maxHeight: "40rem",
                              overflow: "auto",
                            }}
                          >
                            <MaterialTable
                              size="medium"
                              sx={{ minWidth: "500" }}
                              aria-label="sticky table"
                              style={{
                                background: "none",
                                boxShadow: "none",
                              }}
                            >
                              <TableHead
                                style={{
                                  background: "#ced7de",
                                }}
                              >
                                <TableRow>
                                  <TableCell
                                    /*  width={"5%"} */
                                    style={{
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    <Checkbox
                                      disabled={
                                        this.props.exceptionModes ===
                                        "BulkComments"
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      size="small"
                                      checked={selectAll}
                                      sx={{
                                        color: "#000000",
                                        "&.Mui-checked": {
                                          color: "#3DA470",
                                        },
                                        "& .MuiSvgIcon-root": {
                                          borderRadius: "4px",
                                        },
                                      }}
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) =>
                                        this.onClickCheckBox(
                                          event,
                                          "all",
                                          record,
                                          index
                                        )
                                      }
                                    />
                                  </TableCell>

                                  {headCellData.map((ele, ind) => {
                                    if (
                                      ele &&
                                      !["id", "checked"].includes(ele.key)
                                    ) {
                                      return (
                                        <TableCell
                                          key={`${ind}-headcell`}
                                          style={{
                                            background: "none",
                                            minWidth: "10em",
                                            whiteSpace: "nowrap",
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {ele.label}
                                        </TableCell>
                                      );
                                    }
                                    return null;
                                  })}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.props.exceptionModes ===
                                "BulkComments" ? (
                                  <StyledTableRow
                                    className="CommentsRow"
                                    style={{
                                      background: "white",
                                    }}
                                    mode={this.props.exceptionModes}
                                  >
                                    <TableCell
                                      style={{
                                        position: "sticky",
                                        left: "0px",
                                      }}
                                      colSpan={3}
                                    >
                                      <Grid item container>
                                        <Grid item xs={9.5} mt={1}>
                                          <Typography
                                            style={{
                                              textAlign: "left",
                                              fontStyle: "italic",
                                              fontFamily: "Roboto",
                                              fontSize: 14,
                                              color: "#FF4A4A",
                                            }}
                                          >
                                            Enter the value to apply across all
                                            records displayed.
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                          <IconButton
                                            onClick={(event) =>
                                              this.props.handleApply(event)
                                            }
                                          >
                                            <CheckIcon
                                              color={
                                                bulkEditInputFormData.Comments ||
                                                bulkEditInputFormData.Status ||
                                                bulkEditInputFormData.Assigned_To
                                                  ? "success"
                                                  : "disabled"
                                              }
                                            />
                                          </IconButton>
                                          <IconButton
                                            onClick={(event: any) => {
                                              this.handleCommentClose(event);
                                            }}
                                          >
                                            <CloseIcon color={"error"} />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    </TableCell>

                                    <TableCell
                                      //colSpan={1}
                                      className="OnDemandContent"
                                    >
                                      <SingleSelect
                                        id="Status"
                                        value={
                                          bulkEditInputFormData.Status ?? ""
                                        }
                                        values={this.statusData}
                                        onChange={(event) => {
                                          this.onFormInputChange(
                                            event,
                                            "Status"
                                          );
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell>
                                      <TextInputField
                                        isRequired={false}
                                        placeholder=""
                                        id="Comments"
                                        value={
                                          bulkEditInputFormData.Comments ?? ""
                                        }
                                        onChange={(event) => {
                                          this.onFormInputChange(
                                            event,
                                            "Comments"
                                          );
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell className="OnDemandContent">
                                      <SingleSelect
                                        id="Assigned_To"
                                        isDisabled={
                                          !(
                                            this.state.roleData.includes(
                                              "PUBLICATION_LEAD"
                                            ) ||
                                            this.state.roleData.includes(
                                              "SUPER_USER"
                                            ) ||
                                            this.state.roleData.includes(
                                              "MANAGEMENT_LEADERSHIP"
                                            )
                                          )
                                        }
                                        value={
                                          bulkEditInputFormData.Assigned_To ??
                                          ""
                                        }
                                        values={usersData}
                                        onChange={(event) => {
                                          this.onFormInputChange(
                                            event,
                                            "Assigned_To"
                                          );
                                        }}
                                      />
                                    </TableCell>
                                  </StyledTableRow>
                                ) : (
                                  <></>
                                )}

                                {data.length > 0
                                  ? data.map((rowData: any, id: any) => (
                                      <StyledTableRow
                                        key={`subtable-${id}-${index}`}
                                      >
                                        <TableCell
                                          style={{
                                            paddingLeft: "5px",
                                          }}
                                        >
                                          <Checkbox
                                            disabled={
                                              this.props.exceptionModes ===
                                              "BulkComments"
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                            size="small"
                                            sx={{
                                              color: "#000000",
                                              "&.Mui-checked": {
                                                color: "#3DA470",
                                              },
                                              "& .MuiSvgIcon-root": {
                                                borderRadius: "4px",
                                              },
                                            }}
                                            checked={rowData.checked || false}
                                            onChange={(
                                              event: React.ChangeEvent<HTMLInputElement>
                                            ) =>
                                              this.onClickCheckBox(
                                                event,
                                                "single",
                                                rowData,
                                                id
                                              )
                                            }
                                          />
                                        </TableCell>

                                        {headCellData.map(
                                          (el: any, headIndex: number) => {
                                            if (
                                              el &&
                                              !["id", "checked"].includes(
                                                el.key
                                              )
                                            ) {
                                              return (
                                                <TableCell
                                                  key={`headIndex-${headIndex}-${id}`}
                                                  className="ExceptionHead"
                                                  width={
                                                    el.key === "Comments"
                                                      ? "600px"
                                                      : el.key === "Status"
                                                      ? "170px"
                                                      : el.key === "Assigned_To"
                                                      ? "470px"
                                                      : ""
                                                  }
                                                >
                                                  {el.key === "Status" ? (
                                                    <SingleSelect
                                                      isDisabled={
                                                        !rowData.checked
                                                      }
                                                      value={
                                                        rowData[el.key] ?? ""
                                                      }
                                                      values={this.statusData}
                                                      onChange={(event) =>
                                                        this.handleSelectChange(
                                                          event,
                                                          id,
                                                          "Status"
                                                        )
                                                      }
                                                    />
                                                  ) : el.key === "Comments" ? (
                                                    <>
                                                      {rowData[el.key] &&
                                                      rowData[el.key].length >
                                                        50 ? (
                                                        <TextAreaInputField
                                                          id="comments"
                                                          maxRows={2}
                                                          isDisabled={
                                                            !rowData.checked
                                                          }
                                                          isRequired={
                                                            rowData[
                                                              "Status"
                                                            ] ===
                                                            "No Action Required"
                                                          }
                                                          value={
                                                            rowData[el.key] ??
                                                            ""
                                                          }
                                                          onChange={(event) =>
                                                            this.handleEditInputChange(
                                                              event,
                                                              id,
                                                              rowData,
                                                              el.key
                                                            )
                                                          }
                                                          color={
                                                            !rowData.checked
                                                              ? "#9e9e9e"
                                                              : ""
                                                          }
                                                        />
                                                      ) : (
                                                        <TextField
                                                          className="ExceptionComment"
                                                          disabled={
                                                            !rowData.checked
                                                          }
                                                          required={
                                                            rowData[
                                                              "Status"
                                                            ] ===
                                                            "No Action Required"
                                                          }
                                                          size="small"
                                                          fullWidth
                                                          placeholder="Enter your Comments"
                                                          name="comments"
                                                          id="comments"
                                                          value={
                                                            rowData[el.key] ??
                                                            ""
                                                          }
                                                          onChange={(event) =>
                                                            this.handleEditInputChange(
                                                              event,
                                                              id,
                                                              rowData,
                                                              el.key
                                                            )
                                                          }
                                                          InputProps={{
                                                            endAdornment: (
                                                              <InputAdornment position="end">
                                                                {rowData[el.key]
                                                                  ? ""
                                                                  : rowData[
                                                                      "Status"
                                                                    ] ===
                                                                    "No Action Required"
                                                                  ? "Required"
                                                                  : "Optional"}
                                                              </InputAdornment>
                                                            ),
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                  ) : el.key ===
                                                    "Assigned_To" ? (
                                                    <SingleSelect
                                                      isDisabled={
                                                        rowData.checked
                                                          ? !(
                                                              this.state.roleData.includes(
                                                                "PUBLICATION_LEAD"
                                                              ) ||
                                                              this.state.roleData.includes(
                                                                "SUPER_USER"
                                                              ) ||
                                                              this.state.roleData.includes(
                                                                "MANAGEMENT_LEADERSHIP"
                                                              )
                                                            )
                                                          : true
                                                      }
                                                      id="selectedUser"
                                                      value={
                                                        rowData[el.key] ?? ""
                                                      }
                                                      values={usersData}
                                                      onChange={(event) =>
                                                        this.handleSelectChange(
                                                          event,
                                                          id,
                                                          "Assigned_To"
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <>
                                                      <Typography
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        {rowData[el.key] ??
                                                          null}
                                                      </Typography>
                                                    </>
                                                  )}
                                                </TableCell>
                                              );
                                            }
                                            return null;
                                          }
                                        )}
                                      </StyledTableRow>
                                    ))
                                  : null}
                              </TableBody>
                            </MaterialTable>
                          </TableContainer>
                        )}

                        <Grid className="tablePagination">
                          <PaginationNew
                            isEmptyReset={this.props.isEmptyReset}
                            isPaginationReset={this.props.isPaginationReset}
                            isDisabled={this.state.selectedData.length > 0}
                            onChangePage={this.props.onChangePage}
                            onChangeRow={this.props.onChangeRow}
                            totalRecordsCount={
                              this.props.totalRecordsCount || 0
                            }
                          />
                        </Grid>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Card>
                ))}
              </form>
            ) : (
              <Box style={{ margin: "20vh" }}>
                <NoRecords />
              </Box>
            )}
          </>
        )}

        <PaginationNew
          isEmptyReset={this.props.isErrTblEmptyReset}
          isPaginationReset={this.props.isPaginationReset}
          isDisabled={this.props.isErrTblPaginationDisabled}
          onChangePage={this.props.onErrTblChangePage}
          onChangeRow={this.props.onErrTblChangeRow}
          totalRecordsCount={this.props.errTblRecordsCount || 0}
        />
      </Box>
    );
  }
}

export default withRouter(Table);
