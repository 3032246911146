import {
  Grid,
  InputAdornment,
  SnackbarOrigin,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React from "react";

import IconButton from "../../Components/Button/IconButton";

import { RouteComponentProps, withRouter } from "react-router-dom";
import { FileType } from "../../Common/Enums";
import { AccessedSDMTable } from "../../Models/SDMTableAccessModel";
import { GetColumnDetailsResponseModel } from "../../Models/SDMTableRequestModel";

import {
  faCircleInfo as InfoIcon,
  faCheck,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";
import { SUPPORT_DATA_FILE_TYPE } from "../../Common/Constants";
import Utils from "../../Common/Utils";
import FileUploadDetailModel from "../../Components/File/FileUploadDetailModel";
import ModalDialog from "../../Components/Modal/ModelDialog";
import SupportDataService from "../../Services/SupportDataService";
import FilesInfo from "../SDM/FileUpload/FilesInfo";
import SupportDataFileUpload from "./SupportDataFileUpload";
const env = Utils.getEnvVars();
const SupportDataServices = new SupportDataService();

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[10],
    fontSize: 13,
    maxWidth: "none",
    ZIndex: 99999,
    "& .MuiTooltip-arrow": {
      color: "#FFFFFF",
    },
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
    fontSize: 14,
  },
}));
interface Props extends RouteComponentProps<any, any, any> {}

interface State extends SnackbarOrigin {
  isUploaded: boolean;
  isError: boolean;
  isLoading: boolean;
  columnDetails: GetColumnDetailsResponseModel[];
  tblData: any[];
  dynamicTable: any[];
  filterCol: { fieldName: string; checked: boolean }[];
  uniqueColumnObj: any;
  uniqueColumnValue: string;
  totalRecordCount: number;
  isSDMListLoading: boolean;
  accessedSdmTableData: AccessedSDMTable[];
  roleData: any;
  selectedSdmTableValue: string;
  selectedCountry: string;
  selectedStatus: string;
  uploadedFileName: string;
  uploadedFileCount: any;
  uploadMessage: string;
  isSubmitted: boolean;
  submittedMsg: string;
  disbldSubmit: boolean;
  submittedErrMsg: string;
  isSubmtdError: boolean;
  isSuccess: boolean;
  tableHeadData: any;
  tableNameList: any;
  files: FileUploadDetailModel[];
  totalRecordNo: number | null;
  isRefreshTableData: boolean;
  isDialogOpen: boolean;
  filesInfo: any;
  isCopy: boolean;
}

class UploadFile extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      vertical: "top",
      horizontal: "center",
      isUploaded: false,
      tblData: [],
      isError: false,
      isLoading: false,
      columnDetails: [],
      dynamicTable: [],
      filterCol: [],
      uniqueColumnObj: {},
      uniqueColumnValue: "",
      totalRecordCount: 0,
      isSDMListLoading: false,
      accessedSdmTableData: [],
      roleData: [],
      selectedSdmTableValue: "",
      selectedCountry: "",
      selectedStatus: "",
      uploadedFileName: "",
      uploadedFileCount: [],
      uploadMessage: "",
      isSubmitted: false,
      submittedMsg: "",
      disbldSubmit: false,
      submittedErrMsg: "",
      isSubmtdError: false,
      isSuccess: false,
      tableHeadData: [],
      tableNameList: [],
      files: [],
      totalRecordNo: null,
      isRefreshTableData: false,
      isDialogOpen: false,
      filesInfo: [],
      isCopy: false,
    };
  }
  async componentDidMount() {
    this.setState({
      tableNameList: SUPPORT_DATA_FILE_TYPE,
      filesInfo: [
        { roleName: null, fileName: [...SUPPORT_DATA_FILE_TYPE] },
      ],
    });
  }

  fileUploadInput: HTMLInputElement | null = null;

  handleClose = () => {
    this.setState({
      isError: false,
      isSuccess: false,
      isSubmitted: false,
      isSubmtdError: false,
    });
  };
  handleDeleteClick = (file: FileUploadDetailModel) => {
    if (file) {
      const fltrdArr = this.state.files.filter(
        (arr) => arr.fileName !== file.fileName
      );
      this.setState({ files: fltrdArr, isUploaded: false });
    }
  };
  action = (
    <>
      <IconButton IconType="Cancel" onClick={this.handleClose} />
    </>
  );
  copyLink = (url: string) => {
    navigator.clipboard.writeText(url);
    this.setState({ isCopy: true });
  };

  render() {
    return (
      <>
        <ModalDialog
          isOpen={this.state.isDialogOpen}
          title="File Size Warning"
          onClose={() => this.setState({ isDialogOpen: false })}
          dialogWidth="sm"
        >
          <Typography>
            The upload is exceeding file limit, please try uploading file less
            than 120 MB. For files size more than 120MB, kindly upload files
            through this link:
          </Typography>
          <TextField
            fullWidth
            className="CopyText"
            style={{
              backgroundColor: "grey",
              color: "#fff",
              borderRadius: "4px",
            }}
            value={env.apps.Support_Data}
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {this.state.isCopy ? (
                    <LightTooltip title="Copied">
                      <FontAwesomeIcon
                        onClick={() => {}}
                        color="#efefef"
                        icon={faCheck}
                        cursor="pointer"
                        fontSize={"22px"}
                      />
                    </LightTooltip>
                  ) : (
                    <LightTooltip title="Copy">
                      <FontAwesomeIcon
                        onClick={() => this.copyLink(env.apps.Support_Data)}
                        color="#efefef"
                        icon={faCopy}
                        cursor="pointer"
                        fontSize={"22px"}
                      />
                    </LightTooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </ModalDialog>
        <ModalDialog
          isOpen={this.state.isError || this.state.isSubmtdError}
          title={"File Upload Error"}
          onClose={this.handleClose}
          dialogWidth="sm"
        >
          <Typography>
            {this.state.isError
              ? this.state.uploadMessage
              : this.state.isSubmtdError
              ? this.state.submittedErrMsg
              : ""}
          </Typography>
        </ModalDialog>
        <Grid mt={"1.8em"} container direction="row">
          <h2
            style={{
              fontSize: "22px",
              paddingLeft: "10px",
              margin: "0",
            }}
          >
            Support Data
          </h2>
        </Grid>
        <Grid mb={"0.5em"} container direction="row">
          <Grid item container xs={12} justifyContent="flex-end">
            <Grid item xs={0.19} justifyContent="flex-end">
              <FontAwesomeIcon
                style={{ color: "#263F6A", fontWeight: 300 }}
                icon={InfoIcon}
              />
            </Grid>
            <Grid item xs={1.5} justifyContent="flex-end">
              <>
                <LightTooltip
                  placement="bottom-end"
                  title={<FilesInfo values={this.state.filesInfo} />}
                  arrow
                >
                  <Typography
                    color={"#000"}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: 650,
                    }}
                  >
                    Allowed Files Info
                  </Typography>
                </LightTooltip>
              </>
            </Grid>
          </Grid>
        </Grid>
        <SupportDataFileUpload
          isUploaded={this.state.isUploaded}
          isRefresh={this.state.isRefreshTableData}
          submittedErrMsg={this.state.submittedErrMsg}
          onSubmitClick={this.handleSubmitClick}
          files={this.state.files}
          isError={this.state.isError}
          isLoading={this.state.isLoading}
          isDisabled={this.state.files.length > 0 ? false : true}
          onChange={this.handleFileUpload}
          onDelete={this.handleDeleteClick}
          onClose={this.handleClose}
          uploadMessage={this.state.uploadMessage}
          isSuccess={this.state.isSuccess}
          isSubmitted={this.state.isSubmitted}
          submittedMsg={this.state.submittedMsg}
          isSubmtdError={this.state.isSubmtdError}
        />
      </>
    );
  }

  handleFileUpload = (file: FileUploadDetailModel) => {
    const fileData = file.file;
    if (fileData) {
      this.getDataFromExcel(fileData);
    }
  };
  getDataFromExcel = async (file: File) => {
    this.setState({ isLoading: true });

    const { tableNameList } = this.state;
    const fileName = file.name.split(".")[0];
    const cmsFile = file.name.includes("_Payments");
    const fileNameWithoutSuffix = file.name.split(" (")[0];

    const isFileNameValid = cmsFile
      ? cmsFile
      : this.state.tableNameList.includes(file.name.split(".")[0]);
    if (
      tableNameList.some((el: any) => el === fileName) ||
      cmsFile ||
      tableNameList.some((el: any) => el === fileNameWithoutSuffix)
    ) {
      this.setState({ isLoading: true });
      const isValidFile = [`${FileType.XLSX}`, `${FileType.CSV}`].includes(
        file.type
      );
      if (!isValidFile) {
        this.handleFileError(
          "File format is invalid. Please convert the file to excel (.xlsx or .csv) and resubmit the file."
        );
      } else if (!isFileNameValid) {
        this.handleFileError(
          "File is not allowed; ensure that it adheres to the support data files. For more information, please refer to the Allowed Files Info."
        );
      } else if (file.size >= 125829120) {
        this.setState({ isDialogOpen: true }, () => {
          this.setState({ isLoading: false });
        });
      } else {
        this.readFileContents(file);
      }
    } else {
      this.handleFileError(
        "File is not allowed; ensure that it adheres to the support data files. For more information, please refer to the Allowed Files Info."
      );
    }
  };

  handleFileError = (errorMessage: string) => {
    this.setState({ isError: true, uploadMessage: errorMessage }, () => {
      this.setState({ isLoading: false });
    });
  };

  readFileContents = (file: File) => {
    const reader = new FileReader();

    reader.onload = async (evt) => {
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data: any = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
      });

      const filess: FileUploadDetailModel[] = [
        {
          fileName: file.name,
          totalRecords: data.length - 1,
          status: "",
          sizeInKB: file.size,
          sizeInMB: 0,
          ext: "",
          file: file,
          type: FileType.XLSX,
          fileType: file.name.split(".")[0],
        },
      ];

      let isError = false;
      let uploadMessage = "";
      const isValidFile = [`${FileType.XLSX}`, `${FileType.CSV}`].includes(
        file.type
      );
      const cmsFile = file.name.includes("_Payments");
      const isFileNameValid = cmsFile
        ? cmsFile
        : this.state.tableNameList.includes(file.name.split(".")[0]);

      if (!isValidFile) {
        isError = true;
        uploadMessage =
          "File format is invalid. Please convert the file to excel (.xlsx or .csv) and resubmit the file.";
      } else if (!isFileNameValid) {
        isError = true;
        uploadMessage =
          "File is not allowed; ensure that it adheres to the support data files. For more information, please refer to the Allowed Files Info.";
      } else if (data.length < 2) {
        isError = true;
        uploadMessage =
          "Submitted file is empty. Please upload a file with valid data and correct format.";
      } else {
        isError = false;
        uploadMessage = "File ready for upload, submit the selected file.";
      }

      if (isError) {
        this.handleFileError(uploadMessage);
      } else {
        this.setState(
          {
            files: filess,
            totalRecordNo: data.length - 1,
            isSuccess: true,
            uploadMessage,
            disbldSubmit: false,
          },
          () => {
            this.setState({ isLoading: false, isUploaded: true });
          }
        );
      }
    };

    reader.readAsBinaryString(file);
  };
  fileToBase64 = async (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handleSubmitClick = async () => {
    this.setState({ isLoading: true });
    try {
      if (this.state.files) {
        const res = await SupportDataServices.uploadSupportFile(
          this.state.files[0],
          this.state.files[0].totalRecords
        );
        if (res?.isSuccess) {
          this.setState({
            isSubmitted: true,
            submittedMsg: res.message,
            disbldSubmit: true,
            files: [],
            isLoading: false,
            isRefreshTableData: !this.state.isRefreshTableData,
            isUploaded: false,
          });
          return res.data;
        }
        if (res?.errors) {
          this.setState({
            isSubmtdError: true,
            submittedErrMsg: "Failed to upload file",
            isLoading: false,
            isUploaded: false,
          });
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      console.log("err", err);
    }
  };
}
export default withRouter(UploadFile);
