import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Box, Grid, InputLabel, Link, Typography } from "@mui/material";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../../App.css";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import { IBreadCrumb } from "../../Components/BreadCrumb/IBreadCrumb";
import GradientButton from "../../Components/Button/GradientButton";
import CardContainer from "../../Components/Card/CardContainer";
import MultiAutoComplete from "../../Components/Select/MultiAutoComplete";
import SelectModel from "../../Components/Select/SelectModel";
import PageHeader from "../../Components/Text/PageHeader";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { CreateTicketModel } from "../../Models/CreateTicketModel";

interface Props extends RouteComponentProps<any, any, any> {
  handleCreateNewTicket: () => void;
  newTicket: CreateTicketModel;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  isButtonLoading: boolean;
  usersData: SelectModel[];
  selectedValues: SelectModel[];
  handleMultiSelectChange: (selected: SelectModel[], targetId?: string) => void;
}

interface State {}

class CreateNewTicket extends Component<Props, State> {
  breadcrumbs: IBreadCrumb[] = [{ text: "Create New Ticket" }];

  isEmptyCheck = () => {
    const values = Object.values(this.props.newTicket);
    const arr = values.filter((el) => el.trim() === "");

    if (
      values.length === 2 &&
      this.props.selectedValues.length >= 1 &&
      arr.length === 0 &&
      !this.props.isButtonLoading
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const {
      newTicket,
      onInputChange,
      onSubmit,
      isButtonLoading,
      usersData,
      selectedValues,
      handleMultiSelectChange,
    } = this.props;

    return (
      <form autoComplete="off">
        <Grid mt={"3em"} container direction="row" mb={"2em"}>
          <NavigateBeforeIcon fontWeight={"bold"} fontSize="small" />
          <Link
            style={{
              color: "#00749E",
              fontWeight: "bold",
              marginRight: "1em",
              cursor: "pointer",
            }}
            onClick={() => {
              this.props.handleCreateNewTicket();
            }}
          >
            Back
          </Link>
          <BreadCrumb breadcrumbs={this.breadcrumbs} />
        </Grid>
        <Box pb={4}>
          <CardContainer>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 17px",
              }}
            >
              <PageHeader
                label="Enter Ticket Details"
                style={{
                  fontSize: "16px",
                }}
              />
              <Box style={{ display: "flex", gap: "1em" }}>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: "red",
                    textAlign: "right",
                    fontStyle: "italic",
                  }}
                >
                  * All Fields Mandatory
                </Typography>
              </Box>
            </Box>
            <Grid container p={2} flexDirection="row">
              <Grid container item columnSpacing={2}>
                <Grid item xs={3}>
                  <InputLabel
                    sx={{
                      visibility: newTicket.title ? "visible" : "hidden",
                    }}
                  >
                    Title
                  </InputLabel>
                  <TextInputField
                    isRequired={true}
                    placeholder="Enter Title Name"
                    name="title"
                    id="title"
                    value={newTicket.title}
                    onChange={onInputChange}
                  />
                </Grid>
                <Grid item xs={4} display="block">
                  <InputLabel
                    sx={{
                      visibility:
                        selectedValues.length > 0 ? "visible" : "hidden",
                    }}
                  >
                    Assigned To
                  </InputLabel>
                  <MultiAutoComplete
                    id="assignedName"
                    label="Assigned To"
                    selected={selectedValues}
                    values={usersData}
                    onChange={handleMultiSelectChange}
                  />
                </Grid>
                <Grid item xs={4} className="CommentTextfield">
                  <InputLabel
                    sx={{
                      visibility: newTicket.comments ? "visible" : "hidden",
                    }}
                  >
                    Comments
                  </InputLabel>
                  <TextInputField
                    isRequired={true}
                    placeholder="Comments"
                    name="comments"
                    id="comments"
                    value={newTicket.comments}
                    onChange={onInputChange}
                  />
                </Grid>
                <Grid item xs={1} className="SubmitTicketBtn">
                  <GradientButton
                    isButtonLoad={isButtonLoading}
                    disabled={this.isEmptyCheck()}
                    label="Submit"
                    onClick={onSubmit}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContainer>
        </Box>
      </form>
    );
  }
}
export default withRouter(CreateNewTicket);
