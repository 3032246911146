import { Close } from "@mui/icons-material";
import { Alert, AlertColor, Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Component } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  message: string;
  onClickView?: () => void;
  alertType: AlertColor;
};

type State = {};

class SnackbarAlert extends Component<Props, State> {
  state = {};

  render() {
    const { open, onClose, message, onClickView, alertType } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        sx={{width:"50%"}}
        style={{ marginTop: 50,zIndex:9999999 }}
      >
        <Alert
      
          onClose={onClose}
          severity={alertType}
          action={
            <>
              {onClickView && alertType === "success" ? (
                <Button color="error" size="small" onClick={onClickView}>
                  View
                </Button>
              ) : null}

              <Close style={{cursor:"pointer"}} onClick={onClose} />
            </>
          }
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }
}

export default SnackbarAlert;
