import {
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import { Component } from "react";
import GradientButton from "../../../../Components/Button/GradientButton";
import RedButton from "../../../../Components/Button/RedButton";
import ChartPerCountry from "../../../../Components/Charts/ChartPerCountry";
import DatePicker from "../../../../Components/DatePicker/DatePicker";
import NoRecords from "../../../../Components/NoRecords/NoRecords";
import MultiAutoComplete from "../../../../Components/Select/MultiAutoComplete";
import SelectModel from "../../../../Components/Select/SelectModel";
import SnackbarAlert from "../../../../Components/SnackBarAlert/SnackbarAlert";
import {
  DefaultChartProps,
  ExceptionByCountryResponse,
} from "../../../../Models/DashboardModel";
import DashboardService from "../../../../Services/DashboardService";
import Utils from "../../../../Common/Utils";
const DashboardServices = new DashboardService();
interface Props extends DefaultChartProps {
  showExceptioReportingDateDialog?: boolean;
}

type State = {
  xAxisCategories: string[];
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  selectedCountryValues: SelectModel[];
  countryMappingData: any;
  startDatePicker: string | null;
  endDatePicker: string | null;
  isSearchButtonLoading: boolean;
  isChartLoading: boolean;
  exceptionByCountry: ExceptionByCountryResponse[];
};
class ExceptionPerCountry extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      xAxisCategories: [],
      selectedStartDate: this.props.defaultStartDate,
      selectedEndDate: this.props.defaultEndDate,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      selectedCountryValues: this.props.defaultCountries,
      countryMappingData: this.props.countryDropDownValues,
      startDatePicker: this.props.defaultStartDate,
      endDatePicker: this.props.defaultEndDate,
      isSearchButtonLoading: false,
      isChartLoading: false,
      exceptionByCountry: [],
    };
  }
  async componentDidMount() {
    this.getExceptionCountryData();
  }
  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): Promise<void> {
    if (
      prevProps.showExceptioReportingDateDialog !==
      this.props.showExceptioReportingDateDialog
    ) {
      if (this.props.showExceptioReportingDateDialog === true) {
        this.setState(
          {
            selectedStartDate: this.props.defaultStartDate,
            selectedEndDate: this.props.defaultEndDate,
            selectedCountryValues: this.props.defaultCountries,
            startDatePicker: this.props.defaultStartDate,
            endDatePicker: this.props.defaultEndDate,
          },
          () => {
            this.getExceptionCountryData();
          }
        );
      }
    }
  }

  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        startDatePicker: moment(value).format("YYYY-MM-DD"),
        endDatePicker: null,
      });
    }
  };
  handleEndDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (this.state.startDatePicker) {
      const startDate = new Date(this.state.startDatePicker);
      const endDate = new Date(value!);
      const currentDate = new Date();

      // Calculate the difference in days between the start and end dates
      const diffInDays =
        Math.abs(Utils.getDaysBetweenDates(endDate, startDate)) + 1;
      if (
        7 <= diffInDays &&
        diffInDays <= 365 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          endDatePicker: moment(value).format("YYYY-MM-DD"),
        });
      } else if (startDate > endDate || endDate > currentDate) {
        this.setState({
          openAlert: true,
          alertMsg:
            "The End Date cannot be greater than current date or less than start date",
          alertType: "error",
          endDatePicker: null,
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be 7 or less than 365",
          alertType: "error",
          endDatePicker: null,
        });
      }
    }
  };
  handleCountrySelectChange = (selected: SelectModel[], targetId?: string) => {
    if (selected.length <= 1) {
      if (targetId) {
        this.setState({ selectedCountryValues: selected }, () => {});
      }
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select only 1 country",
        alertType: "error",
      });
    }
  };
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 364);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;
    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };

  onResetClick = () => {
    this.setState({
      selectedCountryValues: [],
      startDatePicker: null,
      endDatePicker: null,
    });
  };
  isEmptyCheck = () => {
    const { selectedCountryValues, startDatePicker, endDatePicker } =
      this.state;
    if (startDatePicker && endDatePicker && selectedCountryValues.length > 0) {
      return false;
    }
    return true;
  };
  handleSearchClick = () => {
    this.setState(
      {
        isChartLoading: true,
        isSearchButtonLoading: true,
        selectedStartDate: this.state.startDatePicker,
        selectedEndDate: this.state.endDatePicker,
      },
      () => {
        this.getExceptionCountryData();
      }
    );
  };

  getExceptionCountryData = async () => {
    const { selectedCountryValues, startDatePicker, endDatePicker } =
      this.state;

    try {
      let countryVal = selectedCountryValues
        .map((el) => el.value)
        .map((item) => "''" + item + "''")
        .join();
      let parListCountryVal = selectedCountryValues
        .map((el) => el.value)
        .join(",");
      let payloadObj1: any = {};
      payloadObj1 = {
        par_group_source: 0,
        par_list: parListCountryVal,
        par_start_date: startDatePicker,
        par_end_date: endDatePicker,
        par_whereclause: `Country in (${countryVal}) and CAST(exception_datetime as DATE) BETWEEN ''${startDatePicker}'' and ''${endDatePicker}'' `,
      };
      this.setState({ isSearchButtonLoading: true, isChartLoading: true });
      let responseCountry = await DashboardServices.getCountryExceptionData(
        payloadObj1
      );
      this.setState({
        isSearchButtonLoading: false,
        isChartLoading: false,
        exceptionByCountry: responseCountry.result,
      });
    } catch (error) {
      console.log("unexpected error during fecthing:", error);
      this.setState({
        isSearchButtonLoading: false,
        isChartLoading: false,
      });
    }
  };
  render() {
    const {
      alertMsg,
      alertType,
      openAlert,
      countryMappingData,
      selectedCountryValues,
      startDatePicker,
      endDatePicker,
      isSearchButtonLoading,
      isChartLoading,
      exceptionByCountry,
      selectedEndDate,
      selectedStartDate,
    } = this.state;
    return (
      <Grid>
        <div className="AlertException">
          <SnackbarAlert
            alertType={alertType}
            open={openAlert}
            message={alertMsg}
            onClose={() => {
              this.setState({ openAlert: false });
            }}
          />
        </div>
        <Grid
          style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "40px 5px 25px 5px",
          }}
        >
          {" "}
          <Grid container columnGap={1}>
            <Grid item xs={5}>
              <InputLabel>Select Country</InputLabel>
              <MultiAutoComplete
                id="countryName"
                label="Select Country"
                selected={selectedCountryValues}
                values={countryMappingData}
                onChange={this.handleCountrySelectChange}
              />
            </Grid>
            <Grid item xs={2.2} ml={0.8}>
              <InputLabel> Select Start Date</InputLabel>
              <DatePicker
                name="startDate"
                placeHolder="Select Date"
                maxDate={
                  new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
                }
                value={startDatePicker}
                onChange={this.handleStartDateChange}
              />
            </Grid>
            <Grid item xs={2.2} className="EndDate" ml={0.8}>
              <InputLabel> Select End Date</InputLabel>
              <DatePicker
                disabled={!startDatePicker}
                name="endDate"
                placeHolder="Select Date"
                minDate={new Date(startDatePicker!)}
                maxDate={this.calculateMaxDate(startDatePicker)}
                value={endDatePicker}
                onChange={this.handleEndDateChange}
              />
            </Grid>
            <Grid item xs={0.8} mt={3.2} ml={0.8}>
              <GradientButton
                disabled={this.isEmptyCheck()}
                isButtonLoad={isSearchButtonLoading}
                label="Search"
                onClick={this.handleSearchClick}
              />
            </Grid>
            <Grid item xs={0.8} mt={3.2} ml={1}>
              <RedButton label="Reset" onClick={this.onResetClick} />
            </Grid>
          </Grid>
        </Grid>
        {isChartLoading ? (
          <Grid
            item
            style={{
              height: "8rem",
              marginLeft: "auto",
              marginRight: "auto",
              margin: "10vh",
            }}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress
              disableShrink
              sx={{ color: "#d52b1e", marginTop: 4 }}
            />
          </Grid>
        ) : (
          <>
            {exceptionByCountry.length > 0 ? (
              <ChartPerCountry
                chartDataCountryArr={exceptionByCountry}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
              />
            ) : (
              <Box
                style={{
                  position: "sticky",
                  left: "50%",
                  marginTop: "25vh",
                  marginBottom: "25vh",
                }}
                width={"82px"}
              >
                <NoRecords msg="No Data" />
              </Box>
            )}
          </>
        )}
      </Grid>
    );
  }
}
export default ExceptionPerCountry;
