import React from "react";
import { RolesModel } from "../Models/RolesModel";

export const RoleContext = React.createContext<{
  userRoles: RolesModel[];
  accessedTables: any;
  uniqueColumnObj: any;
}>({
  userRoles: [],
  accessedTables: [],
  uniqueColumnObj: {},
});
export const UserLoginContext = React.createContext<{
  isUserLoggedIn: boolean;
  setIsLoggedIn: (isUserLoggedIn: boolean) => void;
}>({
  isUserLoggedIn: false,
  setIsLoggedIn: () => {},
});
