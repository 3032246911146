import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Component } from "react";

type Props = {
  chartDataArr: any;
  xAxisCategories: string[];
  resultArr: any;
};
class LineKOChart extends Component<Props> {
  render() {
    const chartData: Highcharts.Options = {
      chart: {
        type: "areaspline",
      },

      title: {
        text: "",
      },
      legend: {
        enabled: true,
        verticalAlign: "top",
        align: "left",
      },
      subtitle: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#2E2F30",

        formatter: function () {
          return `
                  <div  style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
                 <b>${this.x}</b><br><br>
                  <span style=color:#BFBFBF>Total No. Of Count</span> <span style=padding-left:10px>${this.y}</span><br>
                 </div>
                  `;
        },
      },
      xAxis: {
        categories: this.props.xAxisCategories,
        tickmarkPlacement: "on",
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: "",
        },
        startOnTick: true,
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.3,
          dataLabels: {
            enabled: false,
          },
          lineWidth: 3, // Increase line width for smoother appearancelinecap: "round",
          linecap: "round",
          enableMouseTracking: true,
        },
      },
      series: this.props.chartDataArr,
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartData} />
      </div>
    );
  }
}

export default LineKOChart;
