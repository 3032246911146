import React from "react";
import { Box, Typography } from "@mui/material";
import EliLillyLogo from "../../Static/EliLilly_Logo.png";
class Footer extends React.Component {
  render() {
    const year = new Date().getFullYear();

    return (
      <Box
        p={2}
        display="flex"
        justifyContent="center"
        position='relative'
        style={{
          backgroundImage: "linear-gradient(to right, #D52B1E,#78105B,#273E69)",
           color: "#FFFFFF",
          zIndex: 1,
          }}
      >
       <img
          src={EliLillyLogo}
          alt="logo"
          width={'3%'}
          style={{ filter: "brightness(0)invert(1)"}}
        />{" "}
        <Typography style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>
          Copyright ©{year}. All rights reserved.
        </Typography>
      </Box>
    );
  }
}

export default Footer;
