import { Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

import IconButton from "../../Components/Button/IconButton";

import { RouteComponentProps, withRouter } from "react-router-dom";

import FileUploadDetailModel from "../../Components/File/FileUploadDetailModel";

interface Props extends RouteComponentProps<any, any, any> {
  files: FileUploadDetailModel[];
  onDelete: (file: FileUploadDetailModel) => void;
  isStatusRequire?: boolean;
}

interface State {}

class FileUploadDetailCard extends React.Component<Props, State> {
  public static defaultProps = {
    isStatusRequire: true,
  };
  render() {
    const { files, isStatusRequire } = this.props;

    return (
      <Grid container mb={1}>
        {files.map((file, i) => {
          return (
            <Grid item xs={6} key={i} md={5.92} mr={1} mb={2}>
              <Card
                style={{
                  border: "1px solid #DDDDDD",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "6px",
                  opacity: 1,
                }}
              >
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        style={{ fontWeight: 600, color: "#000000d6" }}
                      >
                        Total Records in File
                      </Typography>
                      <Typography>{file.totalRecords}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ fontWeight: 600, color: "#000000d6" }}
                      >
                        Uploaded File Name
                      </Typography>
                      <Typography>{file.fileName}</Typography>
                    </Grid>
                    {isStatusRequire ? (
                      <Grid item>
                        <Typography
                          style={{ fontWeight: 600, color: "#000000d6" }}
                        >
                          Status
                        </Typography>
                        <Grid container direction="row">
                          <Grid item style={{ marginLeft: -5 }}>
                            <Typography
                              style={{ fontWeight: 600, color: "#D97E14" }}
                            >
                              Pending
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <Grid
                      item
                      style={{
                        borderLeft: "1px solid #C9C9C9",
                        paddingLeft: "15px",
                      }}
                    >
                      <IconButton
                        IconType="Delete"
                        onClick={() => this.props.onDelete(file)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}
export default withRouter(FileUploadDetailCard);
