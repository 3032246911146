import { Button } from "@mui/material";
import { grey as greyColor } from "@mui/material/colors";
import React from "react";


interface Props {
    label: string;
    onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    style?: React.CSSProperties;
    disabled?: boolean;
}
interface State { }

class WhiteButton extends React.Component<Props, State> {
    render() {
        const { label, onClick, style, disabled } = this.props;

        const defaultStyle: any = { color: greyColor[500], borderColor: greyColor[500], textTransform: 'none' };
        if(disabled) {
            defaultStyle.borderColor = '#BDBDBD';
            defaultStyle.color = '#BDBDBD';
            defaultStyle.textTransform = 'none';
        }
        let mergeStyle = { ...defaultStyle, ...style };

        return (
            <Button disabled={disabled} size="medium" aria-disabled = {disabled} variant="outlined" style={mergeStyle}
                onClick={onClick}>{label}</Button>
        );
    }
}

export default WhiteButton;