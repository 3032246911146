import {
  Box,
  CircularProgress,
  Grid,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  styled,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  faCaretDown,
  faCaretUp,
  faCheck,
  faClose,
  faExclamationTriangle,
  faUpDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";
import Utils from "../../Common/Utils";
import NoRecords from "../NoRecords/NoRecords";

import PaginationNew from "../Pagination";

type SortType = {
  key: string;
  direction: string;
};

interface Props extends RouteComponentProps<any, any, any> {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  headCellDataForEntries: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  sortConfig: SortType | null;
  data: any;
  totalRecordsCount?: number;

  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  checkBoxDifferentiator?: string | number;
  onClickLink?: (fieldClicked: string, record: any) => void;

  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
}

interface State {
  sortConfig: SortType | null;
  data: any;
  resetRow: any;
  selectedData: any;
  selectAll: boolean;
  selectSingle: boolean;
  rowsPerPage: number;
  page: number;
  caretHover: boolean;
  ShowComponent: boolean;
  open: boolean;
  anchorEl: any;
  anchorReference: any;
  ActionMenu: boolean;
  addComment: number | undefined;
  showEditField: number | undefined;
  showCloneFields: number | undefined;
  showbulkEditFields: number | undefined;
  commentId: any;
  editId: any;
  cloneId: any;
  record: any;
  commentForId: any;
  formData: { [index: string]: any };
  cloneFormData: { [index: string]: any };
  uniqueValue: any;
  showDelDialoge: boolean;
  editInput: string;
}

const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
class CommonTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sortConfig: null,
      data: [],
      resetRow: [],
      selectAll: false,
      selectSingle: false,
      selectedData: [],
      rowsPerPage: 5,
      page: 0,
      caretHover: false,
      ShowComponent: false,
      open: false,
      anchorEl: null,
      anchorReference: null,
      ActionMenu: false,
      addComment: undefined,
      showEditField: undefined,
      showCloneFields: undefined,
      showbulkEditFields: undefined,
      editId: null,
      commentId: null,
      cloneId: null,
      record: [],
      commentForId: undefined,
      formData: {},
      cloneFormData: {},
      uniqueValue: null,
      showDelDialoge: false,
      editInput: "",
    };
  }
  handleCommentClick = (record: any) => {
    this.setState({ commentForId: record });
  };
  handleDialogClick = () => {
    this.setState({ showDelDialoge: true });
  };
  handleEditClick = (record: any) => {
    this.setState({ showEditField: record });
  };

  getRecordDetails = async () => {
    const formData: { [index: string]: any } = {};
    StyledTableRow.Each((el: any) => {
      formData[el.fieldName] = el.value;
    });
    this.setState({
      data: StyledTableRow.data,
      formData,
      cloneFormData: formData,
    });
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data, sortConfig: null });
    }
  };

  componentDidMount = () => {
    this.setState({
      data: this.props.data,
    });
  };

  requestSort = (key: string) => {
    const { sortConfig } = this.state;
    let direction = "ascending";
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending";
      }
    }
    this.setState({ sortConfig: { key, direction } }, () => {
      this.sorter();
    });
  };
  customComparer = (a: any, b: any, direction: string) => {
    // Handle null values by placing them at the end (ascending) or beginning (descending)
    if (a === null) {
      return b === null ? 0 : direction === "ascending" ? 1 : -1;
    }
    if (b === null) {
      return direction === "ascending" ? -1 : 1;
    }

    // Convert values to strings for consistent comparison
    const aValue = String(a);
    const bValue = String(b);

    // Compare strings based on sort direction
    if (direction === "ascending") {
      return aValue.localeCompare(bValue);
    } else {
      return bValue.localeCompare(aValue);
    }
  };
  sorter = () => {
    const { sortConfig, data } = this.state;
    let sortedData = data;
    if (sortConfig !== null) {
      sortedData = sortedData.sort(
        (a: { [x: string]: number }, b: { [x: string]: number }) => {
          //console.log("sort", sortConfig.key, a, b);
          if (sortConfig.key === "failureReason") {
            return this.customComparer(
              a[sortConfig.key],
              b[sortConfig.key],
              sortConfig.direction
            );
          } else if (sortConfig.key === "fileSubmittedTime") {
            let aDate = a[sortConfig.key].toString().split("|");
            let aFinalDate = aDate.join(" ");
            let bDate = b[sortConfig.key].toString().split("|");
            let bFinalDate = bDate.join(" ");
            if (new Date(aFinalDate) < new Date(bFinalDate)) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (new Date(aFinalDate) > new Date(bFinalDate)) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
          return 0;
        }
      );
    }
    this.setState({ data: sortedData });
    return sortedData;
  };

  handleChangePage = (_event: any, page: any) => {
    this.setState({ page: page });
    this.props.onChangePage(page);
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
    this.props.onChangeRow(event.target.value);
  };
  defaultSortIconColor = "#959595";
  activeSortIconColor = "#000000";

  faCaretUpColor = this.activeSortIconColor;
  faCaretDownColor = this.activeSortIconColor;
  faUpDownColor = this.defaultSortIconColor;

  positionArrow = (key: string) => {
    if (["action"].includes(key.toLowerCase())) {
      return null;
    }
    const { sortConfig } = this.state;
    if (sortConfig) {
      if (sortConfig.direction === "ascending" && sortConfig.key === key)
        return (
          <FontAwesomeIcon
            icon={faCaretUp}
            color={this.faCaretUpColor}
            size="xs"
          />
        );
      if (sortConfig.direction === "descending" && sortConfig.key === key)
        return (
          <FontAwesomeIcon
            icon={faCaretDown}
            color={this.faCaretDownColor}
            size="xs"
          />
        );
    }
    return (
      <FontAwesomeIcon
        icon={faUpDown}
        color={this.defaultSortIconColor}
        size="sm"
      />
    );
  };

  onClickLink = (fieldClicked: string, record: any) => {
    if (this.props.onClickLink) {
      this.props.onClickLink(fieldClicked, record);
    }
  };

  render() {
    const {
      headCellData,

      fieldsToColor,
    } = this.props;
    const { data } = this.state;

    return (
      <Box>
        {this.props.isLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            {headCellData.length > 0 ? (
              <TableContainer component={Paper} sx={{ maxHeight: "30rem" }}>
                <MaterialTable
                  stickyHeader
                  sx={{ minWidth: "fit-content" }}
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {headCellData.map((el, index) => {
                        if (el && !["id", "checked"].includes(el.key)) {
                          return (
                            <TableCell
                              key={`${el.key}-${index}-head`}
                              style={{
                                fontSize: "16px",
                                borderBottom: "2px solid red",
                                whiteSpace: "nowrap",
                                minWidth: "8em",
                                fontWeight: "bold",
                              }}
                              onClick={() => this.requestSort(el.key)}
                            >
                              {Utils.camelToTitle(el.label)}{" "}
                              {this.positionArrow(el.key)}
                            </TableCell>
                          );
                        }
                        return null;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0
                      ? data.map((record: any, index: number) => {
                          return (
                            <StyledTableRow key={`${index}-row-data`}>
                              {headCellData.map((el, cellIndex) => {
                                if (el?.key !== "id") {
                                  const clrObj = fieldsToColor?.find(
                                    (clr) => clr.key === el?.key
                                  );
                                  return (
                                    <TableCell
                                      key={`${el.key}-${index}-${cellIndex}-cell`}
                                      style={{
                                        fontSize: "16px",
                                        // wordWrap: "break-word",
                                        // hyphens: "auto",
                                        // wordBreak: "break-all",
                                      }}
                                    >
                                      <>
                                        {
                                          <>
                                            <Box
                                              style={{
                                                maxHeight: "70px",
                                                overflow: "auto",
                                                wordWrap: "break-word",
                                                hyphens: "auto",
                                                wordBreak: "break-all",
                                                maxWidth: "325px",
                                                color: clrObj
                                                  ? clrObj.values[
                                                      record[el?.key]
                                                    ]
                                                  : "black",
                                              }}
                                            >
                                              {record[el?.key] === "pending" ? (
                                                <Grid
                                                  container
                                                  maxWidth="8rem"
                                                  style={{
                                                    background:
                                                      "#FEF5E7 0% 0% no-repeat padding-box",
                                                    border: "1px solid #F69A19",
                                                    borderRadius: "17px",
                                                    opacity: 1,
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={
                                                        faExclamationTriangle
                                                      }
                                                      size="lg"
                                                      style={{
                                                        color: "#F69A19",
                                                        fontSize: "14px",
                                                        marginRight: "8px",
                                                        marginLeft: "15px",
                                                      }}
                                                    />
                                                    <Typography>
                                                      Pending
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : record[el?.key] ===
                                                "completed" ? (
                                                <Grid
                                                  container
                                                  maxWidth="9rem"
                                                  style={{
                                                    background:
                                                      "#E5FFF2 0% 0% no-repeat padding-box",
                                                    border: "1px solid #3DA470",
                                                    borderRadius: "17px",
                                                    opacity: 1,
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faCheck}
                                                      size="lg"
                                                      style={{
                                                        color: "#3DA470",
                                                        fontSize: "14px",
                                                        marginRight: "8px",
                                                        marginLeft: "15px",
                                                      }}
                                                    />
                                                    <Typography>
                                                      Completed
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : record[el?.key] ===
                                                "failed" ? (
                                                <Grid
                                                  container
                                                  maxWidth="7rem"
                                                  style={{
                                                    background:
                                                      "#FFEAE8 0% 0% no-repeat padding-box",
                                                    border:
                                                      " 1px solid #EF3E32",
                                                    opacity: 1,
                                                    borderRadius: "17px",
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faClose}
                                                      size="lg"
                                                      style={{
                                                        color: "#EF3E32",
                                                        fontSize: "14px",
                                                        marginRight: "8px",
                                                        marginLeft: "15px",
                                                      }}
                                                    />
                                                    <Typography>
                                                      Failed
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : (
                                                record[el?.key]
                                              )}
                                            </Box>
                                            {el.elementToLoad}{" "}
                                          </>
                                        }
                                      </>
                                    </TableCell>
                                  );
                                }
                                return null;
                              })}
                            </StyledTableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </MaterialTable>
              </TableContainer>
            ) : (
              <Box style={{ margin: "20vh" }}>
                <NoRecords />
              </Box>
            )}
          </>
        )}

        <PaginationNew
          isEmptyReset={this.props.isEmptyReset}
          isPaginationReset={this.props.isPaginationReset}
          isDisabled={this.props.isPaginationDisabled}
          onChangePage={this.props.onChangePage}
          onChangeRow={this.props.onChangeRow}
          totalRecordsCount={this.props.totalRecordsCount || 1}
        />
      </Box>
    );
  }
}

export default withRouter(CommonTable);
