import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import React from "react";

interface Props {
  id?: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  checked?: boolean;
}
interface State {}
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 5,
  marginLeft: 5,
  "& .MuiSwitch-root": {
    width: 80,
  },
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(-5px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(10px)",

      "& .MuiSwitch-thumb:before": {
        content: "''",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#31855B",
      },
      "& .Mui-checked + & .MuiSwitch-track": { backgroundColor: "#31855B" },
      "&:before": {
        content: "'ON'",
        top: 0,
        position: "relative",
        left: 0,
        fontSize: "13px",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 10,
    height: 11,
    margin: 4,
    padding: "6px",

    "&::before": {
      content: "'OFF'",
      position: "absolute",
      width: "80px",
      height: "100%",
      left: "32px",
      top: "23%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      fontSize: "13px",
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    opacity: 1,
    borderRadius: 20 / 2,
  },
  "& .MuiFormControlLabel-label": {
    fontWeigh: "bold",
  },
}));
class SwitchButton extends React.Component<Props, State> {
  public static defaultProps = {
    isDisabled: false,
  };
  render() {
    const { label, onChange, checked, isDisabled } = this.props;
    return (
      <FormControlLabel
        control={
          <MaterialUISwitch
            disabled={isDisabled}
            onChange={onChange}
            checked={checked}
          />
        }
        label={label}
        labelPlacement="start"
      />
    );
  }
}

export default SwitchButton;
