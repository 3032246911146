import { faCheck, faClose, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import React, { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Utils from "../../Common/Utils";
import IconButton, { IconType } from "../Button/IconButton";
import NoRecords from "../NoRecords/NoRecords";
import PaginationNew from "../Pagination";

interface Props extends RouteComponentProps<any, any, any> {
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  totalRecordsCount: number | undefined;
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  isLoading?: boolean;
  data: any;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
  actionArray?: IconType[];
  onActionClick?: (action: IconType, record: any) => void;
}

type State = {
  data: any;
};

class ReportingTable extends Component<Props, State> {
  state = {
    data: [],
  };
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data });
    }
  };
  componentDidMount = () => {
    this.setState({
      data: this.props.data,
    });
  };
  render() {
    const { headCellData } = this.props;
    const { data } = this.state;
    return (
      <Box pr={"16px"} pl={"16px"}>
        {this.props.isLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <Box>
            {headCellData.length > 0 ? (
              <>
                <TableContainer
                  component={Paper}
                  style={{
                    boxShadow: "none",
                    background: "none",
                  }}
                  sx={{
                    overflow: "auto",
                  }}
                >
                  {data.length > 0
                    ? data.map((record: any, index: number) => {
                        return (
                          <Card
                            key={`${index}-row-data`}
                            style={{
                              marginBottom: "10px",
                              background: "#CCD5DD",
                              boxShadow: "0px 3px 6px #00000029",
                              borderRadius: "6px",
                              opacity: 1,
                              width: "125rem",
                            }}
                          >
                            <CardContent
                              style={{
                                paddingBottom: "18px",
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                columns={12}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                {headCellData.map((el, cellIndex) => {
                                  return (
                                    <React.Fragment key={`${cellIndex}-data`}>
                                      {el.key === "filePath" ? null : (
                                        <Grid
                                          item
                                          xs={
                                            el.label === "Action"
                                              ? 0.5
                                              : el.key === "Status"
                                              ? 1
                                              : 1.2
                                          }
                                          key={`${cellIndex}-cell-data`}
                                          style={{
                                            wordWrap: "break-word",
                                            hyphens: "auto",
                                            wordBreak: "break-all",
                                            maxWidth: "450px",
                                            maxHeight: "120px",
                                          }}
                                        >
                                          <Typography
                                            style={{ fontWeight: 600 }}
                                          >
                                            {Utils.camelToTitle(
                                              el.key === "Status" ? "" : el.key
                                            )}
                                          </Typography>
                                          <Grid
                                            item
                                            style={{ paddingTop: "5px" }}
                                          >
                                            {record[el?.key] === "Requested" ||
                                            record[el?.key] ===
                                              "In Progress" ? (
                                              <Grid
                                                container
                                                style={{
                                                  background:
                                                    "#E5F6FF 0% 0% no-repeat padding-box",
                                                  border: "1px solid #0099E6",
                                                  borderRadius: "17px",
                                                  opacity: 1,
                                                  marginTop: "8px",
                                                  maxWidth: "140px",
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faRefresh}
                                                    size="lg"
                                                    style={{
                                                      color: "#0099E6",
                                                      fontSize: "14px",
                                                      marginRight: "8px",
                                                      marginLeft: "15px",
                                                    }}
                                                  />
                                                  <Typography>
                                                    In Progress
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            ) : record[el?.key] === "Failed" ? (
                                              <Grid
                                                container
                                                maxWidth="100px"
                                                style={{
                                                  background:
                                                    "#FFEAE8 0% 0% no-repeat padding-box",
                                                  border: " 1px solid #EF3E32",
                                                  opacity: 1,
                                                  borderRadius: "17px",
                                                  marginTop: "8px",
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faClose}
                                                    size="lg"
                                                    style={{
                                                      color: "#EF3E32",
                                                      fontSize: "14px",
                                                      marginRight: "8px",
                                                      marginLeft: "15px",
                                                    }}
                                                  />
                                                  <Typography>
                                                    Failed
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            ) : record[el?.key] ===
                                              "File Generated" ? (
                                              <Grid
                                                container
                                                maxWidth="160px"
                                                style={{
                                                  background:
                                                    "#E5FFF2 0% 0% no-repeat padding-box",
                                                  border: "1px solid #3DA470",
                                                  borderRadius: "17px",
                                                  opacity: 1,
                                                  marginTop: "8px",
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                    size="lg"
                                                    style={{
                                                      color: "#3DA470",
                                                      fontSize: "14px",
                                                      marginRight: "8px",
                                                      marginLeft: "15px",
                                                    }}
                                                  />
                                                  <Typography>
                                                    File Generated
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            ) : record[el?.key] ===
                                                "Build Completed" ||
                                              record[el?.key] ===
                                                "Completed" ? (
                                              <Grid
                                                container
                                                maxWidth="165px"
                                                style={{
                                                  background:
                                                    "#FEF5E7 0% 0% no-repeat padding-box",
                                                  border: "1px solid #F69A19",
                                                  borderRadius: "17px",
                                                  opacity: 1,
                                                  marginTop: "8px",
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                    size="lg"
                                                    style={{
                                                      color: "#F69A19",
                                                      fontSize: "14px",
                                                      marginRight: "8px",
                                                      marginLeft: "15px",
                                                    }}
                                                  />
                                                  <Typography>
                                                    Build Completed
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            ) : (
                                              <Typography
                                                style={{
                                                  color:
                                                    el.key === "Rpt_Id"
                                                      ? "#0075A2"
                                                      : "",
                                                  fontWeight: 500,
                                                }}
                                              >
                                                {record[el?.key]}
                                              </Typography>
                                            )}
                                            {el.elementToLoad}{" "}
                                            {el?.label === "Action" ? (
                                              <Box>
                                                {this.props.actionArray?.map(
                                                  (action, ind) => (
                                                    <IconButton
                                                      isDisabled={[
                                                        "Requested",
                                                        "",
                                                        null,
                                                        "Build Completed",
                                                        "In Progress",
                                                        "Failed",
                                                        "Completed",
                                                      ].includes(record.Status)}
                                                      key={ind}
                                                      IconType={action}
                                                      onClick={() => {
                                                        if (
                                                          this.props
                                                            .onActionClick
                                                        ) {
                                                          this.props.onActionClick(
                                                            action,
                                                            record
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  )
                                                )}
                                              </Box>
                                            ) : null}
                                          </Grid>
                                        </Grid>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </Grid>
                            </CardContent>
                          </Card>
                        );
                      })
                    : null}
                </TableContainer>
              </>
            ) : (
              <Box style={{ margin: "20vh" }}>
                <NoRecords />
              </Box>
            )}
          </Box>
        )}
        <PaginationNew
          isEmptyReset={this.props.isEmptyReset}
          isPaginationReset={this.props.isPaginationReset}
          isDisabled={this.props.isPaginationDisabled}
          onChangePage={this.props.onChangePage}
          onChangeRow={this.props.onChangeRow}
          totalRecordsCount={this.props.totalRecordsCount || 0}
        />
      </Box>
    );
  }
}
export default withRouter(ReportingTable);
