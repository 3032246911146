import {
  faCheck,
  faClose,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Utils from "../../Common/Utils";
import NoRecords from "../NoRecords/NoRecords";
import PaginationNew from "../Pagination";

interface Props extends RouteComponentProps<any, any, any> {
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  totalRecordsCount: number | undefined;
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  isLoading?: boolean;
  data: any;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
}

type State = {
  data: any;
};

class SupportDataTable extends Component<Props, State> {
  state = {
    data: [],
  };
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data });
    }
  };
  componentDidMount = () => {
    this.setState({
      data: this.props.data,
    });
  };
  render() {
    const { headCellData } = this.props;
    const { data } = this.state;
    return (
      <Box pr={"16px"} pl={"16px"}>
        <TableContainer component={Paper} style={{ boxShadow: "none" }}>
          <Box>
            {this.props.isLoading ? (
              <Box
                style={{
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  margin: "30vh",
                }}
              >
                <CircularProgress sx={{ color: "#d52b1e" }} />
              </Box>
            ) : (
              <>
                {headCellData.length > 0 ? (
                  <>
                    <TableContainer
                      component={Paper}
                      style={{
                        boxShadow: "none",
                        maxHeight: "38rem",
                      }}
                    >
                      {data.length > 0
                        ? data.map((record: any, index: number) => {
                            return (
                              <Card
                                key={`${index}-row-data`}
                                style={{
                                  marginBottom: "10px",
                                  background: "#CCD5DD",
                                  boxShadow: "0px 3px 6px #00000029",
                                  borderRadius: "6px",
                                  opacity: 1,
                                }}
                              >
                                <CardContent>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    {headCellData.map((el, cellIndex) => {
                                      return (
                                        <Grid
                                          item
                                          xs={2}
                                          key={`${cellIndex}-cell-data`}
                                        >
                                          <Typography
                                            style={{ fontWeight: 600 }}
                                          >
                                            {Utils.camelToTitle(el.key)}
                                          </Typography>
                                          <Grid
                                            style={{
                                              paddingTop: "5px",
                                              wordBreak: "break-all",
                                              wordWrap: "break-word",
                                              maxWidth: "200px",
                                            }}
                                          >
                                            {record[el?.key] === "pending" ? (
                                              <Grid
                                                container
                                                width="8rem"
                                                style={{
                                                  background:
                                                    "#FEF5E7 0% 0% no-repeat padding-box",
                                                  border: "1px solid #F69A19",
                                                  borderRadius: "17px",
                                                  opacity: 1,
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                    size="lg"
                                                    style={{
                                                      color: "#F69A19",
                                                      fontSize: "14px",
                                                      marginRight: "8px",
                                                      marginLeft: "15px",
                                                    }}
                                                  />
                                                  <Typography>
                                                    Pending
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            ) : record[el?.key] ===
                                              "completed" ? (
                                              <Grid
                                                container
                                                maxWidth="9rem"
                                                style={{
                                                  background:
                                                    "#E5FFF2 0% 0% no-repeat padding-box",
                                                  border: "1px solid #3DA470",
                                                  borderRadius: "17px",
                                                  opacity: 1,
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                    size="lg"
                                                    style={{
                                                      color: "#3DA470",
                                                      fontSize: "14px",
                                                      marginRight: "8px",
                                                      marginLeft: "15px",
                                                    }}
                                                  />
                                                  <Typography>
                                                    Completed
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            ) : record[el?.key] === "failed" ? (
                                              <Grid
                                                container
                                                maxWidth="7rem"
                                                style={{
                                                  background:
                                                    "#FFEAE8 0% 0% no-repeat padding-box",
                                                  border: " 1px solid #EF3E32",
                                                  opacity: 1,
                                                  borderRadius: "17px",
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faClose}
                                                    size="lg"
                                                    style={{
                                                      color: "#EF3E32",
                                                      fontSize: "14px",
                                                      marginRight: "8px",
                                                      marginLeft: "15px",
                                                    }}
                                                  />
                                                  <Typography>
                                                    Failed
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            ) : el?.key === "failureReason" ? (
                                              <Grid
                                                item
                                                style={{
                                                  background: "#CCD5DD" /* ,
                                                                        paddingBottom:"0"*/,
                                                  paddingTop: "0",
                                                  paddingLeft: "0",
                                                }}
                                              >
                                                {record[el?.key] === null ? (
                                                  <Typography
                                                    style={{
                                                      background:
                                                        "#DDDEE0 0% 0% no-repeat padding-box",
                                                      color: "#434343",
                                                      padding: "5px",
                                                      border:
                                                        "1px solid #9E9E9E",
                                                      maxHeight: "25px",
                                                      overflow: "auto",
                                                      wordWrap: "break-word",
                                                      hyphens: "auto",
                                                      wordBreak: "break-all",
                                                    }}
                                                  >
                                                    NA
                                                  </Typography>
                                                ) : (
                                                  <Typography
                                                    style={{
                                                      background:
                                                        "#263F6A 0% 0% no-repeat padding-box",
                                                      color: "#FFFFFF",
                                                      padding: "5px",
                                                      maxHeight: "25px",
                                                      overflow: "auto",
                                                      wordWrap: "break-word",
                                                      hyphens: "auto",
                                                      wordBreak: "break-all",
                                                    }}
                                                  >
                                                    {record[el?.key]}
                                                  </Typography>
                                                )}
                                              </Grid>
                                            ) : (
                                              record[el?.key]
                                            )}
                                            {el.elementToLoad}{" "}
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                </CardContent>
                              </Card>
                            );
                          })
                        : null}
                    </TableContainer>
                  </>
                ) : (
                  <Box style={{ margin: "20vh" }}>
                    <NoRecords />
                  </Box>
                )}
              </>
            )}
          </Box>
          <PaginationNew
            isEmptyReset={this.props.isEmptyReset}
            isPaginationReset={this.props.isPaginationReset}
            isDisabled={this.props.isPaginationDisabled}
            onChangePage={this.props.onChangePage}
            onChangeRow={this.props.onChangeRow}
            totalRecordsCount={this.props.totalRecordsCount || 0}
          />
        </TableContainer>
      </Box>
    );
  }
}
export default withRouter(SupportDataTable);
