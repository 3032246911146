import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Card,
  CardContent,
  Grid,
  InputLabel,
  Link,
  Popover,
} from "@mui/material";
import React, { Component } from "react";
import "../../../App.css";
import GradientButton from "../../../Components/Button/GradientButton";
import RedButton from "../../../Components/Button/RedButton";
import RedOutlinedButton from "../../../Components/Button/RedOutlinedButton";
import CardContainer from "../../../Components/Card/CardContainer";
import SingleSelect from "../../../Components/Select/SingleSelect";
import PageHeader from "../../../Components/Text/PageHeader";
import TextInputField from "../../../Components/TextInputField/TextInputField";
//import TableData from "./MockData/CustomerData.json";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { COUNTRY_CODE, COUNTRY_DATA } from "../../../Common/Constants";
import SelectModel from "../../../Components/Select/SelectModel";
import SnackbarAlert from "../../../Components/SnackBarAlert/SnackbarAlert";
import SearchDataLoader from "../../../Components/Table/SearchDataLoader";
import { ICustomerFilter } from "../../../Models/ICustomerFilter";
import ExcelService from "../../../Services/ExcelService";
import SearchService from "../../../Services/SearchService";
const SearchServices = new SearchService();
type Props = {};

type State = {
  showSearchContent: boolean;
  searchResultContent: boolean;
  isOpenAdvancedSearch: boolean;
  tblData: any[];
  completeCustomerSearchData: ICustomerFilter;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  customerDataLoading: boolean;
  page: number;
  rows: number;
  totalRecordsCount: number;
  openAlert: boolean;
  alertMsg: string;
  countryData: SelectModel[];
  isDownloadLoading: boolean;
};

class CustomerSearchTab extends Component<Props, State> {
  state = {
    showSearchContent: false,
    searchResultContent: false,
    isOpenAdvancedSearch: false,
    tblData: [],
    completeCustomerSearchData: {} as ICustomerFilter,
    isPaginationDisabled: false,
    isPaginationReset: false,
    customerDataLoading: false,
    page: 0,
    rows: 5,
    totalRecordsCount: 0,
    openAlert: false,
    alertMsg: "",
    countryData: [],
    isDownloadLoading: false,
  };
  componentDidMount = () => {
    this.setState({ countryData: COUNTRY_DATA });
    this.onDropDownChange({ text: "HCP", value: "HCP" }, "type");
  };

  showFormContent = () => {
    this.setState({
      showSearchContent: true,
    });
  };

  fetchCustomerData = async () => {
    try {
      this.setState({ customerDataLoading: true }, async () => {
        const { completeCustomerSearchData } = this.state;

        let completeCustomerSearchFieldCopy: any = {
          ...completeCustomerSearchData,
        };
        for (var key in completeCustomerSearchFieldCopy) {
          if (completeCustomerSearchFieldCopy[key] === "") {
            delete completeCustomerSearchFieldCopy[key];
          }
        }
        const { type: Type, ...customerFilters } =
          completeCustomerSearchFieldCopy;
        let countryCodeData: any = COUNTRY_CODE;

        const filter = completeCustomerSearchFieldCopy.country
          ? {
              ...customerFilters,
              country: countryCodeData[completeCustomerSearchFieldCopy.country],
            }
          : customerFilters;

        const response = await SearchServices.customerSearch(
          completeCustomerSearchData.type,
          filter,
          this.state.rows,
          this.state.page
        );
        if (Object.keys(response.data.data).length > 0) {
          const tableData = response.data?.data?.data;
          const count = response?.data?.data?.totalCount;
          if (!tableData) {
            this.setState({
              page: 0,
              rows: 5,
              isPaginationReset: true,
            });
          }
          this.setState({
            tblData: tableData || [],
            totalRecordsCount: count || 0,
            customerDataLoading: false,
            searchResultContent: true,
            isOpenAdvancedSearch: false,
            isPaginationReset: false,
          });
        } else {
          this.setState({
            customerDataLoading: false,
            alertMsg: "Something went wrong.",
            openAlert: true,
            isPaginationReset: true,
            page: 0,
            rows: 5,
          });
        }
      });
    } catch (error) {
      this.setState({
        customerDataLoading: false,
        alertMsg: "Something went wrong.",
        openAlert: true,
        isPaginationReset: true,
        page: 0,
        rows: 5,
      });
    }
  };
  handleAdvancedSearchLinkClick = () => {
    this.setState({ isOpenAdvancedSearch: !this.state.isOpenAdvancedSearch });
  };
  isEmptyCheck = () => {
    return (
      Object.values(this.state.completeCustomerSearchData).filter(
        (val) => val.length > 0
      ).length <= 0
    );
  };

  formReset = (page?: string) => {
    if (page === "searchTablePage") {
      this.setState(
        {
          completeCustomerSearchData: {
            ...this.state.completeCustomerSearchData,
            address: "",
            npiNumber: "",
            stateLicenseNumber: "",
            referenceId: "",
            enterpriseId: "",
            Name: "",
            firstName: "",
            lastName: "",
            country: "",
            customerId: "",
          },
          isPaginationReset: true,
          page: 0,
          rows: 5,
        },
        () => {
          this.fetchCustomerData();
        }
      );
    } else {
      this.setState({
        completeCustomerSearchData: {
          ...this.state.completeCustomerSearchData,
          address: "",
          npiNumber: "",
          stateLicenseNumber: "",
          referenceId: "",
          enterpriseId: "",
          Name: "",
          firstName: "",
          lastName: "",
          country: "",
          customerId: "",
        },
      });
    }
  };
  onSearchCustomerInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      completeCustomerSearchData: {
        ...this.state.completeCustomerSearchData,
        [event.target.name]: event.target.value,
      },
      isPaginationReset: true,
      page: 0,
      rows: 5,
    });
  };
  onDropDownChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState(
        {
          completeCustomerSearchData: {
            ...this.state.completeCustomerSearchData,
            [targetId]: selected.value,
          },
          isPaginationReset: true,
          page: 0,
          rows: 5,
        },
        () => {
          this.setState({
            completeCustomerSearchData: {
              ...this.state.completeCustomerSearchData,
              address: "",
              npiNumber: "",
              stateLicenseNumber: "",
              referenceId: "",
              enterpriseId: "",
              Name: "",
              firstName: "",
              lastName: "",
              country: "",
              customerId: "",
            },
          });
        }
      );
    }
  };
  onCountryDropDownChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState({
        completeCustomerSearchData: {
          ...this.state.completeCustomerSearchData,
          country: selected.value,
        },
        isPaginationReset: true,
        page: 0,
        rows: 5,
      });
    }
  };
  onChangePage = (page: number) => {
    this.setState({ page: page }, () => {
      this.fetchCustomerData();
    });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row }, () => {
      this.fetchCustomerData();
    });
  };
  handleExcelDownload = async (event: any) => {
    this.setState({ isDownloadLoading: true }, () => {
      const { tblData } = this.state;
      const searchResult: any = [];
      const tableHeaders: any = Object.keys(tblData[0]);
      tblData.forEach((obj) => {
        const allValues = Object.values(obj);
        searchResult.push(allValues);
      });
      ExcelService.CreateDataFile(
        this.state.completeCustomerSearchData.type,
        searchResult,
        tableHeaders
      );
      this.setState({ isDownloadLoading: false });
    });
  };
  render() {
    const {
      showSearchContent,
      searchResultContent,
      completeCustomerSearchData,
      isPaginationDisabled,
      isPaginationReset,
      customerDataLoading,
      tblData,
      totalRecordsCount,
      countryData,
      isDownloadLoading,
    } = this.state;
    return (
      <>
        <SnackbarAlert
          open={this.state.openAlert}
          alertType="error"
          message={this.state.alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />

        {searchResultContent && (
          <Box
            style={{
              display: "flex",
              gap: "1em",
              position: "absolute",
              top: "86px",
              right: "25px",
            }}
          >
            <RedOutlinedButton
              isButtonLoad={isDownloadLoading}
              label="Download Excel"
              onClick={this.handleExcelDownload}
              startIcon={<ArrowDownwardIcon />}
            />
          </Box>
        )}
        {!searchResultContent ? (
          <Box mr={10} ml={10} mt={10} className="SearchContent">
            <PageHeader
              label="Customer Search"
              style={{ color: "#ffffff", fontWeight: "normal" }}
            />
            <Grid
              container
              columns={6}
              mt={3}
              columnSpacing={3}
              mb={4}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Grid item xs={2} mb={4} className="DropDownLabel">
                <InputLabel>Select Customer Type</InputLabel>
                <SingleSelect
                  style={{
                    color: "#212121 !important",
                  }}
                  size="small"
                  name="type"
                  id="type"
                  defaultValue="HCP"
                  isRequired
                  value={completeCustomerSearchData.type}
                  onChange={this.onDropDownChange}
                  values={[
                    { text: "HCP", value: "HCP" },
                    { text: "HCO", value: "HCO" },
                  ]}
                />
              </Grid>
              <Grid item xs={2} mb={4}>
                <InputLabel
                  sx={{
                    visibility: completeCustomerSearchData.customerId
                      ? "visible"
                      : "hidden",
                  }}
                >
                  Customer ID
                </InputLabel>
                <TextInputField
                  placeholder="Enter Customer ID"
                  name="customerId"
                  id="customerId"
                  value={completeCustomerSearchData.customerId}
                  onChange={this.onSearchCustomerInputChange}
                />
              </Grid>
              <Grid item xs={2} mb={4} className="DropDownLabel">
                <InputLabel
                  sx={{
                    visibility: completeCustomerSearchData.country
                      ? "visible"
                      : "hidden",
                  }}
                >
                  Country
                </InputLabel>
                <SingleSelect
                  customOptions="Select Country"
                  size="small"
                  name="country"
                  id="country"
                  noneValue={true}
                  value={completeCustomerSearchData.country ?? ""}
                  values={countryData}
                  onChange={this.onCountryDropDownChange}
                />
              </Grid>
              {completeCustomerSearchData.type === "HCP" ? (
                <>
                  <Grid item xs={2} mb={4}>
                    <InputLabel
                      sx={{
                        visibility: completeCustomerSearchData.firstName
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      First Name
                    </InputLabel>
                    <TextInputField
                      placeholder="Enter First Name"
                      name="firstName"
                      id="firstName"
                      value={completeCustomerSearchData.firstName}
                      onChange={this.onSearchCustomerInputChange}
                    />
                  </Grid>
                  <Grid item xs={2} mb={4}>
                    <InputLabel
                      sx={{
                        visibility: completeCustomerSearchData.lastName
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      Last Name
                    </InputLabel>
                    <TextInputField
                      placeholder="Enter Last Name"
                      name="lastName"
                      id="lastName"
                      value={completeCustomerSearchData.lastName}
                      onChange={this.onSearchCustomerInputChange}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={2} mb={4}>
                  <InputLabel
                    sx={{
                      visibility: completeCustomerSearchData.Name
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    Organization Name
                  </InputLabel>
                  <TextInputField
                    isRequired={false}
                    placeholder="Search by Organization Name"
                    name="Name"
                    id="Name"
                    value={completeCustomerSearchData.Name}
                    onChange={this.onSearchCustomerInputChange}
                  />
                </Grid>
              )}
              {!showSearchContent && (
                <Grid item xs={6} justifyContent={"flex-end"} mb={4}>
                  <Box style={{ justifyContent: "flex-end", display: "flex" }}>
                    <RedOutlinedButton
                      label="Advanced Search"
                      onClick={this.showFormContent}
                      startIcon={<FilterListIcon />}
                    />
                  </Box>
                </Grid>
              )}
              {showSearchContent && (
                <>
                  <Grid item xs={2} mb={4}>
                    <InputLabel
                      sx={{
                        visibility: completeCustomerSearchData.address
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      Address
                    </InputLabel>
                    <TextInputField
                      placeholder="Enter Address"
                      name="address"
                      id="address"
                      value={completeCustomerSearchData.address}
                      onChange={this.onSearchCustomerInputChange}
                    />
                  </Grid>
                  <Grid item xs={2} mb={4}>
                    <InputLabel
                      sx={{
                        visibility: completeCustomerSearchData.npiNumber
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      NPI Number
                    </InputLabel>
                    <TextInputField
                      name="npiNumber"
                      value={completeCustomerSearchData.npiNumber}
                      placeholder="Enter NPI Number"
                      onChange={this.onSearchCustomerInputChange}
                    />
                  </Grid>
                  <Grid item xs={2} mb={4}>
                    <InputLabel
                      sx={{
                        visibility:
                          completeCustomerSearchData.stateLicenseNumber
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      State License
                    </InputLabel>
                    <TextInputField
                      name="stateLicenseNumber"
                      value={completeCustomerSearchData.stateLicenseNumber}
                      placeholder="Enter State License"
                      onChange={this.onSearchCustomerInputChange}
                    />
                  </Grid>

                  <Grid item xs={2} mb={4}>
                    <InputLabel
                      sx={{
                        visibility: completeCustomerSearchData.enterpriseId
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      Enterprise ID
                    </InputLabel>
                    <TextInputField
                      placeholder="Enter Enterprise ID"
                      name="enterpriseId"
                      id="enterpriseId"
                      value={completeCustomerSearchData.enterpriseId}
                      onChange={this.onSearchCustomerInputChange}
                    />
                  </Grid>
                  <Grid item xs={2} mb={4}>
                    <InputLabel
                      sx={{
                        visibility: completeCustomerSearchData.referenceId
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      Reference ID
                    </InputLabel>
                    <TextInputField
                      placeholder="Enter Reference ID"
                      name="referenceId"
                      id="referenceId"
                      value={completeCustomerSearchData.referenceId}
                      onChange={this.onSearchCustomerInputChange}
                    />
                  </Grid>
                </>
              )}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item mt={2.9}>
                  <RedButton
                    disabled={this.isEmptyCheck()}
                    label="Reset"
                    onClick={() => this.formReset()}
                  />
                </Grid>
                <Grid item mt={2.9}>
                  <GradientButton
                    disabled={this.isEmptyCheck()}
                    label="Search"
                    isButtonLoad={customerDataLoading}
                    onClick={this.fetchCustomerData}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box p={0} className={this.state.page > 0 ? "SearchTablePage" : ""}>
            <CardContainer>
              <Grid container p={2} flexDirection="row">
                <Grid container item columnSpacing={2}>
                  <Grid item xs={1.8}>
                    <InputLabel>Select Customer Type</InputLabel>
                    <SingleSelect
                      size="small"
                      isDisabled={this.state.page > 0}
                      name="type"
                      id="type"
                      isRequired
                      defaultValue="HCP"
                      value={completeCustomerSearchData.type}
                      onChange={this.onDropDownChange}
                      values={[
                        { text: "HCP", value: "HCP" },
                        { text: "HCO", value: "HCO" },
                      ]}
                    />
                  </Grid>
                  {completeCustomerSearchData.type === "HCP" ? (
                    <>
                      <Grid item xs={1.6} mb={4}>
                        <InputLabel
                          sx={{
                            visibility: completeCustomerSearchData.firstName
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          First Name
                        </InputLabel>
                        <TextInputField
                          isReadonly={this.state.page > 0}
                          placeholder="Enter First Name"
                          name="firstName"
                          id="firstName"
                          value={completeCustomerSearchData.firstName}
                          onChange={this.onSearchCustomerInputChange}
                        />
                      </Grid>
                      <Grid item xs={1.6} mb={4}>
                        <InputLabel
                          sx={{
                            visibility: completeCustomerSearchData.lastName
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          Last Name
                        </InputLabel>
                        <TextInputField
                          isReadonly={this.state.page > 0}
                          placeholder="Enter Last Name"
                          name="lastName"
                          id="lastName"
                          value={completeCustomerSearchData.lastName}
                          onChange={this.onSearchCustomerInputChange}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={3.2} mb={4}>
                      <InputLabel
                        sx={{
                          visibility: completeCustomerSearchData.Name
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        Organization Name
                      </InputLabel>
                      <TextInputField
                        isReadonly={this.state.page > 0}
                        isRequired={false}
                        placeholder="Search by Organization Name"
                        name="Name"
                        id="Name"
                        value={completeCustomerSearchData.Name}
                        onChange={this.onSearchCustomerInputChange}
                      />
                    </Grid>
                  )}
                  <Grid item xs={1.7}>
                    <InputLabel
                      sx={{
                        visibility: completeCustomerSearchData.customerId
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      Customer ID
                    </InputLabel>
                    <TextInputField
                      isReadonly={this.state.page > 0}
                      placeholder="Enter Customer ID"
                      name="customerId"
                      id="customerId"
                      value={completeCustomerSearchData.customerId}
                      onChange={this.onSearchCustomerInputChange}
                    />
                  </Grid>
                  <Grid item xs={1.7} className="DropDownLabel">
                    <InputLabel
                      sx={{
                        visibility: completeCustomerSearchData.country
                          ? "visible"
                          : "hidden",
                      }}
                    >
                      Country
                    </InputLabel>
                    <SingleSelect
                      customOptions="Select Country"
                      isDisabled={this.state.page > 0}
                      size="small"
                      name="country"
                      id="country"
                      noneValue={true}
                      value={completeCustomerSearchData.country ?? ""}
                      values={countryData}
                      onChange={this.onCountryDropDownChange}
                    />
                  </Grid>

                  <Grid item xs={1.88} mt={2}>
                    <Link
                      style={{
                        color: "#0577A3",
                        cursor: "pointer",
                        textDecoration: "none",
                        marginTop: "10px",
                        display: "block",
                      }}
                      onClick={this.handleAdvancedSearchLinkClick}
                    >
                      <FilterListIcon
                        style={{
                          marginTop: "1px",
                          float: "left",
                          marginRight: "3px",
                        }}
                      />
                      Advanced Search
                    </Link>
                    <Grid></Grid>
                    <Popover
                      className="CustomPopover"
                      open={this.state.isOpenAdvancedSearch}
                      anchorReference="anchorPosition"
                      anchorPosition={{ top: 150, left: 835 }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      sx={{
                        "& .MuiPopover-paper": {
                          width: 700,
                          position: "absolute",
                          top: "305px!important",
                          maxHeight: "450px",
                          overflowY: "auto",
                        },
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <Grid container>
                          <Grid item xs={6} md={8}>
                            <PageHeader label="Advanced Search" />
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            md={4}
                            style={{ textAlign: "right" }}
                          >
                            <FontAwesomeIcon
                              size="2x"
                              onClick={this.handleAdvancedSearchLinkClick}
                              icon={faXmark}
                            />
                          </Grid>
                        </Grid>
                        <hr style={{ border: "1px solid #D1D1D1" }} />

                        <Grid
                          container
                          mt={3}
                          columns={4}
                          className={
                            this.state.page > 0 ? "SearchTablePage" : ""
                          }
                          style={{ justifyContent: "space-around" }}
                        >
                          <Grid item xs={1.7} mb={4}>
                            <InputLabel
                              sx={{
                                visibility: completeCustomerSearchData.address
                                  ? "visible"
                                  : "hidden",
                              }}
                            >
                              Address
                            </InputLabel>
                            <TextInputField
                              isReadonly={this.state.page > 0}
                              placeholder="Enter Address"
                              name="address"
                              id="address"
                              value={completeCustomerSearchData.address}
                              onChange={this.onSearchCustomerInputChange}
                            />
                          </Grid>
                          <Grid item xs={1.7} mb={4}>
                            <InputLabel
                              sx={{
                                visibility: completeCustomerSearchData.npiNumber
                                  ? "visible"
                                  : "hidden",
                              }}
                            >
                              NPI Number
                            </InputLabel>
                            <TextInputField
                              isReadonly={this.state.page > 0}
                              name="npiNumber"
                              value={completeCustomerSearchData.npiNumber}
                              placeholder="Enter NPI Number"
                              onChange={this.onSearchCustomerInputChange}
                            />
                          </Grid>
                          <Grid item xs={1.7} mb={4}>
                            <InputLabel
                              sx={{
                                visibility:
                                  completeCustomerSearchData.stateLicenseNumber
                                    ? "visible"
                                    : "hidden",
                              }}
                            >
                              State License
                            </InputLabel>
                            <TextInputField
                              isReadonly={this.state.page > 0}
                              name="stateLicenseNumber"
                              value={
                                completeCustomerSearchData.stateLicenseNumber
                              }
                              placeholder="Enter State License"
                              onChange={this.onSearchCustomerInputChange}
                            />
                          </Grid>
                          <Grid item xs={1.7} mb={4}>
                            <InputLabel
                              sx={{
                                visibility:
                                  completeCustomerSearchData.referenceId
                                    ? "visible"
                                    : "hidden",
                              }}
                            >
                              Reference ID
                            </InputLabel>
                            <TextInputField
                              isReadonly={this.state.page > 0}
                              placeholder="Enter Reference ID"
                              name="referenceId"
                              id="referenceId"
                              value={completeCustomerSearchData.referenceId}
                              onChange={this.onSearchCustomerInputChange}
                            />
                          </Grid>
                          <Grid item xs={1.7} mb={4}>
                            <InputLabel
                              sx={{
                                visibility:
                                  completeCustomerSearchData.enterpriseId
                                    ? "visible"
                                    : "hidden",
                              }}
                            >
                              Enterprise ID
                            </InputLabel>
                            <TextInputField
                              isReadonly={this.state.page > 0}
                              placeholder="Enter Enterprise ID"
                              name="enterpriseId"
                              id="enterpriseId"
                              value={completeCustomerSearchData.enterpriseId}
                              onChange={this.onSearchCustomerInputChange}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          columnGap={1.5}
                          justifyContent={"flex-end"}
                        >
                          <RedButton
                            label="Reset"
                            disabled={this.isEmptyCheck()}
                            onClick={() => this.formReset("searchTablePage")}
                          />

                          <GradientButton
                            disabled={
                              this.isEmptyCheck() || this.state.page > 0
                            }
                            label="Search"
                            isButtonLoad={customerDataLoading}
                            onClick={this.fetchCustomerData}
                          />
                        </Grid>
                      </Box>
                    </Popover>
                  </Grid>
                  <Grid item mt={3}>
                    <RedButton
                      disabled={this.isEmptyCheck()}
                      label="Reset"
                      onClick={() => this.formReset("searchTablePage")}
                    />
                  </Grid>
                  <Grid item mt={3}>
                    <GradientButton
                      disabled={this.isEmptyCheck() || this.state.page > 0}
                      label="Search"
                      isButtonLoad={customerDataLoading}
                      onClick={this.fetchCustomerData}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <hr
                style={{
                  border: "0.5px solid #C7C7C7",
                  opacity: "0.5",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              />
              <Card>
                <CardContent>
                  <SearchDataLoader
                    tableData={tblData}
                    isPaginationReset={isPaginationReset}
                    isPaginationDisabled={isPaginationDisabled}
                    isLoading={customerDataLoading}
                    totalRecordsCount={totalRecordsCount}
                    onChangePage={this.onChangePage}
                    onChangeRow={this.onChangeRow}
                    sortingArrow={true}
                  />
                </CardContent>
              </Card>
            </CardContainer>
          </Box>
        )}
      </>
    );
  }
}
export default CustomerSearchTab;
