import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { isEqual } from "lodash";
import { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IconType } from "../Button/IconButton";

import ReportingTable from "./ReportingTable";

interface Props extends RouteComponentProps<any, any, any> {
  tableData: any;
  actionArray?: IconType[];
  IconType?: IconDefinition;
  totalRecordsCount?: number;

  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  handleToolbarCancel?: () => void;
  onActionClick?: (action: IconType, recordId: any) => void;
  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
}

interface State {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  totalCount: number;
}

class ReportingDataLoader extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      headCellData: [],
      totalCount: 0,
    };
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      this.props.tableData &&
      !isEqual(prevProps.tableData[0], this.props.tableData[0])
    ) {
      this.changeHeadCellData();
    }
    if (prevProps.IconType !== this.props.IconType) {
      this.changeHeadCellData();
    }
    if (prevProps.actionArray?.length !== this.props.actionArray?.length) {
      this.changeHeadCellData();
    }
  };

  componentDidMount = () => {
    this.changeHeadCellData();
  };

  changeHeadCellData = () => {
    if (this.props.tableData.length) {
      const headerData: any = Object.keys(this.props.tableData[0]).map((k) => {
        return {
          key: k,
          label: k,
        };
      });

      if (this.props.actionArray?.length) {
        headerData.push({
          key: "",
          label: "Action",
          linkTo: null,
        });
      }
      this.setState({ headCellData: headerData });
    } else {
      this.setState({ headCellData: [] });
    }
  };

  onChangePage = (page: number) => {
    this.props.onChangePage(page);
  };

  onChangeRow = (row: number) => {
    this.props.onChangeRow(row);
  };

  render() {
    const { headCellData } = this.state;
    return (
      <ReportingTable
        isEmptyReset={this.props.isEmptyReset}
        totalRecordsCount={this.props.totalRecordsCount}
        isPaginationReset={this.props.isPaginationReset}
        isPaginationDisabled={this.props.isPaginationDisabled}
        onChangeRow={this.onChangeRow}
        onChangePage={this.onChangePage}
        headCellData={headCellData}
        data={this.props.tableData}
        isLoading={this.props.isLoading}
        onActionClick={this.props.onActionClick}
        actionArray={this.props.actionArray}
      />
    );
  }
}

export default withRouter(ReportingDataLoader);
