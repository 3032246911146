import { FileType } from "../../Common/Enums";

export default class FileUploadDetailModel {
    id?: number;
    fileName: string = '';
    sizeInKB: number = 0;
    sizeInMB: number = 0;
    type!: FileType;
    status: string = '';
    ext: string = '';
    file: File | null = null;
    totalRecords: number = 0;
    fileType?: string = ''

}