import * as React from "react";
import { Stack, Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { IBreadCrumb } from "./IBreadCrumb";

interface Props extends RouteComponentProps<any, any, any> {
  breadcrumbs: IBreadCrumb[];
}
interface State {}
class BreadCrumb extends React.Component<Props, State> {
  render() {
    const { breadcrumbs } = this.props;
    return (
      <Stack spacing={2}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontWeight={'bold'} fontSize="small" />}
          
          aria-label="breadcrumb"
        >
          {breadcrumbs.map((el, index) => {
            if (el.link) {
              return <Link
              key={index+1}
              style={{color: '#00749E', fontWeight: 'bold'}}
              to={el.link}
              onClick={() => {
                this.props.history.push(el.link);
              }}
            >
              {el.text}
            </Link>
            } return (<Typography key={index+1} style={{fontWeight: 'bold'}} color="text.primary">
              {el.text}
            </Typography>)
          })}
        </Breadcrumbs>
      </Stack>
    );
  }
}
export default withRouter(BreadCrumb);
