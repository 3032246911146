import { Box } from '@mui/material';
import React from 'react';
import TabBar from '../../Components/Tabs/TabBar';
import { TabModel, TabType } from '../../Components/Tabs/TabModel';
import TabPanel from "../../Components/Tabs/TabPanel";

import { RouteComponentProps } from 'react-router-dom';
import PageHeader from '../../Components/Text/PageHeader';
import ExploreTabs from "./Tabs";

interface Props extends RouteComponentProps<any, any, any> { }
interface State {
    currentTab: TabModel;
    tableData: any[];
    countObj: { [index in TabType]: number };
    tabs: TabModel[];
    isLoading: boolean;

}

class Explore extends React.Component<Props, State> {
    
    readonly Tabs: TabModel[] = [
        {
            name: 'Customer Search', 
            type: 'CustomerSearch',
           
        },
        {
            name: 'Clinical Trial Search', 
            type: 'ClinicalTrialSearch',
           
        }
    ]
    constructor(props: Props | Readonly<Props>) {
        super(props)

        this.state = {
            currentTab: {} as TabModel,
            tableData: [],
            tabs: [],
            countObj: {
                CustomerSearch:0,
                ClinicalTrialSearch:0,
            } as { [index in TabType]: number },
            isLoading: true,
        }
    }
    

    componentDidMount(): void {
        this.setState({ tabs: this.Tabs, currentTab: this.Tabs[0]});
    }
    handleCount = (dataS: any, label?: string) => {
        if (label) {
            this.setState({ countObj: { ...this.state.countObj, [label]: dataS } });
        }
    }
    render() {
        const { currentTab, tabs } = this.state;
        /* if(isLoading) {
            return <BackdropLoaderCircular show={true} style={{ backgroundColor: '#FFFFFF' }} />
        } */
        return (
            <>
                <Box style={{ display: 'flex', justifyContent: 'space-between', padding: "25px 10px 0", }}>
                    <PageHeader label='Explore' style={{
                        fontSize:"22px"
                    }} />
                   
                    
                    
                </Box>
                <TabBar countObj={this.state.countObj} currentTab={currentTab} tabs={tabs} onTabChange={this.handleTabChange} />
                {tabs.map((tab,i) => {
                    return (
                        <TabPanel key={i} tabs={tabs} value={currentTab.type} index={i}>
                            <ExploreTabs key={`expense-${i}`} tabType={tab.type} />
                        </TabPanel>
                    );
                })}

            </>
        )
    }
    handleTabChange = (selectedTab: TabModel) => {
        this.setState({ currentTab: selectedTab });
    }

}
 export default Explore;