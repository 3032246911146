import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Component } from "react";

class AODSRawDataChart extends Component {
  render() {
    const options = {
      chart: {
        type: "spline",
      },
      title: {
        text: "",
      },

      legend: {
        enabled: true,
        verticalAlign: "top",
        align: "right",
      },
      subtitle: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#2E2F30",

        formatter: function () {
          return `
                  <div  style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
                 <b>14 Jul, 2022</b><br><br>
                  <span style=color:#BFBFBF; padding-bottom:10px>Spend Src Sys Cd :</span> <span style=padding-left:10px>AMX</span><br>
                  <span style=color:#BFBFBF>Year of Olds Ld Tmstmp :</span> <span style=padding-left:10px>2022</span><br>
                  <span style=color:#BFBFBF>Distinct Count of Spnd ID : </span> <span style=padding-left:10px>3,80,845</span><br>
                 </div>
                  `;
        },
      },
      xAxis: {
        lineWidth: 0,
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        tickmarkPlacement: "on",
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: "",
        },
        startOnTick: true,

        tickInterval: 200,
      },

      plotOptions: {
        line: {
          dataLabels: {
            enabled: false,
          },
          lineWidth: 3, // Increase line width for smoother appearancelinecap: "round",
          linecap: "rounds",
          enableMouseTracking: true,
        },
      },
      series: [
        {
          name: "AMX",
          color: "#4791FF",
          marker: {
            symbol: "circle",
          },
          padding: 2,
          data: [400, 570, 520, 590, 380, 210, 550],
          pointPlacement: "on",
        },
        {
          name: "ANC",
          color: "#44BA7F",
          marker: {
            symbol: "circle",
          },

          data: [100, 210, 180, 400, 500, 250, 150],
          pointPlacement: "on",
        },
        {
          name: "ATR",
          color: "#8C83FB",
          marker: {
            symbol: "circle",
          },

          data: [440, 780, 750, 850, 750, 830, 500],
          pointPlacement: "on",
        },
      ],
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  }
}

export default AODSRawDataChart;
