import { AlertColor, Box, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SingleSelect from "../../Components/Select/SingleSelect";

import { isEqual } from "lodash";
import GradientButton from "../../Components/Button/GradientButton";
import RedButton from "../../Components/Button/RedButton";
import CardContainer from "../../Components/Card/CardContainer";
import MultiStepSelect from "../../Components/Select/MultiStepSelect";
import SelectModel from "../../Components/Select/SelectModel";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import ExceptionDataLoader from "../../Components/Table/ExceptionDataLoader";
import PageHeader from "../../Components/Text/PageHeader";
import TextInputField from "../../Components/TextInputField/TextInputField";
import {
  ExceptionLinkMappingModel,
  ExceptionReportDataAndCountModel,
  ExceptionReportDataModel,
  ExceptionReportSaveDataModel,
} from "../../Models/ExceptionReportModel";
import { OnDemandSelectionModel } from "../../Models/OnDemandReportModels";
import ExceptionReportingSevice from "../../Services/ExceptionReportingServices";
import MissingTransactionService from "../../Services/MissingTransactionServices";
import NotificationService from "../../Services/NotificationWidgetService";
import OnDemandReportService from "../../Services/OnDemandReportServices";
const OnDemandReportServices = new OnDemandReportService();
const MissingTransactionServices = new MissingTransactionService();
const ExceptionReportingServices = new ExceptionReportingSevice();
const notificationServices = new NotificationService();
type tableModeForException = "BulkComments" | undefined;
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  selectedSourceValue: string;
  isPopoverOpen: boolean;
  tblData: any[];
  errorData: any[];
  allErrorData: any[];
  checkedData: any;
  collapsedData: any;
  rows: number;
  page: number;
  errTblRows: number;
  errTblPage: number;
  searchText: string;
  selectedUser: string;
  selectedFilterStatus: string;
  yearValue: string;
  reportVal: string;
  isPaginationDisabled: boolean;
  isEmptyReset: boolean;
  isErrTblPaginationDisabled: boolean;
  isErrTblEmptyReset: boolean;
  isErrTblPaginationReset: boolean;
  totalRecordsCount: number | undefined;
  errTblRecordsCount: number | undefined;
  isLoading: boolean;
  selectedExceptionTable: any;
  exceptionModes: tableModeForException;
  bulkEditInputFormData: any;
  sourceMappingData: any;
  isSubTableLoading: boolean;
  reportTypeData: SelectModel[];
  yearData: SelectModel[];
  usersData: SelectModel[];
  allSubTableData: any;
  errorMsg: string;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  isButtonLoad: boolean;
  isSearchButtonLoad: boolean;
  openLinksData: any[];
  isSearchClick: boolean;
}

class ExceptionReporting extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      selectedSourceValue: "",
      isPopoverOpen: true,
      tblData: [],
      errorData: [],
      allErrorData: [],
      checkedData: [],
      collapsedData: [],
      rows: 5,
      page: 1,
      errTblPage: 1,
      errTblRows: 5,
      searchText: "",
      selectedUser: "",
      selectedFilterStatus: "",
      yearValue: "",
      reportVal: "",
      isPaginationDisabled: false,
      isEmptyReset: false,
      isErrTblEmptyReset: false,
      isErrTblPaginationReset: false,
      isErrTblPaginationDisabled: false,
      isLoading: false,
      totalRecordsCount: undefined,
      errTblRecordsCount: undefined,
      selectedExceptionTable: [],
      exceptionModes: undefined,
      bulkEditInputFormData: {},
      sourceMappingData: {},
      isSubTableLoading: false,
      reportTypeData: [],
      yearData: [],
      usersData: [],
      allSubTableData: [],
      errorMsg: "",
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isButtonLoad: false,
      isSearchButtonLoad: false,
      openLinksData: [],
      isSearchClick: false,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    this.getSourceMappingData();
    this.getSelectionData();

    const userName = localStorage.getItem("exceptionUser");
    this.getUsersData().then(() => {
      if (userName) {
        this.setState({ selectedUser: userName }, () => {
          this.handleSearchClick();
          localStorage.removeItem("exceptionUser");
        });
      } else {
        this.getErrorData();
      }
    });
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!isEqual(prevState.errorMsg, this.state.errorMsg)) {
      this.setState({ openLinksData: [] });
    }
  }
  getSourceMappingData = async () => {
    try {
      const response = await MissingTransactionServices.getSourceMappingData();
      const dataArr = response.data.data.map((el) => {
        return {
          ...el,
          searchFields: el.searchFields.replace(/[{}"]/g, "").split(","),
        };
      });
      let result: any = {};

      dataArr.forEach((item) => {
        if (!result[item.sourceType]) {
          result[item.sourceType] = [];
        }
        let fieldExists = result[item.sourceType].some(
          (el: any) => el.text === item.sourceName
        );
        if (!fieldExists) {
          result[item.sourceType].push({
            text: item.sourceName,
            value: item.sourceName,
          });
        }
      });
      this.setState({
        sourceMappingData: result,
      });
    } catch (error) {
      console.log(error);
    }
  };
  getUsersData = async () => {
    try {
      const getAllUsers = await notificationServices.getAllUsersData();

      const usersData = getAllUsers.data.data.map((item: any) => {
        return {
          id: item.id,
          text: item.userName,
          value: item.userName,
          personaType: item.persona,
        };
      });
      const filteredUserData = usersData.filter((ele: any, index: any) => {
        return (
          index ===
          usersData.findIndex((val: any) => {
            return val.text === ele.text;
          })
        );
      });

      this.setState({ usersData: filteredUserData });
    } catch (error) {
      console.log(error);
    }
  };
  getSelectionData = async () => {
    const response = await OnDemandReportServices.getSelectionData();
    const rptTypeData = [
      ...new Set(
        response.data.result.map((el: OnDemandSelectionModel) => el.rpt_type)
      ),
    ].map((elem) => {
      return {
        text: elem,
        value: elem,
      };
    });
    const yearData = [
      ...new Set(
        response.data.result.map((el: OnDemandSelectionModel) => el.year)
      ),
    ].map((elem) => {
      return {
        text: elem,
        value: elem,
      };
    });
    this.setState({ reportTypeData: rptTypeData, yearData });
  };
  getErrorData = async () => {
    try {
      this.setState({ isLoading: true });
      const obj: ExceptionReportDataAndCountModel = {
        par_groupclause: "",
        par_whereclause: "",
        pageno: this.state.errTblPage,
        rows_per_page: this.state.errTblRows,
      };
      const response = await ExceptionReportingServices.getDataAndCount(obj);
      if (response.result.length > 0) {
        const errorData = response.result.map((el: any) => {
          return {
            errorType: el.var_err_desc,
            count: el.var_total_count ? el.var_total_count : 0,
            correctionMade: `${
              el.var_completed_count ? el.var_completed_count : 0
            }/${el.var_total_count ? el.var_total_count : 0}`,
          };
        });
        this.setState({
          errorData,
          isLoading: false,
          errTblRecordsCount: response.result[0].var_total_records,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };
  getSubTableData = async (errorType: string) => {
    try {
      this.setState({ isSubTableLoading: true });
      const obj: ExceptionReportDataModel = {
        par_whereclause: `err_desc=''${errorType}''`,
        pageno: this.state.page,
        rows_per_page: this.state.rows,
      };
      const response = await ExceptionReportingServices.getSubTableData(obj);
      const subTableData = response.result.map((el: any) => {
        return {
          RPT_RCRD_ID: el.var_rpt_rcrd_id,
          CUST_ID: el.cust_id,
          Status: el.status,
          Comments: el.comments,
          Assigned_To: el.assigned_to,
        };
      });
      this.setState({
        tblData: subTableData,
        collapsedData: subTableData,
        isSubTableLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getLinkMappingData = async (errorMsg: string) => {
    try {
      this.setState({ isSubTableLoading: true });
      const obj: ExceptionLinkMappingModel = {
        errorMsg: errorMsg,
      };
      const response = await ExceptionReportingServices.getLinkMappingData(obj);
      if (response?.data.data.length > 0) {
        this.setState({ openLinksData: response?.data.data[0].links });
      } else {
        this.setState({ openLinksData: [] });
      }
    } catch (error) {
      console.log(error);
    }
  };
  getErrorType = (record: any) => {
    if (record) {
      this.setState(
        {
          isEmptyReset: !this.state.isEmptyReset,
          rows: 5,
          page: 1,
          totalRecordsCount: record.count,
          errorMsg: record.errorType,
          isErrTblPaginationDisabled: true,
          exceptionModes: undefined,
        },
        async () => {
          await Promise.all([
            this.getLinkMappingData(record.errorType),
            Object.keys(this.createFilterString.length > 0)
              ? this.getFilteredSubTableData(record.errorType)
              : this.getSubTableData(record.errorType),
          ]);
        }
      );
    } else {
      this.setState({ isErrTblPaginationDisabled: false });
    }
  };

  handleSourceDropDownChange = (selected: SelectModel) => {
    this.setState({
      selectedSourceValue: selected.value,
    });
  };
  onChangePage = (page: number) => {
    this.setState({ page: page }, () => {
      this.state.isSearchClick
        ? this.getFilteredSubTableData(this.state.errorMsg)
        : this.getSubTableData(this.state.errorMsg);
    });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row }, () => {
      this.setState(
        {
          page: this.state.page > 1 ? 1 : this.state.page,
          isEmptyReset: !this.state.isEmptyReset,
        },
        () => {
          this.state.isSearchClick
            ? this.getFilteredSubTableData(this.state.errorMsg)
            : this.getSubTableData(this.state.errorMsg);
        }
      );
    });
  };
  onErrTblChangePage = (page: number) => {
    this.setState({ errTblPage: page }, () => {
      this.state.isSearchClick ? this.onSearchClick() : this.getErrorData();
    });
  };
  onErrTblChangeRow = (row: number) => {
    this.setState({ errTblRows: row }, () => {
      this.setState(
        {
          errTblPage: this.state.errTblPage > 1 ? 1 : this.state.errTblPage,
          isErrTblEmptyReset: !this.state.isErrTblEmptyReset,
        },
        () => {
          this.state.isSearchClick ? this.onSearchClick() : this.getErrorData();
        }
      );
    });
  };
  onClickCheckBox = (selectedRows: any, data: any) => {
    this.setState({
      selectedExceptionTable: selectedRows,
      checkedData: data,
    });
  };

  handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchText = event.target.value;
    this.setState({
      searchText,
    });
  };
  handleSelectChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState({ ...this.state, [targetId]: selected.value });
    }
  };

  handleToolbarButtons = (action: tableModeForException) => {
    this.setState({
      exceptionModes: action,
      bulkEditInputFormData: {},
    });
  };

  handleApply = (event: any) => {
    event.preventDefault();
    const { bulkEditInputFormData } = this.state;

    const obj: any = {};
    Object.keys(bulkEditInputFormData).map((el) => {
      if (`${bulkEditInputFormData[el]}`.length > 0) {
        obj[el] = bulkEditInputFormData[el];
      }
      return el;
    });

    this.setState({
      tblData: this.state.checkedData.map((el: any, ind: any) => {
        if (el.checked === true) {
          return {
            ...el,
            ...obj,
          };
        }
        return el;
      }),
    });
  };
  handleEditInputChange = (
    event: any,
    index: any,
    data?: any,
    targetId?: any
  ) => {
    this.setState({
      tblData: this.state.checkedData.map((el: any, ind: any) => {
        if (ind === index) {
          return {
            ...el,
            [targetId]: event.target.value ? event.target.value : null,
            checked: true,
          };
        }
        return el;
      }),
    });
  };
  handleTableSelectChange = (selected: any, index: any, targetId?: any) => {
    this.setState({
      tblData: this.state.checkedData.map((el: any, ind: any) => {
        if (ind === index) {
          return {
            ...el,
            [targetId]: selected.value ? selected.value : null,
            checked: true,
          };
        }
        return el;
      }),
    });
  };
  handleBulkInputChange = (event: any, targetId?: any) => {
    if (targetId === "Status" || targetId === "Assigned_To") {
      this.setState({
        bulkEditInputFormData: {
          ...this.state.bulkEditInputFormData,
          [targetId]: event.value ? event.value : null,
        },
      });
    } else {
      this.setState({
        bulkEditInputFormData: {
          ...this.state.bulkEditInputFormData,
          [targetId]: event.target.value ? event.target.value : null,
        },
      });
    }
  };
  isEmptyCheck = () => {
    const {
      selectedSourceValue,
      selectedFilterStatus,
      searchText,
      reportVal,
      yearValue,
      selectedUser,
    } = this.state;
    if (
      selectedSourceValue ||
      selectedFilterStatus ||
      reportVal ||
      searchText ||
      yearValue ||
      selectedUser
    ) {
      return false;
    }
    return true;
  };
  createFilterString = (errorString?: string) => {
    const {
      selectedUser,
      selectedFilterStatus,
      searchText,
      selectedSourceValue,
      yearValue,
      reportVal,
    } = this.state;

    const filterConditions = [];
    const groupParams = [];
    groupParams.push("er.err_desc");
    if (searchText || errorString) {
      filterConditions.push(
        errorString
          ? `err_desc=''${errorString}''`
          : `Lower(er.err_desc) like Lower(''%${searchText}%'')`
      );
    }

    if (selectedSourceValue) {
      filterConditions.push(`source=''${selectedSourceValue}''`);
      groupParams.push("source");
    }
    if (reportVal) {
      filterConditions.push(`rpt_type=''${reportVal}''`);
      groupParams.push("rpt_type");
    }
    if (selectedUser) {
      filterConditions.push(`assigned_to=''${this.formatName(selectedUser)}''`);
      groupParams.push("assigned_to");
    }
    if (yearValue) {
      filterConditions.push(`year=''${yearValue}''`);
      groupParams.push("year");
    }
    if (selectedFilterStatus) {
      filterConditions.push(`status=''${selectedFilterStatus}''`);
      groupParams.push("status");
    }

    const nonEmptyConditions = filterConditions.filter(
      (condition) => condition.trim() !== ""
    );

    if (nonEmptyConditions.length > 0) {
      return {
        par_whereclause: nonEmptyConditions.join(" and "),
        par_groupclause: groupParams.join(","),
      };
    }

    return "";
  };
  getFilteredSubTableData = async (errorType: string) => {
    try {
      const filterString = this.createFilterString(errorType);
      this.setState({ isSubTableLoading: true });
      const obj: ExceptionReportDataModel = {
        par_whereclause: filterString ? filterString.par_whereclause : "",
        pageno: this.state.page,
        rows_per_page: this.state.rows,
      };
      const response = await ExceptionReportingServices.getSubTableData(obj);
      const subTableData = response.result.map((el: any) => {
        return {
          RPT_RCRD_ID: el.var_rpt_rcrd_id,
          CUST_ID: el.cust_id,
          Status: el.status,
          Comments: el.comments,
          Assigned_To: el.assigned_to,
        };
      });
      this.setState({
        tblData: subTableData,
        collapsedData: subTableData,
        isSubTableLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  onSearchClick = async () => {
    try {
      const filterString = this.createFilterString();
      this.setState({ isLoading: true, isErrTblPaginationReset: true });
      const obj: ExceptionReportDataAndCountModel = {
        par_groupclause: filterString ? filterString.par_groupclause : "",
        par_whereclause: filterString ? filterString.par_whereclause : "",
        pageno: this.state.errTblPage,
        rows_per_page: this.state.errTblRows,
      };
      const response = await ExceptionReportingServices.getDataAndCount(obj);
      const errorData = response.result.map((el: any) => {
        return {
          errorType: el.var_err_desc,
          count: el.var_total_count ? el.var_total_count : 0,
          correctionMade: `${
            el.var_completed_count ? el.var_completed_count : 0
          }/${el.var_total_count ? el.var_total_count : 0}`,
        };
      });
      this.setState({
        errorData,
        errTblRecordsCount: response.result[0].var_total_records,
        isLoading: false,
        isSearchButtonLoad: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
        isSearchButtonLoad: false,
        errorData: [],
        errTblRecordsCount: undefined,
      });
    }
  };
  handleSearchClick = () => {
    this.setState(
      {
        rows: 5,
        page: 1,
        errTblPage: 1,
        errTblRows: 5,
        exceptionModes: undefined,
        isErrTblPaginationReset: !this.state.isErrTblPaginationReset,
        bulkEditInputFormData: {},
        checkedData: [],
        selectedExceptionTable: [],
        isSearchButtonLoad: true,
        isErrTblPaginationDisabled: false,
        isSearchClick: true,
      },
      () => {
        this.onSearchClick();
      }
    );
  };
  onResetClick = () => {
    this.setState(
      {
        selectedSourceValue: "",
        selectedFilterStatus: "",
        searchText: "",
        reportVal: "",
        yearValue: "",
        selectedUser: "",
        tblData: [],
        errorData: [],
        checkedData: [],
        isErrTblPaginationReset: !this.state.isErrTblPaginationReset,
        errTblPage: 1,
        errTblRows: 5,
        selectedExceptionTable: [],
        bulkEditInputFormData: {},
        exceptionModes: undefined,
        isErrTblPaginationDisabled: false,
        openLinksData: [],
        isSearchClick: false,
      },
      () => {
        this.getErrorData();
      }
    );
  };
  handleSave = async (data: any) => {
    try {
      const { collapsedData, errorMsg } = this.state;
      this.setState({ isButtonLoad: true });
      let result = data
        .map((obj1: any) => {
          let obj2 = collapsedData.find(
            (obj2: any) => obj2.RPT_RCRD_ID === obj1.RPT_RCRD_ID
          );
          if (
            obj2 &&
            (obj1.Status !== obj2.Status ||
              obj1.Comments !== obj2.Comments ||
              obj1.Assigned_To !== obj2.Assigned_To)
          ) {
            return {
              rpt_rcrd_id: obj1.RPT_RCRD_ID,
              err_desc: errorMsg,
              status: obj1.Status,
              comments: obj1.Comments,
              assigned_to: this.formatName(obj1.Assigned_To),
            };
          }
          return null; // return null when the condition is not met
        })
        .filter(Boolean); // filter out null values

      const payload: ExceptionReportSaveDataModel = {
        exception_report_payload: result,
      };
      const response = await ExceptionReportingServices.saveData(payload);
      if (response.result === "Success") {
        this.setState(
          {
            alertMsg:
              result.length > 1
                ? "Records are updated successfully."
                : "Record is updated successfully.",
            alertType: "success",
            openAlert: true,
            isButtonLoad: false,
          },
          () => {
            this.onResetClick();
          }
        );
      } else {
        this.setState({
          alertMsg: "Something went wrong.",
          alertType: "error",
          openAlert: true,
          isButtonLoad: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  isSaveDisabled = () => {
    const { selectedExceptionTable, collapsedData } = this.state;

    let result = selectedExceptionTable
      .map((obj1: any) => {
        let obj2 = collapsedData.find(
          (obj2: any) => obj2.RPT_RCRD_ID === obj1.RPT_RCRD_ID
        );
        if (
          obj2 &&
          (obj1.Status !== obj2.Status ||
            obj1.Comments !== obj2.Comments ||
            obj1.Assigned_To !== obj2.Assigned_To)
        ) {
          return {
            status: obj1.Status,
            comments: obj1.Comments,
            assigned_to: obj1.Assigned_To,
          };
        }
        return null; // return null when the condition is not met
      })
      .filter(Boolean); // filter out null values
    const values = result.map((el: any) =>
      el.comments ? el.comments.trim() === "" : el.comments === ""
    );

    if (result.length > 0 && !values.includes(true)) {
      return false;
    } else {
      return true;
    }
  };
  selectStatusData = [
    {
      text: "Action Required",
      value: "Action Required",
    },
    {
      text: "Sent to Source",
      value: "Sent to Source",
    },
    {
      text: "In Progress",
      value: "In Progress",
    },
    {
      text: "No Action Required",
      value: "No Action Required",
    },
    {
      text: "Completed",
      value: "Completed",
    },
  ];
  formatName = (name: string) => {
    if (name.includes("O'")) {
      return name.replace("O'", "O''''");
    }
    return name;
  };
  render() {
    const {
      searchText,
      selectedUser,
      selectedFilterStatus,
      yearValue,
      reportVal,
      isPaginationDisabled,
      isEmptyReset,
      totalRecordsCount,
      exceptionModes,
      bulkEditInputFormData,
      isLoading,
      sourceMappingData,
      isSubTableLoading,
      tblData,
      errorData,
      reportTypeData,
      yearData,
      usersData,
      openAlert,
      alertMsg,
      alertType,
      isButtonLoad,
      isSearchButtonLoad,
      errTblRecordsCount,
      isErrTblEmptyReset,
      isErrTblPaginationDisabled,
      isErrTblPaginationReset,
      openLinksData,
    } = this.state;

    return (
      <Box pb={4}>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "25px 10px",
          }}
        >
          <PageHeader
            label="Exception Reporting"
            style={{
              fontSize: "22px",
            }}
          />
        </Box>
        <CardContainer>
          <Grid container p={2} flexDirection="row">
            <Grid container item columnSpacing={2}>
              <Grid item xs={2.6}>
                <InputLabel>Search by Error Type</InputLabel>
                <TextInputField
                  placeholder="Search by Error Type"
                  name="searchText"
                  id="SearchText"
                  value={searchText}
                  onChange={this.handleSearchChange}
                />
              </Grid>
              <Grid item xs={1.3} className="WhiteIcon">
                <InputLabel> Sources</InputLabel>
                <MultiStepSelect
                  className="ExceptionNatcPopover"
                  id="sources"
                  name="sources"
                  size="small"
                  value={this.state.selectedSourceValue}
                  values={sourceMappingData.us}
                  multiStepValue="NATC"
                  multiStepOptionValues={sourceMappingData.natc}
                  onChange={this.handleSourceDropDownChange}
                />
              </Grid>
              <Grid item xs={1.6}>
                <InputLabel> Report Type</InputLabel>
                <SingleSelect
                  id="reportVal"
                  value={reportVal}
                  values={reportTypeData}
                  onChange={this.handleSelectChange}
                />
              </Grid>

              <Grid item xs={2.3}>
                <InputLabel> Assigned To</InputLabel>
                <SingleSelect
                  id="selectedUser"
                  value={selectedUser}
                  values={usersData}
                  onChange={this.handleSelectChange}
                />
              </Grid>

              <Grid item xs={1}>
                <InputLabel>Year</InputLabel>
                <SingleSelect
                  id="yearValue"
                  name="yearValue"
                  value={yearValue}
                  values={yearData}
                  onChange={this.handleSelectChange}
                />
              </Grid>
              <Grid item xs={1.5}>
                <InputLabel> Filter by Status</InputLabel>
                <SingleSelect
                  id="selectedFilterStatus"
                  AllValue={true}
                  value={selectedFilterStatus}
                  values={this.selectStatusData}
                  onChange={this.handleSelectChange}
                />
              </Grid>
              <Grid item xs={0.8} mt={3} ml={0.8}>
                <GradientButton
                  isButtonLoad={isSearchButtonLoad}
                  disabled={this.isEmptyCheck()}
                  label="Search"
                  onClick={this.handleSearchClick}
                />
              </Grid>
              <Grid item xs={0.8} mt={3}>
                <RedButton label="Reset" onClick={this.onResetClick} />
              </Grid>
            </Grid>
          </Grid>
          <hr
            style={{
              border: "0.5px solid #D8D8D8",
            }}
          />

          <ExceptionDataLoader
            openLinksData={openLinksData}
            errTblRecordsCount={errTblRecordsCount}
            isErrTblEmptyReset={isErrTblEmptyReset}
            isErrTblPaginationDisabled={isErrTblPaginationDisabled}
            onErrTblChangePage={this.onErrTblChangePage}
            onErrTblChangeRow={this.onErrTblChangeRow}
            handleSave={this.handleSave}
            isButtonLoad={isButtonLoad}
            isSaveDisabled={this.isSaveDisabled()}
            onResetClick={this.onResetClick}
            handleBulkInputChange={this.handleBulkInputChange}
            usersData={usersData}
            isSubTableLoading={isSubTableLoading}
            getErrorType={this.getErrorType}
            onClickCheckBox={this.onClickCheckBox}
            onChangePage={this.onChangePage}
            onChangeRow={this.onChangeRow}
            tableData={tblData}
            collapseDataAll={errorData}
            totalRecordsCount={totalRecordsCount}
            isEmptyReset={isEmptyReset}
            isPaginationDisabled={isPaginationDisabled}
            isPaginationReset={isErrTblPaginationReset}
            exceptionModes={exceptionModes}
            handleMode={this.handleToolbarButtons}
            handleApply={this.handleApply}
            bulkEditInputFormData={bulkEditInputFormData}
            isLoading={isLoading}
          />
        </CardContainer>
      </Box>
    );
  }
}
export default withRouter(ExceptionReporting);
