import React from "react";

import { Card, CardContent } from "@mui/material";
import { TabModel, TabType } from "./TabModel";
import BackgroundImg from "../../Static/Background_Image_Search.png";

interface Props {
  children?: React.ReactNode;
  index: number;
  value: TabType;
  tabs: TabModel[];
  style?: React.CSSProperties;
  element?: React.ReactNode;
}
interface State {}

class TabPanel extends React.Component<Props, State> {
  render() {
    const { children, index, value, tabs, ...other } = this.props;
    const isCurrentSelected =
      tabs.findIndex((tab) => tab.type === value) === index;
    return (
      <div
        style={{
          marginBottom: "3rem",
        }}
        role="tabpanel"
        hidden={!isCurrentSelected}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {isCurrentSelected && (
          <Card
            style={{
              background:
                "transparent url(" + BackgroundImg + ") 0% 0% padding-box",
              opacity: 1,
              backgroundSize: "cover",
              backgroundPosition: "right",
              border: "none",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <CardContent style={{ padding: "0" }}>{children}</CardContent>
          </Card>
        )}
      </div>
    );
  }
}

export default TabPanel;
