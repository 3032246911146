import { URIs } from "../Common/Constants";
import { AuditHistoryModel } from "../Models/AuditHistoryModel";

import GenericAPIService from "./GenericAPIService";

const { AUDIT_HISTORY } = URIs;
class AuditHistoryService extends GenericAPIService {
  async getAuditHistory(body: AuditHistoryModel) {
    const { data } = await this.postAsync<{ result: any; total_count: any }>(
      AUDIT_HISTORY,
      body
    );
    return data;
  }
}

export default AuditHistoryService;
