import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, CircularProgress, Grid, Link } from "@mui/material";
import { Component } from "react";
import KickoutMonitoringTotalRecord from "../../../Components/Charts/KickoutMonitoringTotalRecord";
import ModalDialog from "../../../Components/Modal/ModelDialog";
import PageHeader from "../../../Components/Text/PageHeader";

import { CHART_COLORS } from "../../../Common/Constants";
import KickoutPerSourceChart from "../../../Components/Charts/KickoutPerSourceChart";
import DashboardService from "../../../Services/DashboardService";
import ExpandOpen from "../../../Static/ExpandOpen.png";
import KickoutClinicalNonClinical from "./KickoutClinicalNonClinical/KickoutClinicalNonClinical";
import KickoutPerCountry from "./KickoutPerCountry/KickoutPerCountry";
import KickoutPerSource from "./KickoutPerSource/KickoutPerSource";
import { isEqual } from "lodash";
import { DefaultChartProps } from "../../../Models/DashboardModel";
import NoRecords from "../../../Components/NoRecords/NoRecords";

const DashboardServices = new DashboardService();

interface Props extends DefaultChartProps {}

interface State {
  showKickoutMonitoringDateDialog: boolean;
  kickoutSeriesData: Highcharts.PointOptionsObject[];
  xAxisCategories: string[];
  isLoading: boolean;
}

class KickoutMonitoringSources extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      showKickoutMonitoringDateDialog: false,
      kickoutSeriesData: [],
      xAxisCategories: [],
      isLoading: false,
    };
  }
  async componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      !isEqual(prevProps.defaultSources, this.props.defaultSources) ||
      // !isEqual(prevProps.defaultStartDate, this.props.defaultStartDate) || //commented for result error
      !isEqual(prevProps.defaultEndDate, this.props.defaultEndDate)
    ) {
      this.getSourceData();
    }
  }

  getSourceData = async () => {
    try {
      let sourceVal = this.props.defaultSources
        .map((el) => el.value)
        .map((item) => "''" + item + "''")
        .join();
      let parListSourceVal = this.props.defaultSources
        .map((el) => el.value)
        .join(",");
      this.setState({ isLoading: true });
      const payloadKickOutSource: any = {
        par_group_source: 1,
        par_list: parListSourceVal,
        par_start_date: this.props.defaultStartDate,
        par_end_date: this.props.defaultEndDate,
        par_whereclause: `srcsyscd in (${sourceVal}) and CAST(avarundt as DATE) BETWEEN ''${this.props.defaultStartDate}'' and ''${this.props.defaultEndDate}''`,
        par_whereclause1: null,
      };

      let responseSource = await DashboardServices.getkickoutSourceData(
        payloadKickOutSource
      );
      const sources = [
        ...new Set(this.props.defaultSources.map((elem) => elem.value)),
      ].sort();
      const sourceCountObj: { [index: string]: number } = {};
      responseSource.result.forEach((el) => {
        const { source, totalcount } = el;
        if (sources.includes(source)) {
          if (source in sourceCountObj) {
            sourceCountObj[source] =
              sourceCountObj[source] + Number(totalcount || 0);
          } else {
            sourceCountObj[source] = Number(totalcount || 0);
          }
        }
      });
      this.setState({
        kickoutSeriesData: sources.map((el, ind) => {
          return {
            y: sourceCountObj[el] || 0,
            color: CHART_COLORS[ind],
          };
        }),
        xAxisCategories: sources,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const {
      showKickoutMonitoringDateDialog,
      kickoutSeriesData,
      xAxisCategories,
      isLoading,
    } = this.state;
    const {
      defaultSources,
      defaultStartDate,
      defaultEndDate,
      sourceDropDownValues,
      countryDropDownValues,
      defaultCountries,
    } = this.props;
    return (
      <>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <PageHeader
            label="Kickout Monitoring - Sources"
            style={{
              fontSize: "16px",
              lineHeight: "1",
            }}
          />
          <Box style={{ display: "flex", gap: "1em", cursor: "pointer" }}>
            <img
              alt="ExpandOpen"
              src={ExpandOpen}
              width={"14px"}
              height={"14px"}
              style={{
                cursor: "pointer",
                zIndex: "9999",
              }}
              onClick={() =>
                this.setState({ showKickoutMonitoringDateDialog: true })
              }
            />
          </Box>
        </Box>
        <Grid container mt={2}>
          {isLoading ? (
            <Grid
              item
              style={{ height: "30em", width: "50em" }}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress
                disableShrink
                sx={{
                  color: "#d52b1e",
                  marginTop: 22,
                }}
              />
            </Grid>
          ) : (
            <>
              {kickoutSeriesData[0]?.y !== 0 ? (
                <>
                  <Grid item xs={8}>
                    <KickoutPerSourceChart
                      chartDataSourceArr={kickoutSeriesData}
                      xAxisCategories={xAxisCategories}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <KickoutMonitoringTotalRecord
                      dataValue={kickoutSeriesData.reduce((prev, curr) => {
                        return prev + (curr?.y || 0);
                      }, 0)}
                    />
                  </Grid>
                </>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12em",
                    marginLeft: "20em",
                    marginBottom: "11em",
                  }}
                >
                  <NoRecords msg="No Data" />
                </Box>
              )}
            </>
          )}
        </Grid>
        <Link
          style={{
            color: "#0075A2",
            textDecoration: "none",
            fontWeight: 600,
            float: "right",
            cursor: "pointer",
          }}
          onClick={() => {
            this.setState({ showKickoutMonitoringDateDialog: true });
          }}
        >
          View Details{" "}
          <KeyboardArrowRightIcon
            style={{
              fontSize: "20px",

              verticalAlign: "middle",
            }}
          />
        </Link>
        <ModalDialog
          isOpen={showKickoutMonitoringDateDialog}
          blackTitleColor
          title="Kickout Monitoring - Sources"
          onClose={() =>
            this.setState({ showKickoutMonitoringDateDialog: false })
          }
          dialogWidth="xl"
          scrollBar={showKickoutMonitoringDateDialog}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                style={{
                  background: "#FCFCFC 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #15223214",
                  border: "1px solid #EAEAEA",
                  borderRadius: "10px",
                  marginBottom: "15px",
                }}
              >
                <KickoutPerSource
                  defaultSources={defaultSources}
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                  sourceDropDownValues={sourceDropDownValues}
                />
              </Box>
              <Box
                style={{
                  background: "#FCFCFC 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #15223214",
                  border: "1px solid #EAEAEA",
                  borderRadius: "10px",
                  marginBottom: "15px",
                }}
              >
                <KickoutPerCountry
                  showKickoutMonitoringDateDialog={
                    showKickoutMonitoringDateDialog
                  }
                  defaultCountries={defaultCountries}
                  defaultStartDate={defaultStartDate}
                  defaultEndDate={defaultEndDate}
                  countryDropDownValues={countryDropDownValues}
                />
              </Box>

              <Box
                style={{
                  background: "#FCFCFC 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #15223214",
                  border: "1px solid #EAEAEA",
                  borderRadius: "10px",
                  marginBottom: "15px",
                }}
              >
                {" "}
                <KickoutClinicalNonClinical
                  showKickoutMonitoringDateDialog={
                    showKickoutMonitoringDateDialog
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </ModalDialog>
      </>
    );
  }
}
export default KickoutMonitoringSources;
