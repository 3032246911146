import { Box, Typography } from '@mui/material';
import React from 'react';

import { CustomEventType } from '../../Common/Enums';
import CustomEventHandlerService from '../../Services/CustomEventHandlerService';

interface Props { }
interface State { }
class AccessDenied extends React.Component<Props, State> {
    componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    }

    render() {
        return (
            <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20%',marginBottom:'20%'}}>
                <Typography style={{ fontSize: 20, fontWeight: 600, padding: 20 }}>
                    User is not authorized to access the PPS application, Please contact application owner.
                </Typography>
            </Box>
        );
    }
}

export default AccessDenied;