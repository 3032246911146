import {
  Box,
  Checkbox,
  CircularProgress,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  styled,
  Link,
  Typography,
  Tooltip,
} from "@mui/material";
import { isEqual } from "lodash";
import { Component } from "react";

import { RouteComponentProps, withRouter } from "react-router-dom";
import { v4 as uuid } from "uuid";

import IconButton, { IconType } from "../Button/IconButton";
import NoRecords from "../NoRecords/NoRecords";
import Utils from "../../Common/Utils";

interface Props extends RouteComponentProps<any, any, any> {
  data: any;
  isLoading: boolean;
  switchOn: boolean;
  actionArray: IconType[];
  onActionClick: (action: IconType, record: any) => void;
  onClickCheckBox: (
    event: React.ChangeEvent<HTMLInputElement>,
    singleRow: any
  ) => void;
}

interface State {
  data: any;
  selectSingle: boolean;
  selectAll: boolean;
}

const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

class MyLinksTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      selectSingle: false,
      selectAll: false,
    };
  }
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data });
    }
  };

  componentDidMount = () => {
    this.setState({ data: this.props.data });
  };

  render() {
    const { data } = this.state;
    return (
      <Box>
        {this.props.isLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            {data.length > 0 ? (
              <TableContainer component={Paper} sx={{ maxHeight: "30rem" }}>
                <MaterialTable
                  stickyHeader
                  sx={{ minWidth: "fit-content" }}
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid red",
                          whiteSpace: "nowrap",
                          fontWeight: "600",
                          width: "5rem",
                        }}
                      >
                        Action
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid red",
                          whiteSpace: "nowrap",
                          fontWeight: "600",
                          width: "13rem",
                        }}
                      >
                        Names
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid red",
                          whiteSpace: "nowrap",
                          fontWeight: "600",
                          width: "50rem",
                        }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid red",
                          whiteSpace: "nowrap",
                          fontWeight: "600",
                        }}
                      >
                        {this.props.switchOn ? "Display" : ""}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0
                      ? data.map((record: any, ind: number) => {
                          return (
                            <StyledTableRow key={ind}>
                              <TableCell
                                key={uuid()}
                                style={{
                                  fontSize: "16px",
                                  wordWrap: "break-word",
                                  hyphens: "auto",
                                  wordBreak: "break-all",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: 8,
                                  }}
                                >
                                  {this.props.actionArray?.map(
                                    (action, ind) => (
                                      <IconButton
                                        style={{
                                          marginRight: "5px",
                                        }}
                                        key={ind}
                                        IconType={action}
                                        onClick={() => {
                                          if (this.props.onActionClick) {
                                            this.props.onActionClick(
                                              action,
                                              record
                                            );
                                          }
                                        }}
                                      />
                                    )
                                  )}
                                </Box>
                              </TableCell>

                              <TableCell
                                key={uuid()}
                                style={{
                                  fontSize: "16px",
                                  wordWrap: "break-word",
                                  hyphens: "auto",
                                  wordBreak: "break-all",
                                }}
                              >
                                <Link
                                  style={{
                                    color: "#000000DE",
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    marginTop: "10px",
                                    display: "block",
                                  }}
                                  onClick={() => Utils.openUrl(record.url)}
                                >
                                  <Tooltip
                                    arrow
                                    title={record.name}
                                    disableHoverListener={
                                      record.name &&
                                      record.name.toString().length < 15
                                    }
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          bgcolor: "#F5F5F5",
                                          padding: "5px",
                                          fontSize: "13px",
                                          boxShadow: "0px 3px 6px #00000029",
                                          color: "#000000",
                                          "& .MuiTooltip-arrow": {
                                            color: "#F5F5F5",
                                          },
                                        },
                                      },
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: "15px",
                                        maxWidth: "182px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {record.icon} {record.name}
                                    </Typography>
                                  </Tooltip>
                                </Link>
                              </TableCell>
                              <TableCell
                                key={uuid()}
                                style={{
                                  fontSize: "16px",
                                  wordWrap: "break-word",
                                  hyphens: "auto",
                                  wordBreak: "break-all",
                                }}
                              >
                                <Typography>{record.description}</Typography>
                              </TableCell>
                              <TableCell>
                                {this.props.switchOn ? (
                                  <Checkbox
                                    disabled={record.isDisabled}
                                    checked={record.display}
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#3DA470",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        borderRadius: "4px",
                                      },
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                    size="small"
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      this.props.onClickCheckBox(event, record)
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </StyledTableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </MaterialTable>
              </TableContainer>
            ) : (
              <Box style={{ marginTop: "10vh", marginBottom: "10vh" }}>
                <NoRecords />
              </Box>
            )}
          </>
        )}
      </Box>
    );
  }
}

export default withRouter(MyLinksTable);
