import { URIs } from "../Common/Constants";

import {
  createMyViewModel,
  deleteMyViewModel,
} from "../Models/CustomizedViewModel";
import GenericAPIService from "./GenericAPIService";

const {
  CREATE_MYVIEW_DATA,
  UPDATE_MYVIEW_DATA,
  DELETE_MYVIEW_DATA,
  GET_MYVIEW_DATA,
  COUNTRY_SOURCE_DATA,
} = URIs;
class CustomizedViewService extends GenericAPIService {
  async createMyViewData(body: createMyViewModel) {
    try {
      const { data } = await this.postAsync<{ message: any }>(
        CREATE_MYVIEW_DATA,
        body
      );

      return data;
    } catch (error) {
      console.log("error in create data", error);
    }
  }
  async updateMyViewData(body: any) {
    try {
      const { data } = await this.postAsync<{ message: any }>(
        UPDATE_MYVIEW_DATA,
        body
      );

      return data;
    } catch (error) {
      console.log("error in update", error);
    }
  }
  async deleteMyViewData(body: deleteMyViewModel) {
    try {
      const { data } = await this.postAsync<{ message: any }>(
        DELETE_MYVIEW_DATA,
        body
      );

      return data;
    } catch (error) {
      console.log("error in delete", error);
    }
  }
  async getallMyViewData() {
    try {
      const { data } = await this.getAsync<{ data: { data: any } }>(
        GET_MYVIEW_DATA
      );

      return data;
    } catch (error) {
      console.log("error in fetching data", error);
    }
  }
  async getAllCountryAndSourceData() {
    try {
      const data = await this.getAsync<{ result: any }>(COUNTRY_SOURCE_DATA);
      return data;
    } catch (error) {
      console.log("error in fetching data", error);
    }
  }
}

export default CustomizedViewService;
