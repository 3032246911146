import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { isEqual, isNull } from "lodash";
import { Component, ReactNode } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Utils from "../../Common/Utils";
import NoRecords from "../NoRecords/NoRecords";
import PaginationNew from "../Pagination";

interface Props extends RouteComponentProps<any, any, any> {
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  totalRecordsCount: number | undefined;
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  isLoading?: boolean;
  data: any;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
  notificationData: any;
}

type State = {
  data: any;
  notificationData: any;
};

class AssignmentTable extends Component<Props, State> {
  state = {
    data: [],
    notificationData: [],
  };
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        data: this.props.data,
        notificationData: this.props.notificationData,
      });
    }
  };
  componentDidMount = () => {
    this.setState({
      data: this.props.data,
      notificationData: this.props.notificationData,
    });
  };
  render() {
    const { headCellData } = this.props;
    const { data, notificationData } = this.state;
    return (
      <Box pr={"16px"} pl={"16px"}>
        {this.props.isLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            {headCellData.length > 0 ? (
              <TableContainer
                component={Paper}
                style={{
                  boxShadow: "none",
                }}
                sx={{
                  maxHeight: "40rem",
                  overflow: "auto",
                }}
              >
                <MaterialTable
                  stickyHeader
                  sx={{ minWidth: "fit-content" }}
                  aria-label="sticky table"
                  style={{
                    width: "140rem",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {data.length > 0
                          ? data.map((record: any, index: number) => {
                              return (
                                <Card
                                  key={`${index}-row-data`}
                                  style={{
                                    marginBottom: "10px",
                                    background: "#CCD5DD",
                                    boxShadow: "0px 3px 6px #00000029",
                                    borderRadius: "6px",
                                    opacity: 1,
                                  }}
                                >
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="row"
                                      columns={12}
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      {headCellData.map((el, cellIndex) => {
                                        return (
                                          <Grid
                                            item
                                            xs={
                                              el.key === "id"
                                                ? 0.5
                                                : el.key === "status"
                                                ? 0.8
                                                : 1.2
                                            }
                                            key={`${cellIndex}-cell-data`}
                                          >
                                            <Typography
                                              style={{
                                                fontWeight: 600,
                                                fontSize: "15px",
                                              }}
                                            >
                                              {Utils.camelToTitle(el.key)}
                                            </Typography>

                                            <Grid
                                              item
                                              style={{
                                                paddingTop: "5px",
                                              }}
                                            >
                                              {el.key === "status" &&
                                              record[el?.key].toLowerCase() ===
                                                "on hold" ? (
                                                <Grid
                                                  container
                                                  style={{
                                                    background:
                                                      "#9d24f530 0% 0% no-repeat padding-box",
                                                    border: "1px solid #9D24F5",
                                                    borderRadius: "17px",
                                                    opacity: 1,
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width={"inherit"}
                                                  >
                                                    <Typography>
                                                      On Hold
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : el.key === "status" &&
                                                record[
                                                  el?.key
                                                ].toLowerCase() === "closed" ? (
                                                <Grid
                                                  container
                                                  style={{
                                                    background:
                                                      "#86868636 0% 0% no-repeat padding-box",
                                                    border:
                                                      " 1px solid #868686",
                                                    borderRadius: "17px",
                                                    opacity: 1,
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width={"inherit"}
                                                  >
                                                    <Typography>
                                                      Closed
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : el.key === "status" &&
                                                record[
                                                  el?.key
                                                ].toLowerCase() ===
                                                  "rejected" ? (
                                                <Grid
                                                  container
                                                  style={{
                                                    background:
                                                      "#FFEDEB 0% 0% no-repeat padding-box",
                                                    border:
                                                      " 1px solid #FA0000",
                                                    opacity: 1,
                                                    borderRadius: "17px",
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width={"inherit"}
                                                  >
                                                    <Typography>
                                                      Rejected
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : el.key === "status" &&
                                                record[
                                                  el?.key
                                                ].toLowerCase() ===
                                                  "in progress" ? (
                                                <Grid
                                                  container
                                                  style={{
                                                    background:
                                                      "#FEF5E7 0% 0% no-repeat padding-box",
                                                    border: "1px solid #F69A19",
                                                    borderRadius: "17px",
                                                    opacity: 1,
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width={"inherit"}
                                                  >
                                                    <Typography>
                                                      In Progress
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : el.key === "status" &&
                                                record[
                                                  el?.key
                                                ].toLowerCase() ===
                                                  "review requested" ? (
                                                <Grid
                                                  container
                                                  style={{
                                                    background:
                                                      "#08E7F0 0% 0% no-repeat padding-box",
                                                    border: "1px solid #3DA470",
                                                    borderRadius: "17px",
                                                    opacity: 1,
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width={"inherit"}
                                                  >
                                                    <Typography
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      Review Requested
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : el.key === "status" &&
                                                record[
                                                  el?.key
                                                ].toLowerCase() ===
                                                  "review completed" ? (
                                                <Grid
                                                  container
                                                  style={{
                                                    background:
                                                      "#50AF7F 0% 0% no-repeat padding-box",
                                                    border: "1px solid #3DA470",
                                                    borderRadius: "17px",
                                                    opacity: 1,
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width={"inherit"}
                                                  >
                                                    <Typography
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      Review Completed
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : el.key === "status" &&
                                                record[
                                                  el?.key
                                                ].toLowerCase() === "open" ? (
                                                <Grid
                                                  container
                                                  style={{
                                                    background:
                                                      "#E5FFF2 0% 0% no-repeat padding-box",
                                                    border: "1px solid #3DA470",
                                                    borderRadius: "17px",
                                                    opacity: 1,
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width={"inherit"}
                                                  >
                                                    <Typography
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      Open
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : el.key === "status" &&
                                                record[el?.key] ===
                                                  "review requested" ? (
                                                <Grid
                                                  container
                                                  style={{
                                                    background:
                                                      "#08E7F0 0% 0% no-repeat padding-box",
                                                    border: "1px solid #3DA470",
                                                    borderRadius: "17px",
                                                    opacity: 1,
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width={"inherit"}
                                                  >
                                                    <Typography
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      Review Requested
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : el.key === "status" &&
                                                record[el?.key] ===
                                                  "review completed" ? (
                                                <Grid
                                                  container
                                                  style={{
                                                    background:
                                                      "#50AF7F 0% 0% no-repeat padding-box",
                                                    border: "1px solid #3DA470",
                                                    borderRadius: "17px",
                                                    opacity: 1,
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width={"inherit"}
                                                  >
                                                    <Typography
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      Review Completed
                                                    </Typography>
                                                  </Grid>
                                                </Grid>
                                              ) : (
                                                <>
                                                  {!isNull(el?.linkTo) ? (
                                                    <Link
                                                      style={{
                                                        color: "#0075A2",
                                                        textDecoration: "none",
                                                        fontWeight: 600,
                                                      }}
                                                      to={{
                                                        pathname:
                                                          "/notifications",
                                                        state: {
                                                          record,
                                                          notificationData,
                                                        },
                                                      }}
                                                    >
                                                      {record[el?.key]}
                                                    </Link>
                                                  ) : (
                                                    <Tooltip
                                                      arrow
                                                      title={record[el?.key]}
                                                      disableHoverListener={
                                                        record[el?.key] &&
                                                        record[
                                                          el?.key
                                                        ].toString().length < 26
                                                      }
                                                      componentsProps={{
                                                        tooltip: {
                                                          sx: {
                                                            bgcolor: "#F5F5F5",
                                                            padding: "5px",
                                                            fontSize: "13px",
                                                            boxShadow:
                                                              "0px 3px 6px #00000029",
                                                            color: "#000000",
                                                            "& .MuiTooltip-arrow":
                                                              {
                                                                color:
                                                                  "#F5F5F5",
                                                              },
                                                          },
                                                        },
                                                      }}
                                                    >
                                                      <Typography
                                                        className="AssignedName"
                                                        style={{
                                                          fontSize: "15px",
                                                          maxWidth: "182px",
                                                          whiteSpace: "nowrap",
                                                          overflow: "hidden",
                                                          textOverflow:
                                                            "ellipsis",
                                                        }}
                                                      >
                                                        {record[el?.key]}
                                                      </Typography>
                                                    </Tooltip>
                                                  )}
                                                </>
                                              )}
                                              {el.elementToLoad}{" "}
                                            </Grid>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </CardContent>
                                </Card>
                              );
                            })
                          : null}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </MaterialTable>
              </TableContainer>
            ) : (
              <Box style={{ margin: "20vh" }}>
                <NoRecords />
              </Box>
            )}
          </>
        )}

        <PaginationNew
          isEmptyReset={this.props.isEmptyReset}
          isPaginationReset={this.props.isPaginationReset}
          isDisabled={this.props.isPaginationDisabled}
          onChangePage={this.props.onChangePage}
          onChangeRow={this.props.onChangeRow}
          totalRecordsCount={this.props.totalRecordsCount || 0}
        />
      </Box>
    );
  }
}
export default withRouter(AssignmentTable);
