import {
  faCaretDown,
  faCaretUp,
  faUpDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  CircularProgress,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  styled,
} from "@mui/material";
import { isEqual, isNull, isUndefined } from "lodash";
import { Component, ReactNode } from "react";

import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { v4 as uuid } from "uuid";
import Utils from "../../Common/Utils";

import NoRecords from "../NoRecords/NoRecords";
import PaginationNew from "../Pagination";

type SortType = {
  key: string;
  direction: string;
};

type paginationTyp = "regular" | "cursor";
const rowOptions = [5, 10, 15, 20, 25, 50];

interface Props extends RouteComponentProps<any, any, any> {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  sortConfig: SortType | null;
  data: any;
  totalRecordsCount: number;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
  paginationRequired?: boolean;
  sortingArrow?: boolean;
  isEmptyReset?: boolean;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
}

interface State {
  sortConfig: SortType | null;
  data: any;
  selectedData: any;
  selectAll: boolean;
  rowsPerPage: number;
  page: number;
  caretHover: boolean;
}

const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "td:nth-of-type(1) div": {
    color: "#0075A2!important",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTablePagination: any = styled(TablePagination)(
  ({ theme }: { theme: Theme }) => ({
    ".MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input":
      {
        background: "#ffffff",
        color: "#000000",
        borderRadius: "4px",
        minWidth: "20px",
        paddingRight: "27px",
        border: "1px solid #9E9E9E",
      },
    ".MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiTablePagination-selectIcon.MuiSelect-iconStandard":
      {
        color: "#D52B1E",
      },
    ".MuiTablePagination-selectLabel.MuiTablePagination-selectLabel": {
      marginRight: "5px",
    },
    ".Mui-disabled": {
      color: "#9e9e9e",
    },
  })
);
class SearchTable extends Component<Props, State> {
  static defaultProps = {
    paginationRequired: true,
    paginationType: "regular" as paginationTyp,
    showLastButton: true,
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      sortConfig: null,
      data: [],
      selectAll: false,
      selectedData: [],
      rowsPerPage: 5,
      page: 0,
      caretHover: false,
    };
  }
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data, sortConfig: null });
    }
    if (!isEqual(prevProps.isPaginationReset, this.props.isPaginationReset)) {
      this.setState({ page: 0, rowsPerPage: 5 });
    }
  };

  componentDidMount = () => {
    this.setState({ data: this.props.data });
  };

  requestSort = (key: string) => {
    const { sortConfig } = this.state;
    let direction = "ascending";
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending";
      }
    }
    this.setState({ sortConfig: { key, direction } }, () => {
      this.sorter();
    });
  };
  customComparer = (a: any, b: any, direction: string) => {
    // Handle null values by placing them at the end (ascending) or beginning (descending)
    if (a === null) {
      return b === null ? 0 : direction === "ascending" ? 1 : -1;
    }
    if (b === null) {
      return direction === "ascending" ? -1 : 1;
    }

    // Convert values to strings for consistent comparison
    const aValue = String(a);
    const bValue = String(b);

    // Compare strings based on sort direction
    if (direction === "ascending") {
      return aValue.localeCompare(bValue);
    } else {
      return bValue.localeCompare(aValue);
    }
  };
  sorter = () => {
    const { sortConfig, data } = this.state;
    let sortedData = data;
    if (sortConfig !== null) {
      sortedData = sortedData.sort(
        (a: { [x: string]: number }, b: { [x: string]: number }) => {
          if (sortConfig.key === "protocolTitle") {
            return this.customComparer(
              a[sortConfig.key],
              b[sortConfig.key],
              sortConfig.direction
            );
          } else if (sortConfig.key === "trialNumber") {
            if (Number(a[sortConfig.key]) < Number(b[sortConfig.key])) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (Number(a[sortConfig.key]) > Number(b[sortConfig.key])) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else if (
            sortConfig.key === "trialAlias" &&
            typeof Number(a[sortConfig.key]) !== "string" &&
            typeof Number(b[sortConfig.key]) !== "string"
          ) {
            if (Number(a[sortConfig.key]) < Number(b[sortConfig.key])) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (Number(a[sortConfig.key]) > Number(b[sortConfig.key])) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else if (sortConfig.key === "trialAlias") {
            const partsA = a[sortConfig.key].toString().split("-");
            const partsB = b[sortConfig.key].toString().split("-");

            // Compare the alphanumeric parts
            const alphanumericComparison = partsA[0].localeCompare(partsB[0]);

            if (alphanumericComparison !== 0) {
              return alphanumericComparison; // Sort by alphanumeric part
            } else {
              // If the alphanumeric parts are equal, compare the remaining parts
              return partsA
                .slice(1)
                .join("-")
                .localeCompare(partsB.slice(1).join("-"));
            }
          } else {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
          return 0;
        }
      );
    }

    this.setState({ data: sortedData });
    return sortedData;
  };

  handleChangePage = (_event: any, page: any) => {
    this.setState({ page: page });
    this.props.onChangePage(page);
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
    this.props.onChangeRow(event.target.value);
  };

  defaultSortIconColor = "#959595";
  activeSortIconColor = "#000000";

  faCaretUpColor = this.activeSortIconColor;
  faCaretDownColor = this.activeSortIconColor;
  faUpDownColor = this.defaultSortIconColor;

  positionArrow = (key: string) => {
    const { sortConfig } = this.state;
    if (sortConfig) {
      if (sortConfig.direction === "ascending" && sortConfig.key === key)
        return (
          <FontAwesomeIcon
            icon={faCaretUp}
            color={this.faCaretUpColor}
            size="xs"
          />
        );
      if (sortConfig.direction === "descending" && sortConfig.key === key)
        return (
          <FontAwesomeIcon
            icon={faCaretDown}
            color={this.faCaretDownColor}
            size="xs"
          />
        );
    }
    return (
      <FontAwesomeIcon
        icon={faUpDown}
        color={this.defaultSortIconColor}
        size="sm"
      />
    );
  };

  onClickButton = (action: "view" | "cancel" | "delete" | "back") => {
    switch (action) {
      case "view":
        this.props.history.push("");
        this.props.history.go(0);
        break;
      default:
        break;
    }
  };

  render() {
    const { headCellData, totalRecordsCount, fieldsToColor } = this.props;
    const { data } = this.state;

    return (
      <Box>
        {this.props.isLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "20vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            {headCellData.length > 0 ? (
              <TableContainer component={Paper} sx={{ maxHeight: "40rem" }}>
                <MaterialTable
                  stickyHeader
                  sx={{ minWidth: "fit-content" }}
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      {headCellData.map((el, index) => {
                        if (
                          el &&
                          el.key !== "id" &&
                          el.key !== "checked" &&
                          el.key !== "color" &&
                          el.key !== "Statecol"
                        ) {
                          return (
                            <TableCell
                              key={`${index}-head`}
                              style={
                                el?.key !== "Action"
                                  ? {
                                      fontSize: "16px",
                                      borderBottom: "2px solid red",
                                      whiteSpace: "nowrap",
                                      minWidth:
                                        el.key === "rpt_spnd_amt"
                                          ? "1em"
                                          : "10em",
                                      fontWeight: "600",
                                    }
                                  : {
                                      fontSize: "16px",
                                      borderBottom: "2px solid red",
                                      whiteSpace: "nowrap",
                                      fontWeight: "600",
                                    }
                              }
                              onClick={
                                this.props.sortingArrow
                                  ? () => this.requestSort(el.key)
                                  : () => {}
                              }
                            >
                              {Utils.camelToTitle(el.label)}{" "}
                              {this.props.sortingArrow
                                ? this.positionArrow(el.key)
                                : ""}
                            </TableCell>
                          );
                        }
                        return null;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(data ?? false) && data.length > 0
                      ? data.map((record: any, ind: number) => {
                          return (
                            <StyledTableRow key={ind}>
                              {headCellData.map((el) => {
                                if (
                                  el?.key !== "id" &&
                                  el.key !== "checked" &&
                                  el.key !== "color" &&
                                  el.key !== "Statecol"
                                ) {
                                  const clrObj = fieldsToColor?.find(
                                    (clr) => clr.key === el?.key
                                  );
                                  return (
                                    <TableCell
                                      key={uuid()}
                                      style={{
                                        fontSize: "16px",
                                        wordWrap: "break-word",
                                        hyphens: "auto",
                                        wordBreak: "break-all",
                                        backgroundColor:
                                          el.key === "rpt_spnd_amt"
                                            ? record.color
                                            : "",
                                        textAlign:
                                          el.key === "rpt_spnd_amt"
                                            ? "center"
                                            : "left",
                                        padding:
                                          el.key === "rpt_spnd_amt"
                                            ? "8px"
                                            : "16px",
                                      }}
                                    >
                                      {!isNull(el?.linkTo) ? (
                                        <Link
                                          onClick={() => {}}
                                          to={{ pathname: el.linkTo }}
                                        >
                                          {record[el?.key]}
                                        </Link>
                                      ) : (
                                        <>
                                          <Box
                                            style={{
                                              color: clrObj
                                                ? clrObj.values[record[el?.key]]
                                                : "black",
                                            }}
                                          >
                                            {`${
                                              isUndefined(record[el?.key])
                                                ? ""
                                                : record[el?.key]
                                            }`}
                                          </Box>
                                          {el.elementToLoad}{" "}
                                        </>
                                      )}
                                    </TableCell>
                                  );
                                }
                                return null;
                              })}
                            </StyledTableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </MaterialTable>
              </TableContainer>
            ) : (
              <Box style={{ margin: "10vh" }}>
                <NoRecords />
              </Box>
            )}
          </>
        )}
        {this.props.sortingArrow ? (
          <StyledTablePagination
            component="div"
            count={totalRecordsCount}
            showFirstButton
            page={this.state.page}
            labelRowsPerPage={`${this.state.data.length} of ${totalRecordsCount} records per page`}
            onPageChange={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            rowsPerPageOptions={this.getRowsPerPageOptions()}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        ) : (
          <PaginationNew
            isEmptyReset={this.props.isEmptyReset}
            isPaginationReset={this.props.isPaginationReset}
            isDisabled={this.props.isPaginationDisabled}
            onChangePage={this.props.onChangePage}
            onChangeRow={this.props.onChangeRow}
            totalRecordsCount={totalRecordsCount || 0}
          />
        )}
      </Box>
    );
  }

  getRowsPerPageOptions = () => {
    const maxAllowed = this.props.totalRecordsCount / this.state.page;
    return rowOptions.filter((el) => el < maxAllowed);
  };
}

export default withRouter(SearchTable);
