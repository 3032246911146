import React from 'react';
import AuthProviderService from '../../Services/AuthProviderService';
import BackdropLoaderCircular from '../../Components/Loader/BackdropLoaderCircular';

interface Props { }
interface State { }
class AuthLogin extends React.Component<Props, State> {
    async componentDidMount() {
        AuthProviderService.setCallbackPath(window.location.pathname);
        const isLoggedIn = await AuthProviderService.isLoggedIn();

        if (!isLoggedIn) {
            AuthProviderService.login();
        }
    }

    render() {
        return <BackdropLoaderCircular show={true} style={{ backgroundColor: '#FFFFFF' }} />;
    }
}

export default AuthLogin;