import { faCircleInfo as InfoIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  Link,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BreadCrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { IBreadCrumb } from "../../../Components/BreadCrumb/IBreadCrumb";
import RedButton from "../../../Components/Button/RedButton";
import CardContainer from "../../../Components/Card/CardContainer";
import FileUpload from "../../../Components/File/FileUpload";
import FileUploadDetailCard from "../../../Components/File/FileUploadDetailCard";
import FileUploadDetailModel from "../../../Components/File/FileUploadDetailModel";
import MultiStepSelect from "../../../Components/Select/MultiStepSelect";
import SelectModel from "../../../Components/Select/SelectModel";
import TransactionDataLoader from "../../../Components/Table/TransactionDataLoader";
import FilesInfo from "./FilesInfo";
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[10],
    fontSize: 13,
    maxWidth: "none",
    ZIndex: 99999,
    "& .MuiTooltip-arrow": {
      color: "#FFFFFF",
    },
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
    fontSize: 14,
  },
}));
interface Props extends RouteComponentProps<any, any, any> {
  isDisabled: boolean;
  onDelete: (file: FileUploadDetailModel) => void;
  files: FileUploadDetailModel[];
  onChange: (file: FileUploadDetailModel) => void;

  isTableLoading: boolean;
  tblData: any[];
  totalRecordsCount: number | undefined;
  selectedSourceValue: string;
  sourceMappingData: any;
  sourceWithFieldsData: any;
  searchFields: any;
  isPageLoading: boolean;
  onChangePage: (page: number) => void;
  onChangeRow: (row: number) => void;
  handleExportTemplate: () => void;
  handleUploadExcelDownload: () => void;
  handleSelectSourceChange: (selected: SelectModel) => void;
  onSubmitClick: () => void;
  isLoading: boolean;
  isRefresh: boolean;
  isEnabled: boolean;
  isPaginationReset: boolean;
  filesInfo: any;
}

interface State {}

class TransactionsFileUpload extends Component<Props, State> {
  breadcrumbs: IBreadCrumb[] = [
    { text: "Back", link: "/missingtransactions" },
    { text: "Upload Files" },
  ];

  render() {
    const {
      files,
      onChange,
      isTableLoading,
      totalRecordsCount,
      selectedSourceValue,
      isPageLoading,
      sourceMappingData,
      isLoading,
      isDisabled,
      onSubmitClick,
      onDelete,
      isEnabled,
      filesInfo,
    } = this.props;
    return (
      <>
        {isPageLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            <Grid
              container
              mt={"3em"}
              flexDirection="row"
              className="TransactionsUpload"
            >
              <Grid container item xs={7.8}>
                <Grid item className="UploadSearchInput">
                  <BreadCrumb breadcrumbs={this.breadcrumbs} />
                </Grid>
              </Grid>
              <Grid container item xs={4.2} mb={3} className="WhiteIcon">
                <Grid item xs={3.5}>
                  <InputLabel
                    sx={{
                      visibility: selectedSourceValue ? "visible" : "hidden",
                    }}
                  >
                    Select Source
                  </InputLabel>
                  <MultiStepSelect
                    isDisabled={this.props.files.length > 0}
                    className="UploadScreenNatcPopover"
                    id="sources"
                    name="sources"
                    size="small"
                    customOptions="Select Source"
                    value={selectedSourceValue}
                    values={sourceMappingData.us}
                    multiStepValue="NATC"
                    multiStepOptionValues={sourceMappingData.natc}
                    onChange={this.props.handleSelectSourceChange}
                  />
                </Grid>
                <Grid item xs={4} mt={3} ml={2}>
                  <RedButton
                    disabled={selectedSourceValue ? false : true}
                    label="Export template"
                    onClick={this.props.handleExportTemplate}
                    startIcon={<ArrowDownwardIcon />}
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  mt={5}
                >
                  <FontAwesomeIcon
                    style={{
                      color: "#263F6A",
                      fontWeight: 300,
                      marginRight: 8,
                      marginTop: 3,
                    }}
                    icon={InfoIcon}
                  />
                  <LightTooltip
                    placement="bottom-end"
                    title={<FilesInfo values={filesInfo} />}
                    arrow
                  >
                    <Typography
                      color={"#000"}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: 650,
                      }}
                    >
                      Allowed Files Info
                    </Typography>
                  </LightTooltip>
                </Grid>
              </Grid>
            </Grid>
            <Box pb={3}>
              <FileUpload
                isSourceSelect={!isEnabled}
                isSupportLoad={true}
                onSubmitClick={onSubmitClick}
                isLoading={isLoading}
                isDisabled={isDisabled}
                onChange={onChange}
                sizeValidation="10"
              />
              {files ? (
                <Grid container direction="column">
                  <FileUploadDetailCard
                    isStatusRequire={false}
                    files={files}
                    onDelete={onDelete}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <CardContainer>
                {this.props.tblData.length > 0 ? (
                  <>
                    <Grid
                      container
                      columns={8}
                      p={2}
                      columnSpacing={3}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <Link
                        style={{
                          color: "#0577A3",
                          cursor: "pointer",
                          textDecoration: "none",
                          marginTop: "-10px",
                          display: "block",
                          fontWeight: "500",
                        }}
                        onClick={this.props.handleUploadExcelDownload}
                      >
                        <ArrowDownwardIcon
                          style={{
                            marginTop: "1px",
                            float: "left",
                            marginRight: "3px",
                          }}
                        />
                        Export as Excel
                      </Link>
                    </Grid>
                    <hr
                      style={{
                        border: "0.5px solid #D8D8D8",
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}

                <TransactionDataLoader
                  isEmptyReset={this.props.isPaginationReset}
                  isPaginationRequire={true}
                  isLoading={isTableLoading}
                  tableData={this.props.tblData}
                  totalRecordsCount={totalRecordsCount}
                  onChangeRow={this.props.onChangeRow}
                  onChangePage={this.props.onChangePage}
                />
              </CardContainer>
            </Box>
          </>
        )}
      </>
    );
  }
}
export default withRouter(TransactionsFileUpload);
