import axios from "axios";
import AuthService from "../Services/AuthProviderService";
import { URIs } from "../Common/Constants";
import Utils from "../Common/Utils";
const env = Utils.getEnvVars();
export const registerInterceptor = () => {
    axios.interceptors.request.use(async (config) => {
        if (config.baseURL === env.API_URL) {
            const { accessToken } = await AuthService.getAccessToken();
            const bearer = `Bearer ${accessToken}`;
            config.headers!.Authorization = bearer;
        }
        return config;
    })
}