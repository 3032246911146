import {
    faInfoCircle as infoIcon
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from '@mui/material';
import React, { Component } from 'react';

interface Props {
    msg?: string;
    style?: React.CSSProperties;
    msgType? : string;
 }

interface State { }

export default class NoRecords extends Component<Props, State> {
    state = {}
    iconSetter = () => {
        if(this.props.msgType === 'error'){
            return infoIcon
        }
        return infoIcon;
    }
    render() {
        return (
            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", ...this.props.style }}>
                <FontAwesomeIcon icon={this.iconSetter()} style={{ color: "#007c91" }} />
                <Box>{ this.props.msg?? 'No Records to Display'}</Box>
            </Box>
        )
    }
}