import { Box, Checkbox, Typography } from "@mui/material";
import { Component } from "react";
import DatePicker from "../DatePicker/DatePicker";
import RadioButtons from "../RadioButtons/RadioButtons";
import SingleSelect from "../Select/SingleSelect";
import TextInputField from "../TextInputField/TextInputField";
import { ValidationType } from "../../Common/Enums";

type Props = {
  type: "dropdown" | "text" | "radio" | "checkbox" | "number" | "date";
  label?: string;
  width?: string;
  values?: any;
  value?: any;
  name?: string;
  isDisabled?: boolean;
  isRationale?: boolean;
  radioDirection?: "row" | "column";
  onChange: (event: any, targetId?: any, name?: string) => void;
  size?: "small" | "medium";
  defaultValue?: string;
  isRequired?: boolean;
  noneValue?: boolean;
  maxDate?: any;
  customOptions?: string;
  backgroundColor?: string;
  color?: string;
  validation: boolean;
  onKeyDown?: (event: any) => void;
};

type State = {
  value: string;
};

export default class InputTypeSelector extends Component<Props, State> {
  public static defaultProps = {
    validationType: ValidationType.NONE,
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value ? props.value.toString() : "",
    };
  }

  render() {
    const {
      type,
      label,
      noneValue,
      isRequired,
      defaultValue,
      values,
      value,
      size,
      onChange,
      radioDirection,
      isDisabled,
      width,
      name,
      maxDate,
      customOptions,
      backgroundColor,
      color,
      isRationale,
      validation,
      onKeyDown,
    } = this.props;

    switch (type) {
      case "number":
        return (
          <>
            <TextInputField
              isReadonly={isDisabled}
              isDisabled={isDisabled}
              type="number"
              isRequired={isRequired}
              name={name}
              width={width}
              onChange={onChange}
              label={label}
              value={value}
            />
            <span
              style={{
                color: "#FF4A4A",
                fontStyle: "italic",
                fontFamily: "Roboto",
                fontSize: "14px",
                display: "block",
                height: "14px",
                visibility: validation ? "visible" : "hidden",
              }}
            >
              {validation
                ? isRequired
                  ? value
                    ? ""
                    : "*field required"
                  : ""
                : ""}
            </span>
          </>
        );

      case "text":
        if (isRationale) {
          return (
            <>
              <Box
                sx={{
                  border: "0.5px solid #9E9E9E",
                  borderRadius: "6px",
                  padding: "5px",
                  height: "2.8rem",
                  background: "#EFEFEF",
                  wordWrap: "break-word",
                  hyphens: "auto",
                  wordBreak: "break-all",
                  overflow: "auto",
                }}
                mt={1}
                mb={1}
              >
                <Typography
                  style={{
                    color: "#4B4B4B",
                    border: "none",
                    wordWrap: "break-word",
                    hyphens: "auto",
                    wordBreak: "break-all",
                  }}
                >
                  {value}
                </Typography>
              </Box>
              <span
                className="messageSpan"
                style={{
                  color: "#FF4A4A",
                  fontStyle: "italic",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  display: "block",
                  height: "14px",
                  visibility: validation ? "visible" : "hidden",
                }}
              >
                {validation
                  ? isRequired
                    ? value
                      ? ""
                      : "*field required"
                    : ""
                  : ""}
              </span>
            </>
          );
        } else {
          return (
            <>
              <TextInputField
                isReadonly={isDisabled}
                placeholder=""
                isDisabled={isDisabled}
                isRequired={isRequired}
                name={name}
                width={width}
                onChange={onChange}
                label={label}
                value={value}
                backgroundColor={backgroundColor}
                color={color}
              />
              <span
                style={{
                  color: "#FF4A4A",
                  fontStyle: "italic",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  display: "block",
                  height: "14px",
                  visibility: validation ? "visible" : "hidden",
                }}
              >
                {validation
                  ? isRequired
                    ? value
                      ? ""
                      : "*field required"
                    : ""
                  : ""}
              </span>
            </>
          );
        }

      case "date":
        return (
          <>
            <DatePicker
              onKeyDown={onKeyDown}
              isRequired={isRequired}
              name={name}
              maxDate={maxDate}
              width={width}
              onChange={onChange}
              value={value || null}
              disabled={isDisabled}
            />
            <span
              style={{
                color: "#FF4A4A",
                fontStyle: "italic",
                fontFamily: "Roboto",
                fontSize: "14px",
                display: "block",
                height: "14px",
                visibility: validation ? "visible" : "hidden",
              }}
            >
              {validation
                ? isRequired
                  ? value
                    ? ""
                    : "*field required"
                  : ""
                : ""}
            </span>
          </>
        );

      case "dropdown":
        return (
          <SingleSelect
            isRequired={isRequired}
            customOptions={customOptions}
            noneValue={noneValue}
            defaultValue={defaultValue}
            id={name}
            name={name}
            onChange={onChange}
            label={label}
            values={values}
            value={value}
          />
        );

      case "checkbox":
        return (
          <Checkbox
            readOnly={isDisabled}
            disabled={isDisabled}
            name={name}
            size={size || "small"}
            inputProps={{ "aria-label": "controlled" }}
            checked={value}
            onChange={onChange}
          />
        );

      case "radio":
        return (
          <RadioButtons
            name={name}
            value={value}
            buttonValues={values}
            direction={radioDirection}
            onChange={onChange}
          />
        );

      default:
        return null;
    }
  }
}
