import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  isOpen: boolean;
  title?: string;
  dialogAction?: JSX.Element;
  dialogWidth: DialogProps["maxWidth"];
  onClose: () => void;
  children?: React.ReactNode;
  scrollBar?: boolean;
  blackTitleColor?: boolean;
}
interface State {}

class ModalDialog extends React.Component<Props, State> {
  public static defaultProps = {
    isOpen: false,
    title: "Dialog Modal",
    dialogWidth: "lg",
  };

  render() {
    const {
      isOpen,
      onClose,
      children,
      dialogAction,
      dialogWidth,
      title,
      scrollBar,
      blackTitleColor,
    } = this.props;
    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth={dialogWidth}
        onClose={onClose}
        sx={{
          zIndex: 99999999,
          overflowX: "none",
          overflowY: scrollBar ? "hidden" : "none",
        }}
      >
        <Grid item container>
          <Grid item xs={9}>
            <DialogTitle color={blackTitleColor ? "#000000" : "#D52B1E"}>
              {title}{" "}
            </DialogTitle>
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="flex-end">
            <FontAwesomeIcon
              icon={faXmark}
              size="lg"
              onClick={onClose}
              style={{ marginTop: 22, marginRight: 13, cursor: "pointer" }}
            />
          </Grid>
        </Grid>
        <Divider
          orientation="horizontal"
          style={{ marginBottom: 10, marginLeft: 20, marginRight: 20 }}
        />
        <DialogContent>{children}</DialogContent>
        <DialogActions>{dialogAction}</DialogActions>
      </Dialog>
    );
  }
}

export default ModalDialog;
