import TextField from "@mui/material/TextField";
//import { Box } from "@mui/system";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker as DatePickerMUI } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { Component } from "react";

interface Props {
  label?: string;
  value?: string | null;
  placeHolder?: string;
  /**
   * @property pass Moment Object here
   */
  maxDate?: any;
  /**
   * @property pass Moment Object here
   */
  minDate?: any;
  disabled?: boolean;
  width?: string;
  isRequired?: boolean;
  name?: string;
  onChange: (
    value: string | null,
    keyboardInputValue?: string | undefined,
    name?: string
  ) => void;
  onKeyDown?: (event: any) => void;
}

interface State {}

class DatePicker extends Component<Props, State> {
  state = {};

  render() {
    const { value, label, maxDate, minDate, disabled, width, isRequired } =
      this.props;

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePickerMUI
          inputFormat={"YYYY-MM-DD"}
          OpenPickerButtonProps={{ style: { color: "#FFFFFF" } }}
          InputProps={{ style: { height: "2.5em", width: width || "auto" } }}
          label={label}
          value={value}
          disabled={disabled}
          onChange={this.handleDateChange}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                onKeyDown={this.props.onKeyDown}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "Select Date",
                  "aria-required": false,
                  required: isRequired,
                  "aria-autocomplete": "none",
                }}
                style={{
                  background: "#263F6A",
                  color: "white",
                  borderRadius: "5px",
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    );
  }

  handleDateChange = (
    value: moment.Moment | null,
    keyboardInputValue?: string | undefined
  ) => {
    // const selectedDate: Date | null = value ? new Date(value) : null;
    this.props.onChange(
      value ? value.format("YYYY-MM-DD HH:mm:ss") : null,
      keyboardInputValue,
      this.props.name
    );
  };
}

export default DatePicker;
