import { AuthProvider } from "@elilillyco/spa_auth";
import Utils from "../Common/Utils";
const env = Utils.getEnvVars();

const config = {
  clientId: env.clientId, // '1cd3562b-7d66-42d2-b44a-1b9b7c77e773' // Proquest
  scopes: env.scopes,
  openidPostLogoutRedirectUri: "/",
  cacheLocation: "localStorage",
  logLevel: "ERROR",
};
export default new AuthProvider(config);
