import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { Component } from "react";
import Utils from "../../Common/Utils";
import moment from "moment";
import { KickoutClinicalNonClinicalReponse } from "../../Models/DashboardModel";

type Props = {
  chartDataSourceArr: KickoutClinicalNonClinicalReponse[];
  endDate: string | null | undefined;
  startDate: string | null | undefined;
};
interface State {
  chartData: Highcharts.Options;
}
class KickoutClinicalNonClinicalChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      chartData: {},
    };
  }
  generateCategories = (dates: { startDate: string; endDate: string }[]) => {
    return dates.map((el) => {
      if (el.startDate === el.endDate) {
        return `${moment(el.startDate, "YYYY-MM-DD", true).format("DD MMM")}`;
      }
      return `${moment(el.startDate, "YYYY-MM-DD", true).format(
        "DD MMM"
      )}-${moment(el.endDate, "YYYY-MM-DD", true).format("DD MMM")}`;
    });
  };

  generateSeries = (
    data: KickoutClinicalNonClinicalReponse[],

    dates: { startDate: string; endDate: string }[]
  ) => {
    const series: Highcharts.SeriesOptionsType[] = [
      {
        type: "column",
        name: "Clinical",
        color: "#4791FF",
        data: dates.map((el) => {
          if (el.startDate && el.endDate) {
            const filtered = data.filter((d) => {
              return (
                d.exception_date >= el.startDate &&
                d.exception_date <= el.endDate
              );
            });
            const cnt = filtered.reduce((a, b) => {
              return Number(b.clinical) + a;
            }, 0);
            return cnt || 0;
          }
          return 0;
        }),
      },
      {
        type: "column",
        color: "#45BA7F",
        name: "Non Clinical Trail",
        data: dates.map((el) => {
          if (el.startDate && el.endDate) {
            const filtered = data.filter((d) => {
              return (
                d.exception_date >= el.startDate &&
                d.exception_date <= el.endDate
              );
            });
            const cnt = filtered.reduce((a, b) => {
              return Number(b.nonclinical) + a;
            }, 0);
            return cnt;
          }
          return 0;
        }),
      },
    ];
    return series;
  };

  render() {
    const dates = Utils.dateFunction({
      startDate: this.props.startDate!,
      endDate: moment(this.props.endDate!).format("YYYY-MM-DD"),
    });
    const chartData: Highcharts.Options = {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },

      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#2E2F30",
        formatter: function () {
          return `
            <div  style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
              <b>${this.x}</b><br><br>
              <span style=color:#BFBFBF; padding-bottom:10px>Spend Src Sys Cd :</span> <span style=padding-left:10px>${this.series.name}</span><br>
              <span style=color:#BFBFBF> No. Of Records</span> <span style=padding-left:10px>${this.y}</span><br>
              `;
        },
      },
      xAxis: {
        categories: this.generateCategories(dates),
        labels: {
          enabled: true,

        },
      },
      yAxis: {
        title: {
          text: "",
        },
        type: "logarithmic",
        min: 1,
        gridLineWidth: 0,
      },
      legend: {
        layout: "horizontal",
        align: "left",
        verticalAlign: "top",
        itemMarginTop: -15,
        itemMarginBottom: 10,
        x: -10,
      },
      plotOptions: {
        column: {
          dataLabels: {
            x: 25,
            formatter: function () {
              if (this.y && this.y > 0) {
                return this.y;
              }
              return null;
            },
          },
          pointWidth: 15,
          groupPadding: 0.5, // Increase the gap between stacked columns
        },
        series: {
          stacking: "normal",

          dataLabels: {
            enabled: true,
          },
        },
      },
      series: this.generateSeries(this.props.chartDataSourceArr, dates),
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartData} />
      </div>
    );
  }
}

export default KickoutClinicalNonClinicalChart;
