import { URIs } from "../Common/Constants";

import {
  createOtherAppsModel,
  deleteOtherAppsModel,
} from "../Models/OtherAppsModel";
import GenericAPIService from "./GenericAPIService";

const {
  OTHER_APPS_CREATE,
  OTHER_APPS_UPDATE,
  OTHER_APPS_DELETE,
  OTHER_APPS_GETALL,
} = URIs;
class OtherAppService extends GenericAPIService {
  async createOtherAppData(body: createOtherAppsModel) {
    try {
      const { data } = await this.postAsync<{ message: any }>(
        OTHER_APPS_CREATE,
        body
      );

      return data;
    } catch (error) {
      console.log("error in create data", error);
    }
  }
  async updateOtherAppData(body: any) {
    try {
      const { data } = await this.postAsync<{ message: any }>(
        OTHER_APPS_UPDATE,
        body
      );

      return data;
    } catch (error) {
      console.log("error in update", error);
    }
  }
  async deleteOtherAppData(body: deleteOtherAppsModel) {
    try {
      const { data } = await this.postAsync<{ message: any }>(
        OTHER_APPS_DELETE,
        body
      );

      return data;
    } catch (error) {
      console.log("error in delete", error);
    }
  }
  async getallOtherAppData() {
    try {
      const { data } = await this.getAsync<{ data: { data: any } }>(
        OTHER_APPS_GETALL
      );

      return data;
    } catch (error) {
      console.log("error in fetching data", error);
    }
  }
}

export default OtherAppService;
