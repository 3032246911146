import { URIs } from "../Common/Constants";

import {
  UploadedFilesModel,
  SupportFileSearchModel,
} from "../Models/UploadFileModel";
import GenericAPIService from "./GenericAPIService";
import S3FileService from "./S3FileService";

const {
  SUPPORT_FILEUPLOAD,
  GET_SUPPORT_UPLOADED_FILESDATA,
  CREATE_FILEUPLOAD,
  SUPPORTFILE_SEARCH,
} = URIs;
class SupportDataService extends GenericAPIService {
  async getUploadedFilesDataForUsers(noOfRecords: number, pageNo: number) {
    try {
      const { data } = await this.getAsync<{ data: UploadedFilesModel }>(
        `${GET_SUPPORT_UPLOADED_FILESDATA}?noOfRecords=${noOfRecords}&pageNo=${pageNo}`
      );

      return data.data;
    } catch (error) {
      console.log("error in fetching fileData for users", error);
    }
  }
  async uploadSupportFile(file: any, totalRecords: number) {
    const fileNameWithExt = file.fileName;
    let fileObj = {};
    if (file.fileName.includes("_Payments")) {
      fileObj = {
        key: fileNameWithExt,
        fileType: file.type,
        fileName: "CMS_DEST_RCRD_IDS",
      };
    } else if (file.fileName.includes("sln_hcp_found")) {
      fileObj = {
        key: fileNameWithExt,
        fileType: file.type,
        fileName: "CUST_ST_LIC_SRC_DATA",
      };
    } else {
      fileObj = {
        key: fileNameWithExt,
        fileType: file.type,
        fileName: fileNameWithExt.split(".")[0],
      };
    }

    const filePreSignedResponse = await this.postAsync<any>(
      SUPPORT_FILEUPLOAD,
      fileObj
    );

    if (filePreSignedResponse.data) {
      // put with presigned URL
      try {
        if (file.file) {
          const { presigned_url } = filePreSignedResponse.data.data;

          const isFileUploadSuccess = await S3FileService.putAsync(
            presigned_url,
            file.type.toString(),
            file.file
          );

          if (isFileUploadSuccess) {
            // add entry in table
            const supportFileResponse = await this.postAsync(
              CREATE_FILEUPLOAD,
              { name: fileNameWithExt, totalRecords }
            );
            return supportFileResponse;
          } else {
            throw new Error("Failed to upload file");
          }
        } else {
          throw new Error("No file to upload");
        }
      } catch (error) {
        console.log("upload-error", error);
      }
    } else {
      console.log("filePreSignedResponse", filePreSignedResponse);
    }
  }
  async searchFileData(body: SupportFileSearchModel) {
    try {
      const { data } = await this.postAsync<{ data: any }>(
        SUPPORTFILE_SEARCH,
        body
      );

      return data;
    } catch (error) {
      console.log("error in fetching fileData for users", error);
    }
  }
}

export default SupportDataService;
