import {
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { ReactNode } from "react";
import SelectModel from "./SelectModel";
import ChevronRight from "@mui/icons-material/ChevronRight";

interface Props {
  id?: string;
  label?: string;
  value?: string | number;
  style?: React.CSSProperties;
  size?: "small" | "medium";
  values?: SelectModel[];
  defaultValue?: string;
  placeholder?: string;
  AllValue?: boolean;
  name?: string;
  noneValue?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange: (selected: SelectModel, targetId?: string) => void;
  customOptions?: string;
  displayEmpty?: boolean;
  defaultLabel?: string;
  isAutogenerated?: boolean;
  multiStepValue?: string;
  multiStepOptionValues?: SelectModel[];
  className: string;
}
interface State {
  anchorEl: any;
  isSelectDialogOpen: boolean;
  isOpenPopover: boolean;
}

class MultiStepSelect extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      anchorEl: null,
      isSelectDialogOpen: false,
      isOpenPopover: false,
    };
  }

  render(): React.ReactNode {
    const {
      id,
      AllValue,
      label,
      value,
      defaultValue,
      values,
      size,
      placeholder,
      isDisabled,
      isRequired,
      customOptions,
      defaultLabel,
      noneValue,
      isAutogenerated,
      multiStepOptionValues,
      className,
    } = this.props;
    const { anchorEl } = this.state;
    const labelId = id ? id : label;
    return (
      <FormControl
        size={size ?? "small"}
        style={{ alignContent: "center", justifyContent: "center" }}
        variant="outlined"
        fullWidth
      >
        <InputLabel
          style={{ color: "black" }}
          className="bold-label"
          id={labelId}
        >
          {label}
        </InputLabel>
        <div
          style={{
            width: "100%",
          }}
        >
          <Select
            fullWidth
            required={isRequired}
            disabled={isDisabled}
            labelId={labelId + "label"}
            id={labelId}
            value={value}
            open={this.state.isSelectDialogOpen}
            onOpen={() => {
              this.setState({ isSelectDialogOpen: true });
            }}
            onChange={this.handleChange}
            onClose={this.handleClose}
            label={label}
            defaultValue={defaultValue}
            className="customer-select"
            MenuProps={{
              PaperProps: {
                style: { overflowY: "scroll", maxHeight: "14em" },
              },
            }}
            placeholder={placeholder}
            displayEmpty={true}
            renderValue={(value) => {
              if (!value || value === "None" || (isRequired && value === "")) {
                return (
                  <>
                    {customOptions ||
                      (defaultLabel
                        ? `Select ${defaultLabel}`
                        : "Select Value")}
                  </>
                );
              }
              return value;
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: `${!value ? "#555c57" : "black"}`,
                color: `${!value ? "#555c57" : "black"}`,
                backgroundColor: `${!value ? "white" : "#eaf2f8"}`,
                fontWeight: "bold",
              },
              backgroundColor: `${
                isAutogenerated
                  ? "#eaf2f8"
                  : "#263F6A" || isDisabled
                  ? "#263F6A"
                  : "#263F6A"
              }`,
              color: "white",
              borderRadius: "4px",
            }}
          >
            <MenuItem disabled value={""}>
              <>{customOptions || "Select Value"}</>
            </MenuItem>
            {noneValue ? (
              <MenuItem key={"NONE"} value={"NONE"}>
                None
              </MenuItem>
            ) : null}
            {AllValue ? (
              <MenuItem key={"All"} value={"All"}>
                All
              </MenuItem>
            ) : null}

            {values
              ?.sort((a, b) => {
                if (a.value < b.value) return -1;
                if (a.value > b.value) return 1;
                return 0;
              })
              .map((v) => {
                return (
                  <MenuItem key={v.text} value={v.value}>
                    {v.text}
                  </MenuItem>
                );
              })}
            {this.props.multiStepValue && (
              <MenuItem
                style={{
                  color: "red",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                key={this.props.multiStepValue}
                value={this.props.multiStepValue}
                onMouseDown={this.handleValueClick}
              >
                <div>{this.props.multiStepValue}</div>
                <div
                  style={{
                    marginTop: "0.3em",
                    fontSize: "12px",
                  }}
                >
                  <ChevronRight />
                </div>
              </MenuItem>
            )}
            <Popover
              className={className}
              id={"popover"}
              open={this.state.isOpenPopover}
              anchorEl={anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              style={{ marginLeft: "3.2em", top: "100px!important" }}
            >
              {multiStepOptionValues?.map((val) => {
                return (
                  <MenuItem
                    key={val.text}
                    value={val.value}
                    onClick={this.handlePopoverValueClick}
                  >
                    {val.text}
                  </MenuItem>
                );
              })}
            </Popover>
          </Select>
        </div>
      </FormControl>
    );
  }

  handleChange = (
    event: SelectChangeEvent<string | number>,
    child: ReactNode
  ) => {
    if (event.target.value === this.props.multiStepValue) {
      event.stopPropagation();
      this.setState({
        isOpenPopover: true,
        anchorEl: event.currentTarget,
        isSelectDialogOpen: true,
      });
    } else {
      const { values, onChange } = this.props;
      const selected = values?.find(
        (v) => v.value === (event.target.value as string)
      );
      if (selected) {
        onChange(selected);
      }
      this.setState({ isOpenPopover: false, isSelectDialogOpen: false });
    }
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      isOpenPopover: false,
      isSelectDialogOpen: false,
    });
  };

  handleValueClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    this.setState({
      anchorEl: event.currentTarget,
      isOpenPopover: true,
      isSelectDialogOpen: true,
    });
  };

  handlePopoverValueClick = (event: React.MouseEvent<HTMLElement>) => {
    const { multiStepOptionValues } = this.props;
    const selected = multiStepOptionValues?.find(
      (v) => v.value === (event.currentTarget.getAttribute("value") as string)
    );
    if (selected) {
      this.props.onChange(selected);
    }
    this.setState({
      anchorEl: null,
      isOpenPopover: false,
      isSelectDialogOpen: false,
    });
  };
}

export default MultiStepSelect;
