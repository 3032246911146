import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  styled,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  faCaretDown,
  faCaretUp,
  faUpDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";
import Utils from "../../Common/Utils";
import NoRecords from "../NoRecords/NoRecords";

import React from "react";
import { reviewStatusData } from "../../Common/Constants";
import GradientButton from "../Button/GradientButton";
import RedOutlinedButton from "../Button/RedOutlinedButton";
import PaginationNew from "../Pagination";
import SingleSelect from "../Select/SingleSelect";

type SortType = {
  key: string;
  direction: string;
};

interface Props extends RouteComponentProps<any, any, any> {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  headCellDataForEntries: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  sortConfig: SortType | null;
  data: any;
  totalRecordsCount?: number;

  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  checkBoxDifferentiator?: string | number;
  onClickLink?: (fieldClicked: string, record: any) => void;

  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
  onClickCheckBox: (selectedRows: any, data: any, selectAll: boolean) => void;
  onSelectChange: (selected: any, index: any, targetId?: string) => void;
  modes: any;
  handleCancelClick: () => void;
  handleSave: () => void;
  selectAllCheck: boolean;
  isButtonLoad: boolean;
}

interface State {
  sortConfig: SortType | null;
  data: any;
  resetRow: any;
  selectedData: any;
  selectAll: boolean;
  selectSingle: boolean;
  rowsPerPage: number;
  page: number;
 
  
  record: any;
  formData: { [index: string]: any };
  uniqueValue: any;
}

const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
class ReviewRecordsTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sortConfig: null,
      data: [],
      resetRow: [],
      selectAll: false,
      selectSingle: false,
      selectedData: [],
      rowsPerPage: 5,
      page: 0,
      record: [],
      formData: {},
      uniqueValue: null,
    };
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        data: this.props.data,
        sortConfig: null,
      });
    }
    if (!isEqual(prevProps.selectAllCheck, this.props.selectAllCheck)) {
      this.setState({
        selectAll: this.props.selectAllCheck,
      });
    }
    if (!isEqual(prevProps.checkBoxDifferentiator, this.state.uniqueValue)) {
      this.setState({ uniqueValue: this.props.checkBoxDifferentiator });
    }

    if (this.props.onClickCheckBox) {
      if (!isEqual(prevState.data, this.state.data)) {
        this.setState({
          selectedData: this.state?.data?.filter(
            (el: any) => el.checked === true
          ),
        });
      }

      if (!isEqual(prevState.selectedData, this.state.selectedData)) {
        this.props.onClickCheckBox(
          this.state.selectedData,
          this.state.data,
          this.state.selectAll
        );
      }
    }
  };

  componentDidMount = () => {
    this.setState({
      data: this.props.data,
      uniqueValue: this.props.checkBoxDifferentiator,
    });
  };

  requestSort = (key: string) => {
    const { sortConfig } = this.state;
    let direction = "ascending";
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === "ascending") {
        direction = "descending";
      }
    }
    this.setState({ sortConfig: { key, direction } }, () => {
      this.sorter();
    });
  };

  sorter = () => {
    const { sortConfig, data } = this.state;
    let sortedData = data;

    if (sortConfig !== null) {
      sortedData = sortedData.sort(
        (a: { [x: string]: number }, b: { [x: string]: number }) => {
          if (sortConfig.key === "sqnc_nbr") {
            if (Number(a[sortConfig.key]) < Number(b[sortConfig.key])) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (Number(a[sortConfig.key]) > Number(b[sortConfig.key])) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }
          return 0;
        }
      );
    }

    this.setState({ data: sortedData });

    return sortedData;
  };

  handleChangePage = (_event: any, page: any) => {
    this.setState({ page: page });
    this.props.onChangePage(page);
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
    this.props.onChangeRow(event.target.value);
  };
  defaultSortIconColor = "#959595";
  activeSortIconColor = "#000000";

  faCaretUpColor = this.activeSortIconColor;
  faCaretDownColor = this.activeSortIconColor;
  faUpDownColor = this.defaultSortIconColor;

  positionArrow = (key: string) => {
    if (["action"].includes(key.toLowerCase())) {
      return null;
    }
    const { sortConfig } = this.state;
    if (sortConfig) {
      if (sortConfig.direction === "ascending" && sortConfig.key === key)
        return (
          <FontAwesomeIcon
            icon={faCaretUp}
            color={this.faCaretUpColor}
            size="xs"
          />
        );
      if (sortConfig.direction === "descending" && sortConfig.key === key)
        return (
          <FontAwesomeIcon
            icon={faCaretDown}
            color={this.faCaretDownColor}
            size="xs"
          />
        );
    }
    return (
      <FontAwesomeIcon
        icon={faUpDown}
        color={this.defaultSortIconColor}
        size="sm"
      />
    );
  };

  onClickLink = (fieldClicked: string, record: any) => {
    if (this.props.onClickLink) {
      this.props.onClickLink(fieldClicked, record);
    }
  };
  onSelectChange = (selected: any, index: any, targetId?: string) => {
    this.props.onSelectChange(selected, index, targetId);
  };
  onClickCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    action: "single" | "all",
    singleRow?: any
  ) => {
    switch (action) {
      case "single":
        const { uniqueValue } = this.state;
        if (this.props.checkBoxDifferentiator) {
          const dataMapped = this.state.data.map((el: any) => {
            if (el[uniqueValue] === singleRow[uniqueValue]) {
              return { ...el, checked: event.target.checked };
            }
            return el;
          });
          this.setState({ data: dataMapped });
          if (
            dataMapped.some((d: any) => d.checked === false) &&
            this.state.selectSingle
          ) {
            this.setState({ selectAll: false });
          } else if (
            dataMapped.every((d: any) => d.checked === true) &&
            !this.state.selectAll
          ) {
            this.setState({ selectSingle: true });
          }
        }
        break;
      case "all":
        this.setState({
          data: this.state.data.map((d: any) => ({
            ...d,
            checked: event.target.checked,
          })),
        });
        this.setState({ selectAll: event.target.checked });
        break;
      default:
        break;
    }
  };
  onCancelClick = () => {
    this.props.handleCancelClick();
    this.setState({ selectAll: false });
    const dataMapped = this.state.data.map((el: any) => {
      return { ...el, checked: false };
    });
    this.setState({ data: dataMapped });
  };
  handleSave = (event: any) => {
    this.props.handleSave();
  };

  render() {
    const { headCellData, fieldsToColor, isButtonLoad } = this.props;
    const { data, selectAll, selectedData } = this.state;

    return (
      <Box ml={2} mr={2}>
        {this.props.isLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            {selectedData.length > 0 ? (
              <Grid container mb={1}>
                <Grid item xs={0.8}>
                  <RedOutlinedButton
                    label="Cancel"
                    onClick={this.onCancelClick}
                  />
                </Grid>
                <Grid item xs={10}>
                  <GradientButton
                    isButtonLoad={isButtonLoad}
                    //disabled={!_.isEqual(this.props.data, selectedData)}
                    label="Save"
                    onClick={this.props.handleSave}
                  />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {headCellData.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "30rem",
                }}
              >
                <MaterialTable
                  stickyHeader
                  sx={{ minWidth: "fit-content" }}
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "16px",
                          borderBottom: "2px solid red",
                        }}
                      >
                        <Checkbox
                          disabled={["bulkEdit", "singleEdit"].includes(
                            this.props.modes
                          )}
                          sx={{
                            "&.Mui-checked": {
                              color: "#3DA470",
                            },
                            "& .MuiSvgIcon-root": {
                              borderRadius: "4px",
                            },
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                          checked={selectAll}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => this.onClickCheckBox(event, "all")}
                        />
                      </TableCell>

                      {headCellData.map((el, index) => {
                        if (el && !["id", "checked"].includes(el.key)) {
                          return (
                            <TableCell
                              key={`${el.key}-${index}-head`}
                              style={{
                                fontSize: "16px",
                                borderBottom: "2px solid red",
                                whiteSpace: "nowrap",
                                minWidth: "10em",
                                fontWeight: "bold",
                              }}
                              onClick={() => this.requestSort(el.key)}
                            >
                              {Utils.camelToTitle(el.label)}{" "}
                              {el?.key === "review_status"
                                ? null
                                : this.positionArrow(el.key)}
                            </TableCell>
                          );
                        }
                        return null;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0
                      ? data.map((record: any, index: number) => {
                          return (
                            <StyledTableRow key={`${index}-row-data`}>
                              <TableCell
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                <Checkbox
                                  disabled={["bulkEdit", "singleEdit"].includes(
                                    this.props.modes
                                  )}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#3DA470",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      borderRadius: "4px",
                                    },
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                  size="small"
                                  checked={record.checked || false}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    this.onClickCheckBox(
                                      event,
                                      "single",
                                      record
                                    )
                                  }
                                />
                              </TableCell>
                              {headCellData.map((el, cellIndex) => {
                                if (el?.key !== "id") {
                                  const clrObj = fieldsToColor?.find(
                                    (clr) => clr.key === el?.key
                                  );
                                  return (
                                    <React.Fragment
                                      key={`${el.key}-${index}-check`}
                                    >
                                      <TableCell
                                        key={`${el.key}-${index}-${cellIndex}-cell`}
                                        style={{
                                          fontSize: "16px",
                                          wordWrap: "break-word",
                                          hyphens: "auto",
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {el?.key === "review_status" ? (
                                          <Grid container width="12rem">
                                            <SingleSelect
                                              isDisabled={!record.checked}
                                              value={data[index][el.key] ?? ""}
                                              values={reviewStatusData}
                                              onChange={(event) =>
                                                this.onSelectChange(
                                                  event,
                                                  index,
                                                  "review_status"
                                                )
                                              }
                                            />
                                          </Grid>
                                        ) : (
                                          <Box
                                            style={{
                                              fontSize: "16px",
                                              wordWrap: "break-word",
                                              hyphens: "auto",
                                              wordBreak: "break-all",
                                              color: clrObj
                                                ? clrObj.values[record[el?.key]]
                                                : "black",
                                            }}
                                          >
                                            {record[el?.key]}
                                          </Box>
                                        )}
                                        {el.elementToLoad}{" "}
                                      </TableCell>
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                            </StyledTableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </MaterialTable>
              </TableContainer>
            ) : (
              <Box style={{ margin: "20vh" }}>
                <NoRecords />
              </Box>
            )}
          </>
        )}

        <PaginationNew
          isEmptyReset={this.props.isEmptyReset}
          isPaginationReset={this.props.isPaginationReset}
          isDisabled={this.props.isPaginationDisabled}
          onChangePage={this.props.onChangePage}
          onChangeRow={this.props.onChangeRow}
          totalRecordsCount={this.props.totalRecordsCount || 1}
        />
      </Box>
    );
  }
}

export default withRouter(ReviewRecordsTable);
