import React from 'react';
import { AppBar, Box, Grid, Tab, Tabs, Typography, Divider, Badge } from "@mui/material";
import { TabModel, TabType } from './TabModel';


const TabLabelComponent: React.FC<{ tab: TabModel, isSelected: boolean, count?: any }> = ({ tab, isSelected, count }) => {
    return (
        <Grid container alignItems="center" justifyItems="center">
            <Grid item>
                <Typography style={{ fontSize: 15, fontWeight: 500, color: isSelected ? '#FFFFFF' : '#757575' }}>
                    {tab.name}{/*  <Badge style={{marginLeft:21}} badgeContent = {count} max={count} color="error" /> */}
                </Typography>

            </Grid>
        </Grid>
    )
}

interface Props {
    currentTab: TabModel;
    tabs: TabModel[];
    onTabChange: ((selectedTab: TabModel) => void);
    countObj?: { [index in TabType]: number };
}
interface State { }

class TabBar extends React.Component<Props, State> {
    render() {

        const { tabs, currentTab } = this.props;
        const indexOfCurrentTab =  tabs.findIndex(tab => tab.type === currentTab.type);
        return (
            <Box mt={2}>
                <AppBar
                    position='static'
                    color='default'
                    style={{ backgroundColor: '#F5F5F5' }}
                >
                    <Tabs
                        value={indexOfCurrentTab}
                        onChange={this.handleTabChange}

                        aria-label="Transactions Tabs"
                        TabIndicatorProps={{
                            style: {
                                display: 'none'


                            },
                        }}
                    >
                        {tabs?.map((tab: TabModel, i: any) => {
                            const tabProps = {
                                id: `full-width-tab-${i}`,
                                'aria-controls': `full-width-tabpanel-${i}`
                            };
                            const isSelected = indexOfCurrentTab === i;

                            const tabStyle: React.CSSProperties = {
                                backgroundColor: '#F5F5F5',
                                textTransform: 'none',
                                marginRight: 0,
                                border: '0px solid #E3E3E3',
                            };
                            if (isSelected) {
                                tabStyle.backgroundImage = "linear-gradient(to right, #D52B1E,#78105B)";
                                tabStyle.border = 'none';
                            }
                            return (
                                <Tab {...tabProps} key={tabProps.id}
                                    label={
                                        <TabLabelComponent
                                            tab={tab}
                                            count = {this.props.countObj ? this.props.countObj[tab.type] : undefined }
                                            isSelected={isSelected}
                                        />
                                    }
                                    style={tabStyle} />
                            );
                        })}
                    </Tabs>
                </AppBar>
                <Divider orientation='horizontal' style={{ borderImageOutset: "5px",borderImageWidth: "5px", borderImage : "linear-gradient(to right, #D42B1F, #70185B)  3 5 2 2 / 8 / 0 ", height: 5, borderRadius: 'none' }} />
            </Box>
        )
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const { tabs } = this.props;

        const selectedTab = tabs[newValue];

        if (selectedTab) {
            this.props.onTabChange(selectedTab);
        }
    }
}

export default TabBar;