import { URIs } from "../Common/Constants";

import {
  createMyLinkAppModel,
  deleteMyLinkAppModel,
  searchMyLinkAppModel,
} from "../Models/MyLinksModel";
import GenericAPIService from "./GenericAPIService";

const {
  MYLINK_APPS_CREATE,
  MYLINK_APPS_UPDATE,
  MYLINK_APPS_DELETE,
  MYLINK_APPS_GETALL,
  MYLINK_APPS_SEARCH,
} = URIs;
class MyLinkAppService extends GenericAPIService {
  async createMyLinkAppData(body: createMyLinkAppModel) {
    try {
      const { data } = await this.postAsync<{ message: any }>(
        MYLINK_APPS_CREATE,
        body
      );

      return data;
    } catch (error) {
      console.log("error in create data", error);
    }
  }
  async updateMyLinkAppData(body: any) {
    try {
      const { data } = await this.postAsync<{ message: any }>(
        MYLINK_APPS_UPDATE,
        body
      );

      return data;
    } catch (error) {
      console.log("error in update", error);
    }
  }
  async deleteMyLinkAppData(body: deleteMyLinkAppModel) {
    try {
      const { data } = await this.postAsync<{ message: any }>(
        MYLINK_APPS_DELETE,
        body
      );

      return data;
    } catch (error) {
      console.log("error in delete", error);
    }
  }
  async getallMyLinkAppData() {
    try {
      const { data } = await this.getAsync<{ data: { data: any } }>(
        MYLINK_APPS_GETALL
      );

      return data;
    } catch (error) {
      console.log("error in fetching data", error);
    }
  }
  async searchMyLinkAppData(body: searchMyLinkAppModel) {
    try {
      const { data } = await this.postAsync<{ data: { data: any } }>(
        MYLINK_APPS_SEARCH,
        body
      );

      return data;
    } catch (error) {
      console.log("error in fetching data", error);
    }
  }
}

export default MyLinkAppService;
