import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  InputLabel,
  Paper,
  TableContainer,
  TableHead,
  Table as MaterialTable,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Theme,
  styled,
} from "@mui/material";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Utils from "../../Common/Utils";
import GradientButton from "../../Components/Button/GradientButton";
import RedOutlinedButton from "../../Components/Button/RedOutlinedButton";
import ModalDialog from "../../Components/Modal/ModelDialog";
import MultiAutoComplete from "../../Components/Select/MultiAutoComplete";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import PageHeader from "../../Components/Text/PageHeader";
import TextAreaInputField from "../../Components/TextInputField/TextAreaInputField";
import { ActionBtnModel } from "../../Models/ActionModel";
import {
  NotificationHistoryModel,
  NotificationWidgetModifyModel,
} from "../../Models/NotificationWidgetModel";
import NotificationService from "../../Services/NotificationWidgetService";
import UserAuthService from "../../Services/UserAuthService";
import { reviewSdmTable } from "../../Common/Constants";
import _ from "lodash";
const notificationServices = new NotificationService();
interface Props extends RouteComponentProps<any, any, any> {
  item: any;
  title: string;
  chatHistoryData: NotificationHistoryModel[];
  isChatBoxLoading: boolean;
  userName: string;
  selectedValues: SelectModel[];
  usersData: SelectModel[];
  handleMultiSelectChange: (selected: SelectModel[], targetId?: string) => void;

  handleReassignMultiSelectChange: (
    selected: SelectModel[],
    targetId?: string
  ) => void;
  openChatBox: (item: any) => void;
  reassignedUserData: SelectModel[];
  notificationStatus: string;
  handleChatBoxOpen: () => void;
  publicationLeadData: SelectModel[];
  notificationData: any[];
  clearReassignedUserData: () => void;
  tableValuesHistoryData: SelectModel[];
}
interface State {
  showReassignDialog: boolean;
  showActionContent: boolean;
  ActionItems: ActionBtnModel;
  comment: string;
  selectedStatus: string;
  currentChat: boolean;
  isActionClick: boolean;
  isButtonLoading: boolean;
  chatData: { date: string; result: NotificationHistoryModel[] }[];
  userRoles: any;
  isActionDisabled: boolean;
  reassignComment: string;
  isReassignButtonLoading: boolean;
  notificationStatus: string;
  showLinkDialog: boolean;
  selectedTableValues: SelectModel[];
  linkData: any;
  assignedUsers: SelectModel[];
}
const StyledTableRow: any = styled(TableRow)(({ theme }: { theme: Theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
class ChatBox extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      showReassignDialog: false,
      showActionContent: false,
      ActionItems: {} as ActionBtnModel,
      comment: "",
      selectedStatus: "",
      currentChat: false,
      isActionClick: false,
      isButtonLoading: false,
      chatData: [],
      userRoles: [],
      isActionDisabled: false,
      reassignComment: "",
      isReassignButtonLoading: false,
      notificationStatus: this.props.notificationStatus,
      showLinkDialog: false,
      selectedTableValues: [],
      linkData: [],
      assignedUsers: [],
    };
  }
  componentDidMount() {
    this.getuserRoles();

    if (this.state.notificationStatus.length > 0) {
      const str = this.props.notificationStatus
        .toLowerCase()
        .replace(/\b(\w)/g, (s: any) => s.toUpperCase());

      this.setState({ selectedStatus: str }, () => {
        this.handleSelectChange({
          text: str,
          value: str,
        });
      });
    } else {
      const str = this.props.item.notification_status
        .toLowerCase()
        .replace(/\b(\w)/g, (s: any) => s.toUpperCase());

      this.setState({ selectedStatus: str }, () => {
        this.handleSelectChange({
          text: str,
          value: str,
        });
      });
    }

    this.getSeggregatedData();
    if (this.props.tableValuesHistoryData.length > 0) {
      this.setState({ selectedTableValues: this.props.tableValuesHistoryData });
    }
  }
  getSeggregatedData = () => {
    const today = new Date().toISOString().split("T")[0];
    const yesterDay = new Date(Date.now() - 864e5).toISOString().split("T")[0];

    const result: any = [];

    const otherEntries = this.props.chatHistoryData.filter((item) => {
      const itemDate = item.var_last_updated_datetime.split(" ")[0];
      return itemDate !== today && itemDate !== yesterDay;
    });
    const groupedOtherEntries = otherEntries.reduce((acc: any, item) => {
      const itemDate = item.var_last_updated_datetime.split(" ")[0];
      acc[itemDate] = acc[itemDate] || { date: itemDate, result: [] };
      acc[itemDate].result.push(item);
      return acc;
    }, {});
    result.push(...Object.values(groupedOtherEntries));
    const yesterdayEntries = this.props.chatHistoryData.filter(
      (item) => item.var_last_updated_datetime.split(" ")[0] === yesterDay
    );
    if (yesterdayEntries.length > 0) {
      result.push({
        date: "Yesterday",
        result: yesterdayEntries,
      });
    }

    const todayEntries = this.props.chatHistoryData.filter(
      (item) => item.var_last_updated_datetime.split(" ")[0] === today
    );
    if (todayEntries.length > 0) {
      result.push({
        date: "Today",
        result: todayEntries,
      });
    }

    this.setState({
      chatData: result,
      comment: "",
      selectedStatus: "",
      showActionContent: false,
    });
  };
  getuserRoles = async () => {
    try {
      const userRole = await UserAuthService.fetchUserRoles();
      this.setState(
        {
          userRoles: userRole.data.data.data.roles,
        },
        () => {
          this.setState({
            isActionDisabled: this.isActionDisabled(),
            assignedUsers: this.props.selectedValues,
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  isActionDisabled = () => {
    const { userRoles } = this.state;
    const { item } = this.props;
    const status = item.notification_status.toLowerCase();
    if (status === "review requested" || status === "review completed") {
      return !(
        userRoles.includes("PUBLICATION_LEAD") ||
        userRoles.includes("SUPER_USER") ||
        userRoles.includes("MANAGEMENT_LEADERSHIP")
      );
    } else {
      return false;
    }
  };
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.chatHistoryData !== this.props.chatHistoryData) {
      this.getSeggregatedData();
      this.getuserRoles();
      const statusStr = this.props.notificationStatus
        ? this.props.notificationStatus
        : this.props.item.notification_status;
      const str = statusStr
        .toLowerCase()
        .replace(/\b(\w)/g, (s: any) => s.toUpperCase());
      this.setState(
        {
          isActionDisabled: this.isActionDisabled(),
          selectedStatus: str,
        },
        () => {
          this.handleSelectChange({
            text: str,
            value: str,
          });
        }
      );
    }
    if (
      prevProps.tableValuesHistoryData !== this.props.tableValuesHistoryData
    ) {
      this.setState({
        selectedTableValues: this.props.tableValuesHistoryData,
      });
    }
  }
  isEmptyCheck = () => {
    if (this.state.selectedStatus === "Review Requested") {
      if (
        this.state.comment.trim() &&
        this.state.selectedStatus &&
        this.state.selectedTableValues.length !== 0 &&
        this.props.selectedValues.length !== 0 &&
        !this.state.isButtonLoading
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        this.state.comment.trim() &&
        this.state.selectedStatus &&
        this.props.selectedValues.length !== 0 &&
        !this.state.isButtonLoading
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  onInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ ...this.state, [event.target.id]: event.target.value });
  };
  handleSelectChange = (selected: SelectModel) => {
    this.setState({
      selectedStatus: selected.value,
    });
  };

  onSubmit = async () => {
    try {
      this.setState({ isButtonLoading: true });
      const { userName, selectedValues, title, item } = this.props;
      const closedStatusPayload: NotificationWidgetModifyModel = {
        notification_payload: [
          {
            title: title,
            comments: this.state.comment,
            assigned_to: selectedValues
              .map((el) => this.formatName(el.text))
              .join(","),
            created_by: this.formatName(userName),
            created_datetime: Utils.getCurrentUTCDateTimeMonth(),
            notification_id: item.var_notification_id,
            notification_status: this.state.selectedStatus.toLowerCase(),
            updated_by: this.formatName(userName),
            impacted_objects: this.state.selectedTableValues
              .map((el) => el.text)
              .join(","),
          },
        ],
      };
      const updatePayload: NotificationWidgetModifyModel = {
        notification_payload: [
          {
            title: title,
            comments: this.state.comment,
            assigned_to: selectedValues
              .map((el) => this.formatName(el.text))
              .join(","),
            created_by: this.formatName(userName),
            created_datetime: Utils.getCurrentUTCDateTimeMonth(),
            notification_id: item.var_notification_id,
            notification_status: this.state.selectedStatus.toLowerCase(),
            updated_by: this.formatName(userName),
            impacted_objects: "",
          },
        ],
      };
      const response = await notificationServices.notificationWidgetModify(
        this.state.selectedStatus.toLowerCase() === "review requested"
          ? closedStatusPayload
          : updatePayload
      );
      if (response.result === "Success") {
        this.setState({
          isButtonLoading: false,
          comment: "",
          selectedStatus: "",
          selectedTableValues: [],
        });
        this.props.openChatBox(this.props.item);
        if (
          this.state.selectedStatus.toLowerCase() === "review requested" ||
          this.state.selectedStatus.toLowerCase() === "closed" ||
          !selectedValues.map((el) => el.text).includes(userName)
        ) {
          this.props.handleChatBoxOpen();
        }
      } else {
        this.setState({
          isButtonLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        isButtonLoading: false,
      });
    }
  };
  onReassignClick = async () => {
    try {
      this.setState({ isReassignButtonLoading: true });
      const { userName, reassignedUserData, title, item } = this.props;
      const updatePayload: NotificationWidgetModifyModel = {
        notification_payload: [
          {
            title: title,
            comments: this.state.reassignComment,
            assigned_to: reassignedUserData
              .map((el) => this.formatName(el.text))
              .join(","),
            created_by: this.formatName(userName),
            created_datetime: Utils.getCurrentUTCDateTimeMonth(),
            notification_id: item.var_notification_id,
            notification_status: this.props.item.notification_status,
            updated_by: this.formatName(userName),
          },
        ],
      };
      const response = await notificationServices.notificationWidgetModify(
        updatePayload
      );
      if (response.result === "Success") {
        this.setState({
          isReassignButtonLoading: false,
          reassignComment: "",
          showReassignDialog: false,
        });

        //this.props.openChatBox(this.props.item);
        this.props.handleChatBoxOpen();
      } else {
        this.setState({
          isReassignButtonLoading: false,
        });
      }
    } catch (error) {
      this.setState({
        isReassignButtonLoading: false,
      });
    }
  };
  onModalClose = () => {
    this.setState({
      showReassignDialog: false,
      showLinkDialog: false,
      reassignComment: "",
    });

    this.props.clearReassignedUserData();
  };
  onLinkModalClose = () => {
    this.setState({
      showLinkDialog: false,
    });
  };
  statusValue: SelectModel[] = [
    { text: "Review Requested", value: "Review Requested" },
    { text: "Open", value: "Open" },
    { text: "In Progress", value: "In Progress" },
    { text: "Closed", value: "Closed" },
    { text: "Rejected", value: "Rejected" },
    { text: "On Hold", value: "On Hold" },
  ];
  reviewStatusValue: SelectModel[] = [
    { text: "Closed", value: "Closed" },
    { text: "Review Completed", value: "Review Completed" },
    { text: "Review Requested", value: "Review Requested" },
    { text: "Open", value: "Open" },
    { text: "In Progress", value: "In Progress" },
    { text: "Rejected", value: "Rejected" },
    { text: "On Hold", value: "On Hold" },
  ];
  reassignUserData = () => {
    if (
      this.props.item &&
      this.props.item.notification_status.toLowerCase() === "review requested"
    ) {
      if (
        this.props.publicationLeadData &&
        this.props.chatHistoryData.length > 0
      ) {
        return this.props.publicationLeadData.filter((el) => {
          const assignedUsers =
            this.props.chatHistoryData[
              this.props.chatHistoryData.length - 1
            ].var_agg_assigned_to.split(",");
          return !assignedUsers.includes(el.text);
        });
      } else {
        return this.props.publicationLeadData;
      }
    } else {
      if (this.props.usersData && this.props.chatHistoryData.length > 0) {
        return this.props.usersData.filter((el) => {
          const assignedUsers =
            this.props.chatHistoryData[
              this.props.chatHistoryData.length - 1
            ].var_agg_assigned_to.split(",");
          return !assignedUsers.includes(el.text);
        });
      } else {
        return this.props.usersData;
      }
    }
  };
  onActionClick = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
    this.setState({
      showActionContent: true,
    });
  };
  onCancelClick = () => {
    if (this.state.notificationStatus === "closed") {
      this.setState({
        showActionContent: false,
      });
    } else {
      this.props.openChatBox(this.props.item);
      this.setState({
        showActionContent: false,
      });
    }
  };
  handleTableSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (targetId) {
      //const values = selected.map((p) => p.value as string);
      this.setState({ selectedTableValues: selected });
    }
  };
  handleGenerateLink = () => {
    const notificationData = this.props.notificationData.find(
      (el) => el.var_notification_id === this.props.item.var_notification_id
    );
    if (notificationData) {
      const tableName = notificationData.impacted_objects.split(",");

      const tableData = tableName.map((el: string, ind: number) => {
        return {
          id: ind + 1,
          link: `${window.location.origin}/reviewrecords/${el.toLowerCase()}/${
            notificationData.var_notification_id
          }`,
        };
      });
      this.setState({ showLinkDialog: true, linkData: tableData });
    } else {
      const tableName =
        this.props.chatHistoryData[0].impacted_objects.split(",");

      const tableData = tableName.map((el: string, ind: number) => {
        return {
          id: ind + 1,
          link: `${window.location.origin}/reviewrecords/${el.toLowerCase()}/${
            this.props.item.var_notification_id
          }`,
        };
      });
      this.setState({ showLinkDialog: true, linkData: tableData });
    }
  };
  onClickLinkOpen = (url: string) => {
    const newTab = window.open(url, "_blank", "noopener,noreferrer");
    if (newTab) newTab.opener = null;
  };
  getFilteredStatus = () => {
    const currentData = this.props.selectedValues.map((el) => {
      return {
        text: el.text,
      };
    });
    const prevData = this.state.assignedUsers.map((el) => {
      return {
        text: el.text,
      };
    });
    return _.isEqual(currentData, prevData);
  };
  formatName = (name: string) => {
    if (name.includes("O'")) {
      return name.replace("O'", "O''''");
    }
    return name;
  };
  render() {
    const {
      showReassignDialog,
      showActionContent,
      comment,
      selectedStatus,
      isButtonLoading,
      chatData,
      isActionDisabled,
      userRoles,
      reassignComment,
      isReassignButtonLoading,
      showLinkDialog,
      selectedTableValues,
      linkData,
    } = this.state;
    return (
      <Box>
        {this.props.chatHistoryData.length === 0 && this.props.item ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "31px 20px",
              }}
            >
              {this.props.item && this.props.title ? (
                <Box>
                  <PageHeader
                    label={`ID_${this.props.item.var_notification_id} ${this.props.title}`}
                    style={{
                      fontSize: "1rem",
                      marginTop: "12px",
                    }}
                  />
                </Box>
              ) : (
                <></>
              )}

              <Box style={{ display: "flex", gap: "1em" }}>
                {this.props.item ? (
                  (this.props.item.notification_status.toLowerCase() ===
                    "review requested" ||
                    this.props.item.notification_status.toLowerCase() ===
                      "review completed") &&
                  this.props.chatHistoryData[0].impacted_objects ? (
                    <GradientButton
                      label="Open Link"
                      onClick={this.handleGenerateLink}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {this.props.item ? (
                  <RedOutlinedButton
                    disabled={
                      this.props.item.notification_status.toLowerCase() ===
                        "review requested" ||
                      this.props.item.notification_status.toLowerCase() ===
                        "closed"
                    }
                    label="Reassign"
                    onClick={() => {
                      this.setState({ showReassignDialog: true });
                    }}
                  />
                ) : (
                  <></>
                )}
                {showActionContent ? (
                  <GradientButton
                    id="button"
                    label="Cancel"
                    onClick={this.onCancelClick}
                  />
                ) : (
                  <GradientButton
                    id="button"
                    label="Action"
                    onClick={this.onActionClick}
                    disabled={isActionDisabled || showActionContent}
                  />
                )}
              </Box>
            </Box>
            <Box
              bgcolor={"#EBEFF2"}
              borderTop={"0.5px solid #D8D8D8"}
              maxHeight={"82vh"}
              height={"82vh"}
              style={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {this.props.isChatBoxLoading ? (
                <Box
                  style={{
                    justifyContent: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    margin: "30vh",
                  }}
                >
                  <CircularProgress sx={{ color: "#d52b1e" }} />
                </Box>
              ) : (
                <Box>
                  {chatData.map((el: any, index: number) => (
                    <React.Fragment key={`${index}-data`}>
                      <Chip
                        label={el.date}
                        variant="outlined"
                        style={{
                          color: "#404040",
                          background: "#FFFFFF",
                          border: "1px solid #DDDDDD",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "fit-content",
                          margin: "25px auto",
                          height: "25px",
                        }}
                      />
                      {el.result.map((item: any, ind: number) => (
                        <React.Fragment key={ind}>
                          {this.props.userName === item.var_assigned_to ? (
                            <Grid
                              container
                              mb={5}
                              pr={3}
                              display={"flex"}
                              justifyContent={"flex-end"}
                            >
                              <Grid item>
                                <Card
                                  style={{
                                    background: "#FDF4F3",
                                    border: "1px solid #DBDBDB",
                                    borderRadius: "6px",
                                  }}
                                >
                                  <CardContent
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordWrap: "break-word",
                                      hyphens: "auto",
                                      wordBreak: "break-all",
                                      paddingBottom: "15px",
                                      width: "550px",
                                    }}
                                  >
                                    <Grid item mb={0.5} display={"flex"}>
                                      <Typography
                                        style={{
                                          color: "#797979",
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Assigned On :{" "}
                                        {Utils.getFormattedDateTime(
                                          item.var_assigned_on
                                        )}
                                      </Typography>

                                      {item.var_status.toLowerCase() ===
                                      "rejected" ? (
                                        <Grid
                                          container
                                          maxWidth="4.3rem"
                                          style={{
                                            background:
                                              "#FFEDEB 0% 0% no-repeat padding-box",
                                            border: " 1px solid #FA0000",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#F00F0F",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              Rejected
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : item.var_status.toLowerCase() ===
                                        "in progress" ? (
                                        <Grid
                                          container
                                          maxWidth="5rem"
                                          style={{
                                            background:
                                              "#FFF1E0 0% 0% no-repeat padding-box",
                                            border: " 1px solid #FA0000",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#F06A08",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              In Progress
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : item.var_status.toLowerCase() ===
                                        "open" ? (
                                        <Grid
                                          container
                                          maxWidth="3rem"
                                          style={{
                                            background:
                                              "#E5FFF2 0% 0% no-repeat padding-box",
                                            border: " 1px solid #50AF7F",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#50AF7F",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              Open
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : item.var_status.toLowerCase() ===
                                        "unread" ? (
                                        <Grid
                                          container
                                          maxWidth="3.5rem"
                                          style={{
                                            background:
                                              "#289aed24 0% 0% no-repeat padding-box",
                                            border: " 1px solid #289AED",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#289AED",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              Unread
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : item.var_status.toLowerCase() ===
                                        "on hold" ? (
                                        <Grid
                                          container
                                          maxWidth="4rem"
                                          style={{
                                            background:
                                              "#9d24f530 0% 0% no-repeat padding-box",
                                            border: " 1px solid #9D24F5",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#9D24F5",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              On Hold
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : item.var_status.toLowerCase() ===
                                        "closed" ? (
                                        <Grid
                                          container
                                          maxWidth="3.4rem"
                                          style={{
                                            background:
                                              "#86868636 0% 0% no-repeat padding-box",
                                            border: " 1px solid #868686",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#868686",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              Closed
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>

                                    <Grid item mb={1.5}>
                                      <Typography fontWeight={"bold"}>
                                        {" "}
                                        {item.var_comments}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Chip
                                        label={item.var_assigned_to}
                                        variant="outlined"
                                        style={{
                                          color: "#D42B1E",
                                          background: "#F6F9FC",
                                          border: "1px solid #D42B1E",
                                          height: "28px",
                                        }}
                                      />
                                    </Grid>
                                  </CardContent>
                                </Card>
                                <Grid item>
                                  <Typography
                                    style={{
                                      color: "#797979",
                                      fontSize: "14px",
                                      padding: "8px 13px",
                                    }}
                                  >
                                    {Utils.getFormattedDateTime(
                                      item.var_last_updated_datetime
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <AccountCircleIcon
                                  sx={{
                                    width: "2rem",
                                    height: "2rem",
                                    marginLeft: "0.5em",
                                  }}
                                  style={{
                                    color: "#7A8FB2",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container mb={6} ml={3} display={"flex"}>
                              <Grid item>
                                <AccountCircleIcon
                                  sx={{
                                    width: "2rem",
                                    height: "2rem",
                                    marginRight: "0.5em",
                                  }}
                                  style={{
                                    color: "#7A8FB2",
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Card
                                  style={{
                                    background: "#FFFFFF",
                                    border: "1px solid #DBDBDB",
                                    borderRadius: "6px",
                                  }}
                                >
                                  <CardContent
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      wordWrap: "break-word",
                                      hyphens: "auto",
                                      wordBreak: "break-all",
                                      paddingBottom: "15px",
                                      width: "550px",
                                    }}
                                  >
                                    <Grid item mb={0.5} display={"flex"}>
                                      <Typography
                                        style={{
                                          color: "#797979",
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Assigned On :{" "}
                                        {Utils.getFormattedDateTime(
                                          item.var_assigned_on
                                        )}
                                      </Typography>
                                      {item.var_status.toLowerCase() ===
                                      "rejected" ? (
                                        <Grid
                                          container
                                          maxWidth="4.3rem"
                                          style={{
                                            background:
                                              "#FFEDEB 0% 0% no-repeat padding-box",
                                            border: " 1px solid #FA0000",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#F00F0F",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              Rejected
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : item.var_status.toLowerCase() ===
                                        "in progress" ? (
                                        <Grid
                                          container
                                          maxWidth="5rem"
                                          style={{
                                            background:
                                              "#FFF1E0 0% 0% no-repeat padding-box",
                                            border: " 1px solid #FA0000",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#F06A08",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              In Progress
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : item.var_status.toLowerCase() ===
                                        "open" ? (
                                        <Grid
                                          container
                                          maxWidth="3rem"
                                          style={{
                                            background:
                                              "#E5FFF2 0% 0% no-repeat padding-box",
                                            border: " 1px solid #50AF7F",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#50AF7F",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              Open
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : item.var_status.toLowerCase() ===
                                        "unread" ? (
                                        <Grid
                                          container
                                          maxWidth="3.5rem"
                                          style={{
                                            background:
                                              "#289aed24 0% 0% no-repeat padding-box",
                                            border: " 1px solid #289AED",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#289AED",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              Unread
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : item.var_status.toLowerCase() ===
                                        "on hold" ? (
                                        <Grid
                                          container
                                          maxWidth="4rem"
                                          style={{
                                            background:
                                              "#9d24f530 0% 0% no-repeat padding-box",
                                            border: " 1px solid #9D24F5",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#9D24F5",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              On Hold
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : item.var_status.toLowerCase() ===
                                        "closed" ? (
                                        <Grid
                                          container
                                          maxWidth="3.4rem"
                                          style={{
                                            background:
                                              "#86868636 0% 0% no-repeat padding-box",
                                            border: " 1px solid #868686",
                                            opacity: 1,
                                            borderRadius: "17px",
                                            color: "#868686",
                                            padding: "0 5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography fontSize={"13px"}>
                                              Closed
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                    <Grid item mb={1.5}>
                                      <Typography fontWeight={"bold"}>
                                        {" "}
                                        {item.var_comments}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Chip
                                        label={item.var_assigned_to}
                                        variant="outlined"
                                        style={{
                                          color: "#404040",
                                          background: "#F6F9FC",
                                          border: "1px solid #D9D9D9",
                                          height: "28px",
                                        }}
                                      />
                                    </Grid>
                                  </CardContent>
                                </Card>
                                <Grid item>
                                  <Typography
                                    style={{
                                      color: "#797979",
                                      fontSize: "14px",
                                      padding: "8px 13px",
                                    }}
                                  >
                                    {Utils.getFormattedDateTime(
                                      item.var_last_updated_datetime
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </Box>
              )}
            </Box>

            <Grid container ml={6} mr={6} mt={2} mb={2}>
              <Grid item xs={10} pr={4}>
                {showActionContent ? (
                  <Grid container>
                    {selectedStatus === "Review Requested" ? (
                      <Grid item xs={12} mb={2}>
                        <MultiAutoComplete
                          id="tableName"
                          label="Select Table Name:"
                          selected={selectedTableValues}
                          values={reviewSdmTable}
                          onChange={this.handleTableSelectChange}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <Grid item xs={9.4} pr={1}>
                      <MultiAutoComplete
                        id="assignedName"
                        disabled={selectedStatus === "Closed"}
                        label="Assign team by tagging:"
                        selected={this.props.selectedValues}
                        values={this.props.usersData}
                        onChange={this.props.handleMultiSelectChange}
                      />
                    </Grid>
                    <Grid item xs={2.6}>
                      <SingleSelect
                        id="selectedStatus"
                        customOptions="Select Status"
                        value={selectedStatus}
                        values={
                          (userRoles.includes("PUBLICATION_LEAD") ||
                            userRoles.includes("SUPER_USER") ||
                            userRoles.includes("MANAGEMENT_LEADERSHIP")) &&
                          (this.props.item.notification_status.toLowerCase() ===
                            "review requested" ||
                            this.props.item.notification_status.toLowerCase() ===
                              "review completed")
                            ? this.reviewStatusValue
                            : this.statusValue
                        }
                        onChange={this.handleSelectChange}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
                <TextAreaInputField
                  placeholder="Enter your comments"
                  id="comment"
                  isDisabled={showActionContent ? false : true}
                  value={comment}
                  onChange={this.onInputChange}
                  minRows={3}
                  backgroundColor={showActionContent ? "#ffffff" : "#EAEAEA"}
                  color="#000000"
                />
              </Grid>
              <Grid
                item
                xs={1}
                display={"flex"}
                alignItems={"end"}
                mb={1.5}
                className="ChatCommentBox"
              >
                <GradientButton
                  buttonType="IconButton"
                  isButtonLoad={isButtonLoading}
                  disabled={this.isEmptyCheck()}
                  id="button"
                  label=""
                  onClick={this.onSubmit}
                  startIcon={<img src="SendIcon.png" alt="SendIcon" />}
                />
              </Grid>
            </Grid>
          </>
        )}
        <ModalDialog
          isOpen={showReassignDialog}
          title="Reassign"
          onClose={this.onModalClose}
          dialogWidth="md"
        >
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "red",
              textAlign: "right",
              fontStyle: "italic",
            }}
          >
            * All Fields Mandatory
          </Typography>

          <Typography>
            You have selected ID_
            {this.props.item ? this.props.item.var_notification_id : ""}{" "}
            {this.props.title}. Are you sure to continue?
          </Typography>
          <Grid container>
            <Grid item xs={8} pt={3}>
              <InputLabel> Select User Name </InputLabel>
              <MultiAutoComplete
                id="assignedName"
                label=""
                selected={this.props.reassignedUserData}
                values={this.reassignUserData()}
                onChange={this.props.handleReassignMultiSelectChange}
              />
            </Grid>
            <Grid item xs={12} pt={3}>
              <InputLabel> Add Comments </InputLabel>
              <TextAreaInputField
                placeholder="comments"
                minRows={3}
                id="reassignComment"
                value={reassignComment}
                onChange={this.onInputChange}
              />
            </Grid>
          </Grid>

          <Grid item container mt={4}>
            <Grid item>
              <RedOutlinedButton label="No" onClick={this.onModalClose} />
            </Grid>
            <Grid item ml={1.5}>
              <GradientButton
                disabled={
                  this.props.reassignedUserData.length === 0 ||
                  reassignComment.length === 0
                }
                isButtonLoad={isReassignButtonLoading}
                label="Yes"
                onClick={this.onReassignClick}
              />
            </Grid>
          </Grid>
        </ModalDialog>
        <ModalDialog
          isOpen={showLinkDialog}
          title="Review Link"
          onClose={this.onLinkModalClose}
          dialogWidth="md"
        >
          <TableContainer component={Paper} sx={{ maxHeight: "30rem" }}>
            <MaterialTable
              stickyHeader
              sx={{ minWidth: "fit-content" }}
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "17px",
                      borderBottom: "2px solid red",
                    }}
                  >
                    <Typography fontWeight={600}>Id</Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "17px",
                      borderBottom: "2px solid red",
                    }}
                  >
                    <Typography fontWeight={600}>Link</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {linkData.map((val: any, index: number) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell>
                        <Typography fontSize={"16px"} fontWeight={510}>
                          {val.id}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          fontSize={"16px"}
                          fontWeight={510}
                          color={"#0075a2"}
                          style={{ cursor: "pointer" }}
                          onClick={() => this.onClickLinkOpen(val.link)}
                        >
                          {val.link}
                        </Typography>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </MaterialTable>
          </TableContainer>
        </ModalDialog>
      </Box>
    );
  }
}

export default withRouter(ChatBox);
