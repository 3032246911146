import {
  Box,
  CSSObject,
  Card,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  Theme,
  styled,
} from "@mui/material";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MuiDrawer from "@mui/material/Drawer";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CustomEventType } from "../../Common/Enums";
import "../../Components/Layout/Layout.css";
import SearchFilter from "../../Components/Search/SearchFilter";
import { RoleContext } from "../../Contexts";
import { AccessedSDMTable } from "../../Models/SDMTableAccessModel";
import CustomEventHandlerService from "../../Services/CustomEventHandlerService";
import NoRecords from "../NoRecords/NoRecords";
import { CpsMenuList, reportMenuList } from "../../Common/Constants";

interface Props extends RouteComponentProps<any, any, any> {
  onMonitoringTableToggleDrawer?: (tableDrawerState: boolean) => void;
  isMonitoringDrawerOpen: boolean;
  showTableMenu: boolean;
}
interface State {
  tableDrawerOpen: boolean;
  open: boolean;
  menuSelected: string;
  roleData: any;
  accessedSdmTableData: AccessedSDMTable[];
  searchedSdmTableData: AccessedSDMTable[];
  selectedSdmTableValue: string;
  expanded: boolean;
  setExpanded: boolean;
  roles: any;
  isLoading: boolean;
  searchText: string;
  reportList: any[];
  cpsList: any[];
  setOpen: boolean;
  cpsMenuSelected: string;
}
//drawer js code starts here
const drawerWidth = 295;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.leavingScreen,
  }),

  width: /*  `calc(${theme.spacing(7)} + 1px)` */ "",
  [theme.breakpoints.up("sm")]: {
    width: /*  `calc(${theme.spacing(8)} + 1px)` */ "",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

class MonitoringDrawer extends Component<Props, State> {
  static contextType = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      tableDrawerOpen: false,
      open: false,
      menuSelected: "State-CA",
      roleData: [],
      accessedSdmTableData: [],
      searchedSdmTableData: [],
      selectedSdmTableValue: "",
      expanded: false,
      setExpanded: false,
      roles: [],
      isLoading: false,
      searchText: "",
      reportList: reportMenuList,
      cpsList: CpsMenuList,
      setOpen: false,
      cpsMenuSelected: "",
    };
  }
  async componentDidMount() {
    this.setState({
      tableDrawerOpen:
        this.props.isMonitoringDrawerOpen === true ? true : false,
    });
  }

  handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ searchText: event.target.value }, async () => {
      if (this.state.searchText.length > 0) {
        try {
          let tblResult = reportMenuList.filter((val: any) =>
            val.name.toLowerCase().includes(event.target.value.toLowerCase())
          );
          let cpsResult = CpsMenuList.filter((val: any) =>
            val.name.toLowerCase().includes(event.target.value.toLowerCase())
          );
          this.setState({
            reportList: tblResult,
            cpsList: cpsResult,
            setOpen: true,
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        this.setState({
          reportList: reportMenuList,
          cpsList: CpsMenuList,
          setOpen: false,
        });
      }
    });
  };
  handleIconClick = (item: any) => {
    this.setState({
      menuSelected: item.name,
      cpsMenuSelected: item.name,
    });
    this.props.history.push(`/monitoring`);
    CustomEventHandlerService.dispatch(CustomEventType.ChangeMonitorTable, {
      item,
    });
  };
  handleMyToolsClick = () => {
    this.setState((prevState) => ({
      setOpen: !prevState.setOpen,
    }));
  };

  renderToolMenuItems = (cpsList: any) => {
    return cpsList.map((item: any, index: number) => (
      <ListItemButton
        key={index}
        style={{
          marginLeft: "-28px",
          borderImage:
            item.name === this.state.cpsMenuSelected
              ? "linear-gradient(to bottom, #D42A1E,  #890756) 0 0 0 1"
              : "",
          borderWidth: item.name === this.state.cpsMenuSelected ? "5px" : "",
          borderStyle: item.name === this.state.cpsMenuSelected ? "solid" : "",
          background:
            item.name === this.state.cpsMenuSelected ? "#FFE7E5" : "#F4F4F4",
        }}
        onClick={() => this.handleIconClick(item)}
      >
        <ListItemText primary={item.name} />
      </ListItemButton>
    ));
  };
  renderMenuItems = (reportList: any) => {
    return reportList.map((item: any, index: number) => (
      <ListItemButton
        key={index}
        onClick={() => this.handleIconClick(item)}
        style={{
          borderImage:
            item.name === this.state.menuSelected
              ? "linear-gradient(to bottom, #D42A1E,  #890756) 0 0 0 1"
              : "",
          borderWidth: item.name === this.state.menuSelected ? "5px" : "",
          borderStyle: item.name === this.state.menuSelected ? "solid" : "",
          background:
            item.name === this.state.menuSelected ? "#FFE7E5" : "#ffffff",
        }}
      >
        <ListItemText primary={item.name} />
      </ListItemButton>
    ));
  };
  render() {
    const {
      tableDrawerOpen,
      isLoading,
      searchText,
      reportList,
      setOpen,
      cpsList,
    } = this.state;
    return (
      <Grid container item height="100%" className="TableDrawer">
        {this.props.showTableMenu ? (
          <Drawer variant="permanent" open={this.state.tableDrawerOpen}>
            <Card
              style={{
                minHeight: "100%",
                borderRadius: "6px",
              }}
            >
              <Grid item>
                <DrawerHeader>
                  <IconButton
                    onClick={() => {
                      this.setState(
                        { tableDrawerOpen: !this.state.tableDrawerOpen },
                        () => {
                          if (this.props.onMonitoringTableToggleDrawer) {
                            this.props.onMonitoringTableToggleDrawer(
                              this.state.tableDrawerOpen
                            );
                          }
                        }
                      );
                    }}
                    edge="start"
                    sx={{
                      marginLeft: "0em",
                      ...(this.state.open && { display: "none" }),
                    }}
                    style={{
                      marginRight: "-22px",
                      position: "absolute",
                    }}
                  >
                    {tableDrawerOpen ? (
                      <NavigateBeforeRoundedIcon
                        style={{
                          background: "#ffffff",
                          fontSize: "20px",
                          color: "#D42A1E",
                          marginLeft: "-5px",
                          border: "1px solid #D22A20",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <NavigateNextIcon
                        style={{
                          background: "#ffffff",
                          fontSize: "20px",
                          color: "#D42A1E",
                          marginLeft: "-5px",
                          border: "1px solid #D22A20",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </IconButton>
                </DrawerHeader>

                {tableDrawerOpen ? (
                  <>
                    {isLoading ? (
                      <Box
                        style={{
                          justifyContent: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "flex",
                          marginTop: "15vh",
                        }}
                      >
                        <CircularProgress sx={{ color: "#d52b1e" }} />
                      </Box>
                    ) : (
                      <>
                        <span
                          style={{
                            top: "285px",
                            left: "-6px",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#ffffff",
                            transform: " translateY(-50%) rotate(42deg)",
                            zIndex: "999",
                            position: "absolute",
                            boxShadow: "0px 2px 0px #00000029",
                          }}
                        ></span>

                        <List
                          style={{
                            marginTop: "-35px",
                          }}
                        >
                          <h3
                            style={{
                              paddingLeft: "10px",
                            }}
                          >
                            Report Tables
                          </h3>
                          <Box
                            className="SearchNameInput SearchTable DrawerSearch"
                            display="flex"
                            justifyContent="center"
                          >
                            <InputLabel></InputLabel>
                            <SearchFilter
                              placeholder="Search"
                              value={searchText}
                              onChange={this.handleSearchChange}
                            />
                          </Box>

                          {searchText &&
                          reportList.length <= 0 &&
                          cpsList.length <= 0 ? (
                            <>
                              <NoRecords msg="No Reports To Display" />
                            </>
                          ) : (
                            <>
                              <>
                                {cpsList.length > 0 ? (
                                  <ListItemButton
                                    onClick={this.handleMyToolsClick}
                                    className="myToolsList"
                                  >
                                    <ListItemText primary="CPS Monitoring" />
                                    {setOpen ? (
                                      <ExpandLess
                                        style={{
                                          color: "#D52B1E",
                                        }}
                                      />
                                    ) : (
                                      <ExpandMore
                                        style={{
                                          color: "#D52B1E",
                                        }}
                                      />
                                    )}
                                  </ListItemButton>
                                ) : (
                                  <></>
                                )}
                              </>
                              <Collapse
                                in={setOpen}
                                timeout="auto"
                                unmountOnExit
                                className="MyTools"
                              >
                                {this.renderToolMenuItems(cpsList)}
                              </Collapse>

                              {this.renderMenuItems(reportList)}
                            </>
                          )}
                        </List>
                      </>
                    )}
                  </>
                ) : null}
              </Grid>
            </Card>
          </Drawer>
        ) : null}
      </Grid>
    );
  }
}
export default withRouter(MonitoringDrawer);
