import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RESTClient, RESTResponse } from '../Common/RESTClient';
import Utils from '../Common/Utils';

abstract class GenericAPIService {
    private readonly _client: RESTClient;

    constructor() {
        const env = Utils.getEnvVars();
        const baseURL = env.API_URL ?? '';
        this._client = new RESTClient(baseURL);
    }

    protected async getAsync<T>(url: string, params?: AxiosRequestConfig<any>): Promise<RESTResponse<T>> {
        const response = await this._client.get<T>(url, params);
        return response;
    }

    protected async postAsync<T>(url: string, data: any): Promise<RESTResponse<T>> {
        const response = await this._client.post<T>(url, data);
        return response;
    }

    protected async putAsync<T>(url: string, data: any): Promise<RESTResponse<T>> {
        const response = await this._client.put<T>(url, data);
        return response;
    }

    protected async deleteAsync<T>(url: string): Promise<RESTResponse<T>> {
        const response = await this._client.delete<T>(url);
        return response;
    }
    protected async PutAsync<T>(url: string, headers: any, data: any): Promise<AxiosResponse<T>> {
        let config: AxiosRequestConfig = {
            baseURL: this._client.baseURL,
            headers,
        };
        
        const response = await axios.put<T>(url, data, config);
        return response;
    }

}

export default GenericAPIService;