import {
  AlertColor,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import { Component } from "react";
import { CHART_COLORS } from "../../../../Common/Constants";
import GradientButton from "../../../../Components/Button/GradientButton";
import RedButton from "../../../../Components/Button/RedButton";
import KickoutPerSourceChart from "../../../../Components/Charts/KickoutPerSourceChart";
import DatePicker from "../../../../Components/DatePicker/DatePicker";
import NoRecords from "../../../../Components/NoRecords/NoRecords";
import MultiAutoComplete from "../../../../Components/Select/MultiAutoComplete";
import SelectModel from "../../../../Components/Select/SelectModel";
import SnackbarAlert from "../../../../Components/SnackBarAlert/SnackbarAlert";
import DashboardService from "../../../../Services/DashboardService";

const DashboardServices = new DashboardService();
interface Props {
  showKickoutMonitoringDateDialog?: boolean;
  defaultSources: SelectModel[];
  defaultStartDate: string | null;
  defaultEndDate: string | null;
  sourceDropDownValues: SelectModel[];
}

interface State {
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  selectedSourceValues: SelectModel[];
  sourceMappingData: any;
  selectedStartDate?: string | null;
  selectedEndDate?: string | null;
  kickoutSeriesData: Highcharts.PointOptionsObject[];
  xAxisCategories: string[];
  isChartLoading: boolean;
  isSearchButtonLoading: boolean;
}

class KickoutPerSource extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      selectedStartDate: this.props.defaultStartDate,
      selectedEndDate: this.props.defaultEndDate,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      selectedSourceValues: this.props.defaultSources,
      sourceMappingData: [],
      kickoutSeriesData: [],
      xAxisCategories: [],
      isChartLoading: false,
      isSearchButtonLoading: false,
    };
  }
  async componentDidMount() {
    this.getSourceData();
  }

  
  handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
    if (selected.length <= 5) {
      if (targetId) {
        this.setState({ selectedSourceValues: selected }, () => {});
      }
    } else {
      this.setState({
        openAlert: true,
        alertMsg: "You can select upto 5 sources",
        alertType: "error",
      });
    }
  };
  handleEndDateChange = (value: string | null) => {
    if (this.state.selectedStartDate) {
      const startDate = new Date(this.state.selectedStartDate);
      const endDate = new Date(value!);
      const currentDate = new Date();

      const diffInDays =
        moment(moment(endDate).format("MM-DD-YYYY"), "MM-DD-YYYY", true).diff(
          moment(moment(startDate).format("MM-DD-YYYY"), "MM-DD-YYYY", true),
          "days"
        ) + 1;
      if (
        7 <= diffInDays &&
        diffInDays <= 365 &&
        startDate < endDate &&
        endDate <= currentDate
      ) {
        this.setState({
          selectedEndDate: moment(value).format("YYYY-MM-DD 23:59:59.999"),
        });
      } else {
        this.setState({
          openAlert: true,
          alertMsg:
            "The difference between the start date and end date should be between 7 and 365 days",
          alertType: "error",
          selectedEndDate: null,
        });
      }
    }
  };
  handleStartDateChange = (
    value: string | null,
    _keyboardInputValue?: string | undefined,
    _name?: string | undefined
  ) => {
    if (value) {
      this.setState({
        selectedStartDate: moment(value).format("YYYY-MM-DD"),
        selectedEndDate: null,
      });
    }
  };
  calculateMaxDate = (startDate: any) => {
    const startDateObj = new Date(startDate);
    const maxDateObj = new Date(startDateObj);
    maxDateObj.setDate(startDateObj.getDate() + 179);

    const currentDate = new Date();
    const maxDate = maxDateObj > currentDate ? currentDate : maxDateObj;
    const year = maxDate.getFullYear();
    const month = (maxDate.getMonth() + 1).toString().padStart(2, "0");
    const day = maxDate.getDate().toString().padStart(2, "0");

    // Format the maximum date as "YYYY-MM-DD"
    const maxDateFormatted = `${year}-${month}-${day}`;
    return maxDateFormatted;
  };
  onResetClick = () => {
    this.setState({
      selectedSourceValues: [],
      selectedStartDate: null,
      selectedEndDate: null,
    });
  };
  isEmptyCheck = () => {
    const { selectedSourceValues, selectedStartDate, selectedEndDate } =
      this.state;
    if (
      selectedStartDate &&
      selectedEndDate &&
      selectedSourceValues.length > 0
    ) {
      return false;
    }
    return true;
  };
  handleSearchClick = () => {
    this.getSourceData();
  };
  getSourceData = async () => {
    try {
      const { selectedEndDate, selectedStartDate, selectedSourceValues } =
        this.state;
      if (selectedStartDate && selectedEndDate && selectedSourceValues) {
        let sourceVal = selectedSourceValues
          .map((el) => el.value)
          .map((item) => "''" + item + "''")
          .join();
          let parListSourceVal = selectedSourceValues
          .map((el) => el.value)
          .join(",");
        const payloadKickOutSource: any = {
          par_group_source: 1,
          par_list: parListSourceVal,
          par_start_date: selectedStartDate,
          par_end_date: selectedEndDate,
          par_whereclause: `srcsyscd in (${sourceVal}) and CAST(avarundt as DATE) BETWEEN ''${selectedStartDate}'' and ''${selectedEndDate}''`,
          par_whereclause1: null,
        };

        let responseSource = await DashboardServices.getkickoutSourceData(
          payloadKickOutSource
        );
        this.setState({ isChartLoading: true, isSearchButtonLoading: true });
        const sources = [
          ...new Set(this.state.selectedSourceValues.map((elem) => elem.value)),
        ].sort();
        const sourceCountObj: { [index: string]: number } = {};
        responseSource.result.forEach((el) => {
          const { source, totalcount } = el;
          if (sources.includes(source)) {
            if (source in sourceCountObj) {
              sourceCountObj[source] =
                sourceCountObj[source] + Number(totalcount || 0);
            } else {
              sourceCountObj[source] = Number(totalcount || 0);
            }
          }
        });
        this.setState({
          kickoutSeriesData: sources.map((el, ind) => {
            return {
              y: sourceCountObj[el] || 0,
              color: CHART_COLORS[ind],
            };
          }),
          xAxisCategories: sources,
          isChartLoading: false,
          isSearchButtonLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const {
      alertMsg,
      openAlert,
      alertType,
      selectedSourceValues,
      sourceMappingData,
      selectedStartDate,
      selectedEndDate,
      kickoutSeriesData,
      xAxisCategories,
      isChartLoading,
      isSearchButtonLoading
    } = this.state;
    return (
      <>
        <Grid>
          <SnackbarAlert
            alertType={alertType}
            open={openAlert}
            message={alertMsg}
            onClose={() => {
              this.setState({ openAlert: false });
            }}
          />

          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "10px 5px 25px 5px",
            }}
          >
            <Grid item xs={5}>
              {" "}
              <h3>Kickouts Per Source</h3>
            </Grid>{" "}
            <Grid container columnGap={1}>
              <Grid item xs={5}>
                <InputLabel>Select Source</InputLabel>
                <MultiAutoComplete
                  id="sourceName"
                  label="Select Source"
                  selected={selectedSourceValues}
                  values={sourceMappingData}
                  onChange={this.handleMultiSelectChange}
                />
              </Grid>
              <Grid item xs={2.2} ml={0.8}>
                <InputLabel> Select Start Date</InputLabel>
                <DatePicker
                  name="startDate"
                  placeHolder="Select Date"
                  maxDate={
                    new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
                  }
                  value={selectedStartDate}
                  onChange={this.handleStartDateChange}
                />
              </Grid>
              <Grid item xs={2.2} className="EndDate" ml={0.8}>
                <InputLabel> Select End Date</InputLabel>
                <DatePicker
                  disabled={!selectedStartDate}
                  name="endDate"
                  placeHolder="Select Date"
                  minDate={new Date(selectedStartDate!)}
                  maxDate={this.calculateMaxDate(selectedStartDate)}
                  value={selectedEndDate}
                  onChange={this.handleEndDateChange}
                />
              </Grid>
              <Grid item xs={0.8} mt={3.2} ml={0.8}>
                <GradientButton
                  disabled={this.isEmptyCheck()}
                  isButtonLoad={isSearchButtonLoading}
                  label="Search"
                  onClick={this.handleSearchClick}
                />
              </Grid>
              <Grid item xs={0.8} mt={3.2} ml={1}>
                <RedButton label="Reset" onClick={this.onResetClick} />
              </Grid>
            </Grid>
          </Grid>
          {isChartLoading ? (
            <Grid
              item
              style={{
                height: "8rem",
                marginLeft: "auto",
                marginRight: "auto",
                margin: "10vh",
              }}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress
                disableShrink
                sx={{ color: "#d52b1e", marginTop: 4 }}
              />
            </Grid>
          ) : (
            <>
              {kickoutSeriesData[0]?.y !== 0 ? (
                <KickoutPerSourceChart
                  chartDataSourceArr={kickoutSeriesData}
                  xAxisCategories={xAxisCategories}
                />
              ) : (
                <Box
                  style={{
                    position: "sticky",
                    left: "50%",
                    marginTop: "25vh",
                    marginBottom: "25vh",
                  }}
                  width={"82px"}
                >
                  <NoRecords msg="No Data" />
                </Box>
              )}
            </>
          )}
        </Grid>
      </>
    );
  }
}
export default KickoutPerSource;
