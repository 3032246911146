import { URIs } from "../Common/Constants";

import { ClinicalTrialFilterModel } from "../Models/ClinicalTrialSearch";
import { ICustomerData } from "../Models/ICustomerData";
import { ICustomerFilter } from "../Models/ICustomerFilter";
import GenericAPIService from "./GenericAPIService";


const { CLINICAL_TRIAL_SEARCH,CUSTOMER_SEARCH } = URIs
class SearchService extends GenericAPIService {

    async clinicalTrialSearch(filterObj: ClinicalTrialFilterModel, pageNo: number, noOfRecords: number) {
        const res = await this.postAsync<{ data: { trialData: any, totalRecords: number } }>(CLINICAL_TRIAL_SEARCH, { filterObj, pageNo, noOfRecords });
        return res;
    }
    async customerSearch(type: string, filters: Omit<ICustomerFilter, 'type'>, noOfRecords: number, pageNo: number) {
        const res = await this.postAsync<{ data: {data: ICustomerData[], totalCount: number} }>(
          CUSTOMER_SEARCH,
          { type, filters, noOfRecords, pageNo }
        );
        return res;
      }


}

export default SearchService;