import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import FileUploadDetailModel from "../../../Components/File/FileUploadDetailModel";
import SelectModel from "../../../Components/Select/SelectModel";
import ExcelService from "../../../Services/ExcelService";
import MissingTransactionService from "../../../Services/MissingTransactionServices";
import TransactionsFileUpload from "./TransactionsFileUpload";
import { Alert, Snackbar, SnackbarOrigin, Typography } from "@mui/material";
import { FileType } from "../../../Common/Enums";
import ModalDialog from "../../../Components/Modal/ModelDialog";
import * as XLSX from "xlsx";
import _ from "lodash";
const MissingTransactionServices = new MissingTransactionService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State extends SnackbarOrigin {
  isTableLoading: boolean;
  tblData: any[];
  selectedSourceValue: string;
  sourceMappingData: any;
  sourceWithFieldsData: any;
  searchFields: any;
  isPageLoading: boolean;
  uploadMessage: string;
  isSuccess: boolean;
  files: FileUploadDetailModel[];
  totalRecordNo: number | null;
  isRefreshTableData: boolean;
  isError: boolean;
  isLoading: boolean;
  isRefresh: boolean;
  fileData: any;
  isEnabled: boolean;
  rows: number;
  page: number;
  isPaginationReset: boolean;
  filesInfo: any;
}
class TransactionUploadFile extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      isTableLoading: false,
      tblData: [],
      selectedSourceValue: "",
      sourceMappingData: {},
      sourceWithFieldsData: [],
      searchFields: [],
      isPageLoading: false,
      uploadMessage: "",
      isSuccess: false,
      files: [],
      totalRecordNo: null,
      isRefreshTableData: false,
      vertical: "top",
      horizontal: "center",
      isError: false,
      isLoading: false,
      isRefresh: false,
      fileData: [],
      isEnabled: false,
      rows: 5,
      page: 0,
      isPaginationReset: false,
      filesInfo: [],
    };
  }
  async componentDidMount() {
    this.setState({ isPageLoading: true });
    const response = await MissingTransactionServices.getSourceMappingData();
    const dataArr = response.data.data.map((el) => {
      return {
        ...el,
        searchFields: el.searchFields.replace(/[{}"]/g, "").split(","),
      };
    });
    let result: any = {};

    dataArr.forEach((item) => {
      if (!result[item.sourceType]) {
        result[item.sourceType] = [];
      }
      let fieldExists = result[item.sourceType].some(
        (el: any) => el.text === item.sourceName
      );
      if (!fieldExists) {
        result[item.sourceType].push({
          text: item.sourceName,
          value: item.sourceName,
        });
      }
    });
    this.setState({
      sourceMappingData: result,
      sourceWithFieldsData: dataArr,
      isPageLoading: false,
      filesInfo: response.data.data.map((el) => el.sourceName),
    });
  }
  handleExportTemplate = () => {
    const { sourceWithFieldsData, selectedSourceValue } = this.state;

    const sourceFields = sourceWithFieldsData.find(
      (ele: any) =>
        ele.sourceName.toLowerCase() === selectedSourceValue.toLowerCase()
    );

    ExcelService.CreateTemplate(selectedSourceValue, sourceFields.searchFields);
  };
  handleUploadExcelDownload = () => {
    const { tblData, selectedSourceValue, searchFields } = this.state;
    const searchResult: any = [];
    const tableHeaders = Object.keys(tblData[0]).filter(
      (el) => !el.includes("param")
    );
    searchFields.forEach((ele: any) => tableHeaders.unshift(ele));
    const fileHeaders = tableHeaders.map((val) =>
      val.includes("info") ? _.startCase(val.replace("info", " info")) : val
    );
    tblData.forEach((obj) => {
      Object.keys(obj).map((el) => {
        if (el.includes("param") && !obj[el]) {
          delete obj[el];
        }
        return el;
      });

      const allValues = Object.values(obj);
      searchResult.push(allValues);
    });
    ExcelService.CreateDataFile(
      `${selectedSourceValue}_Result_Data`,
      searchResult,
      fileHeaders
    );
  };
  handleSelectSourceChange = (selected: SelectModel) => {
    const { sourceWithFieldsData } = this.state;
    let fieldsArr = sourceWithFieldsData.find(
      (el: any) => el.sourceName === selected.value
    ).searchFields;

    this.setState({
      selectedSourceValue: selected.value,
      searchFields: fieldsArr,
      isEnabled: true,
      page: 0,
      rows: 5,
      tblData: [],
    });
  };
  onChangePage = (page: number) => {
    this.setState({ page: page - 1 });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row }, () => {
      this.setState({
        page: this.state.page > 0 ? 0 : this.state.page,
        isPaginationReset: !this.state.isPaginationReset,
      });
    });
  };
  render() {
    const {
      isTableLoading,
      selectedSourceValue,
      isPageLoading,
      sourceMappingData,
      searchFields,
      tblData,
      sourceWithFieldsData,
      isError,
      isRefresh,
      isLoading,
      isSuccess,
      uploadMessage,
      isEnabled,
      rows,
      page,
      isPaginationReset,
      filesInfo,
    } = this.state;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: this.state.vertical,
            horizontal: this.state.horizontal,
          }}
          open={isSuccess}
          autoHideDuration={7000}
          onClose={this.handleClose}
          style={{ marginTop: 50 }}
        >
          {isSuccess ? (
            <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
              {uploadMessage}
            </Alert>
          ) : (
            <span></span>
          )}
        </Snackbar>
        <ModalDialog
          isOpen={isError}
          title={"File Upload Error"}
          onClose={this.handleClose}
          dialogWidth="sm"
        >
          <Typography>{isError ? uploadMessage : ""}</Typography>
        </ModalDialog>
        <TransactionsFileUpload
          filesInfo={filesInfo}
          isEnabled={isEnabled}
          isDisabled={this.state.files.length > 0 ? false : true}
          onDelete={this.handleDeleteClick}
          files={this.state.files}
          onChange={this.handleFileUpload}
          handleExportTemplate={this.handleExportTemplate}
          handleSelectSourceChange={this.handleSelectSourceChange}
          handleUploadExcelDownload={this.handleUploadExcelDownload}
          isPageLoading={isPageLoading}
          isTableLoading={isTableLoading}
          onChangePage={this.onChangePage}
          onChangeRow={this.onChangeRow}
          searchFields={searchFields}
          selectedSourceValue={selectedSourceValue}
          sourceMappingData={sourceMappingData}
          sourceWithFieldsData={sourceWithFieldsData}
          tblData={
            rows > 0 ? tblData.slice(page * rows, page * rows + rows) : tblData
          }
          totalRecordsCount={tblData.length}
          isRefresh={isRefresh}
          isLoading={isLoading}
          onSubmitClick={this.handleSubmit}
          isPaginationReset={isPaginationReset}
        />
      </div>
    );
  }

  handleFileUpload = (file: FileUploadDetailModel) => {
    const fileData = file.file;
    if (fileData) {
      this.getDataFromExcel(fileData);
    }
  };
  getDataFromExcel = async (file: File) => {
    this.setState({ isLoading: true });
    this.setState({ isLoading: true });
    const isValidFile = [`${FileType.XLSX}`, `${FileType.CSV}`].includes(
      file.type
    );
    if (!isValidFile) {
      this.handleFileError(
        "File format is invalid. Please convert the file to excel (.xlsx or .csv) and resubmit the file."
      );
    } else if (
      file.name.toLowerCase().split(".")[0] !==
      this.state.selectedSourceValue.toLowerCase()
    ) {
      this.handleFileError(
        "The selected file is not allowed. Please upload a file from the same source."
      );
    } else if (file.size >= 10485760) {
      this.handleFileError("File size should not exceed more than 10 mb.");
    } else {
      this.readFileContents(file);
    }
  };

  handleFileError = (errorMessage: string) => {
    this.setState({ isError: true, uploadMessage: errorMessage }, () => {
      this.setState({ isLoading: false });
    });
  };

  readFileContents = (file: File) => {
    const reader = new FileReader();

    reader.onload = async (evt) => {
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data: any = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
      });
      const sourceFields = this.state.sourceWithFieldsData.find(
        (ele: any) =>
          ele.sourceName.toLowerCase() ===
          this.state.selectedSourceValue.toLowerCase()
      );

      let isColumnMismatchA = _.isEqual(
        sourceFields.searchFields.sort(),
        data[0].sort()
      )
        ? false
        : true;
      let isColumnMismatchB = data[0].length > 0 ? false : true;
      let isFieldMatch = ["Concur", "CVENT"].includes(
        this.state.selectedSourceValue
      )
        ? isColumnMismatchA
        : isColumnMismatchB;
      const filess: FileUploadDetailModel[] = [
        {
          fileName: file.name,
          totalRecords: data.length - 1,
          status: "",
          sizeInKB: file.size,
          sizeInMB: 0,
          ext: "",
          file: file,
          type: FileType.XLSX,
          fileType: file.name.split(".")[0],
        },
      ];

      let isError = false;
      let uploadMessage = "";
      const isValidFile = [`${FileType.XLSX}`, `${FileType.CSV}`].includes(
        file.type
      );

      if (!isValidFile) {
        isError = true;
        uploadMessage =
          "File format is invalid. Please convert the file to excel (.xlsx or .csv) and resubmit the file.";
      } else if (data.length < 2) {
        isError = true;
        uploadMessage =
          "Submitted file is empty. Please upload a file with valid data and correct format.";
      } else if (data.length - 1 > 500) {
        isError = true;
        uploadMessage =
          "Maximum number of rows exceeded in the submitted file. Maximum 500 rows/records per file is allowed.";
      } else if (isFieldMatch) {
        isError = true;
        uploadMessage =
          "Column heading(s) does not match the template. Please refer to the excel template for the correct column headers and resubmit the file.";
      } else {
        isError = false;
        uploadMessage = "File ready for upload, submit the selected file.";
      }

      if (isError) {
        this.handleFileError(uploadMessage);
      } else {
        this.setState(
          {
            files: filess,
            totalRecordNo: data.length - 1,
            fileData: data,
            isSuccess: true,
            uploadMessage,
            isEnabled: false,
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
      }
    };

    reader.readAsBinaryString(file);
  };

  handleDeleteClick = (file: FileUploadDetailModel) => {
    if (file) {
      const fltrdArr = this.state.files.filter(
        (arr) => arr.fileName !== file.fileName
      );
      this.setState({ files: fltrdArr, isEnabled: true });
    }
  };
  handleClose = () => {
    this.setState({
      isError: false,
      isSuccess: false,
    });
  };
  handleSubmit = async () => {
    try {
      const { fileData, selectedSourceValue } = this.state;
      this.setState({ isLoading: true });
      // Remove the first element
      fileData.shift();

      // Transform the array
      let newArray = fileData.map((item: any, index: number) => {
        let obj: any = {};
        item.forEach((subItem: any, subIndex: number) => {
          obj[`param${subIndex}`] = subItem;
        });
        obj.sourcename = selectedSourceValue;
        return obj;
      });

      if (newArray) {
        const payLoad = {
          missing_transaction_report_payload: newArray,
        };
        const response =
          await MissingTransactionServices.getMissingTransactionReport(payLoad);
        if (JSON.parse(response.result)) {
          this.setState({
            tblData: JSON.parse(response.result),
            isLoading: false,
            files: [],
            fileData: [],
            isEnabled: true,
            page: 0,
            rows: 5,
          });
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
        isError: true,
        uploadMessage: "Something went wrong.",
        isTableLoading: false,
      });
    }
  };
}

export default withRouter(TransactionUploadFile);
