import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { RoleContext } from '../../Contexts';
import { RolesModel } from '../../Models/RolesModel'
import AuthProviderService from '../../Services/AuthProviderService';
import BackdropLoaderCircular from '../Loader/BackdropLoaderCircular';

interface Props{ }

interface State {
    userRoles: RolesModel[];
    isLoading: boolean;
    redirectPath: string | undefined;
}

class DynamicRedirect extends Component<Props, State> {
    static contextType = RoleContext;
    context!: React.ContextType<typeof RoleContext>;

    constructor(props: Props) {
        super(props)

        this.state = {
            userRoles: [],
            isLoading: true,
            redirectPath: undefined
        }

    }
    componentDidMount(): void {
        AuthProviderService.isLoggedIn().then(res => {
            if(!res) {
                AuthProviderService.login()
            } else {
                const redirectPath = AuthProviderService.getCallbackPath();
                this.setState({userRoles: this.context.userRoles, isLoading: false, redirectPath});
            }
        })
    }

    render() {
        const {userRoles, isLoading, redirectPath} = this.state;
        if(isLoading) {
            return <BackdropLoaderCircular show={true} style={{ backgroundColor: '#FFFFFF' }} />;
        }

        if(redirectPath?.length && redirectPath !== '/') {
            return <Redirect to={redirectPath} />
        }

        if(userRoles.includes("SUPER_USER")){
            return <Redirect to='/dashboard' />
        }

        return <Redirect to='/dashboard' />

    }
}

export default DynamicRedirect