import { CustomEventType } from "../Common/Enums";
import CustomEventHandler from "../Handlers/CustomEventHandler";

class CustomEventHandlerService {
    dispatch(eventType: CustomEventType, data?: any): void {
        const customEvent = CustomEventHandler(eventType, data);
        document.dispatchEvent(customEvent as Event);
    }
}

export default new CustomEventHandlerService();