import {
  Box,
  CSSObject,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Theme,
  Typography,
  styled,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiDrawer from "@mui/material/Drawer";
import _ from "lodash";
import { Component, SyntheticEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CustomEventType } from "../../Common/Enums";
import { RoleContext } from "../../Contexts";
import { AccessedSDMTable } from "../../Models/SDMTableAccessModel";
import CustomEventHandlerService from "../../Services/CustomEventHandlerService";
import NoRecords from "../NoRecords/NoRecords";
import SearchFilter from "../Search/SearchFilter";
import "./Layout.css";

interface Props extends RouteComponentProps<any, any, any> {
  onTableToggleDrawer?: (tableDrawerState: boolean) => void;
  TableDrawerOpenMyTools?: boolean;
  showTableMenu: boolean;
  isTableDrawerOpen: boolean;
  /* handleSearchChange : (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=> void; */
}
interface State {
  tableDrawerOpen: boolean;
  open: boolean;
  menuSelected: string;
  roleData: any;
  accessedSdmTableData: AccessedSDMTable[];
  searchedSdmTableData: AccessedSDMTable[];
  selectedSdmTableValue: string;
  expanded: boolean;
  setExpanded: boolean;
  roles: any;
  isLoading: boolean;
  searchText: string;
}
//drawer js code starts here
const drawerWidth = 295;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.leavingScreen,
  }),

  width: /*  `calc(${theme.spacing(7)} + 1px)` */ "",
  [theme.breakpoints.up("sm")]: {
    width: /*  `calc(${theme.spacing(8)} + 1px)` */ "",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowDropDownIcon
        sx={{ fontSize: "0.9rem" }}
        style={{
          color: "#D42A1F",
          fontSize: "20px",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

class TableDrawer extends Component<Props, State> {
  static contextType = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      tableDrawerOpen: false,
      open: false,
      menuSelected: "",
      roleData: [],
      accessedSdmTableData: [],
      searchedSdmTableData: [],
      selectedSdmTableValue: "",
      expanded: false,
      setExpanded: false,
      roles: [],
      isLoading: false,
      searchText: "",
    };
  }
  async componentDidMount() {
    this.setState({
      isLoading: true,
      tableDrawerOpen: this.props.isTableDrawerOpen === true ? true : false,
    });
    if (this.context.accessedTables.length > 0) {
      
      const data = this.context.accessedTables.map((el: any) => {
        return {
          roleAssigned: el.roleAssigned,
          tables: el.tables.map((val: any) => {
            return { text: val, value: val };
          }),
          isExpand: false,
        };
      });
      const roleData = data.map((val: any) => {
        return { role: val.roleAssigned, isExpand: false };
      });
      let defaultTable = data
        .map((el: any) => el.tables)
        .map((el: any) => el[0])[0].text;

      this.setState(
        {
          accessedSdmTableData: data,
          roleData,
          selectedSdmTableValue: defaultTable,
        },
        () => {
          const sdmTableValue = localStorage.getItem("sdmTable");
          const role = localStorage.getItem("Role");
          if (sdmTableValue && role) {
            this.handleMenuChange(sdmTableValue, role);
          } else {
            this.handleMenuChange(defaultTable, data[0].roleAssigned);
          }
          this.setState({ isLoading: false });
        }
      );
    }
  }
  handleExpandChange = (role: string) => {
    const { accessedSdmTableData } = this.state;

    const newRoleData = accessedSdmTableData.map((el: any) => {
      if (el.roleAssigned === role) {
        return { ...el, isExpand: !el.isExpand };
      } else {
        return { ...el, isExpand: false };
      }
    });

    this.setState({ accessedSdmTableData: newRoleData });
  };

  handleIconClick = (event: any) => {
    this.setState({
      menuSelected: event.currentTarget.textContent,
    });
  };
  handleMenuChange = async (value: string, role: string) => {
    this.setState({ selectedSdmTableValue: value });
    /* this.props.handleSearchChange; */
    localStorage.setItem("sdmTable", value);
    localStorage.setItem("Role", _.startCase(_.lowerCase(role)));
    this.props.history.push(`/sdm`);
    const roleValue = _.startCase(_.lowerCase(role));
    CustomEventHandlerService.dispatch(CustomEventType.ChangeTable, {
      value,
      roleValue,
    });
  };
  handleChange = (event: SyntheticEvent, isExpanded: boolean) => {
    this.setState({
      expanded: isExpanded,
    });
  };
  handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ searchText: event.target.value }, async () => {
      if (this.state.searchText.length > 0) {
        try {
          const recursiveSdmData = this.state.accessedSdmTableData.map(
            (tbl: any, i) => {
              let tblResult = tbl.tables.filter((val: any) => {
                if (
                  val.text
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
                )
                  return val;
              });
              if (tblResult.length > 0) {
                return {
                  ...tbl,
                  tables: [...tblResult],
                  isExpand: true,
                };
              } else {
                return {
                  ...tbl,
                  tables: [...tblResult],
                };
              }
            }
          );
          const filterData = recursiveSdmData.filter((data) => {
            return data.tables.length > 0;
          });
          this.setState({
            searchedSdmTableData: [...filterData],
          });
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  render() {
    const {
      tableDrawerOpen,
      accessedSdmTableData,
      selectedSdmTableValue,
      isLoading,
      searchText,
      searchedSdmTableData,
    } = this.state;
    return (
      <Grid container item height="100%" className="TableDrawer">
        {this.props.showTableMenu ? (
          <Drawer variant="permanent" open={this.state.tableDrawerOpen}>
            <Card
              style={{
                minHeight: "100%",
                borderRadius: "6px",
              }}
            >
              <Grid item>
                <DrawerHeader>
                  <IconButton
                    onClick={() => {
                      this.setState(
                        { tableDrawerOpen: !this.state.tableDrawerOpen },
                        () => {
                          if (this.props.onTableToggleDrawer) {
                            this.props.onTableToggleDrawer(
                              this.state.tableDrawerOpen
                            );
                          }
                        }
                      );
                    }}
                    edge="start"
                    sx={{
                      marginLeft: "0em",
                      ...(this.state.open && { display: "none" }),
                    }}
                    style={{
                      marginRight: "-22px",
                      position: "absolute",
                    }}
                  >
                    {tableDrawerOpen ? (
                      <NavigateBeforeRoundedIcon
                        style={{
                          background: "#ffffff",
                          fontSize: "20px",
                          color: "#D42A1E",
                          marginLeft: "-5px",
                          border: "1px solid #D22A20",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <NavigateNextIcon
                        style={{
                          background: "#ffffff",
                          fontSize: "20px",
                          color: "#D42A1E",
                          marginLeft: "-5px",
                          border: "1px solid #D22A20",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </IconButton>
                </DrawerHeader>

                {tableDrawerOpen ? (
                  <>
                    {isLoading ? (
                      <Box
                        style={{
                          justifyContent: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "flex",
                          marginTop: "15vh",
                        }}
                      >
                        <CircularProgress sx={{ color: "#d52b1e" }} />
                      </Box>
                    ) : (
                      <>
                        <span
                          style={{
                            top: "190px",
                            left: "-6px",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#ffffff",
                            transform: " translateY(-50%) rotate(42deg)",
                            zIndex: "999",
                            position: "absolute",
                            boxShadow: "0px 2px 0px #00000029",
                          }}
                        ></span>

                        <List
                          style={{
                            marginTop: "-35px",
                          }}
                        >
                          <h3
                            style={{
                              paddingLeft: "10px",
                            }}
                          >
                            SDM Tables
                          </h3>
                          <Box
                            className="SearchNameInput SearchTable DrawerSearch"
                            display="flex"
                            justifyContent="center"
                          >
                            <InputLabel></InputLabel>
                            <SearchFilter
                              placeholder="Search"
                              value={searchText}
                              onChange={this.handleSearchChange}
                            />
                          </Box>
                          {searchText && searchedSdmTableData.length <= 0 ? (
                            <>
                              <NoRecords msg="No Tables To Display" />
                            </>
                          ) : (
                            (searchText
                              ? searchedSdmTableData
                              : accessedSdmTableData
                            ).map((el: any, index: any) => {
                              return (
                                <Accordion
                                  key={index}
                                  expanded={el.isExpand}
                                  onChange={() =>
                                    this.handleExpandChange(el.roleAssigned)
                                  }
                                >
                                  <AccordionSummary
                                    style={{
                                      background: "#ffffff",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontSize: "0.95rem",
                                      }}
                                    >
                                      {el.roleAssigned}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    style={{
                                      background: "#FFFAFA",
                                      overflowY: "auto",
                                      maxHeight: "319px",
                                      overflowX: "hidden",
                                      padding: "0",
                                    }}
                                  >
                                    {el.isExpand && (
                                      <List>
                                        {el.tables.map((p: any) => (
                                          <ListItem
                                            key={p.value}
                                            disablePadding
                                            sx={{
                                              fontSize: "0.8rem",
                                              backgroundColor:
                                                selectedSdmTableValue ===
                                                p.value
                                                  ? "#FFE7E5"
                                                  : "#ffffff",
                                              color:
                                                selectedSdmTableValue ===
                                                p.value
                                                  ? "red"
                                                  : "#000000",
                                              borderImage:
                                                selectedSdmTableValue ===
                                                p.value
                                                  ? "linear-gradient(to bottom, #D42A1E,  #890756) 0 0 0 1"
                                                  : "",
                                              borderWidth:
                                                selectedSdmTableValue ===
                                                p.value
                                                  ? "5px"
                                                  : "",
                                              borderStyle:
                                                selectedSdmTableValue ===
                                                p.value
                                                  ? "solid"
                                                  : "",
                                              ".MuiListItemText-primary": {
                                                fontSize: "0.9rem",
                                              },
                                            }}
                                          >
                                            <ListItemButton
                                              onClick={() =>
                                                this.handleMenuChange(
                                                  p.value,
                                                  el.roleAssigned
                                                )
                                              }
                                            >
                                              <ListItemText primary={p.value} />
                                            </ListItemButton>
                                          </ListItem>
                                        ))}
                                      </List>
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              );
                            })
                          )}
                        </List>
                      </>
                    )}
                  </>
                ) : null}
              </Grid>
            </Card>
          </Drawer>
        ) : null}
      </Grid>
    );
  }
}
export default withRouter(TableDrawer);
