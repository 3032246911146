import { URIs } from "../Common/Constants";
import {
  ExceptionLinkMappingModel,
  ExceptionReportDataAndCountModel,
  ExceptionReportDataModel,
  ExceptionReportSaveDataModel,
} from "../Models/ExceptionReportModel";

import GenericAPIService from "./GenericAPIService";

const {
  EXCEPTION_REPORTING_COUNT,
  EXCEPTION_REPORTING_DATA,
  EXCEPTION_REPORTING_UPDATE,
  EXCEPTION_LINK_MAPPING,EXCEPTION_ASSIGNED_USER
} = URIs;
class ExceptionReportingSevice extends GenericAPIService {
  async getDataAndCount(body: ExceptionReportDataAndCountModel) {
    const { data } = await this.postAsync<{ result: any }>(
      EXCEPTION_REPORTING_COUNT,
      body
    );
    return data;
  }
  async getSubTableData(body: ExceptionReportDataModel) {
    const { data } = await this.postAsync<{ result: any }>(
      EXCEPTION_REPORTING_DATA,
      body
    );
    return data;
  }
  async saveData(body: ExceptionReportSaveDataModel) {
    const { data } = await this.postAsync<{ result: any }>(
      EXCEPTION_REPORTING_UPDATE,
      body
    );
    return data;
  }
  async getLinkMappingData(body: ExceptionLinkMappingModel) {
    try {
      const { data } = await this.postAsync<{ data: { data: any } }>(
        EXCEPTION_LINK_MAPPING,
        body
      );

      return data;
    } catch (error) {
      console.log("error in fetching data", error);
    }
  }
  async getExceptionAssignedUserData() {
    const data = await this.getAsync<{ result: any }>(EXCEPTION_ASSIGNED_USER);
    return data;
  }
}

export default ExceptionReportingSevice;
