import AddIcon from "@mui/icons-material/Add";
import {
  AlertColor,
  Box,
  Grid,
  InputLabel,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FileType } from "../../Common/Enums";
import GradientButton from "../../Components/Button/GradientButton";
import RedOutlinedButton from "../../Components/Button/RedOutlinedButton";
import CardContainer from "../../Components/Card/CardContainer";
import FileUploadDetailModel from "../../Components/File/FileUploadDetailModel";
import ImageUpload from "../../Components/ImageUpload";
import PageHeader from "../../Components/Text/PageHeader";
import TextInputField from "../../Components/TextInputField/TextInputField";

import { createMyLinkAppModel } from "../../Models/MyLinksModel";

import { ImageOutlined } from "@mui/icons-material";
import _ from "lodash";
import { IconType } from "../../Components/Button/IconButton";
import SwitchButton from "../../Components/Button/Switchbutton";
import ModalDialog from "../../Components/Modal/ModelDialog";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import MyLinksTable from "../../Components/Table/MyLinksTable";
import MyLinkAppService from "../../Services/MyLinkAppService";
const MyLinkAppServices = new MyLinkAppService();
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 16,
    zIndex: 999999,
    top: "10px",
    "& .MuiTooltip-arrow": {
      color: "000000",
    },
  },
}));

interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  addLinksContent: boolean;
  myLinksFormData: createMyLinkAppModel;
  searchText: string;
  tblData: any;
  showDeleteDialog: boolean;
  showEditDialog: boolean;
  switchOn: boolean;
  imgString: string | null;
  isButtonLoading: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  file: File | null;
  imagePreview: string | null;
  responseData: any;
  isLoading: boolean;
  isUpdateButtonLoading: boolean;
  isDeleteButtonLoading: boolean;
}
class MyLinks extends Component<Props, State> {
  private timer?: NodeJS.Timeout = undefined;
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      addLinksContent: false,
      myLinksFormData: {} as createMyLinkAppModel,
      searchText: "",
      tblData: [],
      showDeleteDialog: false,
      showEditDialog: false,
      switchOn: false,
      file: null,
      imagePreview: "",
      imgString: "",
      isButtonLoading: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      isLoading: false,
      responseData: {},
      isUpdateButtonLoading: false,
      isDeleteButtonLoading: false,
    };
  }
  async componentDidMount() {
    try {
      this.getAllAppData();
    } catch (error) {
      console.log(error);
    }
  }
  getAllAppData = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await MyLinkAppServices.getallMyLinkAppData();
      if (response?.data.data) {
        const myLinkAppsData = response.data.data.map((el: any) => {
          return {
            id: el.id,
            name: el.appName,
            icon: el.img ? (
              <img
                src={el.img}
                style={{
                  verticalAlign: "middle",
                  height: el.appName.toLowerCase() === "sap" ? "20px" : "36px",
                  width: el.appName.toLowerCase() === "sap" ? "40px" : "36px",
                }}
                alt=""
              />
            ) : (
              <ImageOutlined
                style={{
                  verticalAlign: "middle",
                  height: "36px",
                  width: "36px",
                  color: "#999999",
                }}
              />
            ),
            url: el.appUrl,
            imgPath: el.img,
            display: el.isDisplay,
            description: el.appDescription,
            isDisabled: false,
          };
        });
        this.setState({
          tblData: myLinkAppsData.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          ),
          isLoading: false,
        });
        const checkedData = myLinkAppsData.filter(
          (el: any) => el.display === true
        );
        sessionStorage.setItem("myLinkData", JSON.stringify(checkedData));
      }
    } catch (error) {
      console.log(error);
    }
  };
  getAllUpdatedAppData = async () => {
    try {
      const response = await MyLinkAppServices.getallMyLinkAppData();
      if (response?.data.data) {
        const myLinkAppsData = response.data.data.map((el: any) => {
          return {
            id: el.id,
            name: el.appName,
            icon: el.img ? (
              <img
                src={el.img}
                style={{
                  verticalAlign: "middle",
                  height: el.appName.toLowerCase() === "sap" ? "20px" : "36px",
                  width: el.appName.toLowerCase() === "sap" ? "40px" : "36px",
                }}
                alt=""
              />
            ) : (
              <ImageOutlined
                style={{
                  verticalAlign: "middle",
                  height: "36px",
                  width: "36px",
                  color: "#999999",
                }}
              />
            ),
            url: el.appUrl,
            imgPath: el.img,
            display: el.isDisplay,
            description: el.appDescription,
            isDisabled: false,
          };
        });
        this.setState({
          tblData: myLinkAppsData.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          ),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  handlAddClick = () => {
    this.setState({
      addLinksContent: true,
    });
  };

  handleChange = () => {
    this.setState((prevState) => ({
      switchOn: !prevState.switchOn,
    }));
    if (this.state.switchOn === false) {
      this.state.tblData.filter((el: any) => el);
    }
  };
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "name") {
      if (event.target.value.length < 101)
        this.setState({
          myLinksFormData: {
            ...this.state.myLinksFormData,
            [event.target.name]: event.target.value,
          },
        });
    } else if (event.target.name === "description") {
      if (event.target.value.length < 501) {
        this.setState({
          myLinksFormData: {
            ...this.state.myLinksFormData,
            [event.target.name]: event.target.value,
          },
        });
      }
    } else {
      this.setState({
        myLinksFormData: {
          ...this.state.myLinksFormData,
          [event.target.name]: event.target.value,
        },
      });
    }
  };
  handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        const { name, size, type } = file;
        this.setState({
          imgString: fileReader.result?.toString() ?? null,
          file: file,
          imagePreview: e.target?.result?.toString() ?? null,
        });
        const fileModel = new FileUploadDetailModel();
        fileModel.fileName = name;
        fileModel.sizeInKB = Math.round(size / 500);
        fileModel.sizeInMB = Math.round(size / 500 / 500);

        fileModel.type = type as FileType;
        fileModel.ext = type.replace(/(.*)\//g, "");
        fileModel.file = file;
      };
    }
  };
  onSaveClick = async () => {
    try {
      this.setState({ isButtonLoading: true });
      const { myLinksFormData, imgString } = this.state;

      const response = await MyLinkAppServices.createMyLinkAppData({
        ...myLinksFormData,
        imgPath: imgString,
        isDisplay: false,
      });

      if (response?.message) {
        this.setState(
          {
            myLinksFormData: {} as createMyLinkAppModel,
            imgString: null,
            file: null,
            imagePreview: null,
            isButtonLoading: false,
            openAlert: true,
            addLinksContent: false,
            alertMsg: "App data created successfully.",
            alertType: "success",
          },
          () => {
            this.getAllAppData();
          }
        );
      } else {
        this.setState({
          isButtonLoading: false,
          openAlert: true,
          alertMsg: "Something went wrong",
          alertType: "error",
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isButtonLoading: false,
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  onEditClick = async () => {
    try {
      this.setState({ isUpdateButtonLoading: true });
      const { myLinksFormData, imgString, imagePreview, responseData } =
        this.state;
      const formData: any = myLinksFormData;
      const differenceObj: any = {};

      for (let key in responseData) {
        if (responseData[key] !== formData[key]) {
          if (key === "name") {
            differenceObj["appName"] = formData[key];
          }
          if (key === "url") {
            differenceObj["appUrl"] = formData[key];
          }
          if (key === "description") {
            differenceObj["appDescription"] = formData[key];
          }
        }
      }
      const isImgAdded = _.isEqual(responseData.imgPath, imagePreview);
      const payloadObj = {
        setFields: isImgAdded
          ? differenceObj
          : {
              ...differenceObj,
              img: imgString ? imgString : imagePreview,
            },
        id: { id: myLinksFormData.id },
      };
      const response = await MyLinkAppServices.updateMyLinkAppData(payloadObj);

      if (response?.message) {
        this.setState(
          {
            myLinksFormData: {} as createMyLinkAppModel,
            imgString: null,
            file: null,
            imagePreview: null,
            isUpdateButtonLoading: false,
          },
          () => {
            this.getAllAppData();
            this.setState({
              showEditDialog: false,
              openAlert: true,
              alertMsg: "App data updated successfully.",
              alertType: "success",
            });
          }
        );
      } else {
        this.setState({
          isUpdateButtonLoading: false,
          openAlert: true,
          alertMsg: "Something went wrong",
          alertType: "error",
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isUpdateButtonLoading: false,
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };

  handleSubmit = (mode: string) => {
    switch (true) {
      case mode === "save":
        this.onSaveClick();
        break;
      case mode === "edit":
        this.onEditClick();
        break;

      default:
        break;
    }
  };

  onDeleteClick = async () => {
    try {
      this.setState({ isDeleteButtonLoading: true });
      const payloadObj = {
        id: this.state.responseData.id,
      };
      const response = await MyLinkAppServices.deleteMyLinkAppData(payloadObj);
      if (response?.message) {
        this.setState(
          {
            responseData: {},
            isDeleteButtonLoading: false,
          },
          () => {
            this.getAllAppData();
            this.setState({
              showDeleteDialog: false,
              openAlert: true,
              alertMsg: "App data deleted successfully.",
              alertType: "success",
            });
          }
        );
      } else {
        this.setState({
          showDeleteDialog: false,
          isDeleteButtonLoading: false,
          openAlert: true,
          alertMsg: "Something went wrong",
          alertType: "error",
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        showDeleteDialog: false,
        isDeleteButtonLoading: false,
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.setState(
      {
        searchText: event.target.value,
        isLoading: true,
      },
      async () => {
        this.timer = setTimeout(async () => {
          if (this.state.searchText.length > 0) {
            try {
              const response = await MyLinkAppServices.searchMyLinkAppData({
                searchQuery: this.state.searchText.trim(),
              });
              if (response?.data.data) {
                const myLinkAppsData = response.data.data.map((el: any) => {
                  return {
                    id: el.id,
                    name: el.appName,
                    icon: el.img ? (
                      <img
                        src={el.img}
                        style={{
                          verticalAlign: "middle",
                          height: "36px",
                          width: "36px",
                        }}
                        alt=""
                      />
                    ) : (
                      <ImageOutlined
                        style={{
                          verticalAlign: "middle",
                          height: "36px",
                          width: "36px",
                          color: "#999999",
                        }}
                      />
                    ),
                    url: el.appUrl,
                    imgPath: el.img,
                    display: el.isDisplay,
                    description: el.appDescription,
                    isDisabled: false,
                  };
                });
                this.setState({
                  tblData: myLinkAppsData.sort((a: any, b: any) =>
                    a.name.localeCompare(b.name)
                  ),
                  isLoading: false,
                });
              }
            } catch (error) {
              console.log("error", error);
            }
          } else {
            this.setState(
              {
                isLoading: false,
              },
              () => {
                this.getAllAppData();
              }
            );
          }
        }, 1000);
      }
    );
  };
  onActionClick = (action: IconType, record: any) => {
    switch (action) {
      case "Edit":
        const editFormData = this.state.tblData.filter(
          (el: any) => el.id === record.id
        );
        this.setState({
          showEditDialog: true,
          myLinksFormData: editFormData[0],
          responseData: editFormData[0],
          imagePreview: editFormData[0].imgPath,
        });
        break;
      case "Delete":
        const recordData = this.state.tblData.filter(
          (el: any) => el.id === record.id
        );
        this.setState({
          showDeleteDialog: true,
          responseData: recordData[0],
        });

        break;
    }
  };
  handleSelectedFileCancel = () => {
    this.setState({ file: null, imagePreview: null, imgString: null });
  };
  isEmptyCheck = () => {
    const { myLinksFormData, showEditDialog, responseData, imagePreview } =
      this.state;
    if (showEditDialog) {
      if (
        Object.keys(myLinksFormData).includes("name") &&
        myLinksFormData["name"] &&
        myLinksFormData["url"] &&
        myLinksFormData["description"] &&
        (!_.isEqual(myLinksFormData.name, responseData.name) ||
          !_.isEqual(myLinksFormData.url, responseData.url) ||
          !_.isEqual(myLinksFormData.description, responseData.description) ||
          !_.isEqual(responseData.imgPath, imagePreview))
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      const values = Object.values(myLinksFormData);
      const arr = values.filter((el) => el.trim() === "");
      if (values.length === 3 && arr.length === 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  handleCancelClick = () => {
    this.setState({
      addLinksContent: false,
      showEditDialog: false,
      imgString: "",
      myLinksFormData: {} as createMyLinkAppModel,
      file: null,
      imagePreview: null,
    });
  };

  onClickCheckBox = async (
    event: React.ChangeEvent<HTMLInputElement>,
    singleRow: any
  ) => {
    try {
      const dataMapped = this.state.tblData.map((el: any) => {
        if (el.id === singleRow.id) {
          return { ...el, display: event.target.checked, isDisabled: true };
        }
        return el;
      });
      if (event.target.checked) {
        const checkedData = dataMapped.filter((el: any) => el.display === true);
        sessionStorage.setItem("myLinkData", JSON.stringify(checkedData));
      } else {
        let data = sessionStorage.getItem("myLinkData");
        if (data) {
          const storedData = JSON.parse(data) || [];
          const updatedData = storedData.filter(
            (item: any) => item.id !== singleRow.id
          );
          sessionStorage.setItem("myLinkData", JSON.stringify(updatedData));
        }
      }

      this.setState({ tblData: dataMapped });

      const payloadObj = {
        setFields: { isDisplay: event.target.checked },
        id: { id: singleRow.id },
      };
      const response = await MyLinkAppServices.updateMyLinkAppData(payloadObj);

      if (response?.message) {
        //this.getAllUpdatedAppData();
        const data = this.state.tblData.map((el: any) => {
          if (el.id === singleRow.id) {
            return { ...el, isDisabled: false };
          }
          return el;
        });
        this.setState({ tblData: data });
      } else {
        this.setState({
          openAlert: true,
          alertMsg: "Something went wrong",
          alertType: "error",
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  render() {
    const {
      myLinksFormData,
      searchText,
      showDeleteDialog,
      showEditDialog,
      switchOn,
      file,
      imagePreview,
      tblData,
      alertType,
      openAlert,
      alertMsg,
      isButtonLoading,
      isLoading,
      isUpdateButtonLoading,
      isDeleteButtonLoading,
    } = this.state;
    return (
      <>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        <Box pb={4}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "25px 10px",
            }}
          >
            <PageHeader
              label="My Links"
              style={{
                fontSize: "22px",
              }}
            />
          </Box>
          <CardContainer>
            {this.state.addLinksContent ? (
              <>
                <Box
                  p={2}
                  pl={4}
                  pr={4}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "21px",
                      margin: "0",
                    }}
                  >
                    Add Links
                  </h3>
                  <Box>
                    <Grid container>
                      <Grid item pr={1}>
                        <RedOutlinedButton
                          disabled={isButtonLoading}
                          label="Cancel"
                          onClick={this.handleCancelClick}
                        />
                      </Grid>
                      <Grid item>
                        <GradientButton
                          disabled={
                            isButtonLoading
                              ? isButtonLoading
                              : this.isEmptyCheck()
                          }
                          isButtonLoad={isButtonLoading}
                          label="Done"
                          onClick={() => this.handleSubmit("save")}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box p={2} pt={0} pl={4} pr={4}>
                  <Grid container>
                    <Grid container spacing={1}>
                      <Grid item xs={3.5}>
                        <InputLabel
                          sx={{
                            visibility: myLinksFormData.name
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          App Name
                        </InputLabel>
                        <TextInputField
                          placeholder="Enter Name"
                          name="name"
                          id="name"
                          value={myLinksFormData.name}
                          onChange={this.onInputChange}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel
                          sx={{
                            visibility: myLinksFormData.url
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          App URL
                        </InputLabel>
                        <TextInputField
                          placeholder="Enter URL"
                          name="url"
                          id="url"
                          value={myLinksFormData.url}
                          onChange={this.onInputChange}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <InputLabel
                          sx={{
                            visibility: myLinksFormData.description
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          App Description
                        </InputLabel>
                        <TextInputField
                          placeholder="Enter Description"
                          name="description"
                          id="description"
                          value={myLinksFormData.description}
                          onChange={this.onInputChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={11}>
                        <ImageUpload
                          imagePreview={imagePreview}
                          onCancel={this.handleSelectedFileCancel}
                          file={file}
                          onChange={this.handleImageChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : (
              <>
                <Grid container p={2}>
                  <Grid item xs={3}>
                    <InputLabel
                      sx={{
                        visibility: searchText ? "visible" : "hidden",
                      }}
                    >
                      Search by Name
                    </InputLabel>
                    <TextInputField
                      placeholder="Search by Name"
                      name="firstName"
                      id="firstName"
                      value={searchText}
                      onChange={this.handleSearchChange}
                    />
                  </Grid>
                  <Grid item container mt={2} xs={9} justifyContent="flex-end">
                    <Grid item pr={3} fontWeight={"bold"}>
                      <SwitchButton
                        label="Display Settings"
                        checked={switchOn}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    {tblData.length === 20 ? (
                      <LightTooltip
                        title="You’ve reached the limit of 20 apps. Please remove an existing app before adding a new one."
                        arrow
                      >
                        <Grid item>
                          <GradientButton
                            disabled={tblData.length === 20}
                            label="Add Links"
                            onClick={this.handlAddClick}
                            startIcon={<AddIcon />}
                          />
                        </Grid>
                      </LightTooltip>
                    ) : (
                      <Grid item>
                        <GradientButton
                          label="Add Links"
                          onClick={this.handlAddClick}
                          startIcon={<AddIcon />}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            <hr
              style={{
                border: "0.5px solid #D8D8D8",
              }}
            />
            <MyLinksTable
              onClickCheckBox={this.onClickCheckBox}
              isLoading={isLoading}
              data={tblData}
              switchOn={this.state.switchOn}
              actionArray={["Edit", "Delete"]}
              onActionClick={this.onActionClick}
            />
          </CardContainer>
          <ModalDialog
            isOpen={showDeleteDialog}
            title="Delete App"
            onClose={() => this.setState({ showDeleteDialog: false })}
            dialogWidth="sm"
          >
            {alertType === "error" ? (
              <SnackbarAlert
                alertType={alertType}
                open={openAlert}
                message={alertMsg}
                onClose={() => {
                  this.setState({ openAlert: false });
                }}
              />
            ) : (
              <></>
            )}
            <Typography>Are you sure you want to delete ?</Typography>
            <Grid item container mt={2.5}>
              <Grid item>
                <RedOutlinedButton
                  disabled={isDeleteButtonLoading}
                  label="No"
                  onClick={() => this.setState({ showDeleteDialog: false })}
                />
              </Grid>
              <Grid item ml={1.5}>
                <GradientButton
                  disabled={isDeleteButtonLoading}
                  isButtonLoad={isDeleteButtonLoading}
                  label="Yes"
                  onClick={this.onDeleteClick}
                />
              </Grid>
            </Grid>
          </ModalDialog>
          <ModalDialog
            isOpen={showEditDialog}
            title={"Edit App"}
            onClose={this.handleCancelClick}
            dialogWidth="lg"
          >
            {alertType === "error" ? (
              <SnackbarAlert
                alertType={alertType}
                open={openAlert}
                message={alertMsg}
                onClose={() => {
                  this.setState({ openAlert: false });
                }}
              />
            ) : (
              <></>
            )}

            <Grid container>
              <Grid container spacing={1}>
                <Grid item xs={4.4}>
                  <InputLabel
                    sx={{
                      visibility: myLinksFormData.name ? "visible" : "hidden",
                    }}
                  >
                    App Name
                  </InputLabel>
                  <TextInputField
                    placeholder="Enter Name"
                    name="name"
                    id="name"
                    value={myLinksFormData.name}
                    onChange={this.onInputChange}
                  />
                </Grid>
                <Grid item xs={3.6}>
                  <InputLabel
                    sx={{
                      visibility: myLinksFormData.url ? "visible" : "hidden",
                    }}
                  >
                    App URL
                  </InputLabel>
                  <TextInputField
                    placeholder="Enter URL"
                    name="url"
                    id="url"
                    value={myLinksFormData.url}
                    onChange={this.onInputChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel
                    sx={{
                      visibility: myLinksFormData.description
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    App Description
                  </InputLabel>
                  <TextInputField
                    placeholder="Enter Description"
                    name="description"
                    id="description"
                    value={myLinksFormData.description}
                    onChange={this.onInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <ImageUpload
                    imagePreview={imagePreview}
                    onCancel={this.handleSelectedFileCancel}
                    file={file}
                    onChange={this.handleImageChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container mt={2.5}>
              <Grid item>
                <RedOutlinedButton
                  label="Cancel"
                  disabled={isUpdateButtonLoading}
                  onClick={this.handleCancelClick}
                />
              </Grid>
              <Grid item ml={1.5}>
                <GradientButton
                  isButtonLoad={isUpdateButtonLoading}
                  disabled={
                    isUpdateButtonLoading
                      ? isUpdateButtonLoading
                      : this.isEmptyCheck()
                  }
                  label="Update"
                  onClick={() => this.handleSubmit("edit")}
                />
              </Grid>
            </Grid>
          </ModalDialog>
        </Box>
      </>
    );
  }
}
export default withRouter(MyLinks);
