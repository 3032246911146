import React from "react";
import { Button, CircularProgress } from "@mui/material";
import "./style.css";

interface Props {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
  isButtonLoad?: boolean;
}
interface State {}

class RedOutlinedButton extends React.Component<Props, State> {
  public static defaultProps = {
    disabled: false,

    isButtonLoad: false,
  };
  render() {
    const { label, onClick, startIcon, disabled, isButtonLoad, endIcon } =
      this.props;
    const customStyle: React.CSSProperties = { textTransform: "none" };
    if (disabled) {
      customStyle.borderColor = "#BDBDBD";
      customStyle.color = "#BDBDBD";
      customStyle.background = "#ffffff";
    }
    return (
      <Button
        disabled={disabled}
        startIcon={startIcon}
        endIcon={endIcon}
        size="medium"
        variant="outlined"
        style={
          disabled
            ? customStyle
            : {
                background: "#ffffff",
                textAlign: "center",
                letterSpacing: "0px",
                color: " #D52B1E",
                opacity: 1,
                textTransform: "none",
                border: "2px solid #D52B1E",
                borderRadius: "4px",
              }
        }
        onClick={onClick}
        className="OutlineBtnHover"
      >
        {isButtonLoad ? (
          <CircularProgress
            size="1.65rem"
            sx={{ color: "#BDBDBD", paddingLeft: 1, paddingRight: 1 }}
          />
        ) : (
          <>{label}</>
        )}
      </Button>
    );
  }
}

export default RedOutlinedButton;
