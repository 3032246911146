import {
  Grid,
  Pagination,
  Stack,
  TablePagination,
  Theme,
  styled,
} from "@mui/material";
import { Component } from "react";
import "./pagination.css";
import _ from "lodash";

type Props = {
  totalRecordsCount: number;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  isDisabled?: boolean;
  isPaginationReset?: boolean;
  isEmptyReset?: boolean;
};

type State = {
  rowsPerPage: number;
  page: number;
};
const StyledTablePagination: any = styled(TablePagination)(
  ({ theme }: { theme: Theme }) => ({
    ".MuiTablePagination-actions .MuiButtonBase-root, .MuiTablePagination-displayedRows":
      {
        display: "none",
      },
    ".MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input":
      {
        background: "#ffffff",
        borderRadius: "4px",
        minWidth: "20px",
        paddingRight: "27px",
        color: "#000000",
        border: "1px solid #9E9E9E",
      },
    ".MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiTablePagination-selectIcon.MuiSelect-iconStandard":
      {
        color: "#D52B1E",
      },
    ".MuiTablePagination-selectLabel.MuiTablePagination-selectLabel": {
      marginRight: "5px",
    },
  })
);
export default class PaginationNew extends Component<Props, State> {
  state = {
    rowsPerPage: 5,
    page: 1,
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (!_.isEqual(prevProps.isPaginationReset, this.props.isPaginationReset)) {
      this.setState({ page: 1, rowsPerPage: 5 });
    }
    if (!_.isEqual(prevProps.isEmptyReset, this.props.isEmptyReset)) {
      this.setState({ page: 1 });
    }
  }
  handleChangePage = (_event: any, page: any) => {
    this.setState({ page: page });
    this.props.onChangePage(page);
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
    this.props.onChangeRow(event.target.value);
  };

  render() {
    const { totalRecordsCount, isDisabled } = this.props;
    const { rowsPerPage, page } = this.state;

    return (
      <>
        <Grid
          container
          justifyContent={"flex-end"}
          sx={{ display: totalRecordsCount === 0 ? "none" : "flex" }}
        >
          <Grid item mt={1}>
            <Stack spacing={2}>
              <Pagination
                disabled={isDisabled}
                count={Math.ceil(totalRecordsCount / rowsPerPage)}
                showFirstButton
                showLastButton
                onChange={this.handleChangePage}
                page={page}
              />
            </Stack>
          </Grid>
          <Grid item>
            <StyledTablePagination
              component="div"
              color="primary"
              rowsPerPageOptions={[5, 10, 25, 50]}
              onPageChange={this.handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={
                isDisabled ? () => {} : this.handleChangeRowsPerPage
              }
              labelRowsPerPage={`${
                rowsPerPage * page > totalRecordsCount
                  ? totalRecordsCount
                  : totalRecordsCount > rowsPerPage
                  ? rowsPerPage * page
                  : totalRecordsCount
              } of ${totalRecordsCount} records per page`}
              count={1}
              page={0}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
