import React from "react";
import { Grid, Button, Typography, CircularProgress } from "@mui/material";

import ModalDialog from "../Modal/ModelDialog";
import WhiteButton from "./WhiteButton";
import "./style.css";
type ButtonType = "None" | "Dialog" | "IconButton";
type TypeOfButton = "button" | "submit" | "reset" | undefined;

interface Props {
  id?: string;
  label: string;
  disabled: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  startIcon?: React.ReactNode;
  buttonType: ButtonType;
  dialogMessage?: string;
  type: TypeOfButton;
  isButtonLoad?: boolean;
}
interface State {
  isDialogOpen: boolean;
}

class GradientButton extends React.Component<Props, State> {
  public static defaultProps = {
    buttonType: "None",
    disabled: false,
    type: undefined,
    isButtonLoad: false,
  };

  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      isDialogOpen: false,
    };
  }
  render() {
    const {
      id,
      label,
      disabled,
      onClick,
      startIcon,
      buttonType,
      dialogMessage,
      type,
      isButtonLoad,
    } = this.props;
    const { isDialogOpen } = this.state;

    const style: React.CSSProperties = { textTransform: "none" };

    if (!disabled) {
      style.backgroundImage = "linear-gradient(to right, #D52B1E,#78105B)";
      style.color = "#FFFFFF";
    }
    if (disabled) {
      style.borderColor = "#BDBDBD";
      style.color = "#BDBDBD";
      style.background = "#ffffff";
    }

    switch (buttonType) {
      case "None":
        return (
          <Button
            type={type}
            disabled={disabled}
            id={id}
            startIcon={startIcon}
            size="medium"
            variant="outlined"
            style={style}
            onClick={onClick}
            className="HoverEffect"
          >
            {isButtonLoad ? (
              <CircularProgress
                size="1.65rem"
                sx={{
                  color: disabled ? "#d52b1e" : "#FFFFFF",
                  paddingLeft: 1,
                  paddingRight: 1,
                }}
              />
            ) : (
              <>{label}</>
            )}
          </Button>
        );
      case "IconButton":
        return (
          <Button
            type={type}
            disabled={disabled}
            id={id}
            startIcon={isButtonLoad ? "" : startIcon}
            size="medium"
            variant="outlined"
            style={style}
            onClick={onClick}
            className="HoverEffect"
          >
            {isButtonLoad ? (
              <CircularProgress
                size="1.3rem"
                sx={{
                  color: disabled ? "#d52b1e" : "#FFFFFF",
                }}
              />
            ) : (
              <>{label}</>
            )}
          </Button>
        );
      case "Dialog":
        return (
          <>
            <ModalDialog
              title={"Alert"}
              dialogWidth="sm"
              isOpen={isDialogOpen}
              onClose={this.handleCancel}
              dialogAction={
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ marginRight: 20 }}
                >
                  <Grid item>
                    <WhiteButton label="No" onClick={this.handleCancel} />
                  </Grid>
                  <Grid item>
                    <GradientButton
                      label="Yes"
                      onClick={this.onDialogButtonClick}
                    />
                  </Grid>
                </Grid>
              }
            >
              <Typography
                style={{ fontSize: 16, fontWeight: 400, color: "#383838" }}
              >
                {dialogMessage}
              </Typography>
            </ModalDialog>
            <Button
              disabled={disabled}
              id={id}
              startIcon={startIcon}
              size="medium"
              variant="outlined"
              style={style}
              onClick={this.handleDialogOpen}
              className="HoverEffect"
            >
              {label}
            </Button>
          </>
        );
      default:
        return <></>;
    }
  }

  onDialogButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.handleCancel();

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  handleDialogOpen = () => {
    this.setState({ isDialogOpen: true });
  };

  handleCancel = () => {
    this.setState({ isDialogOpen: false });
  };
}

export default GradientButton;
