import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { Component } from "react";
type Props = {
  chartDataSourceArr: Highcharts.PointOptionsObject[];
  xAxisCategories: string[];
};
interface State {}
class ChartPerSource extends Component<Props, State> {
  render() {
    const chartData: Highcharts.Options = {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#2E2F30",
        formatter: function () {
          return `
          <div  style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
          <b>${this.x} </b><br><br>
          <span style=color:#BFBFBF>No. Of Records :</span> <span style=padding-left:10px>${this.y}</span><br>         
          </div>`;
        },
      },
      xAxis: {
        categories: this.props.xAxisCategories,

        labels: {
          enabled: true,
        },
        min: 0,
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: "",
        },
        type: "logarithmic",
        min: 1, //Set the minimum value to 1 to avoid negative values on a logarithmic scale
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointWidth: 15,
        },
      },
      series: [
        {
          name: "Total Count",
          type: "column",
          data: this.props.chartDataSourceArr,
        },
      ],
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartData} />
      </div>
    );
  }
}

export default ChartPerSource;
