import React from "react";
import { Button } from "@mui/material";

type TypeOfButton = "button" | "submit" | "reset" | undefined;
interface Props {
    label: string;
    onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    style?: React.CSSProperties;
    disabled?: boolean;
    type: TypeOfButton;
}
interface State {}

class BlueButton extends React.Component<Props, State> {
    public static defaultProps = {
        type: undefined,
      };
    render() {
        const { label, onClick, style, disabled,type} = this.props;

        const defaultStyle: any = {backgroundColor: "#0577A3", color: '#FFFFFF',borderColor: '#0577A3', fontWeight: '450' , textTransform: 'none'};
        
        let mergeStyle = {...defaultStyle, ...style};

        return(
            <Button   type={type} size="small" variant="outlined" disabled={disabled} style={mergeStyle}
             onClick={onClick}>{label}</Button>
        );
    }
}

export default BlueButton;