import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TabType } from '../../../Components/Tabs/TabModel';
import ClinicalTrialSearchTab from './ClinicalTrialSearchTab';
import CustomerSearchTab from './CustomerSearchTab';

interface Props extends RouteComponentProps<any, any, any> {
    tabType: TabType;
}

interface State  {}

class ExploreTabs extends React.Component<Props, State> {
  state = {}

  render() {
    const  {tabType} = this.props;
   
    
      if (tabType === 'CustomerSearch') {
        return <CustomerSearchTab/>
      }
      if (tabType === 'ClinicalTrialSearch') {
        return <ClinicalTrialSearchTab/>
      }
      
   
  }
}
export default withRouter(ExploreTabs)