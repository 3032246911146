import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { Component } from "react";
import { RadioButtonValuesModel } from "./RadioButtonValuesModel";

interface Props {
  direction?: "row" | "column";
  buttonValues: RadioButtonValuesModel[];
  formLabel?: string;
  name?: string;
  value?: any;
  isRequired?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  greenColor?: string;
  isDisabled?: boolean;
}

interface State {}

class RadioButtons extends Component<Props, State> {
  render() {
    const {
      buttonValues,
      formLabel,
      onChange,
      direction,
      name,
      value,
      isRequired,
      greenColor,
      isDisabled,
    } = this.props;
    return (
      <FormControl>
        <FormLabel
          id="radio-buttons-group-label"
          disabled={isDisabled}
          style={{
            color: "#ffffff",
          }}
        >
          {formLabel}
        </FormLabel>
        <RadioGroup
          row={direction ? direction === "row" : true}
          name={name}
          onChange={onChange}
          value={value}
        >
          {buttonValues.map((button, index) => {
            return (
              <FormControlLabel
                value={button.value}
                style={{
                  color: "#ffffff",
                  marginTop: "10px",
                }}
                control={
                  <Radio
                    disabled={isDisabled}
                    required={isRequired}
                    sx={{
                      "&.Mui-checked": {
                        color: greenColor ?? "#3da470",
                      },
                    }}
                  />
                }
                label={button.label}
                key={button.key ?? index}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default RadioButtons;
