import moment from "moment";
import config from "../config.json";
import decode from "jwt-decode";

const Environment = config.Environment;
class Utils {
  static isIE() {
    const isEdge = window.navigator.userAgent.indexOf("Edge") !== -1;
    const isIE = window.navigator.userAgent.indexOf("Trident") !== -1 && isEdge;
    return isIE;
  }
  static arraysAreIdentical(arr1: any, arr2: any) {
    if (arr1.length !== arr2.length) return false;
    let sortedArr1 = arr1.sort();
    let sortedArr2 = arr2.sort();
    for (let i = 0, len = sortedArr1.length; i < len; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  }

  static getDaysBetweenDates(dateOne: Date, dateTwo: Date): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      (dateTwo.getTime() - dateOne.getTime()) / oneDay
    );

    return diffDays;
  }

  static isEqualDates(dateOne?: Date | null, dateTwo?: Date | null): boolean {
    if (dateOne && dateTwo) {
      return (
        dateOne.getFullYear() === dateTwo.getFullYear() &&
        dateOne.getDate() === dateTwo.getDate() &&
        dateOne.getMonth() === dateTwo.getMonth()
      );
    }

    return false;
  }

  static getCurrentEnv(): any {
    if (process.env.APP_ENV) return process.env.APP_ENV;
    // Production
    if (
      window.location.hostname.includes("prod") ||
      window.location.hostname === "" ||
      window.location.hostname === "pps.lilly.com"
    ) {
      return "prod";
    }
    // Staging
    if (
      window.location.hostname.includes("qa") ||
      window.location.hostname === "d175hzx6uy8ptg.cloudfront.net" ||
      window.location.hostname === "qa.pps.lilly.com"
    ) {
      return "qa";
    }
    if (
      window.location.hostname.includes("uat") ||
      window.location.hostname === "" ||
      window.location.hostname === "uat.pps.lilly.com"
    ) {
      return "uat";
    }
    // Develop
    return "dev";
  }

  static getEnvVars(): typeof Environment.dev {
    const env = Utils.getCurrentEnv();
    const envVariables: any = Object.assign({}, Environment);
    return envVariables[env];
  }

  static isInt(value: string): boolean {
    return !isNaN(parseInt(value));
  }

  static getQueryParam(
    querySearchString: string,
    param: string
  ): string | null {
    const query = new URLSearchParams(querySearchString);
    return query.get(param);
  }

  static decodeJWT(token: string | null) {
    return decode(token!);
  }

  static getEnumKeyByEnumValue(
    myEnum: any,
    enumValue: number | string
  ): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : "";
  }

  static paginate<T>(array: T[], pageSize: number, pageNumber: number): T[] {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  static getFormattedDateTime(dateTime: Date | string | null | undefined) {
    if (dateTime) {
      try {
        return moment(dateTime).format("YYYY-MM-DD | HH:mm:ss");
      } catch (error) {
        console.error(error);
      }
    }

    return "";
  }
  static getFormattedDateTimeMonth(dateTime: Date | string | null | undefined) {
    if (dateTime) {
      try {
        return moment(dateTime).format("DD MMM YYYY | HH:mm:ss");
      } catch (error) {
        console.error(error);
      }
    }

    return "";
  }
  static getCurrentUTCDateTimeMonth() {
    try {
      var date = new Date();
      return (
        date.getUTCFullYear() +
        "-" +
        ("0" + (date.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getUTCDate()).slice(-2) +
        " " +
        ("0" + date.getUTCHours()).slice(-2) +
        ":" +
        ("0" + date.getUTCMinutes()).slice(-2) +
        ":" +
        ("0" + date.getUTCSeconds()).slice(-2)
      );
    } catch (error) {
      console.error(error);
    }

    return "";
  }

  static getDate(dateTime: Date | string | null | undefined, format: string) {
    if (dateTime) {
      try {
        return moment(dateTime).format(format);
      } catch (error) {
        console.error(error);
      }
    }

    return "";
  }

  static openUrl(url?: string | null) {
    if (url) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "http://" + url;
      }
      const newTab = window.open(url, "_blank", "noopener,noreferrer");
      if (newTab) newTab.opener = null;
    }
  }
  static openUrlParent(url?: string | null) {
    if (url) {
      window.open(url, "_blank");
    }
  }
  static camelToTitle(string: string) {
    return string.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }
  static dateFunction (props: {startDate: string; endDate: string})  {
    const diff = props.endDate && props.startDate
            ? moment(props.endDate).diff(props.startDate, 'd')
            : 0;
    const totalIntervals = Math.floor(diff / 7);
    const dateRange = [];
    let daysToAdd = 0;
    const momentEnd = moment(props.endDate, 'YYYY-MM-DD', true);
    for (let i = 0; i <= 7; i++) {
        daysToAdd = totalIntervals * i;
        const startDateValue = moment(props.startDate, 'YYYY-MM-DD', true).add(daysToAdd + i, 'd');
        const nextDate = moment(props.startDate, 'YYYY-MM-DD', true).add(daysToAdd + i + totalIntervals, 'd');
        if (nextDate.isAfter(momentEnd)) {
            nextDate.subtract(nextDate.diff(momentEnd, 'd'), 'd')
        }
            if (startDateValue.format('YYYY-MM-DD') <= props.endDate) {
                const objToPush: {startDate: string; endDate: string} = {} as any;
                if (startDateValue) {
                    objToPush.startDate = startDateValue.format('YYYY-MM-DD');
                }
                if (nextDate && nextDate.isSameOrBefore(moment(props.endDate, 'YYYY-MM-DD', true))) {
                    objToPush.endDate = nextDate.format('YYYY-MM-DD')
                }
                dateRange.push(objToPush)
            }
        
  
    }
    return dateRange;
  
  }
}

export default Utils;
