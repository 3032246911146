import { Box, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../../App.css'

interface Props {
    msg?: string;
    style?: React.CSSProperties;
    msgType? : string;
 }

interface State { }

export default class NoRecords extends Component<Props, State> {
    state = {}
    
    render() {
        return (
           <>  <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", ...this.props.style }}>
            <Typography  className='ChatImg'>   <img src="Chat.png" alt="ChatIcon" width={"20px"}/></Typography> 
                <Box fontWeight={"bold"} textAlign={"center"}>{ this.props.msg?? 'No Items to Display'}<Typography color={"#848484"}fontSize={"12px"}>Select the items from list to read</Typography></Box>
            </Box> </> 
        )
    }
}