import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  AlertColor,
  Box,
  Card,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { Component } from "react";
import Utils from "../../Common/Utils";
import GradientButton from "../../Components/Button/GradientButton";
import { IconType } from "../../Components/Button/IconButton";
import RedButton from "../../Components/Button/RedButton";
import RedOutlinedButton from "../../Components/Button/RedOutlinedButton";
import RadioButtons from "../../Components/RadioButtons/RadioButtons";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import ReportingDataloader from "../../Components/Table/ReportingDataloader";
import PageHeader from "../../Components/Text/PageHeader";
import {
  OnDemandRptHistoryModel,
  OnDemandRptSubmissionModel,
  OnDemandSelectionModel,
} from "../../Models/OnDemandReportModels";
import AuthProviderService from "../../Services/AuthProviderService";
import OnDemandReportService from "../../Services/OnDemandReportServices";
import BackgroundImg from "../../Static/Background_Image_Search.png";
import ReportHistoryTable from "./ReportHistory";
const OnDemandReportServices = new OnDemandReportService();
type Props = {};

type State = {
  radioButtonValue: string;
  reportType: string;
  reportId: string;
  selectedCountry: string;
  selectedYear: string;
  submissionType: string;
  descriptionText: string;
  isTableLoading: boolean;
  tblData: any[];
  showTableContent: boolean;
  showProceedTableContent: boolean;
  isOpenAdvancedSearch: boolean;
  ProceedViewHistory: boolean;
  countryData: SelectModel[];
  rptTypeData: SelectModel[];
  rptIdData: SelectModel[];
  yearData: SelectModel[];
  result: OnDemandSelectionModel[];
  isButtonLoading: boolean;
  userId: string;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  fileConfigCdData: SelectModel[];
  fileConfigCdValue: string;
};

export default class OnDemandReporting extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      radioButtonValue: "",
      reportType: "",
      reportId: "",
      selectedCountry: "",
      selectedYear: "",
      submissionType: "",
      descriptionText: "",
      isTableLoading: false,
      tblData: [],
      showTableContent: false,
      showProceedTableContent: false,
      isOpenAdvancedSearch: false,
      ProceedViewHistory: false,
      countryData: [],
      rptTypeData: [],
      rptIdData: [],
      yearData: [],
      result: [],
      isButtonLoading: false,
      userId: "",
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      fileConfigCdData: [],
      fileConfigCdValue: "",
    };
  }
  async componentDidMount() {
    try {
      this.getAllData(5, 1);
      const token = await AuthProviderService.getAccessToken();
      const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
      const userId = JSON.parse(decodedToken).uid;
      const response = await OnDemandReportServices.getSelectionData();

      const countryData = [
        ...new Set(
          response.data.result.map(
            (el: OnDemandSelectionModel) => el.rpt_cntry_cd
          )
        ),
      ].map((val: string) => {
        return {
          text: val,
          value: val,
        };
      });
      const fileConfigData = response.data.result[0].file_config_cd.map(
        (el: string) => {
          return {
            text: el,
            value: el,
          };
        }
      );

      this.setState({
        countryData,
        result: response.data.result,
        userId,
        fileConfigCdData: fileConfigData,
      });
    } catch (error) {
      console.log(error);
    }
  }
  getAllData = async (rows: number, page: number) => {
    try {
      this.setState({ isTableLoading: true });
      const payLoadObj: OnDemandRptHistoryModel = {
        var_rpt_cntry_cd: "",
        var_rpt_type: "",
        var_rpt_id: "",
        var_start_dt: "",
        var_end_dt: "",
        var_rpt_sbmsn_typ_cd: "",
        var_rpt_sbmsn_desc: "",
        var_recordsperpage: rows,
        var_page: page,
        var_file_config_cd: "",
      };
      const response = await OnDemandReportServices.getRptHistory(payLoadObj);
      if (Number(response.total_count) > 0) {
        let tableData = response.result.map((el: any) => {
          return {
            Rpt_Id: el.rpt_id,
            Rpt_Type: el.rpt_type,
            Rpt_Cntry_Cd: el.rpt_cntry_cd,
            "Submission Type": el.rpt_sbmsn_typ_cd,
            "Submission Description": el.rpt_sbmsn_desc,
            "Submission Preference": el.submission_preference,
            File_Config_Cd: el.file_config_cd,
            "Build Date": el.build_date,
            filePath: el.file_path,
            Status: el.build_status,
          };
        });

        this.setState({
          tblData: tableData,
          isTableLoading: false,
        });
      } else {
        this.setState({
          isTableLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isTableLoading: false,
      });
    }
  };
  handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      this.setState({ radioButtonValue: event.target.value });
    }
  };
  handleCountrySelectChange = (selected: SelectModel, targetId?: string) => {
    this.setState(
      {
        selectedCountry: selected.value,
      },
      () => {
        const selectedCountryData = this.state.result.filter(
          (ele: OnDemandSelectionModel) => ele.rpt_cntry_cd === selected.value
        );
        const rptTypeData = [
          ...new Set(
            selectedCountryData.map((el: OnDemandSelectionModel) => el.rpt_type)
          ),
        ].map((elem) => {
          return {
            text: elem,
            value: elem,
          };
        });

        this.setState({
          rptTypeData,
          reportType: "",
          reportId: "",
          submissionType: "",
          selectedYear: "",
          descriptionText: "",
        });
      }
    );
  };
  handleRptTypeSelectChange = (selected: SelectModel, targetId?: string) => {
    this.setState(
      {
        reportType: selected.value,
      },
      () => {
        const selectedRptTypeData = this.state.result.filter(
          (ele: OnDemandSelectionModel) =>
            ele.rpt_cntry_cd === this.state.selectedCountry
        );

        const yearData = [
          ...new Set(
            selectedRptTypeData.map((el: OnDemandSelectionModel) => el.year)
          ),
        ].map((elem) => {
          return {
            text: elem,
            value: elem,
          };
        });
        this.setState({
          yearData,
          reportId: "",
          submissionType: "",
          selectedYear: "",
        });
      }
    );
  };
  handleYearSelectChange = (selected: SelectModel, targetId?: string) => {
    this.setState(
      {
        selectedYear: selected.value,
      },
      () => {
        const selectedRptTypeData = this.state.result.filter(
          (ele: OnDemandSelectionModel) =>
            ele.rpt_cntry_cd === this.state.selectedCountry
        );

        const filteredRptIdData = selectedRptTypeData.filter(
          (el: OnDemandSelectionModel) =>
            el.rpt_id.includes("-")
              ? el.rpt_id.split("-")[0] === this.state.reportType.split("-")[0]
              : el.rpt_id.split("_")[0] === this.state.reportType.split("-")[0]
        );
        const selectedYearRptIdData = filteredRptIdData.filter(
          (val: OnDemandSelectionModel) =>
            this.state.reportType.includes("State-")
              ? val.rpt_id.includes(
                  `${this.state.reportType.replace("-", "_")}_${selected.value}`
                )
              : val.rpt_id.includes(selected.value)
        );

        const rptIdData = [
          ...new Set(
            selectedYearRptIdData.map((el: OnDemandSelectionModel) => el.rpt_id)
          ),
        ].map((elem: string) => {
          return {
            text: elem,
            value: elem,
          };
        });

        this.setState({
          rptIdData,
          reportId: "",
          submissionType: "",
        });
      }
    );
  };
  handleSelectChange = (selected: SelectModel, targetId?: string) => {
    if (targetId) {
      this.setState({
        ...this.state,
        [targetId]: selected.value,
      });
    }
  };
  handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const descriptionVal = event.target.value;
    this.setState({
      descriptionText: descriptionVal,
    });
  };
  formReset = () => {
    this.setState({
      selectedCountry: "",
      reportId: "",
      reportType: "",
      selectedYear: "",
      submissionType: "",
      descriptionText: "",
      radioButtonValue: "",
      fileConfigCdValue: "",
    });
  };

  onActionClick = async (action: IconType, record: any) => {
    switch (action) {
      case "Download":
        const filePath = record.filePath.split(".com/")[1].split("/");

        let key = filePath[2];
        let countryName = filePath[0];
        let folderName = filePath[1];
        const response = await OnDemandReportServices.getFile(
          key,
          countryName,
          folderName
        );
        Utils.openUrlParent(response);
        break;
    }
  };
  handleHistoryBtnClick = () => {
    this.setState((prevState) => ({
      showTableContent: !prevState.showTableContent,
      ProceedViewHistory: !prevState.ProceedViewHistory,
    }));
  };
  handleExecuteClick = async () => {
    try {
      const {
        reportId,
        reportType,
        selectedCountry,
        radioButtonValue,
        descriptionText,
        userId,
        submissionType,
        fileConfigCdValue,
      } = this.state;
      this.setState({ isButtonLoading: true });
      const payLoadObj: OnDemandRptSubmissionModel = {
        var_rpt_id: reportId,
        var_rpt_type: reportType,
        var_rpt_cntry_cd: selectedCountry,
        var_rpt_sbmsn_typ_cd: submissionType,
        var_rpt_sbmsn_desc: descriptionText,
        var_rpt_sbmsn_preference: radioButtonValue,
        var_file_config_cd: fileConfigCdValue,
        var_rcrd_crtd_dt: moment(new Date()).format("MM-DD-YYYY HH:mm:ss"),
        var_rcrd_crtd_usr: userId,
      };
      const response = await OnDemandReportServices.submitReport(payLoadObj);
      if (response.result) {
        this.setState(
          {
            isButtonLoading: false,
            openAlert: true,
            alertType: "success",
            alertMsg: "Build details has been submitted successfully.",
          },
          () => {
            this.formReset();
            this.getAllData(5, 1);
          }
        );
      }
    } catch (error) {
      this.setState({
        isButtonLoading: false,
        openAlert: true,
        alertType: "error",
        alertMsg: "Something went wrong.",
      });
    }
  };
  isEmptyCheck = () => {
    const {
      selectedCountry,
      reportType,
      selectedYear,
      submissionType,
      descriptionText,
      radioButtonValue,
      reportId,
    } = this.state;
    if (
      selectedCountry &&
      reportType &&
      selectedYear &&
      submissionType &&
      descriptionText &&
      radioButtonValue &&
      reportId
    ) {
      return false;
    }
    return true;
  };
  isResetEmptyCheck = () => {
    const {
      selectedCountry,
      reportType,
      selectedYear,
      submissionType,
      descriptionText,
      radioButtonValue,
      reportId,
    } = this.state;
    if (
      selectedCountry ||
      reportType ||
      selectedYear ||
      submissionType ||
      descriptionText ||
      radioButtonValue ||
      reportId
    ) {
      return false;
    }
    return true;
  };

  render() {
    const {
      radioButtonValue,
      reportType,
      selectedCountry,
      selectedYear,
      submissionType,
      descriptionText,
      isTableLoading,
      showTableContent,
      showProceedTableContent,
      ProceedViewHistory,
      countryData,
      rptIdData,
      reportId,
      rptTypeData,
      yearData,
      alertType,
      openAlert,
      alertMsg,
      isButtonLoading,
      fileConfigCdData,
      fileConfigCdValue,
    } = this.state;
    return (
      <>
        <SnackbarAlert
          alertType={alertType}
          open={openAlert}
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "25px 5px",
          }}
        >
          <Grid item container>
            {showProceedTableContent ? (
              <>
                <Grid
                  item
                  display={"flex"}
                  justifyContent={"normal"}
                  xs={0.5}
                  mt={0.8}
                >
                  <Typography
                    style={{
                      color: "#00749E",
                      fontWeight: "bold",
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.setState({ showProceedTableContent: false })
                    }
                  >
                    Back
                  </Typography>
                  <NavigateNextIcon
                    style={{ color: "#00749E" }}
                    fontWeight={"bold"}
                    fontSize="small"
                  />
                </Grid>
                <Grid item xs={8.5}>
                  <PageHeader
                    label="On - Demand Reporting"
                    style={{
                      fontSize: "22px",
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={8.5}>
                  <PageHeader
                    label="On - Demand Reporting"
                    style={{
                      fontSize: "22px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  mt={1}
                  xs={3.5}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <RedOutlinedButton
                    label="View History"
                    onClick={() =>
                      this.setState({ showProceedTableContent: true })
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        {showProceedTableContent ? (
          <ReportHistoryTable />
        ) : (
          <>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: 15,
                  fontWeight: 500,
                  color: "#FF0000",
                  fontStyle: "italic",
                }}
              >
                * All Fields Mandatory
              </Typography>
            </Grid>
            <Card
              style={{
                background:
                  "transparent url(" + BackgroundImg + ") 0% 0% padding-box",
                opacity: 1,
                backgroundSize: "cover",
                backgroundPosition: "right",
                backgroundAttachment: "fixed",
                border: "none",
                borderRadius: "8px",
                height: "auto",
                marginBottom: "3rem",
              }}
            >
              <Box mr={20} ml={20} mt={18} mb={20} className="OnDemandContent">
                <Grid
                  container
                  columns={8}
                  mt={3}
                  p={3}
                  columnSpacing={3}
                  mb={4}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Grid item xs={1.5} mb={4} className="OnDemandContent">
                    <InputLabel>Select Country</InputLabel>
                    <SingleSelect
                      size="small"
                      id="selectedCountry"
                      noneValue={true}
                      value={selectedCountry}
                      values={countryData}
                      onChange={this.handleCountrySelectChange}
                    />
                  </Grid>
                  <Grid item xs={1.7} mb={4} className="OnDemandContent">
                    <InputLabel>Report Type</InputLabel>
                    <SingleSelect
                      isDisabled={!selectedCountry}
                      size="small"
                      id="reportType"
                      noneValue={true}
                      value={reportType}
                      values={rptTypeData}
                      onChange={this.handleRptTypeSelectChange}
                    />
                  </Grid>
                  <Grid item xs={1.5} mb={4} className="OnDemandContent">
                    <InputLabel>Select Year</InputLabel>
                    <SingleSelect
                      isDisabled={!reportType}
                      size="small"
                      id="selectedYear"
                      noneValue={true}
                      value={selectedYear}
                      values={yearData}
                      onChange={this.handleYearSelectChange}
                    />
                  </Grid>
                  <Grid item xs={2} mb={4} className="OnDemandContent">
                    <InputLabel>Report ID</InputLabel>
                    <SingleSelect
                      isDisabled={!selectedYear}
                      size="small"
                      id="reportId"
                      noneValue={true}
                      value={reportId}
                      values={rptIdData}
                      onChange={this.handleSelectChange}
                    />
                  </Grid>

                  <Grid item xs={1.3} mb={4} className="OnDemandContent">
                    <InputLabel>Submission Type</InputLabel>
                    <SingleSelect
                      isDisabled={!selectedYear}
                      size="small"
                      id="submissionType"
                      noneValue={true}
                      value={submissionType}
                      values={[
                        {
                          text: "Full",
                          value: "Full",
                        },
                        {
                          text: "Partial",
                          value: "Partial",
                        },
                      ]}
                      onChange={this.handleSelectChange}
                    />
                  </Grid>
                  <Grid item container xs={12} mb={4} columnSpacing={1.5}>
                    <Grid item xs={4}>
                      <InputLabel>File Config Code</InputLabel>
                      <SingleSelect
                        isDisabled={!selectedYear}
                        size="small"
                        id="fileConfigCdValue"
                        noneValue={true}
                        value={fileConfigCdValue}
                        values={fileConfigCdData}
                        onChange={this.handleSelectChange}
                      />
                    </Grid>
                    <Grid item xs={8} mb={4}>
                      <InputLabel>Submission Description</InputLabel>
                      <TextField
                        disabled={!selectedYear}
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Enter your Description"
                        name="descriptionText"
                        id="descriptionText"
                        value={descriptionText}
                        onChange={this.handleDescriptionChange}
                      />
                    </Grid>
                    <Grid item xs={8} mb={4}>
                      <RadioButtons
                        isDisabled={!selectedYear}
                        formLabel="Please choose your preferred option for Report Generation"
                        value={radioButtonValue}
                        onChange={this.handleRadioButtonChange}
                        direction="row"
                        buttonValues={[
                          {
                            value: "N",
                            label: "Without Submission ID",
                          },
                          {
                            value: "Y",
                            label: "With Submission ID",
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={3} mt={1}>
                    <RedOutlinedButton
                      label="View History"
                      onClick={this.handleHistoryBtnClick}
                      endIcon={
                        ProceedViewHistory ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={3} mt={1}>
                    <Grid container columnGap={1.5} justifyContent={"flex-end"}>
                      <RedButton
                        disabled={this.isResetEmptyCheck()}
                        label="Reset"
                        onClick={this.formReset}
                      />

                      <GradientButton
                        isButtonLoad={isButtonLoading}
                        disabled={this.isEmptyCheck()}
                        label="Execute"
                        onClick={this.handleExecuteClick}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {showTableContent ? (
                  <Box mr={-12} ml={-12}>
                    <Card
                      style={{
                        background: "#e8e9ec",
                      }}
                    >
                      <h3
                        style={{
                          paddingLeft: "20px",
                        }}
                      >
                        View History
                      </h3>
                      <hr
                        style={{
                          border: "0.5px solid #D8D8D8",
                          marginBottom: "15px",
                        }}
                      />
                      <ReportingDataloader
                        isPaginationReset={false}
                        isPaginationDisabled={false}
                        isLoading={isTableLoading}
                        tableData={this.state.tblData}
                        totalRecordsCount={0}
                        onChangeRow={() => {}}
                        onChangePage={() => {}}
                        actionArray={["Download"]}
                        onActionClick={this.onActionClick}
                      />
                    </Card>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Card>
          </>
        )}
      </>
    );
  }
}
