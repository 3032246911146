import {
  Alert,
  Box,
  Grid,
  InputLabel,
  Snackbar,
  SnackbarOrigin,
} from "@mui/material";
import React from "react";

import IconButton from "../../Components/Button/IconButton";

import { RouteComponentProps, withRouter } from "react-router-dom";
import CardContainer from "../../Components/Card/CardContainer";

import { Refresh } from "@mui/icons-material";
import axios from "axios";
import Utils from "../../Common/Utils";
import GradientButton from "../../Components/Button/GradientButton";
import FileUpload from "../../Components/File/FileUpload";
import FileUploadDetailCard from "../../Components/File/FileUploadDetailCard";
import FileUploadDetailModel from "../../Components/File/FileUploadDetailModel";
import SearchFilter from "../../Components/Search/SearchFilter";
import SelectModel from "../../Components/Select/SelectModel";
import SingleSelect from "../../Components/Select/SingleSelect";
import SupportDataLoader from "../../Components/Table/SupportDataLoader";
import { SupportFileSearchModel } from "../../Models/UploadFileModel";
import SupportDataService from "../../Services/SupportDataService";

const SupportDataServices = new SupportDataService();

interface Props extends RouteComponentProps<any, any, any> {
  isUploaded: boolean;
  isDisabled: boolean;
  onChange: (file: FileUploadDetailModel) => void;
  submittedErrMsg: string;
  onDelete: (file: FileUploadDetailModel) => void;
  onClose: () => void;
  totalRecordsCount?: number;
  onSubmitClick: () => void;
  isError: boolean;
  isSuccess: boolean;
  uploadMessage: string;
  isSubmitted: boolean;
  submittedMsg: string;
  isLoading: boolean;
  isSubmtdError: boolean;
  files: FileUploadDetailModel[];
  isRefresh: boolean;
}

interface State extends SnackbarOrigin {
  isTableLoading: boolean;
  rows: number;
  page: number;
  tableData: any;
  totalRecordsCount: number | undefined;
  selectedStatus: string;
  filterData: any;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  searchText: string;
  searchFilterData: any;
  isEmptyReset: boolean;
}

class SupportDataFileUpload extends React.Component<Props, State> {
  private timer?: NodeJS.Timeout = undefined;
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      vertical: "top",
      horizontal: "center",
      tableData: [],
      isTableLoading: false,
      rows: 5,
      page: 0,
      totalRecordsCount: undefined,
      selectedStatus: "",
      filterData: [],
      isPaginationDisabled: false,
      isPaginationReset: false,
      searchText: "",
      searchFilterData: [],
      isEmptyReset: false,
    };
  }

  fileUploadInput: HTMLInputElement | null = null;

  action = (
    <>
      <IconButton IconType="Cancel" onClick={this.props.onClose} />
    </>
  );
  async componentDidMount() {
    this.handleStatusChange({ text: "All", value: "All" });
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.isRefresh !== this.props.isRefresh) {
      this.getUploadedFilesData(this.state.rows, this.state.page);
    }
  }

  getUploadedFilesData = async (rows: number, page: number) => {
    try {
      this.setState({ isTableLoading: true });
      const myUploadedFileData =
        await SupportDataServices.getUploadedFilesDataForUsers(rows, page);
      if (myUploadedFileData?.data.length === 0 && this.state.page > 0) {
        const myData = await SupportDataServices.getUploadedFilesDataForUsers(
          rows,
          0
        );
        this.setState({
          tableData: myData?.data.map((el) => {
            return {
              fileName: el.fileName,
              totalRecords: el.totalTransactions,
              "Last Sync Date & Time": Utils.getFormattedDateTime(el.createdAt),
              uploadedBy: el.uploadedBy,
              status: el.status.toLowerCase(),
              failureReason: el.failureReason,
            };
          }),

          totalRecordsCount: myUploadedFileData?.totalRecords,
          isTableLoading: false,
          isEmptyReset: true,
          page: 0,
        });
      } else {
        this.setState({
          tableData: myUploadedFileData?.data.map((el) => {
            return {
              fileName: el.fileName,
              totalRecords: el.totalTransactions,
              "Last Sync Date & Time": Utils.getFormattedDateTime(el.createdAt),
              uploadedBy: el.uploadedBy,
              status: el.status.toLowerCase(),
              failureReason: el.failureReason,
            };
          }),

          totalRecordsCount: myUploadedFileData?.totalRecords,
          isTableLoading: false,
          isEmptyReset: false,
        });
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.log("error message:", err.message);
        return err.message;
      } else {
        console.log("unexpected error during fecthing:", err);
      }
    }
  };

  getSearchFilterData = async (rows: number, page: number) => {
    this.setState({ isTableLoading: true, isPaginationReset: true });
    try {
      const obj: SupportFileSearchModel = {
        searchText: this.state.searchText,
        status: this.state.selectedStatus,
        noOfRecords: rows,
        pageNo: page + 1,
      };

      const response = await SupportDataServices.searchFileData(obj);
      if (response) {
        this.setState(
          {
            tableData: response.data.data.map((el: any) => {
              return {
                fileName: el.fileName,
                totalRecords: el.totalTransactions,
                "Last Sync Date & Time": Utils.getFormattedDateTime(
                  el.createdAt
                ),
                uploadedBy: el.uploadedBy,
                status: el.status.toLowerCase(),
                failureReason: el.failureReason,
              };
            }),
            totalRecordsCount: Number(response.data.totalRecords),
            isTableLoading: false,
          },
          () => {
            this.setState({
              isTableLoading: false,
              isPaginationDisabled: false,
            });
          }
        );
      } else {
        this.setState({
          isTableLoading: false,
          page: 0,
          rows: 5,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isTableLoading: false,
        searchText: "",
      });
    }
  };

  handleStatusChange = (selected: SelectModel) => {
    this.setState(
      {
        selectedStatus: selected.value,
        isPaginationReset: false,
        rows: 5,
        page: 0,
      },
      async () => {
        this.getSearchFilterData(5, 0);
        if (this.state.searchText.length > 0) {
          this.setState({ isEmptyReset: false });
        }
      }
    );
  };
  onChangePage = (page: number) => {
    this.setState({ page: page - 1 }, () => {
      if (
        this.state.searchText.length > 0 ||
        this.state.selectedStatus !== "All"
      ) {
        this.getSearchFilterData(this.state.rows, this.state.page);
      } else {
        this.getUploadedFilesData(this.state.rows, this.state.page);
      }
    });
  };
  onChangeRow = (row: number) => {
    this.setState({ rows: row }, () => {
      if (
        this.state.searchText.length > 0 ||
        this.state.selectedStatus !== "All"
      ) {
        this.setState(
          { isEmptyReset: this.state.selectedStatus !== "All" ? false : true },
          () => {
            this.getSearchFilterData(
              this.state.rows,
              this.state.page > 0 ? 0 : this.state.page
            );
          }
        );
      } else {
        this.getUploadedFilesData(this.state.rows, this.state.page);
      }
    });
  };
  handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.setState(
      {
        searchText: event.target.value,
        isTableLoading: true,
        page: 0,
        rows: 5,
        selectedStatus: "All",
      },
      async () => {
        this.timer = setTimeout(async () => {
          if (this.state.searchText.length > 0) {
            this.setState({ isPaginationReset: false }, () => {
              this.getSearchFilterData(
                this.state.rows,
                this.state.page > 0 ? 0 : this.state.page
              );
            });
          } else {
            this.setState(
              {
                isPaginationReset: false,
                isPaginationDisabled: false,
                isTableLoading: false,
                page: 0,
                rows: 5,
                selectedStatus: "All",
              },
              () => {
                this.getUploadedFilesData(this.state.rows, this.state.page);
              }
            );
          }
        }, 1000);
      }
    );
  };
  handleRefresh = async () => {
    if (this.state.searchText.length > 0) {
      this.setState({ page: 0, rows: 5 }, () => {
        this.getSearchFilterData(5, 0);
      });
    } else if (this.state.selectedStatus !== "All") {
      this.setState({ isTableLoading: true });
      try {
        const obj: SupportFileSearchModel = {
          searchText: this.state.searchText,
          status: this.state.selectedStatus,
          noOfRecords: this.state.rows,
          pageNo: this.state.page > 0 ? 1 : this.state.page + 1,
        };

        const response = await SupportDataServices.searchFileData(obj);
        if (response) {
          this.setState({
            tableData: response.data.data.map((el: any) => {
              return {
                fileName: el.fileName,
                totalRecords: el.totalTransactions,
                "Last Sync Date & Time": Utils.getFormattedDateTime(
                  el.createdAt
                ),
                uploadedBy: el.uploadedBy,
                status: el.status.toLowerCase(),
                failureReason: el.failureReason,
              };
            }),
            totalRecordsCount: Number(response.data.totalRecords),
            isTableLoading: false,
          });
        } else {
          this.setState({
            isTableLoading: false,
            page: 0,
            rows: 5,
          });
        }
      } catch (error) {
        console.log(error);
        this.setState({
          isTableLoading: false,
          searchText: "",
        });
      }
    } else {
      try {
        this.setState({ isTableLoading: true });
        const myUploadedFileData =
          await SupportDataServices.getUploadedFilesDataForUsers(
            this.state.rows,
            this.state.page
          );
        if (myUploadedFileData?.data.length === 0 && this.state.page > 0) {
          const myData = await SupportDataServices.getUploadedFilesDataForUsers(
            this.state.rows,
            0
          );
          this.setState({
            tableData: myData?.data.map((el) => {
              return {
                fileName: el.fileName,
                totalRecords: el.totalTransactions,
                "Last Sync Date & Time": Utils.getFormattedDateTime(
                  el.createdAt
                ),
                uploadedBy: el.uploadedBy,
                status: el.status.toLowerCase(),
                failureReason: el.failureReason,
              };
            }),

            totalRecordsCount: myUploadedFileData?.totalRecords,
            isTableLoading: false,
          });
        } else {
          this.setState({
            tableData: myUploadedFileData?.data.map((el) => {
              return {
                fileName: el.fileName,
                totalRecords: el.totalTransactions,
                "Last Sync Date & Time": Utils.getFormattedDateTime(
                  el.createdAt
                ),
                uploadedBy: el.uploadedBy,
                status: el.status.toLowerCase(),
                failureReason: el.failureReason,
              };
            }),

            totalRecordsCount: myUploadedFileData?.totalRecords,
            isTableLoading: false,
          });
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          console.log("error message:", err.message);
          return err.message;
        } else {
          console.log("unexpected error during fecthing:", err);
        }
      }
    }
  };
  handleSubmit = () => {
    this.setState({
      selectedStatus: "All",
    });
    this.props.onSubmitClick();
  };
  filterValue: SelectModel[] = [
    { text: "All", value: "All" },
    { text: "Pending", value: "Pending" },
    { text: "Failed", value: "Failed" },
    { text: "Completed", value: "Completed" },
  ];

  render() {
    const {
      isDisabled,
      onChange,
      onClose,
      isSuccess,
      uploadMessage,
      files,
      isLoading,
      isSubmitted,
      submittedMsg,
      isUploaded,
    } = this.props;
    const {
      selectedStatus,
      tableData,
      totalRecordsCount,
      isTableLoading,
      isPaginationDisabled,
      isPaginationReset,
      searchText,
      isEmptyReset,
    } = this.state;

    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: this.state.vertical,
            horizontal: this.state.horizontal,
          }}
          open={isSuccess || isSubmitted}
          autoHideDuration={7000}
          onClose={onClose}
          style={{ marginTop: 50 }}
        >
          {isSuccess ? (
            <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
              {uploadMessage}
            </Alert>
          ) : isSubmitted ? (
            <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
              {submittedMsg}
            </Alert>
          ) : (
            <span></span>
          )}
        </Snackbar>

        <Box pb={3}>
          <FileUpload
            sizeValidation="120"
            isSupportLoad={true}
            isUploaded={isUploaded}
            onSubmitClick={this.handleSubmit}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onChange={onChange}
          />

          {files ? (
            <Grid container direction="column">
              <FileUploadDetailCard
                files={files}
                onDelete={this.props.onDelete}
              />
            </Grid>
          ) : (
            <></>
          )}
          <CardContainer>
            <Grid container p={2} flexDirection="row">
              <Grid container item columnSpacing={2}>
                <Grid item xs={9.3} className="UploadSearchInput">
                  <InputLabel>Search By File Name / User Name </InputLabel>
                  <SearchFilter
                    placeholder="Search"
                    value={searchText}
                    onChange={this.handleSearchChange}
                  />
                </Grid>
                <Grid item xs={1.2} mt={3.2}>
                  <GradientButton
                    onClick={this.handleRefresh}
                    label="Refresh"
                    startIcon={<Refresh />}
                  />
                </Grid>
                <Grid
                  item
                  xs={1.5}
                  sx={{
                    "& .MuiInputBase-colorPrimary, .MuiSelect-icon ": {
                      color: "#0577A3!important",
                    },
                  }}
                  className="FilterStatus"
                  style={{
                    borderColor: "#0577A3",
                    textTransform: "none",
                    borderRadius: "5px",
                  }}
                >
                  <InputLabel>Filter By Status</InputLabel>
                  <SingleSelect
                    defaultValue="All"
                    id="status"
                    customOptions="Select Status"
                    size="small"
                    values={this.filterValue}
                    value={selectedStatus}
                    onChange={this.handleStatusChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <SupportDataLoader
              isEmptyReset={isEmptyReset}
              isPaginationReset={isPaginationReset}
              isPaginationDisabled={isPaginationDisabled}
              isLoading={isTableLoading}
              tableData={tableData}
              totalRecordsCount={totalRecordsCount}
              onChangeRow={this.onChangeRow}
              onChangePage={this.onChangePage}
            />
          </CardContainer>
        </Box>
      </>
    );
  }
}
export default withRouter(SupportDataFileUpload);
