import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ExcelJs from "exceljs";

class ExcelService {
  readonly config = {
    fileType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    fileExtension: ".xlsx",
  };

  CreateTemplate(fileName: string, data: any) {
    const { fileType } = this.config;
    let wb = new ExcelJs.Workbook();
    let workbookName = fileName;
    let worksheetName = "sheet1";
    let ws = wb.addWorksheet(worksheetName);

    ws.columns = data.map((el: any) => {
      return {
        key: el,
        header: el,
        width: 20,
      };
    });

    ws.getRow(1).font = { bold: true };

    data.forEach((cell: any, rowIndex: number) => {
      if (cell) {
        let cellRef = ws.getCell(1, rowIndex + 1);
        cellRef.value = cell; // Set the cell value
        cellRef.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFAA00" },
        };
      }
    });

    wb.xlsx.writeBuffer().then(function (buffer) {
      FileSaver.saveAs(new Blob([buffer], { type: fileType }), workbookName);
    });
  }

  CreateFile(fileName: string, data: any) {
    const { fileType, fileExtension } = this.config;

    const workSheet = XLSX.utils.json_to_sheet(data);

    const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(workBook, {
      bookType: "xlsx",
      type: "array",
    });

    const blobData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
  }

  CreateDataFile(fileName: string, data: any, columns: any) {
    const { fileType } = this.config;

    let wb = new ExcelJs.Workbook();
    let workbookName = fileName;
    let worksheetName = "sheet1";
    let ws = wb.addWorksheet(worksheetName);

    ws.columns = columns.map((el: any) => {
      return {
        key: el,
        header: el,
        width: 20,
      };
    });

    ws.getRow(1).font = { bold: true };

    columns.forEach((cell: any, rowIndex: number) => {
      if (cell) {
        let cellRef = ws.getCell(1, rowIndex + 1);
        cellRef.value = cell; // Set the cell value
        cellRef.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFAA00" },
        };
      }
    });
    ws.addRows(data);

    wb.xlsx.writeBuffer().then(function (buffer) {
      FileSaver.saveAs(new Blob([buffer], { type: fileType }), workbookName);
    });
  }
}

export default new ExcelService();
