import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import React, { Component } from "react";
import WhiteButton from "../../Components/Button/RedButton";
import CardContainer from "../../Components/Card/CardContainer";
import PageHeader from "../../Components/Text/PageHeader";

import GradientButton from "../../Components/Button/GradientButton";
interface Props {
  handleFilterColClick: () => void;
  handleSelectAll: (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  propsData?: any;
  onClickCheckBox?: (selectedRows: any) => void;
  filterCol: {
    fieldName: string;
    checked: boolean;
  }[];
  onToggleCheckbox: (fieldName: string) => void;
  onDoneClick: () => void;
  onResetClick: () => void;
}
interface State {
  data: any;
  selectedData: any;
  selectAll: boolean;
  filterPopup: boolean;
  selectedColumns: any;
  setSelectedColumns: any;
}

export default class FilterColumns extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      data: [],
      selectAll: false,
      selectedData: [],
      filterPopup: false,
      selectedColumns: [],
      setSelectedColumns: [],
    };
  }

  checkSelectAll = () => {
    const { data } = this.state;
    if (isEmpty(data)) {
      return false;
    }
    return true;
  };

  render() {
    return (
      <CardContainer>
        <div style={{ padding: "16px" }}>
          <Grid container>
            <Grid item xs={6} md={8}>
              <PageHeader label="Filter by Columns" />
            </Grid>
            <Grid item xs={6} md={4} style={{ textAlign: "right" }}>
              <FontAwesomeIcon
                size="2x"
                onClick={() => {
                  this.props.handleFilterColClick();
                  this.props.onResetClick();
                }}
                icon={faXmark}
              />
            </Grid>
          </Grid>
          <hr style={{ border: "1px solid #D1D1D1" }} />
          <Typography
            variant="subtitle1"
            m={2}
            ml={1}
            component="div"
            gutterBottom
            color={"#6E6E6E"}
          >
            Choose Multiple Column Names
          </Typography>

          <Grid container columns={2} alignItems="center">
            <Grid item xs={2} display="contents">
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                checked={this.props.filterCol.every(
                  (el) => el.checked === true
                )}
                sx={{
                  "&.Mui-checked": {
                    color: "#3DA470",
                  },
                  "& .MuiSvgIcon-root": {
                    borderRadius: "4px",
                  },
                }}
                onChange={this.props.handleSelectAll}
              />
              <Typography padding={2}>Select All</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            columns={12}
            style={{
              maxHeight: 350,
              overflowY: "scroll",
              maxWidth: 500,
            }}
          >
            {this.props.filterCol.map((el, index) => {
              return (
                <Grid item xs={6} display="flex" key={index}>
                  <>
                    <Checkbox
                      id={el.fieldName}
                      key={el.fieldName}
                      checked={el.checked}
                      tabIndex={-1}
                      onChange={(_e, checked) => {
                        this.props.onToggleCheckbox(el.fieldName);
                      }}
                      sx={{
                        "&.Mui-checked": {
                          color: "#3DA470",
                        },
                        "& .MuiSvgIcon-root": {
                          borderRadius: "4px",
                        },
                      }}
                    />
                    <Typography
                      style={{
                        wordBreak: "break-all",
                        wordWrap: "break-word",
                      }}
                      textTransform={"capitalize"}
                      id={el.fieldName}
                      sx={{ p: 2, cursor: "pointer" }}
                      onClick={() => {
                        this.props.onToggleCheckbox(el.fieldName);
                      }}
                    >
                      {el.fieldName}
                    </Typography>
                  </>
                </Grid>
              );
            })}
          </Grid>
          <hr style={{ border: "1px solid #D1D1D1" }} />
          <Grid container columnGap={1.5} justifyContent={"flex-end"}>
            <WhiteButton
              label="Reset"
              disabled={this.props.filterCol.every(
                (el) => el.checked === false
              )}
              onClick={this.props.onResetClick}
            />

            <GradientButton label="Done" onClick={this.props.onDoneClick} />
          </Grid>
        </div>
      </CardContainer>
    );
  }
}
