import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { isEqual } from "lodash";
import { Component, ReactNode } from "react";
import { FIELDS_TO_LINK } from "../../Common/Constants";
import { IconType } from "../Button/IconButton";
import ExceptionTable from "./ExceptionTable";
import SelectModel from "../Select/SelectModel";

type tableModeForException = "BulkComments" | undefined;
interface Props {
  onClickCheckBox?: (selectedRows: any, data: any) => void;
  tableData: any;
  collapseDataAll: any;
  actionArray?: IconType[];
  IconType?: IconDefinition;
  totalRecordsCount?: number;
  errTblRecordsCount: number | undefined;
  onActionClick?: (action: IconType, recordId: any) => void;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  onErrTblChangeRow: (row: number) => void;
  onErrTblChangePage: (page: number) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  handleMode: (action: tableModeForException) => void;
  handleToolbarCancel?: (exceptionModes?: string) => void;
  handleApply: (event: any) => void;
  isLoading: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
  isPaginationDisabled: boolean;
  isPaginationReset?: boolean;
  isEmptyReset?: boolean;
  isErrTblPaginationDisabled: boolean;
  isErrTblEmptyReset: boolean;
  exceptionModes: tableModeForException;
  bulkEditInputFormData: any;
  getErrorType: (record: any) => void;
  isSubTableLoading: boolean;
  usersData: SelectModel[];
  handleBulkInputChange: (event: any, targetId?: string) => void;
  onResetClick: () => void;
  isButtonLoad: boolean;
  isSaveDisabled: boolean;
  handleSave: (data: any) => void;
  openLinksData: any[];
}

interface State {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  headCollapseData: {
    key: string;
    label: string;
  }[];
  totalCount: number;
}

class ExceptionDataLoader extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      headCellData: [],
      headCollapseData: [],
      totalCount: 0,
    };
  }

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      this.props.tableData &&
      !isEqual(prevProps.tableData[0], this.props.tableData[0])
    ) {
      this.changeHeadCellData();
    }
    if (
      this.props.collapseDataAll &&
      !isEqual(prevProps.collapseDataAll[0], this.props.collapseDataAll[0])
    ) {
      this.changeHeadCollapseData();
    }
    if (prevProps.IconType !== this.props.IconType) {
      this.changeHeadCellData();
    }
    if (prevProps.actionArray?.length !== this.props.actionArray?.length) {
      this.changeHeadCellData();
    }
  };

  componentDidMount = () => {
    this.changeHeadCellData();
    this.changeHeadCollapseData();
  };

  changeHeadCellData = () => {
    if (this.props.tableData.length) {
      const headerData: any = Object.keys(this.props.tableData[0]).map((k) => {
        const shouldBeLinked = Object.keys(FIELDS_TO_LINK).includes(k);
        return {
          key: k,
          label: k,
          linkTo: shouldBeLinked
            ? FIELDS_TO_LINK[k as keyof typeof FIELDS_TO_LINK]
            : null,
        };
      });
      this.setState({ headCellData: headerData });
    } else {
      this.setState({ headCellData: [] });
    }
  };
  changeHeadCollapseData = () => {
    if (this.props.collapseDataAll.length) {
      const headerColData: any = Object.keys(this.props.collapseDataAll[0]).map(
        (c) => {
          return {
            key: c,
            label: c,
          };
        }
      );

      this.setState({ headCollapseData: headerColData });
    } else {
      this.setState({ headCollapseData: [] });
    }
  };

  render() {
    const { headCellData, headCollapseData } = this.state;
    return (
      <>
        <ExceptionTable
          openLinksData={this.props.openLinksData}
          errTblRecordsCount={this.props.errTblRecordsCount}
          isErrTblEmptyReset={this.props.isErrTblEmptyReset}
          isErrTblPaginationDisabled={this.props.isErrTblPaginationDisabled}
          onErrTblChangePage={this.props.onErrTblChangePage}
          onErrTblChangeRow={this.props.onErrTblChangeRow}
          handleSave={this.props.handleSave}
          isButtonLoad={this.props.isButtonLoad}
          isSaveDisabled={this.props.isSaveDisabled}
          onResetClick={this.props.onResetClick}
          handleBulkInputChange={this.props.handleBulkInputChange}
          usersData={this.props.usersData}
          isSubTableLoading={this.props.isSubTableLoading}
          getErrorType={this.props.getErrorType}
          data={this.props.tableData}
          dataCollapse={this.props.collapseDataAll}
          onClickCheckBox={this.props.onClickCheckBox}
          totalRecordsCount={this.props.totalRecordsCount}
          headCellData={headCellData}
          headCollapseData={headCollapseData}
          onChangePage={this.props.onChangePage}
          onChangeRow={this.props.onChangeRow}
          isLoading={this.props.isLoading}
          isEmptyReset={this.props.isEmptyReset}
          isPaginationReset={this.props.isPaginationReset}
          isPaginationDisabled={this.props.isPaginationDisabled}
          exceptionModes={this.props.exceptionModes}
          handleMode={this.props.handleMode}
          handleApply={this.props.handleApply}
          bulkEditInputFormData={this.props.bulkEditInputFormData}
        />
      </>
    );
  }
}

export default ExceptionDataLoader;
