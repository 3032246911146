import AppsIcon from "@mui/icons-material/Apps";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import {
  AlertColor,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import React, { Component, KeyboardEvent, MouseEvent } from "react";
import RedOutlinedButton from "../../Button/RedOutlinedButton";
import PageHeader from "../../Text/PageHeader";
import OtherAppsActionMenu from "./OtherAppsActionMenu";

import { ImageOutlined } from "@mui/icons-material";
import _ from "lodash";
import { FileType } from "../../../Common/Enums";
import { createOtherAppsModel } from "../../../Models/OtherAppsModel";
import MyLinkAppService from "../../../Services/MyLinkAppService";
import OtherAppService from "../../../Services/OtherAppsService";
import UserAuthService from "../../../Services/UserAuthService";
import GradientButton from "../../Button/GradientButton";
import FileUploadDetailModel from "../../File/FileUploadDetailModel";
import ImageUpload from "../../ImageUpload";
import ModalDialog from "../../Modal/ModelDialog";
import NoRecords from "../../NoRecords/NoRecords";
import SnackbarAlert from "../../SnackBarAlert/SnackbarAlert";
import TextInputField from "../../TextInputField/TextInputField";
import Utils from "../../../Common/Utils";
const MyLinkAppServices = new MyLinkAppService();
const OtherAppServices = new OtherAppService();
// const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: "#ffffff",
//     boxShadow: theme.shadows[1],
//     fontSize: 16,
//     zIndex: 999999,
//     top: "10px",
//     "& .MuiTooltip-arrow": {
//       color: "000000",
//     },
//   },
// }));
interface State {
  left: boolean;
  showDeleteDialog: boolean;
  showOtherAppsDialog: boolean;
  otherAppsData: any;
  otherAppsFormData: createOtherAppsModel;
  imgString: string | null;
  isButtonLoading: boolean;
  openAlert: boolean;
  alertMsg: string;
  alertType: AlertColor;
  file: File | null;
  imagePreview: string | null;
  isEditDialogOpen: boolean;
  responseData: any;
  isLoading: boolean;
  userRoles: any;
  myLinkAppData: any;
}

class OtherAppsDrawer extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      left: false,
      showDeleteDialog: false,
      showOtherAppsDialog: false,
      otherAppsData: [],
      otherAppsFormData: {} as createOtherAppsModel,
      imgString: "",
      isButtonLoading: false,
      openAlert: false,
      alertMsg: "",
      alertType: "success",
      file: null,
      imagePreview: "",
      isEditDialogOpen: false,
      responseData: {},
      isLoading: false,
      userRoles: [],
      myLinkAppData: [],
    };
  }
  async componentDidMount() {
    try {
      const userRole = await UserAuthService.fetchUserRoles();
      this.setState({ userRoles: userRole.data.data.data.roles });
      this.getAllAppData();
      this.getMyLinkAppData();
    } catch (error) {
      console.log(error);
    }
  }

  getMyLinkAppData = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await MyLinkAppServices.getallMyLinkAppData();
      if (response?.data.data) {
        const myLinkAppsData = response.data.data.map((el: any) => {
          return {
            id: el.id,
            name: el.appName,
            icon: el.img ? (
              <img
                src={el.img}
                style={{
                  verticalAlign: "middle",
                  height: el.appName.toLowerCase() === "sap" ? "20px" : "36px",
                  width: el.appName.toLowerCase() === "sap" ? "40px" : "36px",
                }}
                alt=""
              />
            ) : (
              <ImageOutlined
                style={{
                  verticalAlign: "middle",
                  height: "36px",
                  width: "36px",
                  color: "#999999",
                }}
              />
            ),
            url: el.appUrl,
            imgPath: el.img,
            display: el.isDisplay,
          };
        });
        const checkedData = myLinkAppsData.filter(
          (el: any) => el.display === true
        );
        this.setState({
          myLinkAppData: checkedData.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          ),
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  getAllAppData = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await OtherAppServices.getallOtherAppData();
      if (response?.data.data) {
        const otherAppsData = response.data.data.map((el: any) => {
          return {
            id: el.id,
            name: el.appName,
            icon: el.img ? (
              <img
                src={el.img}
                style={{
                  verticalAlign: "middle",
                  height: el.appName.toLowerCase() === "sap" ? "20px" : "36px",
                  width: el.appName.toLowerCase() === "sap" ? "40px" : "36px",
                }}
                alt=""
              />
            ) : (
              <ImageOutlined
                style={{
                  verticalAlign: "middle",
                  height: "36px",
                  width: "36px",
                  color: "#999999",
                }}
              />
            ),
            url: el.appUrl,
            imgPath: el.img,
          };
        });
        this.setState({
          otherAppsData: otherAppsData.sort((a: any, b: any) =>
            a.name.localeCompare(b.name)
          ),
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  retrieveDataFromStorage = () => {
    let sessionData = sessionStorage.getItem("myLinkData");
    if (sessionData) {
      let data = JSON.parse(sessionData) || [];
      this.setState({
        myLinkAppData: data.map((el: any) => {
          return {
            id: el.id,
            name: el.name,
            icon: el.imgPath ? (
              <img
                src={el.imgPath}
                style={{
                  verticalAlign: "middle",
                  height: "36px",
                  width: "36px",
                }}
                alt=""
              />
            ) : (
              <ImageOutlined
                style={{
                  verticalAlign: "middle",
                  height: "36px",
                  width: "36px",
                  color: "#999999",
                }}
              />
            ),
            url: el.url,
            imgPath: el.imgPath,
            display: el.display,
          };
        }),
      });
    }
  };
  toggleDrawer =
    (anchor: string, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      this.setState({ ...this.state, [anchor]: open }, async () => {
        this.retrieveDataFromStorage();
      });
    };
  handleEditClick = (editId: number) => {
    const EditData = this.state.otherAppsData.filter(
      (item: any, index: number) => editId === item.id
    );
    this.setState({
      isEditDialogOpen: true,
      otherAppsFormData: EditData[0],
      responseData: EditData[0],
      imagePreview: EditData[0].imgPath,
    });
  };
  handleDeleteClick = (id: number) => {
    const result = this.state.otherAppsData.filter(
      (item: any, index: number) => id === item.id
    );

    this.setState({ showDeleteDialog: true, responseData: result[0] });
  };

  renderOtherAppsItems = () => {
    return this.state.otherAppsData.length > 0 ? (
      this.state.otherAppsData.map((item: any, index: any) => (
        <Grid
          key={index}
          item
          container
          xs={6}
          mb={4}
          justifyContent={"space-between"}
          paddingRight={"30px"}
        >
          <Grid item>
            <Box
              onClick={() => Utils.openUrl(item.url)}
              style={{ cursor: "pointer" }}
            >
              <Tooltip
                arrow
                title={item.name}
                disableHoverListener={
                  item.name && item.name.toString().length < 15
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#F5F5F5",
                      padding: "5px",
                      fontSize: "13px",
                      boxShadow: "0px 3px 6px #00000029",
                      color: "#000000",
                      "& .MuiTooltip-arrow": {
                        color: "#F5F5F5",
                      },
                    },
                  },
                }}
              >
                <Typography
                  style={{
                    fontSize: "15px",
                    maxWidth: "182px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.icon}
                  <span
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    {item.name}
                  </span>
                </Typography>
              </Tooltip>
            </Box>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {this.state.userRoles.includes("PUBLICATION_LEAD") ||
            this.state.userRoles.includes("SUPER_USER") ||
            this.state.userRoles.includes("MANAGEMENT_LEADERSHIP") ? (
              <OtherAppsActionMenu
                disabled={this.state.isEditDialogOpen}
                onEditClick={() => this.handleEditClick(item.id)}
                editId={item.id}
                onDeleteClick={() => this.handleDeleteClick(item.id)}
              />
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      ))
    ) : (
      <Box
        style={{
          position: "relative",
          left: "38%",
          marginTop: "10vh",
        }}
        width={"auto"}
      >
        <NoRecords msg="No items to display" />
      </Box>
    );
  };
  renderMyLinkAppsItems = () => {
    return this.state.myLinkAppData.length > 0
      ? this.state.myLinkAppData.map((item: any, index: any) => (
          <Grid
            key={index}
            item
            container
            xs={6}
            mb={4}
            justifyContent={"space-between"}
            paddingRight={"30px"}
          >
            <Grid item>
              <Box
                onClick={() => Utils.openUrl(item.url)}
                style={{ cursor: "pointer" }}
              >
                <Tooltip
                  arrow
                  title={item.name}
                  disableHoverListener={
                    item.name && item.name.toString().length < 15
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#F5F5F5",
                        padding: "5px",
                        fontSize: "13px",
                        boxShadow: "0px 3px 6px #00000029",
                        color: "#000000",
                        "& .MuiTooltip-arrow": {
                          color: "#F5F5F5",
                        },
                      },
                    },
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "15px",
                      maxWidth: "182px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.icon}
                    <span
                      style={{
                        paddingLeft: "10px",
                      }}
                    >
                      {item.name}
                    </span>
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        ))
      : null;
  };
  onInputChange = (event: any) => {
    if (event.target.name === "name") {
      if (event.target.value.length < 101)
        this.setState({
          otherAppsFormData: {
            ...this.state.otherAppsFormData,
            [event.target.name]: event.target.value,
          },
        });
    } else {
      this.setState({
        otherAppsFormData: {
          ...this.state.otherAppsFormData,
          [event.target.name]: event.target.value,
        },
      });
    }
  };
  handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        const { name, size, type } = file;
        this.setState({
          imgString: fileReader.result?.toString() ?? null,
          file: file,
          imagePreview: e.target?.result?.toString() ?? null,
        });
        const fileModel = new FileUploadDetailModel();
        fileModel.fileName = name;
        fileModel.sizeInKB = Math.round(size / 500);
        fileModel.sizeInMB = Math.round(size / 500 / 500);

        fileModel.type = type as FileType;
        fileModel.ext = type.replace(/(.*)\//g, "");
        fileModel.file = file;
      };
    }
  };
  onSaveClick = async () => {
    try {
      this.setState({ isButtonLoading: true });
      const { otherAppsFormData, imgString } = this.state;

      const response = await OtherAppServices.createOtherAppData({
        ...otherAppsFormData,
        imgPath: imgString,
      });

      if (response?.message) {
        this.setState(
          {
            otherAppsFormData: {} as createOtherAppsModel,
            imgString,
            file: null,
            imagePreview: null,
            isButtonLoading: false,
            openAlert: true,
            alertMsg: "App data created successfully.",
            alertType: "success",
          },
          () => {
            this.getAllAppData();
            this.setState({
              showOtherAppsDialog: false,
              openAlert: false,
              imgString: null,
            });
          }
        );
      } else {
        this.setState({
          isButtonLoading: false,
          openAlert: true,
          alertMsg: "Something went wrong",
          alertType: "error",
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isButtonLoading: false,
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  onEditClick = async () => {
    try {
      this.setState({ isButtonLoading: true });
      const { otherAppsFormData, imgString, imagePreview } = this.state;
      const setFieldsObj = {
        appName: otherAppsFormData.name,
        appUrl: otherAppsFormData.url,
        img: imgString ? imgString : imagePreview,
      };
      const payloadObj = {
        setFields: setFieldsObj,
        id: { id: otherAppsFormData.id },
      };
      const response = await OtherAppServices.updateOtherAppData(payloadObj);

      if (response?.message) {
        this.setState(
          {
            otherAppsFormData: {} as createOtherAppsModel,
            imgString,
            file: null,
            imagePreview: null,
            isButtonLoading: false,
            openAlert: true,
            alertMsg: "App data updated successfully.",
            alertType: "success",
          },
          () => {
            this.getAllAppData();
            this.setState({
              isEditDialogOpen: false,
              openAlert: false,
              imgString: null,
            });
          }
        );
      } else {
        this.setState({
          isButtonLoading: false,
          openAlert: true,
          alertMsg: "Something went wrong",
          alertType: "error",
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isButtonLoading: false,
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };

  handleSubmit = (mode: string) => {
    switch (true) {
      case mode === "save":
        this.onSaveClick();
        break;
      case mode === "edit":
        this.onEditClick();
        break;

      default:
        break;
    }
  };

  onDeleteClick = async () => {
    try {
      this.setState({ isButtonLoading: true });
      const payloadObj = {
        id: this.state.responseData.id,
      };
      const response = await OtherAppServices.deleteOtherAppData(payloadObj);
      if (response?.message) {
        this.setState(
          {
            responseData: {},
            isButtonLoading: false,
            openAlert: true,
            alertMsg: "App data deleted successfully.",
            alertType: "success",
          },
          () => {
            this.getAllAppData();
            this.setState({ showDeleteDialog: false, openAlert: false });
          }
        );
      } else {
        this.setState({
          isButtonLoading: false,
          openAlert: true,
          alertMsg: "Something went wrong",
          alertType: "error",
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isButtonLoading: false,
        openAlert: true,
        alertMsg: "Something went wrong",
        alertType: "error",
      });
    }
  };
  handleCancelClick = () => {
    this.setState({
      showOtherAppsDialog: false,
      imgString: "",
      otherAppsFormData: {} as createOtherAppsModel,
      file: null,
      imagePreview: null,
      isEditDialogOpen: false,
    });
  };
  isEmptyCheck = () => {
    const { otherAppsFormData, isEditDialogOpen, responseData, imagePreview } =
      this.state;
    if (isEditDialogOpen) {
      if (
        otherAppsFormData["name"] &&
        otherAppsFormData["url"] &&
        (!_.isEqual(otherAppsFormData.name, responseData.name) ||
          !_.isEqual(otherAppsFormData.url, responseData.url) ||
          !_.isEqual(responseData.imgPath, imagePreview))
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      const values = Object.values(otherAppsFormData);
      const arr = values.filter((el) => el.trim() === "");
      if (values.length === 2 && arr.length === 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  handleSelectedFileCancel = () => {
    this.setState({ file: null, imagePreview: null, imgString: null });
  };
  render() {
    const {
      otherAppsFormData,
      showOtherAppsDialog,
      showDeleteDialog,
      isButtonLoading,
      alertMsg,
      alertType,
      openAlert,
      file,
      imagePreview,
      isEditDialogOpen,
      isLoading,
      myLinkAppData,
    } = this.state;
    return (
      <div>
        {(["left"] as const).map((anchor) => (
          <React.Fragment key={anchor}>
            <Button
              onClick={
                window.location.pathname === "/lockedview"
                  ? () => {}
                  : this.toggleDrawer(anchor, true)
              }
              size="small"
            >
              <AppsIcon
                style={{
                  color: "#ffffff",
                  paddingLeft: "0",
                  cursor:
                    window.location.pathname === "/lockedview"
                      ? "default"
                      : "pointer",
                }}
              />
            </Button>
            <Drawer
              anchor={anchor}
              open={this.state[anchor]}
              onClose={this.toggleDrawer(anchor, false)}
              sx={{
                "& .MuiBackdrop-root": {
                  opacity: "0!important",
                },
              }}
            >
              <Box sx={{ width: 555 }} role="main">
                <IconButton
                  onClick={this.toggleDrawer(anchor, false)}
                  style={{
                    position: "absolute",
                    right: "0",
                    marginTop: "0.9em",
                  }}
                  edge="start"
                >
                  <NavigateBeforeRoundedIcon
                    style={{
                      background: "#ffffff",
                      fontSize: "20px",
                      color: "#D42A1E",
                      marginLeft: "-5px",
                      border: "1px solid #D22A20",
                      borderRadius: "50%",
                    }}
                  />
                </IconButton>
                {isLoading ? (
                  <Box
                    style={{
                      margin: "30vh",
                    }}
                  >
                    <CircularProgress sx={{ color: "#d52b1e" }} />
                  </Box>
                ) : (
                  <>
                    <Box
                      mt={"6em"}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <PageHeader
                        label="Apps"
                        style={{
                          fontSize: "1.1em",
                          marginLeft: "0.8em",
                        }}
                      />
                      {/* {this.state.userRoles.includes("PUBLICATION_LEAD") ||
                      this.state.userRoles.includes("SUPER_USER") ? (
                        <>
                          {otherAppsData.length === 20 ? (
                            <LightTooltip
                              title="You’ve reached the limit of 20 apps. Please remove an existing app before adding a new one."
                              arrow
                            >
                              <Box
                                mr={"0.7rem"}
                                style={{ display: "flex", gap: "1em" }}
                              >
                                <GradientButton
                                  disabled={otherAppsData.length === 20}
                                  label="Add"
                                  onClick={() =>
                                    this.setState({ showOtherAppsDialog: true })
                                  }
                                  startIcon={<Add />}
                                />
                              </Box>
                            </LightTooltip>
                          ) : (
                            <Box
                              mr={"0.7rem"}
                              style={{ display: "flex", gap: "1em" }}
                            >
                              <GradientButton
                                label="Add"
                                onClick={() =>
                                  this.setState({ showOtherAppsDialog: true })
                                }
                                startIcon={<Add />}
                              />
                            </Box>
                          )}
                        </>
                      ) : (
                        <></>
                      )} */}
                    </Box>
                    <hr
                      style={{
                        border: "0.5px solid #D8D8D8",
                      }}
                    />
                    <Grid container rowSpacing={3} p={2} columns={12}>
                      {this.renderOtherAppsItems()}
                    </Grid>
                    {myLinkAppData.length > 0 ? (
                      <>
                        <PageHeader
                          label="My Apps"
                          style={{
                            fontSize: "1.1em",
                            marginTop: "0.8em",
                            marginLeft: "0.8em",
                          }}
                        />
                        <hr
                          style={{
                            border: "0.5px solid #D8D8D8",
                          }}
                        />
                        <Grid container rowSpacing={3} p={2} columns={12}>
                          {this.renderMyLinkAppsItems()}
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>

              <ModalDialog
                isOpen={showOtherAppsDialog || isEditDialogOpen}
                title={
                  showOtherAppsDialog
                    ? "Add App"
                    : isEditDialogOpen
                    ? "Edit App"
                    : ""
                }
                onClose={isButtonLoading ? () => {} : this.handleCancelClick}
                dialogWidth="md"
              >
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: "red",
                    textAlign: "right",
                    fontStyle: "italic",
                  }}
                >
                  * All Fields Mandatory
                </Typography>
                <Grid container>
                  <>
                    <SnackbarAlert
                      alertType={alertType}
                      open={openAlert}
                      message={alertMsg}
                      onClose={() => {
                        this.setState({ openAlert: false });
                      }}
                    />

                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <InputLabel
                          sx={{
                            visibility: otherAppsFormData.name
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          App Name
                        </InputLabel>
                        <TextInputField
                          placeholder="Enter App Name"
                          name="name"
                          id="name"
                          value={otherAppsFormData.name}
                          onChange={this.onInputChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          sx={{
                            visibility: otherAppsFormData.url
                              ? "visible"
                              : "hidden",
                          }}
                        >
                          App URL
                        </InputLabel>
                        <TextInputField
                          placeholder="Enter App URL"
                          name="url"
                          id="url"
                          value={otherAppsFormData.url}
                          onChange={this.onInputChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <ImageUpload
                          imagePreview={imagePreview}
                          onCancel={this.handleSelectedFileCancel}
                          file={file}
                          onChange={this.handleImageChange}
                        />
                      </Grid>
                    </Grid>
                  </>
                </Grid>
                <Grid item container mt={2.5}>
                  <Grid item>
                    <RedOutlinedButton
                      disabled={isButtonLoading}
                      label="Cancel"
                      onClick={this.handleCancelClick}
                    />
                  </Grid>
                  <Grid item ml={1.5}>
                    <GradientButton
                      disabled={
                        isButtonLoading ? isButtonLoading : this.isEmptyCheck()
                      }
                      isButtonLoad={isButtonLoading}
                      label={isEditDialogOpen ? "Update" : "Save"}
                      onClick={() =>
                        this.handleSubmit(isEditDialogOpen ? "edit" : "save")
                      }
                    />
                  </Grid>
                </Grid>
              </ModalDialog>
              <ModalDialog
                isOpen={showDeleteDialog}
                title="Delete App"
                onClose={() => this.setState({ showDeleteDialog: false })}
                dialogWidth="sm"
              >
                <SnackbarAlert
                  alertType={alertType}
                  open={openAlert}
                  message={alertMsg}
                  onClose={() => {
                    this.setState({ openAlert: false });
                  }}
                />
                <Typography>Are you sure you want to delete ?</Typography>
                <Grid item container mt={2.5}>
                  <Grid item>
                    <RedOutlinedButton
                      label="No"
                      onClick={() =>
                        this.setState({
                          showDeleteDialog: false,
                          responseData: {},
                        })
                      }
                    />
                  </Grid>
                  <Grid item ml={1.5}>
                    <GradientButton
                      isButtonLoad={isButtonLoading}
                      label="Yes"
                      onClick={this.onDeleteClick}
                    />
                  </Grid>
                </Grid>
              </ModalDialog>
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

export default OtherAppsDrawer;
