import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faEllipsisH as ActionMenuIcon,
  faEllipsisVertical as OtherAppsActionMenuIcon,
  faPlus as AddIcon,
  faUserPen as AssignForCorrection,
  faUserPlus as AssigntoApprover,
  faClone as CloneIcon,
  faCloudUploadAlt as CloudUploadIcon,
  faComment as CommentIcon,
  faTrashCan as DeleteForeverIcon,
  faPenToSquare as EditIcon,
  faEnvelopeCircleCheck as EmailApprover,
  faExternalLinkAlt as PopupIcon,
  faXmark as cancelIcon,
  faCheck as checkIcon,
  faEye as detailInfoIcon,
  faRefresh,
  faTimesCircle as rejectIcon,
  faCheckCircle as reqCheckIcon,
  faFileText as fileUploadIcon,
  faUpload as uploadBtnIcon,
  faDownload as downloadBtnIcon,
  faShareAlt as shareBtnIcon,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import { Grid, IconButton as IButton, Typography } from "@mui/material";
import React from "react";
import Reassign from "./ReAssign.svg";

import ModalDialog from "../Modal/ModelDialog";
import BlueButton from "./BlueButton";
import RedButton from "./RedButton";
import WhiteButton from "./WhiteButton";
import "./style.css";

export type IconType =
  | "Clone"
  | "ActionMenu"
  | "OtherAppsActionMenu"
  | "Comments"
  | "Add"
  | "Edit"
  | "AssigntoApprover"
  | "Delete"
  | "EmailOwner"
  | "EmailApprover"
  | "DeleteDialog"
  | "CloudUpload"
  | "Popup"
  | "ReAssign"
  | "Approve"
  | "Cancel"
  | "AssignForCorrection"
  | "Approvereq"
  | "Reject"
  | "MoreInfo"
  | "Refresh"
  | "FileUpload"
  | "Upload"
  | "Download"
  | "Share"

interface Props {
  id?: string;
  IconType: IconType;
  isFR: boolean;
  isDisabled: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: React.CSSProperties;
}
interface State {
  isDialogOpen: boolean;
  isHovered: boolean;
}

class IconButton extends React.Component<Props, State> {
  public static defaultProps = {
    isFR: false,
    isDisabled: true,
  };
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      isDialogOpen: false,
      isHovered: false,
    };
  }
  render() {
    const { id, IconType, isFR, onClick, isDisabled, style } = this.props;
    const { isDialogOpen, isHovered } = this.state;

    const buttonStyle: React.CSSProperties = {
      backgroundImage: isHovered
        ? "linear-gradient(to right, #D52B1E,#78105B)"
        : "none",

      borderRadius: "4px",
      border: "1px solid",
      color: isHovered ? "#FFFFFF" : "#d52b1e",
      ...style,
    };
    const buttonStyleNoBorder: React.CSSProperties = {
      borderRadius: "4px",
      color: isDisabled ? "#a8a8a8" : "#d52b1e",
      ...style,
    };
    const buttonStyleOtherApps: React.CSSProperties = {
      color: "#999999",
      ...style,
    };
    const btnStyle: React.CSSProperties = { color: "#d52b1e" };
    const iconStyle: React.CSSProperties = {
      color: isDisabled ? "#a8a8a8":"#D52B1E",
      width: "30px",
      height: "33px",
      background: isDisabled ? "#ededed":"#FFDEDE",
      padding: "25px",
      fontSize: "25px",
    };
    let CloudIconSize: SizeProp = "lg";

    if (isFR) {
      buttonStyle.backgroundColor = "#004693";
      CloudIconSize = "2x";
    }

    if (isDisabled) {
      btnStyle.color ="#616161";
    }
    switch (IconType) {
      case "Refresh":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={faRefresh} size="xs" />
          </IButton>
        );
      case "Add":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={AddIcon} size="xs" />
          </IButton>
        );
      case "Comments":
        return (
          <IButton
            style={buttonStyle}
            onClick={onClick}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
          >
            <FontAwesomeIcon id={id} icon={CommentIcon} size="xs" />
          </IButton>
        );
      case "Clone":
        return (
          <IButton
            style={buttonStyle}
            onClick={onClick}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
          >
            <FontAwesomeIcon id={id} icon={CloneIcon} size="xs" />
          </IButton>
        );
      case "ActionMenu":
        return (
          <>
            <IButton style={buttonStyleNoBorder} onClick={onClick}>
              <FontAwesomeIcon id={id} icon={ActionMenuIcon} size="xs" />
            </IButton>
          </>
        );
      case "OtherAppsActionMenu":
        return (
          <>
            <IButton style={buttonStyleOtherApps} onClick={onClick}>
              <FontAwesomeIcon id={id} icon={OtherAppsActionMenuIcon} size="xs" />
            </IButton>
          </>
        );
      case "Edit":
        return (
          <IButton
            style={buttonStyle}
            onClick={onClick}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
          >
            <FontAwesomeIcon id={id} icon={EditIcon} size="xs" />
          </IButton>
        );
      case "Delete":
        return (
          <IButton
            style={buttonStyle}
            onClick={onClick}
            onMouseEnter={() => this.setState({ isHovered: true })}
            onMouseLeave={() => this.setState({ isHovered: false })}
          >
            <FontAwesomeIcon id={id} icon={DeleteForeverIcon} size="xs" />
          </IButton>
        );
      case "ReAssign":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <img src={Reassign} alt="Reassign" width="80%" />
          </IButton>
        );
      case "AssigntoApprover":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={AssigntoApprover} size="xs" />
          </IButton>
        );
      case "Approve":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={checkIcon} size="xs" />
          </IButton>
        );
      case "EmailOwner":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <ContactMailIcon />
          </IButton>
        );
      case "EmailApprover":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={EmailApprover} size="sm" />
          </IButton>
        );
      case "AssignForCorrection":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={AssignForCorrection} size="xs" />
          </IButton>
        );
      case "Cancel":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={cancelIcon} size="xs" />
          </IButton>
        );
      case "DeleteDialog":
        return (
          <>
            <ModalDialog
              title={`Delete Action`}
              dialogWidth="sm"
              isOpen={isDialogOpen}
              onClose={this.handleCancel}
              dialogAction={
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ marginRight: 30 }}
                >
                  <Grid item>
                    <WhiteButton label="Cancel" onClick={this.handleCancel} />
                  </Grid>
                  <Grid item>
                    {isFR ? (
                      <BlueButton label="Delete" onClick={this.onDeleteClick} />
                    ) : (
                      <RedButton label="Delete" onClick={this.onDeleteClick} />
                    )}
                  </Grid>
                </Grid>
              }
            >
              <Typography
                style={{ fontSize: 16, fontWeight: 400, color: "#383838" }}
              >
                Are you sure you want to delete ?
              </Typography>
            </ModalDialog>
            <IButton style={buttonStyle} onClick={this.handleDelete}>
              <FontAwesomeIcon id={id} icon={DeleteForeverIcon} size="xs" />
            </IButton>
          </>
        );
      case "CloudUpload":
        return (
          <IButton style={btnStyle} disabled={isDisabled} onClick={onClick}>
            <FontAwesomeIcon
              id={id}
              icon={CloudUploadIcon}
              size={CloudIconSize}
            />
          </IButton>
        );
      case "Popup":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={PopupIcon} size="xs" />
          </IButton>
        );
      // case 'Edit':
      // return (
      //     <IButton style={buttonStyle} onClick={onClick}>
      //         <FontAwesomeIcon id={id} icon={EditIcon} size='xs' />
      //     </IButton>
      // );
      case "Approvereq":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon
              id={id}
              icon={reqCheckIcon}
              size="xs"
              style={{ color: "green" }}
            />
          </IButton>
        );
      case "Reject":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={rejectIcon} size="xs" />
          </IButton>
        );
      case "MoreInfo":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={detailInfoIcon} size="xs" />
          </IButton>
        );
      case "FileUpload":
        return (
          <IButton style={iconStyle} disabled={isDisabled} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={fileUploadIcon} size="sm" />
          </IButton>
        );
      case "Upload":
        return (
          <IButton style={buttonStyle} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={uploadBtnIcon} size="xs" />
          </IButton>
        );
        case "Download":
        return (
          <IButton style={buttonStyleNoBorder} disabled={isDisabled} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={downloadBtnIcon} size="xs" />
          </IButton>
        );
      case "Share":
        return (
          <IButton style={buttonStyleNoBorder} disabled={isDisabled} onClick={onClick}>
            <FontAwesomeIcon id={id} icon={shareBtnIcon} size="xs"/>
          </IButton>
        );
      default:
        return <></>;
    }
  }

  onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.handleCancel();
    this.props.onClick(event);
  };

  handleDelete = () => {
    this.setState({ isDialogOpen: true });
  };

  handleCancel = () => {
    this.setState({ isDialogOpen: false });
  };
}

export default IconButton;
