import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { DragEvent } from "react";

import PublishIcon from "@mui/icons-material/Publish";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FileType } from "../../Common/Enums";
import GradientButton from "../../Components/Button/GradientButton";
import IconButton from "../../Components/Button/IconButton";
import FileUploadDetailModel from "./FileUploadDetailModel";

interface Props extends RouteComponentProps<any, any, any> {
  isLoading: boolean;
  isDisabled: boolean;
  isUploaded?: boolean;
  isSourceSelect?: boolean;
  onChange: (file: FileUploadDetailModel) => void;
  onSubmitClick: () => void;
  isSupportLoad?: boolean;
  sizeValidation?: string;
}

interface State {
  isDragOver: boolean;
}

class FileUpload extends React.Component<Props, State> {
  public static defaultProps = {
    isUploaded: false,
    isSourceSelect: false,
    sizeValidation: "70",
  };
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      isDragOver: false,
    };
  }

  fileUploadInput: HTMLInputElement | null = null;

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (target.files && target.files.length > 0) {
      this.handleAndCreateFileUpload(target.files[0]);
    }
  };

  render() {
    const {
      isDisabled,
      isLoading,
      isUploaded,
      isSourceSelect,
      isSupportLoad,
      sizeValidation,
    } = this.props;
    const { isDragOver } = this.state;

    let border = "1px dashed";
    let borderColor = "#D52B1E";
    let backgroundColor = "#ffffff";
    let bgShadow = "0px 0px 24px #B7B7B729";
    let hintColor = "#62686E";
    let browseStyle: React.CSSProperties = {
      color: isUploaded ? "#909090" : isSourceSelect ? "#A7A7A7" : "#D52B1E",
      cursor: isUploaded || isSourceSelect ? "" : "pointer",
    };
    let fileUploadId = "cvUpload";
    let acceptFile = isSupportLoad
      ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv"
      : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    return (
      <Box pb={2}>
        <Box
          mb={2}
          border={border}
          borderColor={borderColor}
          p={2}
          bgcolor={backgroundColor}
          boxShadow={bgShadow}
          borderRadius={3}
          onDragOver={
            isLoading || isUploaded || isSourceSelect
              ? () => {}
              : this.handleDragOver
          }
          onDrop={
            isLoading || isUploaded || isSourceSelect
              ? () => {}
              : this.handleDrop
          }
          onDragLeave={
            isLoading || isUploaded || isSourceSelect
              ? () => {}
              : this.handleDragLeave
          }
        >
          {isLoading ? (
            <Grid
              item
              style={{ height: "10em" }}
              display="flex"
              justifyContent="center"
            >
              <CircularProgress
                disableShrink
                sx={{ color: "#d52b1e", marginTop: 8 }}
              />
            </Grid>
          ) : (
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                style={{
                  cursor: isSourceSelect ? "" : "pointer",
                }}
              >
                <IconButton
                  isDisabled={isSourceSelect ? true : false}
                  style={{
                    color: isSourceSelect ? "#A7A7A7" : "#D52B1E",
                  }}
                  IconType="FileUpload"
                  onClick={this.handleUploadIconClick}
                />
              </Grid>
              <Grid item>
                {!isDragOver ? (
                  <Typography
                    style={{
                      color: "#1D1D1D",
                      fontWeight: 600,
                    }}
                  >
                    Drag and Drop files, or
                    <input
                      ref={(input) => (this.fileUploadInput = input)}
                      accept={acceptFile}
                      style={{ display: "none" }}
                      id={fileUploadId}
                      type="file"
                      disabled={isUploaded || isSourceSelect}
                      onChange={this.handleChange}
                    />
                    &nbsp;
                    <label htmlFor={fileUploadId} style={browseStyle}>
                      Browse
                    </label>
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      color: "#1D1D1D",
                      fontWeight: 400,
                    }}
                  >
                    Drop your file here
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography
                  style={{ color: hintColor, fontWeight: 400, fontSize: 14 }}
                >
                  {`.xlsx${
                    isSupportLoad ? ", .csv" : ""
                  } file is supported. File can not exceed more than ${sizeValidation} MB.`}
                </Typography>
              </Grid>
              <Grid item>
                <GradientButton
                  disabled={isDisabled}
                  id="fileUploadId"
                  label="Upload"
                  onClick={this.props.onSubmitClick}
                  startIcon={<PublishIcon />}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    );
  }
  handleAndCreateFileUpload = (file: File) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      const { name, size, type } = file;

      const fileModel = new FileUploadDetailModel();
      fileModel.fileName = name;
      fileModel.sizeInKB = Math.round(size / 1024);
      fileModel.sizeInMB = Math.round(size / 1024 / 1024);

      fileModel.type = type as FileType;
      fileModel.ext = type.replace(/(.*)\//g, "");
      fileModel.file = file;
      this.props.onChange(fileModel);
    };
  };

  handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isDragOver: true });
  };

  handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isDragOver: false }, () => {
      const { files } = event.dataTransfer;

      if (files && files.length > 0) {
        this.handleAndCreateFileUpload(files[0]);
        event.dataTransfer.clearData();
      }
    });
  };

  handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isDragOver: false });
  };

  handleUploadIconClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (this.fileUploadInput) {
      this.fileUploadInput.value = "";
      this.fileUploadInput.click();
    }
  };
  getFormattedFileName = (file: FileUploadDetailModel) => {
    if (file.fileName.length > 20) {
      return `${file.fileName.substring(0, 20)}... (${file.sizeInKB} KB)`;
    }

    return `${file.fileName} (${file.sizeInKB} KB)`;
  };

  getFileRecordsCount = (file: FileUploadDetailModel) => {
    if (file.totalRecords) {
      return `${file.totalRecords}`;
    }

    return `${file.totalRecords}`;
  };
}
export default withRouter(FileUpload);
