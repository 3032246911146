import { URIs } from "../Common/Constants";
import {
  GetSDMTableRequestModel,
  GetColumnDetailsRequestModel,
  GetColumnDetailsResponseModel,
  SDMRecordPayloadModel,
  ReadRationalePayloadModel,
  KeyTablesModel,
  SDMFileSearchModel,
} from "../Models/SDMTableRequestModel";
import { UploadedFilesModel } from "../Models/UploadFileModel";
import GenericAPIService from "./GenericAPIService";
import S3FileService from "./S3FileService";

const {
  GET_SDM_TABLE_DATA,
  GET_COLUMN_DETAILS,
  CREATE_SDM_RECORDS,
  UPDATE_SDM_RECORDS,
  DELETE_SDM_RECORDS,
  READ_RATIONALE,
  KEY_TABLES,
  SDM_FILEUPLOAD,
  CREATE_SDM_FILEUPLOAD,
  GET_SDM_UPLOADED_FILESDATA,
  SDMFILE_SEARCH,
} = URIs;
class SDMTableService extends GenericAPIService {
  async getSDMTableData(body: GetSDMTableRequestModel) {
    const { data } = await this.postAsync<{ result: any; total_count: any }>(
      GET_SDM_TABLE_DATA,
      body
    );
    return data;
  }
  async getColumnDetails(body: GetColumnDetailsRequestModel) {
    const { data } = await this.postAsync<{
      data: { fieldsData: GetColumnDetailsResponseModel[] };
    }>(GET_COLUMN_DETAILS, body);
    return data;
  }

  async createRecords(body: SDMRecordPayloadModel) {
    const { data } = await this.postAsync<{ result: any }>(
      CREATE_SDM_RECORDS,
      body
    );
    return data;
  }
  async updateRecords(body: SDMRecordPayloadModel) {
    const { data } = await this.postAsync<{ result: any }>(
      UPDATE_SDM_RECORDS,
      body
    );
    return data;
  }
  async deleteRecords(body: SDMRecordPayloadModel) {
    const { data } = await this.postAsync<{ result: any }>(
      DELETE_SDM_RECORDS,
      body
    );
    return data;
  }
  async readRationaleData(body: ReadRationalePayloadModel) {
    const { data } = await this.postAsync<{ result: any }>(
      READ_RATIONALE,
      body
    );
    return data;
  }
  async getKeyTables(body: KeyTablesModel) {
    const { data } = await this.postAsync<{ result: any }>(KEY_TABLES, body);
    return data;
  }
  async uploadSDMFile(file: any, totalRecords: number) {
    const fileNameWithExt = file.fileName;

    const fileObj = {
      key: fileNameWithExt,
      fileType: file.type,
      fileName: fileNameWithExt.split(".")[0],
    };

    const filePreSignedResponse = await this.postAsync<any>(
      SDM_FILEUPLOAD,
      fileObj
    );

    if (filePreSignedResponse.data) {
      // put with presigned URL
      try {
        if (file.file) {
          const { presigned_url } = filePreSignedResponse.data.data;

          const isFileUploadSuccess = await S3FileService.putAsync(
            presigned_url,
            file.type.toString(),
            file.file
          );

          if (isFileUploadSuccess) {
            // add entry in table
            const supportFileResponse = await this.postAsync(
              CREATE_SDM_FILEUPLOAD,
              { name: fileNameWithExt, totalRecords }
            );
            return supportFileResponse;
          } else {
            throw new Error("Failed to upload file");
          }
        } else {
          throw new Error("No file to upload");
        }
      } catch (error) {
        console.log("upload-error", error);
      }
    } else {
      console.log("filePreSignedResponse", filePreSignedResponse);
    }
  }
  async getUploadedFilesDataForUsers(noOfRecords: number, pageNo: number) {
    try {
      const { data } = await this.getAsync<{ data: UploadedFilesModel }>(
        `${GET_SDM_UPLOADED_FILESDATA}?noOfRecords=${noOfRecords}&pageNo=${pageNo}`
      );

      return data.data;
    } catch (error) {
      console.log("error in fetching fileData for users", error);
    }
  }
  async searchFileData(body: SDMFileSearchModel) {
    try {
      const { data } = await this.postAsync<{ data: any }>(
        SDMFILE_SEARCH,
        body
      );

      return data;
    } catch (error) {
      console.log("error in fetching fileData for users", error);
    }
  }
}

export default SDMTableService;
