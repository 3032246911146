import { URIs } from "../Common/Constants";
import {
  OnDemandRptHistoryModel,
  OnDemandRptSubmissionModel,
  OnDemandSelectionModel,
} from "../Models/OnDemandReportModels";

import GenericAPIService from "./GenericAPIService";

const {
  ON_DEMAND_SELECTION_DATA,
  ON_DEMAND_RPT_SUBMISSION,
  ON_DEMAND_RPT_HISTORY,
  REPORT_DOWNLOAD,
} = URIs;
class OnDemandReportService extends GenericAPIService {
  async getSelectionData() {
    const data = await this.getAsync<{ result: OnDemandSelectionModel[] }>(
      ON_DEMAND_SELECTION_DATA
    );
    return data;
  }
  async submitReport(body: OnDemandRptSubmissionModel) {
    const { data } = await this.postAsync<{ result: any }>(
      ON_DEMAND_RPT_SUBMISSION,
      body
    );
    return data;
  }
  async getRptHistory(body: OnDemandRptHistoryModel) {
    const { data } = await this.postAsync<{ result: any; total_count: any }>(
      ON_DEMAND_RPT_HISTORY,
      body
    );
    return data;
  }
  async getFile(
    key: string,
    countryName: string,
    folderName: string
  ): Promise<string | null> {
    const fileObj = {
      key,
      countryName,
      folderName,
    };

    const data = await this.postAsync<{ data: any }>(REPORT_DOWNLOAD, fileObj);

    return data.data.data.presigned_url;
  }
}

export default OnDemandReportService;
