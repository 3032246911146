import React, { Component } from "react";
import { UserLoginContext } from "..";

interface Props extends React.PropsWithChildren {}

interface State {
  isUserLoggedIn: boolean;
}

class UserLoginProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isUserLoggedIn: true,
    };
  }
  setUserLogin = (isUserLoggedIn: boolean) => {
    this.setState({ isUserLoggedIn });
  };
  render() {
    return (
      <UserLoginContext.Provider
        value={{
          isUserLoggedIn: this.state.isUserLoggedIn,
          setIsLoggedIn: this.setUserLogin,
        }}
      >
        {this.props.children}
      </UserLoginContext.Provider>
    );
  }
}

export default UserLoginProvider;
