import {
  Grid,
  SnackbarOrigin,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React from "react";

import BreadCrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { IBreadCrumb } from "../../../Components/BreadCrumb/IBreadCrumb";
import IconButton from "../../../Components/Button/IconButton";

import { RouteComponentProps, withRouter } from "react-router-dom";
import { FileType } from "../../../Common/Enums";
import { AccessedSDMTable } from "../../../Models/SDMTableAccessModel";
import { GetColumnDetailsResponseModel } from "../../../Models/SDMTableRequestModel";

import _ from "lodash";
import * as XLSX from "xlsx";
import { SDM_FILE_UPLOAD_TABLES } from "../../../Common/Constants";
import FileUploadDetailModel from "../../../Components/File/FileUploadDetailModel";
import ModalDialog from "../../../Components/Modal/ModelDialog";
import SDMTableService from "../../../Services/SDMTableService";
import UserAuthService from "../../../Services/UserAuthService";
import FileNewUpload from "./FileNewUpload";

import { faCircleInfo as InfoIcon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Utils from "../../../Common/Utils";
import AuthProviderService from "../../../Services/AuthProviderService";
import FilesInfo from "./FilesInfo";
import { RoleContext } from "../../../Contexts";
const SDMTableServices = new SDMTableService();
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[10],
    fontSize: 13,
    maxWidth: "none",
    ZIndex: 99999,
    "& .MuiTooltip-arrow": {
      color: "#FFFFFF",
    },
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
    fontSize: 14,
  },
}));
interface Props extends RouteComponentProps<any, any, any> {}

interface State extends SnackbarOrigin {
  isDragOver: boolean;
  isError: boolean;
  isLoading: boolean;
  columnDetails: GetColumnDetailsResponseModel[];
  tblData: any[];
  dynamicTable: any[];
  filterCol: { fieldName: string; checked: boolean }[];
  uniqueColumnObj: any;
  uniqueColumnValue: string;
  totalRecordCount: number;
  isSDMListLoading: boolean;
  accessedSdmTableData: AccessedSDMTable[];
  roleData: any;
  selectedSdmTableValue: string;
  selectedCountry: string;
  selectedStatus: string;
  uploadedFileName: string;
  uploadedFileCount: any;
  uploadMessage: string;
  isSubmitted: boolean;
  submittedMsg: string;
  disbldSubmit: boolean;
  submittedErrMsg: string;
  isSubmtdError: boolean;
  isSuccess: boolean;
  tableHeadData: any;
  tableNameList: any;
  files: FileUploadDetailModel[];
  totalRecordNo: number | null;
  isRefreshTableData: boolean;
  filesInfo: any;
  userId: string;
}

class UploadFile extends React.Component<Props, State> {
  static contextType = RoleContext;
  context!: React.ContextType<typeof RoleContext>;
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      vertical: "top",
      horizontal: "center",
      isDragOver: false,
      tblData: [],
      isError: false,
      isLoading: false,
      columnDetails: [],
      dynamicTable: [],
      filterCol: [],
      uniqueColumnObj: {},
      uniqueColumnValue: "",
      totalRecordCount: 0,
      isSDMListLoading: false,
      accessedSdmTableData: [],
      roleData: [],
      selectedSdmTableValue: "",
      selectedCountry: "",
      selectedStatus: "",
      uploadedFileName: "",
      uploadedFileCount: [],
      uploadMessage: "",
      isSubmitted: false,
      submittedMsg: "",
      disbldSubmit: false,
      submittedErrMsg: "",
      isSubmtdError: false,
      isSuccess: false,
      tableHeadData: [],
      tableNameList: [],
      files: [],
      totalRecordNo: null,
      isRefreshTableData: false,
      filesInfo: [],
      userId: "",
    };
  }
  async componentDidMount() {
    const roleResponse = await UserAuthService.fetchUserRoles();
    const rolesAssigned = roleResponse.data.data.data.roles;
    const token = await AuthProviderService.getAccessToken();
    const decodedToken = JSON.stringify(Utils.decodeJWT(token.accessToken));
    const userId = JSON.parse(decodedToken).uid;
    this.setState({
      userId,
      uniqueColumnObj: this.context.uniqueColumnObj,
    });
    let foundArrays = [];
    let sdmFileTable: any = SDM_FILE_UPLOAD_TABLES;

    for (const role of rolesAssigned) {
      if (sdmFileTable[role]) {
        foundArrays.push({
          roleName: role,
          fileName: sdmFileTable[role],
        });
      }
    }

    if (rolesAssigned) {
      const tableList = this.findTableList(rolesAssigned);
      this.setState({
        tableNameList: [...new Set(tableList)],
        filesInfo: foundArrays,
      });
    }
  }
  findTableList = (rolesAssigned: any) => {
    let foundArrays = [];
    let sdmFileTable: any = SDM_FILE_UPLOAD_TABLES;

    for (const role of rolesAssigned) {
      if (sdmFileTable[role]) {
        foundArrays.push(sdmFileTable[role]);
      }
    }

    if (foundArrays.length > 1) {
      return foundArrays.reduce((concatenatedArray, currentArray) => {
        return concatenatedArray.concat(currentArray);
      }, []);
    } else if (foundArrays.length === 1) {
      return foundArrays[0];
    } else {
      return [];
    }
  };
  breadcrumbs: IBreadCrumb[] = [
    { text: "Back", link: "/sdm" },
    { text: "SDM - Files" },
  ];
  fileUploadInput: HTMLInputElement | null = null;

  handleClose = () => {
    this.setState({
      isError: false,
      isSuccess: false,
      isSubmitted: false,
      isSubmtdError: false,
    });
  };
  handleDeleteClick = (file: FileUploadDetailModel) => {
    if (file) {
      const fltrdArr = this.state.files.filter(
        (arr) => arr.fileName !== file.fileName
      );
      this.setState({ files: fltrdArr });
    }
  };
  action = (
    <>
      <IconButton IconType="Cancel" onClick={this.handleClose} />
    </>
  );

  render() {
    return (
      <>
        <Grid mt={"3em"} container direction="row">
          <BreadCrumb breadcrumbs={this.breadcrumbs} />
        </Grid>
        <Grid mb={"0.5em"} container direction="row">
          <Grid item container xs={12} justifyContent="flex-end">
            <Grid item xs={0.19} justifyContent="flex-end">
              <FontAwesomeIcon
                style={{ color: "#263F6A", fontWeight: 300 }}
                icon={InfoIcon}
              />
            </Grid>
            <Grid item xs={1.5} justifyContent="flex-end">
              <>
                <LightTooltip
                  placement="bottom-end"
                  title={<FilesInfo values={this.state.filesInfo} />}
                  arrow
                >
                  <Typography
                    color={"#000"}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: 650,
                    }}
                  >
                    Allowed Files Info
                  </Typography>
                </LightTooltip>
              </>
            </Grid>
          </Grid>
        </Grid>

        <ModalDialog
          isOpen={this.state.isError || this.state.isSubmtdError}
          title={"File Upload Error"}
          onClose={this.handleClose}
          dialogWidth="sm"
        >
          <Typography>
            {this.state.isError
              ? this.state.uploadMessage
              : this.state.isSubmtdError
              ? this.state.submittedErrMsg
              : ""}
          </Typography>
        </ModalDialog>
        <FileNewUpload
          isRefresh={this.state.isRefreshTableData}
          submittedErrMsg={this.state.submittedErrMsg}
          onSubmitClick={this.handleSubmitClick}
          files={this.state.files}
          isError={this.state.isError}
          isLoading={this.state.isLoading}
          isDisabled={this.state.files.length > 0 ? false : true}
          onChange={this.handleFileUpload}
          onDelete={this.handleDeleteClick}
          onClose={this.handleClose}
          uploadMessage={this.state.uploadMessage}
          isSuccess={this.state.isSuccess}
          isSubmitted={this.state.isSubmitted}
          submittedMsg={this.state.submittedMsg}
          isSubmtdError={this.state.isSubmtdError}
        />
      </>
    );
  }

  handleFileUpload = (file: FileUploadDetailModel) => {
    const fileData = file.file;
    if (fileData) {
      this.getDataFromExcel(fileData);
    }
  };
  getDataFromExcel = async (file: File) => {
    this.setState({ isLoading: true });

    if (
      this.state.tableNameList.filter(
        (el: any) => el.split(".")[0] === file.name.split(".")[0]
      ).length > 0 ||
      this.state.tableNameList.filter(
        (el: any) => el.split(".")[0] === file.name.split(" (")[0]
      ).length > 0
    ) {
      if (file.type !== FileType.XLSX) {
        this.setState({ isLoading: true });
        this.setState(
          {
            isError: true,
            uploadMessage:
              "File format is invalid. Please convert the file to excel (.xlsx) and resubmit the file.",
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
      } else if (!this.state.tableNameList.includes(file.name)) {
        this.setState(
          {
            isError: true,
            uploadMessage:
              "File is not allowed; ensure that it adheres to the SDM table. For more information, please refer to the Allowed Files Info.",
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
      } else if (file.size >= 73402880) {
        this.setState(
          {
            isError: true,
            uploadMessage: "File size should not exceed more than 70 mb.",
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
      } else {
        if (
          this.state.tableNameList.filter(
            (el: any) => el.split(".")[0] === file.name.split(".")[0]
          ).length > 0 ||
          this.state.tableNameList.filter(
            (el: any) => el.split(".")[0] === file.name.split(" (")[0]
          ).length > 0
        ) {
          if (
            this.state.tableNameList.filter(
              (el: any) => el.split(".")[0] === file.name.split(" (")[0]
            ).length > 0
          ) {
            this.setState(
              {
                isError: true,
                uploadMessage:
                  "File is not allowed; ensure that it adheres to the SDM table. For more information, please refer to the Allowed Files Info.",
              },
              () => {
                this.setState({ isLoading: false });
              }
            );
          } else {
            const reader = new FileReader();

            // const rABS = !!reader.readAsBinaryString;
            reader.onload = async (evt) => {
              /* Parse data */
              const bstr = evt.target?.result;
              const wb = XLSX.read(bstr, {
                type: "binary",
                cellDates: true,
                raw: false,
                dateNF: "mm/dd/yyyy;@",
              });

              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */

              const data: any = XLSX.utils.sheet_to_json(ws, {
                header: 1,
                blankrows: false,
                raw: false,
                dateNF: "mm/dd/yyyy;@",
              });

              if (data.length - 1 > 50000) {
                this.setState(
                  {
                    isError: true,
                    uploadMessage:
                      "Maximum number of rows exceeded in the submitted file. Maximum 50,000 rows/records per file is allowed.",
                  },
                  () => {
                    this.setState({ isLoading: false });
                  }
                );
              } else {
                try {
                  const response = await SDMTableServices.getColumnDetails({
                    tableName: file.name.split(".")[0].toLowerCase(),
                  });

                  let fieldName = response.data.fieldsData.map(
                    (key) => key.fieldName
                  );

                  fieldName.filter(
                    (val) =>
                      val.toLowerCase() ===
                      this.state.uniqueColumnValue.toLowerCase()
                  );
                  fieldName.unshift(
                    this.state.uniqueColumnObj[
                      file.name.split(".")[0]
                    ].toLowerCase()
                  );

                  let obj: any = {};
                  fieldName.map((value) => {
                    return (obj[value] = null);
                  });

                  const uniqueColumn =
                    this.state.uniqueColumnObj[file.name.split(".")[0]];
                  var extraColumnValue = "";
                  Object.keys([obj][0]).map((el: any) => {
                    if (el === "last_updt_usr")
                      extraColumnValue = "last_updt_usr";
                    if (el === "lst_updt_usr")
                      extraColumnValue = "lst_updt_usr";
                    if (el === "ovr_last_updt_usr")
                      extraColumnValue = "ovr_last_updt_usr";
                    return extraColumnValue;
                  });
                  const tableColumn: any = Object.keys([obj][0]).filter(
                    (el) =>
                      ![
                        "primary_col_name",
                        "last_updt_dt",
                        "lst_updt_dt",
                        "ovr_last_updt_dt",
                        "review_status",
                        "notification_id",
                        uniqueColumn.toLowerCase(),
                      ].includes(el)
                  );

                  // Add a new header for the extra column
                  data[0].push(extraColumnValue); // Append the value to each row
                  for (let i = 1; i < data.length; i++) {
                    data[i].push(this.state.userId);
                  }
                  //console.log("data", data);
                  const updatedSheet = XLSX.utils.aoa_to_sheet(data);

                  // Generate a new workbook
                  const updatedWorkbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(
                    updatedWorkbook,
                    updatedSheet,
                    wsname
                  );

                  // Download the updated Excel file
                  const newFile = XLSX.write(updatedWorkbook, {
                    bookType: "xlsx",
                    type: "array",
                  });

                  const filess: FileUploadDetailModel[] = [];
                  const fileData: FileUploadDetailModel = {
                    fileName: file.name,
                    totalRecords: data.length - 1,
                    status: "",
                    sizeInKB: file.size,
                    sizeInMB: 0,
                    ext: "",
                    file: newFile,
                    type: FileType.XLSX,
                  };

                  filess.push(fileData);

                  let isError = false;
                  let uploadMessage = "";

                  let isColumnMismatch = _.isEqual(
                    tableColumn.sort(),
                    data[0].sort()
                  )
                    ? false
                    : true;
                  const isFileAdded = this.state.files.find(
                    (value) => value.fileName === file.name
                  );

                  if (file.type !== FileType.XLSX) {
                    isError = true;
                    uploadMessage =
                      "File format is invalid. Please convert the file to excel (.xlsx) and resubmit the file.";
                  } else if (!this.state.tableNameList.includes(file.name)) {
                    isError = true;
                    uploadMessage =
                      "File is not allowed; ensure that it adheres to the SDM table. For more information, please refer to the Allowed Files Info.";
                  } else if (data.length - 1 > 50000) {
                    isError = true;
                    uploadMessage =
                      "Maximum number of rows exceeded in the submitted file. Maximum 50,000 rows/records per file is allowed.";
                  } else if (data.length < 2) {
                    isError = true;
                    uploadMessage =
                      "Submitted file is empty. Please upload a file with valid data and correct format.";
                  } else if (isColumnMismatch) {
                    isError = true;
                    uploadMessage =
                      "Column heading(s) does not match the template. Please refer to the excel template for the correct column headers and resubmit the file.";
                  } else if (isFileAdded) {
                    isError = true;
                    uploadMessage = "File is already selected for uploading.";
                  } else {
                    isError = false;
                    uploadMessage =
                      "File selected for upload, add more files or submit the selected file.";
                  }
                  if (isError) {
                    this.setState({ isError, uploadMessage }, () => {
                      this.setState({ isLoading: false });
                    });
                  } else {
                    this.setState(
                      {
                        files: this.state.files.concat(filess),
                        totalRecordNo: data.length - 1,
                        isSuccess: true,
                        uploadMessage,
                        disbldSubmit: false,
                      },
                      () => {
                        this.setState({ isLoading: false });
                      }
                    );
                  }
                } catch (error) {
                  console.log("error", error);
                }
              }
            };
            reader.readAsBinaryString(file);
          }
        } else {
          this.setState(
            {
              isError: true,
              uploadMessage:
                "File is not allowed; ensure that it adheres to the SDM table in accordance with the persona.",
            },
            () => {
              this.setState({ isLoading: false });
            }
          );
        }
      }
    } else {
      this.setState(
        {
          isError: true,
          uploadMessage:
            "File is not allowed; ensure that it adheres to the SDM table in accordance with the persona.",
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
    }
  };
  fileToBase64 = async (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handleSubmitClick = async () => {
    this.setState({ isLoading: true });
    try {
      if (this.state.files) {
        await Promise.all(
          this.state.files.map((file: any) => {
            return SDMTableServices.uploadSDMFile(file, file.totalRecords)
              .then((res) => {
                if (res?.isSuccess) {
                  this.setState({
                    isSubmitted: true,
                    submittedMsg: res.message,
                    disbldSubmit: true,
                    files: [],
                    isLoading: false,
                    isRefreshTableData: !this.state.isRefreshTableData,
                  });
                  return res.data;
                }
              })
              .catch((err) => {
                if (err) {
                  this.setState({
                    isSubmtdError: true,
                    submittedErrMsg: "Failed to upload file",
                    isLoading: false,
                  });
                }
              });
          })
        );
      }
    } catch (err) {
      this.setState({ isLoading: false });
      console.log("err", err);
    }
  };
}
export default withRouter(UploadFile);
