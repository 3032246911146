import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
type Props = {
  chartDataArr: { name: string; y: number; color: any }[];
  xAxisCategories: string[];
  maxExceptionNumber: number;
};

const ExceptionReportingChart: React.FC<Props> = (props: Props) => {
  let backendData = props.chartDataArr;
  // Sort data by highest y value
  backendData.sort((a, b) => b.y - a.y);
  const fixedGradientColors = [
    ["#8B83FB", "#EF4A5F"],
    ["#A066F0", "#5870F5"],
    ["#8C83FA", "#63CADC"],
    ["#F69A19", "#B183FA"],
    ["#39C390", "#877EFF"],
  ];
  backendData.forEach((dataPoint, index) => {
    dataPoint.color = {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, fixedGradientColors[index % fixedGradientColors.length][0]], // Start color
        [1, fixedGradientColors[index % fixedGradientColors.length][1]], // End color
      ],
    };
  });

  const chartData: Highcharts.Options = {
    chart: {
      type: "bar",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: props.xAxisCategories,

      lineWidth: 0,

      labels: {
        enabled: true,
        align: "left",
        x: 0,
        y: -15, // Position the labels above the bars

        style: {
          fontWeight: "bolder",
          margin: 240,
          width: 1200,
        },
      },
    },
    yAxis: {
      visible: false,
      max: props.maxExceptionNumber,
    },
    tooltip: {
      enabled: false,
    },
    colors: ["#4791FF", "#44BA7F", "#8C83FB", "#EF4A5F", "#F69A1A"],
    plotOptions: {
      bar: {
        stacking: "overlap",
        borderWidth: 1, // Remove bar borders
        borderRadius: 0, // Set the border radius to create rounded edges
        dataLabels: {
          enabled: true,
          inside: false,
          align: "right", // Align data labels to the right
          x: 1000, // Adjust the horizontal position of the data labels
          y: -21,
          style: {
            fontSize: "12px",
            fontWeight: "800",
          },
        },
        pointWidth: 20,
      },
    },
    legend: {
      enabled: false,
    },

    series: [
      {
        type: "bar",
        pointPadding: -10,
        groupPadding: -10,

        data: backendData,
      },
    ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartData} />
    </div>
  );
};

export default ExceptionReportingChart;
