import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";
import _, { isEqual } from "lodash";
import { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import NoRecords from "../NoRecords/NoRecords";
import PaginationNew from "../Pagination";

interface Props extends RouteComponentProps<any, any, any> {
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  totalRecordsCount: number | undefined;
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  isLoading?: boolean;
  data: any;
  isPaginationDisabled?: boolean;
  isPaginationReset?: boolean;
  isEmptyReset?: boolean;
  isPaginationRequire: boolean;
}

type State = {
  data: any;
};

class TransactionTable extends Component<Props, State> {
  state = {
    data: [],
  };
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data });
    }
  };
  componentDidMount = () => {
    this.setState({
      data: this.props.data,
    });
  };
  render() {
    const { headCellData } = this.props;
    const { data } = this.state;
    return (
      <Box pr={"16px"} pl={"16px"}>
        <TableContainer
          component={Paper}
          style={{ boxShadow: "none", marginTop: "15px" }}
        >
          <Box>
            {headCellData.length > 0 ? (
              <>
                <TableContainer
                  component={Paper}
                  style={{
                    boxShadow: "none",
                    height: "50vh",
                  }}
                >
                  {this.props.isLoading ? (
                    <Box
                      style={{
                        justifyContent: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                        margin: "20vh",
                      }}
                    >
                      <CircularProgress sx={{ color: "#d52b1e" }} />
                    </Box>
                  ) : (
                    <>
                      <Card
                        style={{
                          marginBottom: "10px",
                          background: "#CCD5DD",
                          boxShadow: "0px 3px 6px #00000029",
                          borderRadius: "6px",
                          opacity: 1,
                        }}
                      >
                        <CardContent>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={3.5}
                          >
                            {headCellData.map((el, cellIndex) => {
                              const keyName = el.key.includes("info")
                                ? el.key.replace("info", " info")
                                : el.key;
                              return (
                                <Grid
                                  item
                                  key={`${cellIndex}-headcell-data`}
                                  style={{
                                    maxWidth: "450px",
                                    maxHeight: "35px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: 600,
                                      textAlign: "center",
                                      width: "9.8rem",
                                    }}
                                  >
                                    {el.key.includes("param")
                                      ? null
                                      : _.startCase(keyName)}
                                  </Typography>
                                  <Grid
                                    item
                                    style={{
                                      paddingTop: "5px",
                                      fontWeight: "bold",
                                    }}
                                    xs={12}
                                  >
                                    {el.elementToLoad}{" "}
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </CardContent>
                      </Card>

                      {data.length > 0
                        ? data.map((record: any, index: number) => {
                            return (
                              <Card
                                key={`${index}-row-data`}
                                style={{
                                  marginBottom: "10px",
                                  background: "#CCD5DD",
                                  boxShadow: "0px 3px 6px #00000029",
                                  borderRadius: "6px",
                                  opacity: 1,
                                }}
                              >
                                <CardContent>
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    spacing={3}
                                  >
                                    {headCellData.map((el, cellIndex) => {
                                      // const keyName = el.key.includes("info")
                                      //   ? el.key.replace("info", " info")
                                      //   : el.key;
                                      return (
                                        <Grid
                                          item
                                          /*  xs={1.5} */
                                          key={`${cellIndex}-cell-data`}
                                          style={{
                                            wordWrap: "break-word",
                                            hyphens: "auto",
                                            wordBreak: "break-all",
                                            maxWidth: "450px",
                                            maxHeight: "120px",
                                          }}
                                        >
                                          {/* <Typography
                                            style={{
                                              fontWeight: 600,
                                              textAlign: "center",
                                            }}
                                          >
                                            {el.key.includes("param")
                                              ? null
                                              : _.startCase(keyName)}
                                          </Typography> */}
                                          <Grid
                                            item
                                            style={{
                                              paddingTop: "5px",
                                              fontWeight: "bold",
                                            }}
                                            xs={12}
                                          >
                                            {record[el?.key] ===
                                            "Item Found" ? (
                                              <Grid
                                                container
                                                width="9.8rem"
                                                style={{
                                                  background:
                                                    "#E5FFF2 0% 0% no-repeat padding-box",
                                                  border: "1px solid #3DA470",
                                                  borderRadius: "17px",
                                                  opacity: 1,
                                                }}
                                                ml={2}
                                              >
                                                <Grid
                                                  item
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                    size="lg"
                                                    style={{
                                                      color: "#3DA470",
                                                      fontSize: "14px",
                                                      marginRight: "8px",
                                                      marginLeft: "15px",
                                                    }}
                                                  />
                                                  <Typography>
                                                    Item Found
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            ) : record[el?.key] ===
                                              "Item Not Found" ? (
                                              <Grid
                                                container
                                                width="9.8rem"
                                                style={{
                                                  background:
                                                    "#FFEAE8 0% 0% no-repeat padding-box",
                                                  border: " 1px solid #EF3E32",
                                                  opacity: 1,
                                                  borderRadius: "17px",
                                                }}
                                                ml={2}
                                              >
                                                <Grid
                                                  item
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faClose}
                                                    size="lg"
                                                    style={{
                                                      color: "#EF3E32",
                                                      fontSize: "14px",
                                                      marginRight: "8px",
                                                      marginLeft: "15px",
                                                    }}
                                                  />
                                                  <Typography>
                                                    No Item Found
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            ) : record[el?.key] === "N.A." ? (
                                              <Grid
                                                container
                                                width="auto"
                                                style={{
                                                  background:
                                                    "#c2c1c1de 0% 0% no-repeat padding-box",
                                                  border: " 1px solid #8b8888",
                                                  opacity: 1,
                                                  borderRadius: "17px",
                                                }}
                                                ml={2}
                                              >
                                                <Grid
                                                  item
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <Typography
                                                    paddingLeft={2}
                                                    paddingRight={2}
                                                  >
                                                    N.A.
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            ) : (
                                              <Tooltip
                                                arrow
                                                title={record[el?.key]}
                                                disableHoverListener={
                                                  record[el?.key] &&
                                                  record[el?.key].toString()
                                                    .length < 15
                                                }
                                                componentsProps={{
                                                  tooltip: {
                                                    sx: {
                                                      bgcolor: "#F5F5F5",
                                                      padding: "5px",
                                                      fontSize: "15px",
                                                      boxShadow:
                                                        "0px 3px 6px #00000029",
                                                      color: "#000000",
                                                      "& .MuiTooltip-arrow": {
                                                        color: "#F5F5F5",
                                                      },
                                                    },
                                                  },
                                                }}
                                              >
                                                <Typography
                                                  style={{
                                                    fontSize: "15px",
                                                    fontWeight: 600,
                                                    maxWidth: "182px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                  }}
                                                >
                                                  {record[el?.key]}
                                                </Typography>
                                              </Tooltip>
                                            )}
                                            {el.elementToLoad}{" "}
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                </CardContent>
                              </Card>
                            );
                          })
                        : null}
                    </>
                  )}
                </TableContainer>
              </>
            ) : (
              <Box style={{ margin: "20vh" }}>
                <NoRecords />
              </Box>
            )}
          </Box>

          <PaginationNew
            isEmptyReset={this.props.isEmptyReset}
            isPaginationReset={undefined}
            isDisabled={this.props.isPaginationDisabled}
            onChangePage={this.props.onChangePage}
            onChangeRow={this.props.onChangeRow}
            totalRecordsCount={this.props.totalRecordsCount || 0}
          />
        </TableContainer>
      </Box>
    );
  }
}
export default withRouter(TransactionTable);
