import {
  faTimesCircle as CancelIcon,
  faTimes as CloseIcon,
  faChevronDown as ExpandMoreIcon,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, TextField } from "@mui/material";
import React from "react";

import SelectModel from "./SelectModel";

interface Props {
  disabled?: boolean;
  id?: string;
  label: string;
  selectedValues?: string[];
  values: SelectModel[];
  selected: SelectModel[];
  isRequired: boolean;
  onChange: (selected: SelectModel[], targetId?: string) => void;
}
interface State {}
class MultiAutoComplete extends React.Component<Props, State> {
  public static defaultProps = {
    disabled: false,

    isRequired: true,
  };

  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {};
  }

  render() {
    const { id, label, values, isRequired, selected, disabled } = this.props;

    const labelId = id ? id : label;

    let closeIconStyle: React.CSSProperties = {
      color: "#FFFFFF",
    };
    let arrowDropDownIconStyle: React.CSSProperties = { color: "#FFFFFF" };
    let cancelIconStyle: React.CSSProperties = { color: "#EE3A29" };
    return (
      <Autocomplete
      
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        size="small"
        multiple
        limitTags={1}
        id={labelId}
        options={values}
        getOptionLabel={(option) => option.text}
        value={selected}
        ChipProps={{
          style: { borderColor: "#EE3A29", backgroundColor: "#FFFFFF"  },
          deleteIcon: (
            <FontAwesomeIcon
              icon={CancelIcon}
              size="xs"
              style={cancelIconStyle}
            />
          ),
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={label}
            required={isRequired}
            variant="outlined"
            InputLabelProps={{
              style: { marginLeft: 5, maxWidth:"400px", overflowX:"scroll" },
            }}
          />
        )}
        onChange={this.handleChange}
        popupIcon={
          <FontAwesomeIcon
            icon={ExpandMoreIcon}
            size="xs"
            style={arrowDropDownIconStyle}
          />
        }
        clearIcon={
          <FontAwesomeIcon icon={CloseIcon} size="xs" style={closeIconStyle} />
        }
        slotProps={{ popper: { sx: { zIndex: "99999999999999!important" } } }}
      />
    );
  }

  handleChange = (
    event: React.ChangeEvent<{}>,
    selectedValues: SelectModel[]
  ) => {
    const { id } = this.props;
    const values = selectedValues.map((p) => p.value);

    this.setState({ values }, () => {
      this.props.onChange(selectedValues, id);
    });
  };
}

export default MultiAutoComplete;
