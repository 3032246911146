import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import {
  CSSObject,
  Card,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
  styled,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { isEqual } from "lodash";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RoleContext } from "../../../Contexts";
import "../Layout.css";
import MenuModel from "./MenuModel";
import ECSImg from "../../../Static/ECS.png";
import GTImg from "../../../Static/GT.png";
import MyLinksImg from "../../../Static/MyLinks.png";
import MyToolsImg from "../../../Static/MyTools.png";
import Utils from "../../../Common/Utils";
const env = Utils.getEnvVars();
interface Props extends RouteComponentProps<any, any, any> {
  onToggleDrawer?: (drawerState: boolean) => void;
  onTableToggleDrawer?: (tableDrawerState: boolean) => void;
  showMenu: boolean;
}
type State = {
  drawerOpen: boolean;
  open: boolean;
  menuSelected: string;
  setOpen: boolean;
  setOpenOtherApps: boolean;
  toolMenuSelected: string;
  otherLinkSelected: string;
};
//drawer js code starts here
const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

//drawer js code ends here

//DrawerMenu list
const DrawerMenuList: MenuModel[] = [
  {
    name: "Assignments",
    icon: <NotificationsNoneIcon />,
    path: "/assignments",
    roles: ["SUPER_USER", "PUBLICATION_LEAD", "MANAGEMENT_LEADERSHIP"],
  },
  {
    name: "SDM",
    icon: <NoteAltOutlinedIcon />,
    path: "/sdm",
    roles: [
      "SUPER_USER",
      "DATA_ANALYST",
      "GTITADMIN_SUPPORT",
      "NATC",
      "PUBLICATION_LEAD",
      "MANAGEMENT_LEADERSHIP",
    ],
  },
  {
    name: "GT Connect",
    icon: <img src={GTImg} alt="" />,
    path: env.apps.GT_Connect,
    roles: [
      "SUPER_USER",
      "DATA_ANALYST",
      "GTITADMIN_SUPPORT",
      "NATC",
      "PUBLICATION_LEAD",
      "MANAGEMENT_LEADERSHIP",
    ],
  },
  {
    name: "ECS",
    icon: <img src={ECSImg} alt="" />,
    path: "https://ecs.lilly.com/",
    roles: [
      "SUPER_USER",
      "DATA_ANALYST",
      "GTITADMIN_SUPPORT",
      "NATC",
      "PUBLICATION_LEAD",
      "MANAGEMENT_LEADERSHIP",
    ],
  },
  {
    name: "Dashboard",
    icon: <LeaderboardOutlinedIcon />,
    path: "/dashboard",
    roles: [
      "SUPER_USER",
      "DATA_ANALYST",
      "GTITADMIN_SUPPORT",
      "NATC",
      "PUBLICATION_LEAD",
      "MANAGEMENT_LEADERSHIP",
    ],
  },
  {
    name: "Audit History",
    icon: <DescriptionOutlinedIcon />,
    path: "/audithistory",
    roles: [
      "SUPER_USER",
      "DATA_ANALYST",
      "GTITADMIN_SUPPORT",
      "NATC",
      "PUBLICATION_LEAD",
      "MANAGEMENT_LEADERSHIP",
    ],
  },
  {
    name: "My Links",
    icon: <img src={MyLinksImg} alt="" />,
    path: "/mylinks",
    roles: [
      "SUPER_USER",
      "DATA_ANALYST",
      "GTITADMIN_SUPPORT",
      "NATC",
      "PUBLICATION_LEAD",
      "MANAGEMENT_LEADERSHIP",
    ],
  },
];
const ToolMenuList: MenuModel[] = [
  {
    name: "Explore",
    path: "/explore",
    roles: [
      "SUPER_USER",
      "DATA_ANALYST",
      "GTITADMIN_SUPPORT",
      "NATC",
      "PUBLICATION_LEAD",
      "MANAGEMENT_LEADERSHIP",
    ],
  },
  {
    name: "Missing transactions",
    path: "/missingtransactions",
    roles: [
      "SUPER_USER",
      "DATA_ANALYST",
      "GTITADMIN_SUPPORT",
      "NATC",
      "PUBLICATION_LEAD",
      "MANAGEMENT_LEADERSHIP",
    ],
  },
  {
    name: "Exception Reporting",
    path: "/exceptionreporting",
    roles: [
      "SUPER_USER",
      "DATA_ANALYST",
      "GTITADMIN_SUPPORT",
      "PUBLICATION_LEAD",
      "MANAGEMENT_LEADERSHIP",
    ],
  },
  {
    name: "Monitoring",
    path: "/monitoring",
    roles: ["SUPER_USER", "PUBLICATION_LEAD", "MANAGEMENT_LEADERSHIP"],
  },
  {
    name: "On - Demand Reporting",
    path: "/ondemandreporting",
    roles: ["SUPER_USER", "PUBLICATION_LEAD"],
  },

  {
    name: "Support Data",
    path: "/supportdata",
    roles: ["SUPER_USER", "PUBLICATION_LEAD", "MANAGEMENT_LEADERSHIP"],
  },
  // {
  //   name: "People Validation",
  //   path: "",
  //   roles: ["SUPER_USER"],
  // },
];

class DrawerMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      drawerOpen: false,
      open: false,
      menuSelected: "",
      setOpen: false,
      setOpenOtherApps: false,
      toolMenuSelected: "",
      otherLinkSelected: "",
    };
  }
  componentDidMount() {
    this.handleToolMenuSelected();
    this.handleDrawerMenuSelected();
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    this.handleDrawerMenuSelected();
    this.handleToolMenuSelected();
  }

  handleDrawerMenuSelected = () => {
    const callbackPath = localStorage.getItem("callbackPath");
    if (
      !isEqual(
        callbackPath,
        window.location.pathname === "/"
          ? "/dashboard"
          : window.location.pathname
      )
    ) {
      const menuSelected = DrawerMenuList.find(
        (el: any) =>
          el.path ===
          (window.location.pathname === "/"
            ? "/dashboard"
            : window.location.pathname)
      )?.name;
      if (!menuSelected && this.state.menuSelected !== "") {
        this.setState({ menuSelected: "" });
      } else if (menuSelected && menuSelected !== this.state.menuSelected) {
        this.setState({
          menuSelected,
          toolMenuSelected: "",
        });
        const path = DrawerMenuList.find((el) => el.name === menuSelected);
        if (path) this.props.history.push(path?.path);
      }
    } else {
      const menuSelected = DrawerMenuList.find(
        (el: any) => el.path === (callbackPath ? callbackPath : "/dashboard")
      )?.name;
      if (menuSelected && menuSelected !== this.state.menuSelected) {
        this.setState({
          menuSelected,
          toolMenuSelected: "",
        });
        const path = DrawerMenuList.find((el) => el.name === menuSelected);
        if (path) this.props.history.push(path?.path);
      }
    }
  };
  handleToolMenuSelected = () => {
    const callbackPath = localStorage.getItem("callbackPath");

    if (
      !isEqual(
        callbackPath,
        window.location.pathname === "/"
          ? "/dashboard"
          : window.location.pathname
      )
    ) {
      const menuSelected = ToolMenuList.find(
        (el: any) =>
          el.path ===
          (window.location.pathname === "/"
            ? "/dashboard"
            : window.location.pathname)
      )?.name;
      if (menuSelected && menuSelected !== this.state.toolMenuSelected) {
        this.setState({
          toolMenuSelected: menuSelected,
          menuSelected: "",
        });
        const path = ToolMenuList.find((el) => el.name === menuSelected);
        if (path) this.props.history.push(path?.path);
      }
    } else {
      const menuSelected = ToolMenuList.find(
        (el: any) => el.path === (callbackPath ? callbackPath : "/dashboard")
      )?.name;
      if (!menuSelected && this.state.toolMenuSelected !== "") {
        this.setState({ toolMenuSelected: "" });
      } else if (menuSelected && menuSelected !== this.state.toolMenuSelected) {
        this.setState({
          toolMenuSelected: menuSelected,
          menuSelected: "",
        });
        const path = ToolMenuList.find((el) => el.name === menuSelected);
        if (path) this.props.history.push(path?.path);
      }
    }
  };
  handleIconClick = (item: any) => {
    this.setState({
      menuSelected: item.name,
      toolMenuSelected: "",
    });
    if (item.name === "ECS" || item.name === "GT Connect") {
      const newTab = window.open(item.path, "_blank", "noopener,noreferrer");
      if (newTab) newTab.opener = null;
    } else {
      this.props.history.push({
        pathname: item.path,
      });
    }
  };
  handleToolClick = (item: any) => {
    this.setState({
      toolMenuSelected: item.name,
      menuSelected: "",
    });

    this.props.history.push({
      pathname: item.path,
    });
  };
  handleOtherLinkClick = (item: any) => {
    this.setState({
      otherLinkSelected: item.name,
      menuSelected: "",
      toolMenuSelected: "",
    });
  };
  handleMyToolsClick = () => {
    this.setState(
      (prevState) => ({
        setOpen: !prevState.setOpen,
        drawerOpen: true,
      }),

      () => {
        if (this.props.onToggleDrawer) {
          this.props.onToggleDrawer(this.state.drawerOpen);
        }
      }
    );
  };

  renderMenuItems = (userRoles: string[]) => {
    return DrawerMenuList.filter(
      (item) =>
        userRoles.includes("SUPER_USER") ||
        item.roles.some((val) => userRoles.includes(val))
    ).map((item, index) => (
      <Tooltip
        key={index}
        title={item.name}
        placement="right"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#F4F4F4",
              color: "#1D1D1D",
              padding: "5px",
              fontSize: "13px",
              "& .MuiTooltip-arrow": {
                color: "#F4F4F4",
              },
            },
          },
        }}
      >
        <ListItemButton
          key={index}
          onClick={
            window.location.pathname === "/lockedview"
              ? () => {}
              : () => this.handleIconClick(item)
          }
          style={{
            borderImage:
              item.name === this.state.menuSelected
                ? "linear-gradient(to bottom, #D42A1E,  #890756) 0 0 0 1"
                : "",
            borderWidth: item.name === this.state.menuSelected ? "5px" : "",
            borderStyle: item.name === this.state.menuSelected ? "solid" : "",
            background:
              item.name === this.state.menuSelected ? "#FFE7E5" : "#ffffff",
            cursor:
              window.location.pathname === "/lockedview"
                ? "default"
                : "pointer",
          }}
        >
          <ListItemIcon
            style={{
              color:
                item.name === this.state.menuSelected ? "#D22A20" : "#7E84A3",
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      </Tooltip>
    ));
  };

  renderToolMenuItems = (userRoles: string[]) => {
    return ToolMenuList.filter(
      (item) =>
        userRoles.includes("SUPER_USER") ||
        item.roles.some((val) => userRoles.includes(val))
    ).map((item, index) => (
      <ListItemButton
        key={index}
        style={{
          marginLeft: "-28px",
          borderImage:
            item.name === this.state.toolMenuSelected
              ? "linear-gradient(to bottom, #D42A1E,  #890756) 0 0 0 1"
              : "",
          borderWidth: item.name === this.state.toolMenuSelected ? "5px" : "",
          borderStyle: item.name === this.state.toolMenuSelected ? "solid" : "",
          background:
            item.name === this.state.toolMenuSelected ? "#FFE7E5" : "#F4F4F4",
        }}
        onClick={() => this.handleToolClick(item)}
      >
        <ListItemText primary={item.name} />
      </ListItemButton>
    ));
  };

  render() {
    const { drawerOpen, setOpen } = this.state;
    const { showMenu } = this.props;

    return (
      <RoleContext.Consumer>
        {({ userRoles }) => (
          <Grid container item height="100%">
            {showMenu ? (
              <Drawer
                variant="permanent"
                open={drawerOpen}
                className="MainNavBar"
              >
                <Card
                  style={{
                    minHeight: "100%",
                    borderRadius: "6px",
                  }}
                >
                  <Grid item>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <IconButton
                            color="inherit"
                            disabled={
                              window.location.pathname === "/lockedview"
                            }
                            aria-label="open drawer"
                            onClick={() => {
                              this.setState(
                                { drawerOpen: !this.state.drawerOpen },
                                () => {
                                  this.handleToolMenuSelected();
                                  this.handleDrawerMenuSelected();
                                  if (this.props.onToggleDrawer) {
                                    this.props.onToggleDrawer(
                                      this.state.drawerOpen
                                    );
                                    if (drawerOpen) {
                                      this.setState({
                                        setOpen: false,
                                      });
                                    }
                                  }
                                }
                              );
                            }}
                            edge="start"
                            sx={{
                              marginLeft: "0em",
                              ...(this.state.open && { display: "none" }),
                            }}
                          >
                            {drawerOpen ? (
                              <FormatIndentDecreaseIcon
                                style={{ color: "#D22A20", marginLeft: "-5px" }}
                              />
                            ) : (
                              <FormatIndentIncreaseIcon
                                style={{ color: "#D22A20", marginLeft: "-5px" }}
                              />
                            )}
                          </IconButton>
                        </ListItemIcon>
                      </ListItemButton>
                      <Tooltip
                        title="My Tools"
                        placement="right"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#F4F4F4",
                              color: "#1D1D1D",
                              padding: "5px",
                              fontSize: "13px",
                              "& .MuiTooltip-arrow": {
                                color: "#F4F4F4",
                              },
                            },
                          },
                        }}
                      >
                        <ListItemButton
                          onClick={
                            window.location.pathname === "/lockedview"
                              ? () => {}
                              : this.handleMyToolsClick
                          }
                          className="myToolsList"
                        >
                          <ListItemIcon>
                            <img src={MyToolsImg} alt="" />
                          </ListItemIcon>
                          <ListItemText primary="My Tools" />
                          {setOpen ? (
                            <ExpandLess
                              style={{
                                color: "#D52B1E",
                              }}
                            />
                          ) : (
                            <ExpandMore
                              style={{
                                color: "#D52B1E",
                              }}
                            />
                          )}
                        </ListItemButton>
                      </Tooltip>
                      <Collapse
                        in={setOpen}
                        timeout="auto"
                        unmountOnExit
                        className="MyTools"
                      >
                        {this.renderToolMenuItems(userRoles)}
                      </Collapse>

                      {this.renderMenuItems(userRoles)}
                    </List>
                  </Grid>
                </Card>
              </Drawer>
            ) : null}
          </Grid>
        )}
      </RoleContext.Consumer>
    );
  }
}

export default withRouter(DrawerMenu);
