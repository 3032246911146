import { Backdrop, CircularProgress, Box } from "@mui/material";
import React from "react";

interface Props {
    show: boolean;
    style?: React.CSSProperties;
}
interface State { }
class BackdropLoaderCircular extends React.Component<Props, State> {
    render() {
        const { show, style } = this.props;

        return (
            <Backdrop open={show} onClick={this.handleClose} style={{ ...{ zIndex: 99 }, ...style }}>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress sx={{color: '#d52b1e'}} />
                </Box>
            </Backdrop>
        );
    }

    handleClose = () => { }
}

export default BackdropLoaderCircular;