export enum FileType {
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  CSV = "text/csv",
}
export enum ImgFileType {
  PDF = "application/pdf",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  JPG = "image/jpeg",
  PNG = "image/png"
}

export enum EventType {
  ADD,
  REMOVE,
}

export enum CustomEventType {
  SuccessAlert,
  ErrorAlert,
  ClearAlert,
  LoadingStop,
  LoadingStart,
  ChangeTable,
  ChangeMonitorTable
}

export enum ValidationType {
  NONE,
  REQUIRED,
  RATE,
}
