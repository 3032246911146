import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PublishIcon from "@mui/icons-material/Publish";
import { Card, CircularProgress, Grid, InputLabel, Link } from "@mui/material";
import Box from "@mui/material/Box";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import GradientButton from "../../Components/Button/GradientButton";
import RedButton from "../../Components/Button/RedButton";
import CardContainer from "../../Components/Card/CardContainer";
import PageHeader from "../../Components/Text/PageHeader";
import TextInputField from "../../Components/TextInputField/TextInputField";
import { TransactionsDataModel } from "../../Models/TransactionsDataModel";
import BackgroundImg from "../../Static/Background_Image_Search.png";

import MultiStepSelect from "../../Components/Select/MultiStepSelect";
import SelectModel from "../../Components/Select/SelectModel";
import SnackbarAlert from "../../Components/SnackBarAlert/SnackbarAlert";
import TransactionDataLoader from "../../Components/Table/TransactionDataLoader";
import ExcelService from "../../Services/ExcelService";
import MissingTransactionService from "../../Services/MissingTransactionServices";
const MissingTransactionServices = new MissingTransactionService();
interface Props extends RouteComponentProps<any, any, any> {}

interface State {
  searchFieldsObj: any;
  searchResultContent: boolean;
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isTableLoading: boolean;
  tblData: any[];
  totalRecordsCount: number | undefined;
  selectedSourceValue: string;
  sourceMappingData: any;
  sourceWithFieldsData: any;
  searchFields: any;
  isPageLoading: boolean;
  isSearchLoading: boolean;
  openAlert: boolean;
  alertMsg: string;
}

class MissingTransactions extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      searchFieldsObj: {},
      searchResultContent: false,
      isPaginationDisabled: false,
      isPaginationReset: false,
      isTableLoading: false,
      tblData: [],
      totalRecordsCount: undefined,
      selectedSourceValue: "",
      sourceMappingData: {},
      sourceWithFieldsData: [],
      searchFields: [],
      isPageLoading: false,
      isSearchLoading: false,
      openAlert: false,
      alertMsg: "",
    };
  }
  async componentDidMount() {
    try {
      this.setState({ isPageLoading: true });
      const response = await MissingTransactionServices.getSourceMappingData();
      const dataArr = response.data.data.map((el) => {
        return {
          ...el,
          searchFields: el.searchFields.replace(/[{}"]/g, "").split(","),
        };
      });
      let result: any = {};

      dataArr.forEach((item) => {
        if (!result[item.sourceType]) {
          result[item.sourceType] = [];
        }
        let fieldExists = result[item.sourceType].some(
          (el: any) => el.text === item.sourceName
        );
        if (!fieldExists) {
          result[item.sourceType].push({
            text: item.sourceName,
            value: item.sourceName,
          });
        }
      });
      this.setState({
        sourceMappingData: result,
        sourceWithFieldsData: dataArr,
        isPageLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isPageLoading: false,
      });
    }
  }
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.trim() === "") {
      let newObj = this.state.searchFieldsObj;
      delete newObj[event.target.name];
      this.setState({
        searchFieldsObj: newObj,
      });
    } else {
      this.setState({
        searchFieldsObj: {
          ...this.state.searchFieldsObj,
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  isEmptyCheck = () => {
    const values = Object.values(this.state.searchFieldsObj);
    const arr = values.filter((el: any) => el.trim() === "");
    let isFieldMatch = ["Concur", "CVENT"].includes(
      this.state.selectedSourceValue
    )
      ? values.length === this.state.searchFields.length
      : values.length > 0;

    if (["Concur", "CVENT"].includes(this.state.selectedSourceValue)) {
      if (isFieldMatch && arr.length === 0 && this.state.selectedSourceValue) {
        return false;
      } else {
        return true;
      }
    } else {
      if (values.length > 0 && this.state.selectedSourceValue) {
        return false;
      } else {
        return true;
      }
    }
  };
  isResetEmptyCheck = () => {
    return (
      Object.values(this.state.searchFieldsObj).filter(
        (el: any) => el.length > 0
      ).length <= 0
    );
  };
  formReset = () => {
    this.setState({
      searchFieldsObj: {} as TransactionsDataModel,
      selectedSourceValue: "",
      searchFields: [],
    });
  };
  onClickButton = (action: "upload") => {
    switch (action) {
      case "upload":
        this.props.history.push({
          pathname: "/TransactionsFileUpload",
        });
        break;
    }
  };
  handleSourceTemplateDownload = async (event: any) => {
    const { sourceWithFieldsData, selectedSourceValue } = this.state;

    const sourceFields = sourceWithFieldsData.find(
      (ele: any) =>
        ele.sourceName.toLowerCase() === selectedSourceValue.toLowerCase()
    );

    ExcelService.CreateTemplate(selectedSourceValue, sourceFields.searchFields);
  };

  handleSelectSourceChange = (selected: SelectModel) => {
    const { sourceWithFieldsData } = this.state;
    let fieldsArr = sourceWithFieldsData.find(
      (el: any) => el.sourceName === selected.value
    ).searchFields;

    this.setState({
      selectedSourceValue: selected.value,
      searchFields: fieldsArr,
      searchFieldsObj: {},
      tblData: [],
    });
  };
  convertObj = () => {
    const { searchFieldsObj, selectedSourceValue, searchFields } = this.state;
    const obj: any = {};
    searchFields.map((el: any) => (obj[el] = searchFieldsObj[el]));
    const keysArr = Object.keys(obj);
    let values = Object.values(obj);
    const newObj: any = {};
    for (let i = 0; i < keysArr.length; i++) {
      newObj[`param${i}`] = values[i];
    }
    return { ...newObj, sourcename: selectedSourceValue };
  };
  onSearchClick = async () => {
    try {
      this.setState({ isSearchLoading: true, isTableLoading: true });
      if (this.convertObj()) {
        const payLoad = {
          missing_transaction_report_payload: [this.convertObj()],
        };
        const response =
          await MissingTransactionServices.getMissingTransactionReport(payLoad);
        if (JSON.parse(response.result)) {
          this.setState({
            tblData: JSON.parse(response.result),
            searchResultContent: true,
            isSearchLoading: false,
            isTableLoading: false,
          });
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({
        isSearchLoading: false,
        alertMsg: "Something went wrong.",
        openAlert: true,
        isTableLoading: false,
      });
    }
  };
  render() {
    const {
      searchFieldsObj,
      searchResultContent,
      isPaginationDisabled,
      isPaginationReset,
      isTableLoading,
      totalRecordsCount,
      sourceMappingData,
      searchFields,
      selectedSourceValue,
      isPageLoading,
      isSearchLoading,
      openAlert,
      alertMsg,
      tblData,
    } = this.state;
    return (
      <>
        <SnackbarAlert
          open={openAlert}
          alertType="error"
          message={alertMsg}
          onClose={() => {
            this.setState({ openAlert: false });
          }}
        />
        {isPageLoading ? (
          <Box
            style={{
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              margin: "30vh",
            }}
          >
            <CircularProgress sx={{ color: "#d52b1e" }} />
          </Box>
        ) : (
          <>
            {" "}
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "25px 5px",
              }}
            >
              <PageHeader
                label="Missing Transactions"
                style={{
                  fontSize: "22px",
                }}
              />
              <Box style={{ display: "flex", gap: "1em" }}>
                <GradientButton
                  id="button"
                  label="Upload Files"
                  onClick={() => {
                    this.onClickButton("upload");
                  }}
                  startIcon={<PublishIcon />}
                />
              </Box>
            </Box>
            {!searchResultContent ? (
              <Card
                style={{
                  background:
                    "transparent url(" + BackgroundImg + ") 0% 0% padding-box",
                  opacity: 1,
                  backgroundSize: "cover",
                  backgroundPosition: "right",
                  border: "none",
                  borderRadius: "8px",
                  height: "75vh",
                }}
              >
                <Box mt={25} className="MissingTransactions">
                  <Grid
                    container
                    mt={3}
                    p={3}
                    mb={3}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Grid item xs={2.5} mb={3} mr={2} className="DropDownLabel">
                      <InputLabel
                        sx={{
                          visibility: selectedSourceValue
                            ? "visible"
                            : "hidden",
                        }}
                      >
                        Select Source
                      </InputLabel>
                      <MultiStepSelect
                        className="HomeScreenNatcPopover"
                        id="sources"
                        name="sources"
                        size="small"
                        customOptions="Select Source"
                        value={selectedSourceValue}
                        values={sourceMappingData.us}
                        multiStepValue="NATC"
                        multiStepOptionValues={sourceMappingData.natc}
                        onChange={this.handleSelectSourceChange}
                      />
                    </Grid>

                    {searchFields.map((el: any, ind: number) => {
                      return (
                        <Grid item xs={3} mb={3} key={ind} mr={2}>
                          <InputLabel
                            sx={{
                              visibility: searchFieldsObj[el]
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {`Enter ${el}`}
                          </InputLabel>
                          <TextInputField
                            isRequired={true}
                            placeholder={`Enter ${el}`}
                            name={el}
                            id={el}
                            value={searchFieldsObj[el]}
                            onChange={this.onInputChange}
                          />
                        </Grid>
                      );
                    })}

                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item mt={2.9}>
                        <RedButton
                          disabled={this.isResetEmptyCheck() || isSearchLoading}
                          label="Reset"
                          onClick={this.formReset}
                        />
                      </Grid>
                      <Grid item mt={2.9}>
                        <GradientButton
                          isButtonLoad={isSearchLoading}
                          disabled={this.isEmptyCheck()}
                          label="Search"
                          onClick={this.onSearchClick}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            ) : (
              <Box>
                <CardContainer>
                  <Grid container p={2} flexDirection="row">
                    <Grid container item>
                      <Grid xs={8} item flexDirection="row">
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="flex-start"
                        >
                          <Grid item xs={3} mr={2} className="WhiteIcon">
                            <InputLabel
                              sx={{
                                visibility: selectedSourceValue
                                  ? "visible"
                                  : "hidden",
                              }}
                            >
                              Select Source
                            </InputLabel>
                            <MultiStepSelect
                              className="SearchScreenNatcPopover"
                              id="sources"
                              name="sources"
                              size="small"
                              customOptions="Select Source"
                              value={this.state.selectedSourceValue}
                              values={sourceMappingData.us}
                              multiStepValue="NATC"
                              multiStepOptionValues={sourceMappingData.natc}
                              onChange={this.handleSelectSourceChange}
                            />
                          </Grid>
                          {searchFields.map((el: any, ind: number) => {
                            return (
                              <Grid item xs={3} mb={3} key={ind} mr={2}>
                                <InputLabel
                                  sx={{
                                    visibility: searchFieldsObj[el]
                                      ? "visible"
                                      : "hidden",
                                  }}
                                >
                                  {`Enter ${el}`}
                                </InputLabel>
                                <TextInputField
                                  isRequired={true}
                                  placeholder={`Enter ${el}`}
                                  name={el}
                                  id={el}
                                  value={searchFieldsObj[el]}
                                  onChange={this.onInputChange}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Grid item xs={6} mr={2} mt={2}>
                            <Link
                              style={{
                                color: "#0577A3",
                                cursor: "pointer",
                                textDecoration: "none",
                                marginTop: "10px",
                                display: "block",
                              }}
                              onClick={this.handleSourceTemplateDownload}
                            >
                              <ArrowDownwardIcon
                                style={{
                                  marginTop: "1px",
                                  float: "left",
                                  marginRight: "3px",
                                }}
                              />
                              Download Template
                            </Link>
                          </Grid>
                          <Grid item mr={2} mt={2}>
                            <RedButton
                              disabled={
                                this.isResetEmptyCheck() || isSearchLoading
                              }
                              label="Reset"
                              onClick={this.formReset}
                            />
                          </Grid>
                          <Grid item mt={2}>
                            <GradientButton
                              isButtonLoad={isSearchLoading}
                              disabled={this.isEmptyCheck()}
                              label="Search"
                              onClick={this.onSearchClick}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <hr
                    style={{
                      border: "0.5px solid #D8D8D8",
                    }}
                  />
                  <TransactionDataLoader
                    isPaginationRequire={false}
                    isPaginationReset={isPaginationReset}
                    isPaginationDisabled={isPaginationDisabled}
                    isLoading={isTableLoading}
                    tableData={tblData}
                    totalRecordsCount={totalRecordsCount}
                    onChangeRow={() => {}}
                    onChangePage={() => {}}
                  />
                </CardContainer>
              </Box>
            )}
          </>
        )}
      </>
    );
  }
}
export default withRouter(MissingTransactions);
