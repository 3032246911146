import React, { Component, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CardContentTable from "./CardContentTable";
import { isEqual } from "lodash";

interface Props extends RouteComponentProps<any, any, any> {
  tableData: any;
  totalRecordsCount?: number;
  onChangeRow: (row: number) => void;
  onChangePage: (page: number) => void;
  onClickLink?: (fieldClicked: string, record: any) => void;
  handleToolbarCancel?: () => void;

  /* columnDetails: GetColumnDetailsResponseModel[]; */
  isLoading?: boolean;
  fieldsToColor?: {
    key: string;
    values: { [index: string]: string };
  }[];
  isPaginationDisabled: boolean;
  isPaginationReset: boolean;
  isEmptyReset?: boolean;
}

interface State  {
  headCellData: {
    key: string;
    label: string;
    linkTo: string | null;
    elementToLoad?: ReactNode;
  }[];
  totalCount: number;
};

 class CardContentDataLoader extends Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      headCellData: [],
      totalCount: 0,
    };
  }
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      this.props.tableData &&
      !isEqual(prevProps.tableData[0], this.props.tableData[0])
    ) {
      this.changeHeadCellData();
    }
   
  };

  componentDidMount = () => {
    this.changeHeadCellData();
  };

  changeHeadCellData = () => {
    if (this.props.tableData.length) {
      const headerData: any = Object.keys(this.props.tableData[0]).map((k) => {
        const shouldBeLinked = k === "id";
        return {
          key: k,
          label: k,
          linkTo: shouldBeLinked ? k : null,
        };
      });

      this.setState({ headCellData: headerData });
    } else {
      this.setState({ headCellData: [] });
    }
  };

  onChangePage = (page: number) => {
    this.props.onChangePage(page);
  };

  onChangeRow = (row: number) => {
    this.props.onChangeRow(row);
  };
  render() {
    const { headCellData } = this.state;
    return (
      <CardContentTable
        isEmptyReset={this.props.isEmptyReset}
        totalRecordsCount={this.props.totalRecordsCount}
        isPaginationReset={this.props.isPaginationReset}
        isPaginationDisabled={this.props.isPaginationDisabled}
        onChangeRow={this.onChangeRow}
        onChangePage={this.onChangePage}
        headCellData={headCellData}
        data={this.props.tableData}
        isLoading={this.props.isLoading}
      />
    );
  }
}
export default withRouter(CardContentDataLoader)
