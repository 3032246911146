import { Grid, MenuItem, Popover, Typography } from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { Component } from "react";
import GradientButton from "../Button/GradientButton";
import IconButton from "../Button/IconButton";
import RedOutlinedButton from "../Button/RedOutlinedButton";
import ModalDialog from "../Modal/ModelDialog";
type Props = {
  onCommentClick: (commentId: number) => void;
  onEditClick: (editId: number) => void;
  onCloneClick: (cloneId: number) => void;
  handleDialogClick: (delDialogId: number) => void;
  handleDelete: () => void;
  commentId: number;
  editId: number;
  cloneId: number;
  delDialogId: number;
  disabled: boolean;
  isButtonLoad: boolean;
};

type State = {
  showDeleteDialoge: boolean;
  addComment: boolean;
};

export default class ActionMenuDropdown extends Component<Props, State> {
  state = {
    showDeleteDialoge: false,
    addComment: false,
  };

  render() {
    const { disabled } = this.props;
    return (
      <>
        {disabled ? (
          <IconButton
            IconType={"ActionMenu"}
            isDisabled={true}
            onClick={() => {}}
          />
        ) : (
          <>
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  <IconButton
                    IconType={"ActionMenu"}
                    {...bindTrigger(popupState)}
                  />

                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    style={{ left: "-25px", zIndex: 999999 }}
                  >
                    <MenuItem
                      onClick={() => this.props.onEditClick(this.props.editId)}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.setState({ showDeleteDialoge: true });
                      }}
                    >
                      Delete
                    </MenuItem>
                   
                    <MenuItem
                      onClick={() =>
                        this.props.onCloneClick(this.props.cloneId)
                      }
                    >
                      Clone
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.props.onCommentClick(this.props.commentId)
                      }
                    >
                      Add Rationale
                    </MenuItem>
                  </Popover>
                </div>
              )}
            </PopupState>
            <ModalDialog
              isOpen={this.state.showDeleteDialoge}
              title="Delete Record"
              onClose={() => this.setState({ showDeleteDialoge: false })}
              dialogWidth="sm"
            >
              <Typography>Are you sure you want to delete ?</Typography>
              <Grid item container mt={2.5}>
                <Grid item>
                  <RedOutlinedButton
                    label="No"
                    onClick={() => this.setState({ showDeleteDialoge: false })}
                  />
                </Grid>
                <Grid item ml={1.5}>
                  <GradientButton
                    isButtonLoad={this.props.isButtonLoad}
                    label="Yes"
                    onClick={this.props.handleDelete}
                  />
                </Grid>
              </Grid>
            </ModalDialog>
          </>
        )}
      </>
    );
  }
}
