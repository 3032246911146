import { URIs } from "../Common/Constants";
import { SourceMappingDataModel } from "../Models/MissingTransactionModel";

import GenericAPIService from "./GenericAPIService";

const { SOURCE_MAPPING,MISSING_TRANSACTION_REPORT } = URIs;
class MissingTransactionService extends GenericAPIService {
  async getSourceMappingData() {
    const { data } = await this.getAsync<{ data: { data: SourceMappingDataModel[] } }>(
      SOURCE_MAPPING
    );
    return data;
  }
  async getMissingTransactionReport(body: any) {
    const { data } = await this.postAsync<{ result: any }>(
      MISSING_TRANSACTION_REPORT,
      body
    );
    return data;
  }
}

export default MissingTransactionService;
