import { URIs } from "../Common/Constants";

import GenericAPIService from "./GenericAPIService";

const { MONITORING_DATA, MONITORING_HEADER } = URIs;
class MonitoringService extends GenericAPIService {

  async getTableData(body: any) {
    const { data } = await this.postAsync<{ result: any }>(
        MONITORING_DATA,
      body
    );
    return data;
  }
  async getHeaderData(payload:any){
      const {data}=await this.postAsync<{result:any}>(MONITORING_HEADER,payload);
      return data
  }
}

export default MonitoringService;
