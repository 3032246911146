import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { Component } from "react";
import Utils from "../../Common/Utils";
import moment from "moment";
import { ExceptionByCountryResponse } from "../../Models/DashboardModel";

type Props = {
  chartDataCountryArr: ExceptionByCountryResponse[];
  endDate: string | null | undefined;
  startDate: string | null | undefined;
};
interface State {
  chartData: Highcharts.Options;
}
class ChartPerCountry extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      chartData: {},
    };
  }
  generateCategories = (dates: { startDate: string; endDate: string }[]) => {
    return dates.map((el) => {
      if (el.startDate === el.endDate) {
        return `${moment(el.startDate, "YYYY-MM-DD", true).format("DD MMM")}`;
      }
      return `${moment(el.startDate, "YYYY-MM-DD", true).format(
        "DD MMM"
      )}-${moment(el.endDate, "YYYY-MM-DD", true).format("DD MMM")}`;
    });
  };
  generateCountrySeries = (
    data: ExceptionByCountryResponse[],
    dates: { startDate: string; endDate: string }[]
  ) => {
    const series: Highcharts.SeriesOptionsType[] = [
      {
        type: "column",
        name: "Total",
        color: "#4791FF",
        data: dates.map((el) => {
          if (el.startDate && el.endDate) {
            const filtered = data.filter((d) => {
              return (
                d.exception_date >= el.startDate &&
                d.exception_date <= el.endDate
              );
            });
            const cnt = filtered.reduce((a, b) => {
              return Number(b.totalcount) + a;
            }, 0);
            return cnt || 0;
          }
          return 0;
        }),
      },
      {
        type: "column",
        color: "#45BA7F",
        name: "Completed",
        data: dates.map((el) => {
          if (el.startDate && el.endDate) {
            const filtered = data.filter((d) => {
              return (
                d.exception_date >= el.startDate &&
                d.exception_date <= el.endDate
              );
            });
            const cnt = filtered.reduce((a, b) => {
              return Number(b.completed) + a;
            }, 0);
            return cnt;
          }
          return 0;
        }),
      },
      {
        type: "column",
        color: "#F69A19",
        name: "Todo",
        data: dates.map((el) => {
          if (el.startDate && el.endDate) {
            const filtered = data.filter((d) => {
              return (
                d.exception_date >= el.startDate &&
                d.exception_date <= el.endDate
              );
            });
            const cnt = filtered.reduce((a, b) => {
              return Number(b.inprogress) + a;
            }, 0);
            return cnt;
          }
          return 0;
        }),
      },
    ];
    return series;
  };
  render() {
    var countryName = this.props.chartDataCountryArr[0].country;
    const dates = Utils.dateFunction({
      startDate: this.props.startDate!,
      endDate: moment(this.props.endDate!).format("YYYY-MM-DD"),
    });
    const chartData: Highcharts.Options = {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#2E2F30",
        formatter: function () {
          return `
          <div  style="background-color: #2E2F30; color: #FFFFFF; padding: 10px; border-radius: 5px;">
          <b>${this.x} </b><br><br>
          <span style=color:#BFBFBF; padding-bottom:10px>Country Cd :</span> <span style=padding-left:10px>${countryName}</span><br>
                <span style=color:#BFBFBF>No. Of Records :</span> <span style=padding-left:10px>${this.y}</span><br>
                
               </div>
                `;
        },
      },
      xAxis: {
        categories: this.generateCategories(dates),

        labels: {
          enabled: true,
          y: 16, // Position the labels above the bars
        },
        tickmarkPlacement: "on",

        min: 0, // Assuming day 1 as the start
      },
      yAxis: {
        gridLineWidth: 0,
        type: "logarithmic", //Logarithmic axes can be useful when dealing with data with spikes or large value gaps, as they allow variance in the smaller values to remain visible(y axis range)
        title: {
          text: "",
        },
        min: 1, //Set the minimum value to 1 to avoid negative values on a logarithmic scale
        startOnTick: true,
      },
      legend: {
        layout: "horizontal",
        align: "left",
        verticalAlign: "top",
        itemMarginTop: -15,
        itemMarginBottom: 10,
        x: -10,
      },
      plotOptions: {
        column: {
          pointWidth: 15,
        },
      },
      series: this.generateCountrySeries(this.props.chartDataCountryArr, dates),
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartData} />
      </div>
    );
  }
}

export default ChartPerCountry;
