import { MenuItem, Popover } from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import IconButton from "../../Button/IconButton";

interface Props extends RouteComponentProps<any, any, any> {
  onEditClick: (editId: number) => void;
  editId: number;
  onDeleteClick: () => void;
  disabled: boolean;
}

interface State {}

class OtherAppsActionMenu extends Component<Props, State> {
  render() {
    return (
      <>
        {this.props.disabled ? (
          <IconButton
            IconType={"OtherAppsActionMenu"}
            isDisabled={true}
            onClick={() => {}}
          />
        ) : (
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (
              <div>
                <IconButton
                  IconType={"OtherAppsActionMenu"}
                  {...bindTrigger(popupState)}
                />

                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      width: "140px",
                    },
                  }}
                  style={{
                    left: "-15px",
                    zIndex: 999999,
                  }}
                >
                  <MenuItem
                    sx={{
                      "&:hover": {
                        background: "#EEEEEE",
                        color: "#0075A2",
                      },
                    }}
                    onClick={() => this.props.onEditClick(this.props.editId)}
                  >
                    Edit
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => this.props.onDeleteClick()}
                    sx={{
                      "&:hover": {
                        background: "#EEEEEE",
                        color: "#0075A2",
                      },
                    }}
                  >
                    Delete
                  </MenuItem> */}
                </Popover>
              </div>
            )}
          </PopupState>
        )}
      </>
    );
  }
}
export default withRouter(OtherAppsActionMenu);
